
/**
 * 多语言配置
 */
import { COMMON_LABELS } from '../js/label_config_common';
export default {
  name: 'saleArea',
  props: {
    saleAreaData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    lang: {
      type: String,
      default: 'zh-cn',
    },
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return COMMON_LABELS[this.lang || 'zh-cn'];
    },
  },
  methods: {
    closedig() {
      this.$emit('closesale');
    },
  },
};
