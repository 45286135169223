
import BasicScrollTable from './BasicScrollTable.vue';
export default {
  name: 'TopBoard',
  components: { BasicScrollTable },
  props: {
    /**
     * TOP榜数据
     */
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      choseDate: [], // 选择的日期时间
      category: [], // 选择的分类数据
      // 分销商采购额TOP榜
      userTableFont: {
        topTitle: '分销商采购额',
        tipContent: '统计时间周期内，分销商所有订单含税额度总和TOP10榜单',
        url: '',
        titleLeft: '分销商名称',
        titleRight: '采购额(￥)',
        titleLeftWidthClass: 'width252',
        titleRightWidthClass: 'width120',
        hasPic: false,
      },
      // 商品销售额TOP榜
      volumeTableFont: {
        topTitle: '商品销售额',
        tipContent: '统计时间周期内，商品累计销售额TOP10榜单',
        url: '',
        titleLeft: '商品信息',
        titleRight: '销售额(￥)',
        titleLeftWidthClass: 'width282',
        titleRightWidthClass: 'width90',
        hasPic: true,
      },
      // 商品销量TOP榜
      numberTableFont: {
        topTitle: '商品销量',
        tipContent: '统计时间周期内，商品累计销量TOP10榜单',
        url: '',
        titleLeft: '商品信息',
        titleRight: '销量',
        titleLeftWidthClass: 'width282',
        titleRightWidthClass: 'width90',
        hasPic: true,
      },
    };
  },
  mounted() {},
  methods: {
    // 传出需要搜索的条件
    searchTopList() {
      let options = {};
      if (this.choseDate && this.choseDate.length != 0) {
        options.start_time = this.choseDate[0];
        options.end_time = this.choseDate[1];
      }

      switch (this.category.length) {
        case 1:
          options.cat1_id = this.category[0];
          break;
        case 2:
          options.cat1_id = this.category[0];
          options.cat2_id = this.category[1];
          break;
        case 3:
          options.cat1_id = this.category[0];
          options.cat2_id = this.category[1];
          options.cat3_id = this.category[2];
          break;
        default:
          break;
      }
      // console.log(options);
      this.$emit('search-list', options);
    },
  },
};
