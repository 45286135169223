/**
 * 中英文标签配置
 * DetailInfoMain.vue
 */
// hello world
export const INFO_MAIN_LABELS = {
  'zh-cn': {
    'goods-intro': '商品介绍',
    'packaging-spec': '包装和规格',
    'packaging-info': '包装信息',
    'spec-info': '规格信息',
    reviews: '商品评价',
    'distributor-info': '分销商专属信息',
    'buy-now': '立即购买',
    publish: '发布',
    'basic-info': '基本信息',
    'detailed-desc': '详情描述',
  },
  en: {
    'goods-intro': 'Goods Introduction',
    'packaging-spec': 'Packaging&Specifications',
    'packaging-info': 'Basic Information',
    'spec-info': 'Specification Information',
    reviews: 'Reviews',
    'distributor-info': 'Distributor exclusive information',
    'buy-now': 'Buy now',
    publish: 'Publish',
    'basic-info': 'Basic Information',
    'detailed-desc': 'Detailed Description',
  },
};

export const BASIC_INFO_LABELS = {
  'zh-cn': {
    'item-id': '商品编号',
    title: '商品名称',
    weight: '商品重量',
    size: '商品尺寸',
    authentication_name: '认证',
    ccc_certificate: 'CCC证书编号',
    brand_name: '品牌',
    is_battery: '是否带电',
    charged: '是',
    'not-charged': '否',
    goods_states: '物体形态',
    texture_name: '材质',
    fragile_level: '易碎等级',
    origin_place_text: '产地',
    defects_liability_period: '保修时限',
    goods_version: '版本',
    upgrade_cycle: '预计升级周期',
    saleable_area: '可售区域',
    no_saleable_area: '禁售区域',
    saleable_platform: '可售平台',
    no_saleable_platform: '禁售平台',
  },
  en: {
    'item-id': 'Goods ID',
    title: 'Goods name',
    weight: 'Goods Weight',
    size: 'Goods Size',
    authentication_name: 'Certification',
    ccc_certificate: 'CCC number',
    brand_name: 'Brand',
    is_battery: 'Charged or Not',
    charged: 'Charged',
    'not-charged': 'Not',
    goods_states: 'Object Morphology',
    texture_name: 'Material quality',
    fragile_level: 'Fragile goods',
    origin_place_text: 'Place of production',
    defects_liability_period: 'Warranty Period',
    goods_version: 'Version Number',
    upgrade_cycle: 'Expected upgrade cycle',
    saleable_area: 'Sellable Area',
    no_saleable_area: 'Prohibited sales area',
    saleable_platform: 'Sellable platform',
    no_saleable_platform: 'Prohibition platform',
  },
};

// 包装与规格
export const PACK_INFO_LABELS = {
  'zh-cn': {
    packing_size: '包装尺寸',
    packing_weight: '包装重量',
    measurement_unit: '最小计量单位',
    sale_method: '售卖方式',
    texture_name: '材质',
    fragile_level: '易碎等级',
    goods_feature: '商品特性',
    goods_states: '物体形态',
    good_pack_mode: '商品包装方式',
    pack_logistics: '运输包装方式',
    pack_logistics_img: '运输包装图片',
  },
  en: {
    packing_size: 'Packaging information',
    packing_weight: 'Package weight',
    measurement_unit: 'Minimum unit',
    sale_method: 'Saleing way',
    texture_name: 'Material quality',
    fragile_level: 'Fragile level',
    goods_feature: 'Goods features',
    goods_states: 'Object morphology',
    good_pack_mode: 'Goods packing',
    pack_logistics: 'Goods transportation',
    pack_logistics_img: 'Transportation packaging pictures',
  },
};

export const SPECIAL_INFO_LABELS = {
  'zh-cn': {
    stock_quantity: '批发数量',
    stock_quantity_col_width: '120',
    discount_price: '批发价(￥)',
    discount_price_col_width: '106',
    insurance_area: '商品险',
    measurement_unit: '最小计量单位',
    sale_method: '售卖方式',
    good_pack_mode: '商品包装方式',
    suggest_price: '建议零售价',
    price_rise_type: '价格稳定截止日期',
    salePolicy: '销售政策',
    stockPolicy: '批发政策',
    'not-support-stockup': '不支持批发',
    sample_policy: '样品政策',
    packing_weight: '包装重量',
    freight_calculation_rules: '运费计算规则',
    packing_size: '包装尺寸',
    volume_weight: '体积重',
    price_weight_ratio: '价重积比',
    price_weight_ratio_tip: '到仓商品价￥：物流重量KG：体积重KG',
    fragile_level: '易碎等级',
    texture_name: '材质',
    requirements: '仓储要求',
    is_battery: '是否含电',
    charged: '是',
    'not-charged': '否',
    product_qualification_rate: '承诺合格率',
    actual_pass_rate: '实际合格率',
    in_time_send_ratio: '发货及时率',
    return_ratio: '退货率',
    manufacture_time: '生产日期',
    origin_place_text: '产地',
    strn: 'EPR证书',
    eu_name: '欧盟代理人',
    customs_bn: '海关编码',
    declare_name: '申报名称(中文)',
    declare_cname: '申报名称(英文)',
    market_time: '上市时间',
    expected_delisting: '预计退市时间',
    goods_version: '版本',
    upgrade_cycle: '预计升级周期',
    upgrade_cycle_tip: '预计升级周期基于上市时间进行累加',
  },
  en: {
    stock_quantity: 'Wholesale quantity',
    stock_quantity_col_width: '172',
    discount_price: 'Wholesale price($)',
    discount_price_col_width: '168',
    insurance_area: 'Goods insurance area',
    measurement_unit: 'Minimum unit',
    sale_method: 'Saleing way',
    good_pack_mode: 'Goods packing',
    suggest_price: 'MSRP',
    price_rise_type: 'Price stability deadline',
    salePolicy: 'Sale policy',
    stockPolicy: 'Wholesale',
    'not-support-stockup': 'Wholesale not supported',
    sample_policy: 'Sample Policy',
    packing_weight: 'Package weight',
    freight_calculation_rules: 'Freight calculation rules',
    packing_size: 'Package size',
    volume_weight: 'Volume weight',
    price_weight_ratio: 'The ratio of price, weight, and volume',
    price_weight_ratio_tip:
      'Price of goods upon arrival ￥: Logistics weight KG: Volume weight KG',
    fragile_level: 'Fragile level',
    texture_name: 'Material quality',
    requirements: 'Storage requirements',
    is_battery: 'Charged or not',
    charged: 'Charged',
    'not-charged': 'Not',
    product_qualification_rate: 'Committed pass rate',
    actual_pass_rate: 'Actual pass rate',
    in_time_send_ratio: 'Timely delivery rate',
    return_ratio: 'Return rate',
    manufacture_time: 'Date of manufacture',
    origin_place_text: 'Place of production',
    strn: 'EPR certification',
    eu_name: 'EU agent',
    customs_bn: 'HS code',
    declare_name: 'Declaration name(Chinese)',
    declare_cname: 'Declaration name(English)',
    market_time: 'Time to market',
    expected_delisting: 'Expected delisting time',
    goods_version: 'Goods Version',
    upgrade_cycle: 'Expected upgrade cycle',
    upgrade_cycle_tip:
      'The expected upgrade cycle is accumulated based on the time to market',
  },
};
