
export default {
  name: 'SkuCompareItem',
  props: {
    // 商品信息，要求的数据结构见 default
    skuInfo: {
      type: Object,
      default: () => {
        // return {
        //   sku_id: '',
        //   item_id: '', // 商品id
        //   title: '', // 商品标题
        //   price: '', // 商品价格（有优惠价格就用优惠价格，无则用价格）
        //   imgUrl: '', // 图片（有sku图片就用sku图片，无则用商品主图）
        //   mainImgUrl: '', // 商品主图（用于跳转商品详情）
        // };
      },
    },
    // 当前商品是否是空对象
    isEmpty: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    // 商品标题
    intro() {
      if (this.skuInfo?.title) {
        return this.skuInfo.title;
      }
      return '您还可以继续添加';
    },
    offerPrice() {
      if (this.skuInfo.price && !isNaN(Number(this.skuInfo.price))) {
        return '¥' + this.skuInfo.price;
      } else {
        return '';
      }
    },
  },
  methods: {
    handleClickDelete(skuId) {
      this.$emit('delete', skuId);
    },
    // 跳转商品详情
    toShopDetail() {
      if (this.skuInfo.item_id) {
        let id = this.skuInfo.item_id;
        let imgUrl = this.skuInfo.mainImgUrl;
        let skuImgUrl = this.skuInfo.imgUrl;
        this.toCachedShopdetail(id, imgUrl, skuImgUrl, '_blank');
      }
    },
  },
};
