
import SaleArea from './SaleArea.vue';
import { BLOCK_LABELS } from '../js/label_config_common';
export default {
  components: {
    SaleArea,
  },
  name: 'MulticolDataBlock',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 多列布局的列数
    columnNum: {
      type: Number,
      default: 1,
    },
    // 多列布局中，列与列的间隔
    columnGap: {
      type: String,
      default: 'normal',
    },
    width: {
      type: String,
      default: 'auto',
    },
    // 固定的标签宽度
    labelWidth: {
      type: String,
      default: '',
    },
    // 标签文本对齐方式
    // 左对齐：'left'；两端对齐：'justify'
    // 如果要设置两端对齐，则务必同时设置 fixedLabelWidth，否则将不会生效
    align: {
      type: String,
      default: 'left',
    },
    // 标签文本后是否显示冒号
    // 默认显示
    showColon: {
      type: Boolean,
      default: true,
    },
    // 要展示的数据数组
    /**
     * type 四种：text, image, table，tag
     * text -> String, image: String, table: Array, tag: 待定 #fixme
     */
    list: {
      type: Array,
      default: () => [],
    },
    marginBottom: {
      type: String,
      default: '',
    },
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return BLOCK_LABELS[this.lang || 'zh-cn'];
    },
    theList() {
      const myList = this.list.filter((x) => !x.hide);
      return myList;
    },
  },
  data() {
    return {
      isLongTextOverflow: false,
      imgUrl: '',
      showImg: false,
      showArea: false,
    };
  },
  methods: {
    isOverFlowCheckFunc(fontCount) {
      if (fontCount) {
        return fontCount + 'em';
      } else {
        return '10em';
      }
    },
    // 表格单元格的公共样式
    tableCommomCellStyle() {
      return {
        height: '30px',
        padding: '0px',
        fontWeight: '400',
        textAlign: 'center',
        borderColor: '#ccc',
      };
    },
    // 表格表头行的单元格样式
    tableHeaderCellStyle() {
      const commonStyle = this.tableCommomCellStyle();
      const myStyle = {
        background: '#F2F2F2',
        color: '#808080',
      };
      return Object.assign(commonStyle, myStyle);
    },
    // 表格非表头行单元格样式
    tableCellStyle() {
      const commonStyle = this.tableCommomCellStyle();
      const myStyle = {
        color: '#666',
      };
      return Object.assign(commonStyle, myStyle);
    },
    handleClickMoreText() {
      this.$emit('click-more-text');
    },
    handleEmptyText(text, labelFlag) {
      if (labelFlag) {
        if (labelFlag == '发货及时率' && text == '0%') {
          return '100%';
        } else if (labelFlag == '评分' && text == 0) {
          return 5;
        }
      }
      if (text == undefined || text.length == 0) {
        return '--';
      }
      if (text === '0') {
        return '';
      }
      return text;
    },
    //预览图片
    showPreviewimg(key) {
      this.showImg = true;
      this.imgUrl = this.$options.filters.imgbaseurl(key);
    },
    //关闭预览图片
    previewClose() {
      this.showImg = false;
    },
    handleClickAll() {
      this.showArea = true;
    },
  },
  mounted() {
    if (this.$refs.longText) {
      const longTextItem = this.$refs?.longText;
      const totalWidth = longTextItem?.scrollWidth;
      const displayWidth = longTextItem?.offsetWidth;
      this.isLongTextOverflow = totalWidth > displayWidth;
    }
  },
};
