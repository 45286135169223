export const state = ()=> ({
 loading: true, 
});
export const getters = {
  currLoading: (state) => {
    return state.loading;
  },
};

export const mutations = {
  SET_LOADING(state, data) {
    return state.loading = data;
  },
  CLOSE_LOADING(state) {
    state.loading = false;
  }
};

export const actions = {
  setLoading({ commit }, data) {
    commit('SET_LOADING', data);
  },
  closeSkeleton({commit}) {
    commit('CLOSE_LOADING')
  }
};
