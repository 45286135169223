
// 组件
import NoShadowBox from '../home/components/NoShadowBox.vue';
export default {
  name: 'SpecialSection',
  components: { NoShadowBox },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 选择的专区类别id
    activeId: {
      type: [String, Number],
      default: '',
    },
    // 专区标题 && 类别
    cateList: {
      type: Object,
      default: () => {},
    },
    // 专区商品列表 && 促销图、链接
    goodsList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    // 切换类型
    handleSwitchTab(val) {
      if (this.activeId == val) return;
      this.$emit('change-cate', val);
    },
    // 处理宣传图跳转方式
    handleRouteJump(url) {
      if (!url) return;
      if (!url.startsWith('http')) {
        if (url.indexOf('/') != 0) {
          url = '/' + url;
        }
        url = process.env.NUXT_ENV.VUE_APP_API_HOST + url;
      }
      return url;
    },
  },
  mounted() {},
};
