/**
 * 店铺、商品详情相关数据
 * @author hukeyi
 * @description 管理店铺以及商品详情的部分数据
 */

export const state = () => ({
  /**
   * 商品对比
   */
  goodsCompareList: [], // 加入商品对比栏的商品列表
  maxGoodsCompareNum: 4, // 商品对比栏最多容纳的对比商品数量
  /**
   * 商品详情主图缓存列表
   */
  goodMainImgMap: {}, // 键值对 (itemId, imgUrl)
});
export const getters = {
  /**
   * 商品对比
   */

  /**
   * 获取商品对比列表
   * @returns 商品对比列表
   */
  getGoodsCompareList: (state) => {
    return state.goodsCompareList;
  },
  /**
   * 获取商品对比栏当前的商品数量
   * @returns 商品对比栏当前的商品数量
   */
  getGoodsCompareSize: (state) => {
    return state.goodsCompareList.length;
  },
  /**
   * 商品对比栏是否已满
   * @param {*} state
   * @returns { Boolean }
   */
  isCompareListFull: (state) => {
    return state.goodsCompareList.length >= state.maxGoodsCompareNum;
  },
  /**
   * 商品对比栏中查找指定 sku_id 的商品索引
   * @param {*} payload
   * @returns sku_id对应的商品在商品对比列表中的索引
   */
  getGoodsCompareItemIndexBySkuId: (state) => (skuId) => {
    let index = state.goodsCompareList.findIndex((x) => x.sku_id == skuId);
    return index;
  },

  /**
   * 商品详情主图
   */

  /**
   * 通过商品id获取商品的主图地址
   * @param String itemId 商品的id
   * @returns 商品主图地址，拼接了 static.ximu.cn 的地址
   */
  getGoodMainImgByItemId: (state) => (itemId) => {
    if (!itemId) return '';
    let hasCached = !!state.goodMainImgMap[itemId];
    return hasCached ? state.goodMainImgMap[itemId] : '';
  },
  /**
   * 当前商品是否已缓存商品主图
   * @returns { Boolean } 已缓存 true；无缓存 false
   */
  hasGoodMainImg: (state) => (itemId) => {
    return itemId && !!state.goodMainImgMap[itemId];
  },
};
export const mutations = {
  /**
   * 商品对比
   */

  INSERT_GOODS_COMPARE_ITEM(state, payload) {
    state.goodsCompareList.push(payload.data);
  },

  DEL_GOODS_COMPARE_ITEM(state, payload) {
    if (payload.index >= 0) {
      state.goodsCompareList.splice(payload.index, 1);
    }
  },
  /**
   * 清空商品对比列表
   */
  CLEAR_GOODS_COMPARE_LIST(state) {
    state.goodsCompareList = [];
  },

  /**
   * 商品详情
   * @param Object payload { itemId: 'xxx', imgUrl: '拼接好的完整url' }
   */
  SET_GOOD_MAIN_IMG(state, payload) {
    if (!payload || !payload.itemId || !payload.imgUrl) {
      return;
    }
    state.goodMainImgMap[payload.itemId] = payload.imgUrl;
  },
};
export const actions = {
  /**
   * 商品对比
   */
  /**
   * 向商品对比列表插入新商品
   * @param Object payload { data: 商品对象 }
   */
  insertGoodsCompareList({ state, getters, commit }, payload) {
    // 如果超过最大数量，则不加入
    if (state.goodsCompareList.length >= state.maxGoodsCompareNum) {
      console.log('商品对比列表已满');
      return;
    }
    if (!payload?.data?.sku_id) {
      console.error('插入商品对比列表失败！传参格式错误');
      return;
    }
    let skuId = payload.data.sku_id;
    let index = getters.getGoodsCompareItemIndexBySkuId(skuId);
    if (index < 0) {
      commit('INSERT_GOODS_COMPARE_ITEM', payload);
    }
  },
  /**
   * 根据 sku_id 删除商品对比列表
   * @param {*} payload { sku_id: x }
   */
  deleteGoodsCompareItemBySkuId({ state, commit }, payload) {
    if (state.goodsCompareList.length == 0) {
      return;
    }
    let index = state.goodsCompareList.findIndex(
      (x) => x.sku_id === payload.sku_id
    );
    if (index >= 0) {
      commit('DEL_GOODS_COMPARE_ITEM', { index });
    }
  },
  /**
   * 清空商品对比列表
   */
  clearGoodsCompareList({ commit }) {
    commit('CLEAR_GOODS_COMPARE_LIST');
  },
  /**
   * 商品详情
   */
  /**
   * 设置商品详情的主图
   * @param { Object } payload { itemId: 'xxx', imgUrl: '拼接好的完整url' }
   */
  setGoodMainImg({ commit }, payload) {
    commit('SET_GOOD_MAIN_IMG', payload);
  },
};
