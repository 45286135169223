import request from '@/utils/request.js';
// 账号注销
//提交申请
export function StorecancellationApply(data) {
  return request({
    apiModule: 'user',
    url: '/signOut/apply',
    method: 'post',
    data: data,
  });
}
//退店详情
export function storecancellationDetail(data) {
  return request({
    apiModule: 'user',
    url: '/signOut/getInfo',
    method: 'get',
    params: data,
  });
}
//撤销申请
export function cancelStorecancellationApply(data) {
  return request({
    apiModule: 'user',
    url: '/signOut/cancelApply',
    method: 'post',
    data: data,
  });
}
//退款申请
export function refundApply(data) {
  return request({
    apiModule: 'user',
    url: '/signOut/refundApply',
    method: 'post',
    data: data,
  });
}
//获取银行卡信息
export function getBankCardInfo(data) {
  return request({
    url: '/order/getBankCardInfo',
    method: 'get',
    params: data,
  });
}
// 获取退店后经营店铺
export function getSlaePlatformList(data) {
  return request({
    url: 'order/getSlaePlatformList',
    method: 'get',
    params: data,
  });
}
