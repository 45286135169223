
import { rechargeTrans } from '@/api/client-api/fund/fundCenter.js';
import bankCardTransfer from './bankCardTransfer.vue';
import { weiPayStatus } from '@/api/client-api/goods/pay.js';
import { Decimal } from 'decimal.js'; // 计算精度
export default {
  components: {
    bankCardTransfer,
  },
  props: {
    // 充值金额
    amount: {
      type: [Number, String],
      default: 0,
    },
    // 充值类型
    target_type: {
      type: String,
      default: '1',
    },
    // 当前余额
    currentBalance: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      payTypeList: [
        {
          type: 3, // 银行卡支付
          icon: require('@/assets/bankCard.png'),
          name: '银行卡支付',
        },
        {
          type: 1, // 微信支付
          icon: require('@/assets/weixin.png'),
          name: '微信支付',
        },
        {
          type: 2, // 支付宝支付
          icon: require('@/assets/zhifubao.png'),
          name: '支付宝支付',
        },
      ],
      payMentMethod: '',
      weChatShow: false,
      payTime: 1800, // 30分钟
      codeUrl: '', // 微信二维码
      backCardShow: false,
      comshow: false, // 提交成功的温馨提示弹窗状态
      weChatUrl: '', // 微信支付
      out_trade_no: '',
      version: 6, // 二维码生成的版本，数字越大，二维码越复杂
      stateTimer: null, // 支付状态定时器
      weChatTimer: null, // 支付倒计时定时器
      // currentBalance: 0, // 当前余额
      currency: '1', //账户类型 1-人民币 2-美元
      type: '', // 类型
      disablePay: false, // 禁止点击立即支付
      disableConfirmPay: false, // 余额支付弹窗 确认支付按钮
    };
  },
  computed: {
    // 充值后余额
    afterPayShow() {
      return new Decimal(this.currentBalance || 0)
        .plus(new Decimal(this.amount || 0))
        .toFixed(2);
    },
    // 充值金额保留2位小数
    amountTwoDecimal() {
      if (!this.amount) return '';
      return Number(this.amount)?.toFixed(2);
    },
  },
  mounted() {
    this.currency = this.$route.params.currency;

    this.type = this.$route.params.type;
    // this.getCapitalInfo();
  },
  beforeDestroy() {
    if (this.stateTimer) {
      clearInterval(this.stateTimer);
      this.stateTimer = null;
    }
    if (this.weChatTimer) {
      clearInterval(this.weChatTimer);
      this.weChatTimer = null;
    }
  },
  methods: {
    // getCapitalInfo() {
    //   let option = {
    //     currency: this.currency,
    //   };
    //   getFundInfo(option).then(res => {
    //     if (res.errcode == 0) {
    //       if (this.type == 'balance') {
    //         this.currentBalance = Number(res.data.total_balance) || 0;
    //       } else if (this.type == 'freight') {
    //         this.currentBalance = Number(res.data.freight_deposit) || 0;
    //       } else if (this.type == 'activity') {
    //         this.currentBalance = Number(res.data.activity_deposit) || 0;
    //       }
    //     }
    //   });
    // },
    // 立即支付
    payment() {
      if (!this.payMentMethod) {
        this.$message.warning('请选择充值方式');
        return;
      }
      if (!this.amount || !Number(this.amount)) {
        this.$message.warning('请输入充值金额');
        return;
      }
      let option = {
        target_type: this.target_type,
        method: this.payMentMethod,
        amount: this.amount,
      };
      // 支付按钮禁用时不可以再次点击，直接return
      if (this.disablePay) return;
      // 点击立即支付后禁用立即支付按钮
      this.disablePay = true;
      switch (this.payMentMethod) {
        case 1:
          // 微信支付
          this.weixinrechargeTrans(option);
          break;
        case 2:
          // 支付宝支付
          this.rechargeTrans(option);
          break;
        case 3:
          this.backCardShow = true;
          // 关闭 禁止点击按钮
          this.disablePay = false;
          // 银行卡支付
          break;
      }
    },
    // 支付宝充值
    rechargeTrans(option) {
      rechargeTrans(option)
        .then((res) => {
          // if (res.errcode && res.errcode != 0) {
          //   this.$message.warning(res.msg);
          //   return;
          // }
          // let str = res.trim();
          // if (str != '') {
          //   const div = document.createElement('div');
          //   div.innerHTML = res;
          //   document.body.appendChild(div);
          //   document.forms[0].submit();
          // } else {
          //   this.$message.warning('支付异常，请重试');
          // }
          if (res?.errcode == 0) {
            let str = res?.data?.trim();
            if (str != '') {
              const div = document.createElement('div');
              div.innerHTML = res?.data;
              document.body.appendChild(div);
              document.forms[0].submit();
            } else {
              // 关闭 禁止点击按钮
              this.disablePay = false;
            }
            // this.$message.warning(res.msg);
          } else {
            // 关闭 禁止点击按钮
            this.disablePay = false;
          }
        })
        .catch((err) => {
          // 关闭 禁止点击按钮
          this.disablePay = false;
          console.error(err);
        });
    },
    // 微信充值
    weixinrechargeTrans(option) {
      rechargeTrans(option)
        .then((res) => {
          // 关闭 禁止点击按钮
          this.disablePay = false;
          if (res?.errcode == 0) {
            this.weChatShow = true;
            this.weChatUrl = res?.data?.code_url;
            this.out_trade_no = res?.data?.out_trade_no;
            this.handleClickCreateQRcode();
            if (this.stateTimer) {
              clearInterval(this.stateTimer);
              this.stateTimer = null;
            }
            this.stateTimer = setInterval(this.weiPayStatus, 1000);
            // 支付倒计时
            if (this.weChatTimer) {
              clearInterval(this.weChatTimer);
              this.weChatTimer = null;
            }
            this.weChatTimer = setInterval(this.payTimeNum, 1000);
          }
        })
        .catch((err) => {
          // 关闭 禁止点击按钮
          this.disablePay = false;
          console.error(err);
        });
    },
    // 更新微信支付状态
    weiPayStatus() {
      let option = {
        out_trade_no: this.out_trade_no,
        MUTE_WARNING: 1,
      };
      weiPayStatus(option)
        .then((res) => {
          if (res.errcode == 0 && res.data.status == 'SUCCESS') {
            if (this.stateTimer) {
              clearInterval(this.stateTimer);
            }
            this.weChatShow = false;
            this.comshow = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    payTimeNum() {
      if (this.payTime > 0) this.payTime--;
      if (this.payTime == 0 && this.weChatTimer) {
        clearInterval(this.weChatTimer);
        this.weChatTimer = null;
      }
      if (this.payTime == 0 && this.stateTimer) {
        clearInterval(this.stateTimer);
        this.stateTimer = null;
      }
    },
    // 点击生成二维码
    async handleClickCreateQRcode() {
      try {
        const options = {
          text: this.weChatUrl,
          version: this.version,
          margin: 0,
        };
        let that = this; // 必须要，否则 this 指向会错误
        function callbackFn(err, url) {
          if (err) {
            console.error(err);
            return;
          }
          that.codeUrl = url;
        }
        await this.createQRcode(options, callbackFn);
      } catch (err) {
        console.error(err);
      }
    },
    // 关闭银行卡支付弹框
    closeBank(type) {
      this.backCardShow = false;
      if (type == 1) {
        this.comshow = true;
      }
    },
    // 关闭微信支付弹窗
    closeweChat() {
      this.weChatShow = false;
      if (this.stateTimer) {
        clearInterval(this.stateTimer);
      }
      if (this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
    },
    // 计算微信二维码失效倒计时
    getHour(time) {
      let mine = time % 60; // 分钟
      let hour = parseInt(time / 60);
      return [hour, mine];
    },
    // 关闭弹框
    closeConfig() {
      this.$router.go(-1);
      this.comshow = false;
    },
  },
};
