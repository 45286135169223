import request from '@/utils/request.js';
// import qs from "qs";

// const baseURL= 'http://192.168.110.250:3000';

/**
 * POST 方法传参的字段是 'data'！不是 'params'!
 */

//登录
export function pwdLogin(data) {
  return request({
    url: '/login/pwdLogin',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 分销商和供应商统一登录接口(密码登录)
export function loginAll(data) {
  return request({
    apiModule: 'user',
    url: '/login/login',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

//验证码登录
export function smsCodeLogin(data) {
  return request({
    apiModule: 'user',
    url: '/login/smsCodeLogin',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

//发送验证码
export function sendSmsCode(data) {
  return request({
    url: '/sms/sendSmsCode',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

//发送重置密码验证码
export function sendRechargeSmsCode(data) {
  return request({
    url: '/Login/setRechargeCode',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

//用户注册
export function registerUser(data) {
  return request({
    url: '/User/registerUser',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 分销商注册
export function registerDistributor(data) {
  return request({
    apiModule: 'user',
    url: '/Register/registerUser',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 供应商注册
export function registerSupplier(data) {
  return request({
    apiModule: 'user',
    url: '/Register/supplierRegister',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 验证原来手机号的验证码
export function sendVerificationSms(data) {
  return request({
    url: '/sms/sendVerificationSms',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 更换手机号验证
export function editPhone(data) {
  return request({
    apiModule: 'user',
    url: '/user/editPhone',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 重置密码验证验证码
export function resetPwCheck(data) {
  return request({
    url: '/Login/rechargePasswordCode',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 忘记密码
export function resetPassword(data) {
  return request({
    apiModule: 'user',
    url: '/Login/rechargePassword',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 埋点
export function getPoint(data) {
  return request({
    url: '/BuryPoint/getPoint',
    method: 'post',
    data: data,
  });
}

// 获取商品的基本信息（标题、图和价格）
export function getItemBasicDetail(data) {
  return request({
    url: '/item/getItemBasicDetails',
    method: 'get',
    params: data,
  });
}

// 获取分销商信息
export function getDistributorInfo() {
  return request({
    url: '/adminUser/getDistributorInfo',
    method: 'get',
  });
}
// 设置用户支付密码
export function setPaymentPassword(data) {
  return request({
    url: '/user/setPaymentPassword',
    method: 'post',
    data: data,
  });
}

// 获取协议
export function getProtocolData(data) {
  return request({
    url: '/Agreement/getAgreement',
    method: 'get',
    params: data,
  });
}

// 登录页供应商修改手机号--验证原手机号
export function loginDiscernAccount(data) {
  return request({
    apiModule: 'user',
    url: '/editPhone/discernAccount',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 登录页供应商修改手机号--验证供应商身份信息
export function loginVerifySupplier(data) {
  return request({
    apiModule: 'user',
    url: '/editPhone/verifySupplier',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 登录页供应商修改手机号--验证供应商身份信息
export function loginEditPhone(data) {
  return request({
    apiModule: 'user',
    url: '/editPhone/editPhone',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

//获取用户数据
// export function getUserInfo(params) {
//   return request({
//     url: baseURL + "/getUserInfo",
//     method: "get",
//     params:qs.stringfy(params)
//   });
// }

//其实，也不一定就是params，也可以是 query 还有 data 的呀！
//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。
