/**
 * 中英文标签配置
 * DetailInfoAside.vue
 */
// hello world
// 按钮的标签配置
export const BTN_LABELS = {
  'zh-cn': {
    'follow-store': '关注店铺',
    'enter-the-store': '进入店铺',
  },
  en: {
    'follow-store': 'Follow',
    'enter-the-store': 'Enter',
  },
};

// 商家评分标签配置
export const SCORE_TYPES_ABBR = {
  'zh-cn': [
    {
      label: '商品',
      prop: 'commodityScore',
      value: '',
    },
    {
      label: '服务',
      prop: 'serviceScore',
      value: '',
    },
    {
      label: '物流',
      prop: 'logisticsScore',
      value: '',
    },
  ],
  en: [
    {
      label: 'Goods', // 缩写版
      prop: 'commodityScore',
      value: '',
    },
    {
      label: 'Serve', // 缩写版
      prop: 'serviceScore',
      value: '',
    },
    {
      label: 'Logistics', // 缩写版
      prop: 'logisticsScore',
      value: '',
    },
  ],
};

// 标签页标签配置
export const INFO_ASIDE_LABEL = {
  'zh-cn': {
    'store-hot': '店铺热销',
    'hot-attention': '热门收藏',
    sales: '销量',
  },
  en: {
    'store-hot': 'Store hot sales',
    'hot-attention': 'Products with a high number of followers',
    sales: 'Sales',
  },
};
