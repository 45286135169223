export default ({ $axios }, inject) => {
  // 精选商品
  inject('getSelectedItemList', (data) =>
    $axios({
      url: '/item/getSelectedItemList',
      method: 'get',
      params: data,
    })
  );
};
