
import {
  getUserEvaluateList,
  getEvaluatePanel,
} from '@/api/client-api/trade/orderlist.js';
import Report from '@/pages/back-stage/complaints-module/complaints-report/components/Report.vue'; //举报
import { COMPLAINT_SCENARIO } from '@/pages/back-stage/complaints-module/complaints-report/js/complaint_type.js'; //发起场景
import MyPublishTableVue from './components/MyPublishTable.vue'; // 我已发表评价的订单
import WaitEvaluateTable from './components/WaitEvaluateTable.vue';
export default {
  name: 'noEvaluate',
  components: {
    Report,
    MyPublishTableVue,
    WaitEvaluateTable,
  },
  data() {
    return {
      COMPLAINT_SCENARIO,
      evaluateStatus: '1',
      isSearch: false,
      total: '',
      pageNum: 1,
      pageSize: 10,
      orderlist: [], //待评价列表
      waitEvaluatePanle: '', // 等待我评价的订单  总数据
      publishPanle: '', // 我已发表评价的订单  总数据

      // evaluateStatus: '1',

      // orderActiveName: '6',
      search: {
        orderTime: [], // 选择的筛选时间
        evaType: '1', // 评分类型
        score: '', // 评分分数
        selectKey: 'tid', // 下拉搜索key
        selectValue: '', // 下拉搜索value
        evaContent: '', // 查看评论类型
      },
      scoreType: [
        { label: '商品得分', value: '1' },
        { label: '物流得分', value: '2' },
        { label: '总体印象得分', value: '3' },
      ],
      scoreNum: [
        { label: '五星', value: '5' },
        { label: '四星', value: '4' },
        { label: '三星', value: '3' },
        { label: '二星', value: '2' },
        { label: '一星', value: '1' },
      ],
      searchOptions: [
        { label: '订单编号', value: 'tid' },
        { label: '商品标题', value: 'title' },
        { label: '商品ID', value: 'item_id' },
        { label: '供应商店铺', value: 'shop_name' },
      ],
      textType: [
        { label: '全部', value: '' },
        { label: '有图片', value: '1' },
        { label: '有视频', value: '2' },
      ],
      colors: ['#FF7802', '#FF7802', '#FF7802'], // 评分选中的icon颜色
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() + 1 * 24 * 3600 * 1000;
        },
      },
    };
  },

  mounted() {
    // 获取vuex中的数据
    let info = this.getPageFilterData();
    if (info) {
      this.pageNum = info?.pageNum || 1;
      this.evaluateStatus = info?.evaluateStatus || '1';
      this.search = JSON.parse(JSON.stringify(info || {}));
      this.$refs.XmSelectOption.reVal(
        info?.selectKey || '',
        info?.selectValue || ''
      );
    }
    this.getEvaluatePanelData();
    this.getUserEvaList();
  },
  methods: {
    // 切换标签
    handleEvaluateTabs(evaulateStatus) {
      this.getEvaluatePanelData();
      this.evaluateStatus = evaulateStatus;
      this.reset();
      this.isSearch = false;
    },
    // 下拉输入搜索
    handleChangeKeyword(key, value) {
      this.search.selectKey = key;
      this.search.selectValue = value;
      this.getUserEvaList();
    },
    // 跳转商品详情
    toShopDetail(item) {
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img
      );
    },
    // 获取评价列表
    async getUserEvaList() {
      this.orderlist = [];
      try {
        let options = {
          sub_table: this.evaluateStatus,
          pageNum: this.pageNum, // 页号
          pageSize: this.pageSize, // 页大小
          MUTE_WARNING: 1,
        };
        this.setPageFilter({
          ...this.search,
          pageNum: this.pageNum,
          evaluateStatus: this.evaluateStatus,
        });
        this.isSearch = false;
        // 订单号/标题/ID/供应商id，输入框搜索
        if (this.search.selectValue != '') {
          options.keys = this.search.selectKey;
          options.value = this.search.selectValue;
          this.isSearch = true;
        }
        // 时间范围搜索
        if (
          this.search.orderTime != null &&
          this.search.orderTime.length != 0
        ) {
          options.start_time = this.search.orderTime[0];
          options.end_time = this.search.orderTime[1];
          this.isSearch = true;
        }
        // 得分类型/分数搜索
        if (this.search.score != '') {
          options.evaType = this.search.evaType;
          options.score = this.search.score;
          this.isSearch = true;
        }
        // 按评价内容 图片/视频
        if (this.search.evaContent != '') {
          options.evaContent = this.search.evaContent;
          this.isSearch = true;
        }
        const res = await getUserEvaluateList(options);
        if (res?.errcode == 0) {
          this.total = Number(res.data?.total || 0);
          this.orderlist = res.data?.list;
          this.orderlist.forEach((item) => {
            item.score = Number(item.score || 0);
            item.shop_score = Number(item.shop_score || 0);
            item.logistics_score = Number(item.logistics_score || 0);
            item.reply_score = Number(item.reply_score || 0);
          });

          // 处理当前页码过大的情况
          if (this.pageNum != 1 && this.orderlist?.length == 0) {
            this.pageNum--;
            this.getUserEvaList();
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 选择分数类型后清空分数的选择
    changeEvaType() {
      this.search.score = '';
    },
    // 更新数据
    updateData() {
      this.getUserEvaListSearch();
      this.getEvaluatePanelData();
    },

    async getEvaluatePanelData() {
      try {
        let res = await getEvaluatePanel({ MUTE_WARNING: 1 });
        if (res?.errcode == 0) {
          this.waitEvaluatePanle = res?.data?.notEvaluated;
          this.publishPanle = res?.data?.evaluated;
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 搜索
    getUserEvaListSearch() {
      this.pageNum = 1;
      this.getUserEvaList();
    },
    // 重置搜索栏
    reset() {
      this.search = {
        orderTime: [], // 选择的筛选时间
        evaType: '1', // 评分类型
        score: '', // 评分分数
        selectKey: 'tid', // 下拉搜索key
        selectValue: '', // 下拉搜索value
        evaContent: '', // 查看评论类型
      };
      this.pageNum = 1;
      this.$refs.XmSelectOption.reVal();
      this.getUserEvaList();
    },
    // 页数改变触发
    goPage(val) {
      this.pageNum = val;
      this.getUserEvaList();
    },
  },
};
