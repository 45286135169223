/**
 * 这是一个全局注册的组件的js呀._.,跟原分销商项目差不多用法呀
 * 公共的才放这里！！！！！！！！！！
 * 公共的才放这里！！！！！！！！！！
 * 公共的才放这里！！！！！！！！！！
 */

import Vue from 'vue';
import XmHeader from '@/components/XimuHeader.vue';
import XmTopNav from '@/components/XimuComponents/XmTopNav.vue'; // 顶部导航栏
import XmFooter from '@/components/XimuFooter.vue'; // 网页的底部，网站信息（备案号等）
import XmContact from '@/components/XimuComponents/XmContact.vue'; // 联系xx
import XmInput from '@/components/XimuComponents/xm-input/XmInput.vue'; // 带有限制的输入框(暂时只限制输入数字)
import XmDatePicker from '@/components/XimuComponents/xm-datePicker/index.vue'; //带下拉框的时间选择器，下拉框可要可不要自己控制
import XmEmpty from '@/components/XimuComponents/xm-empty/XmEmpty.vue'; //无数据(空状态)
import XmGoodsBox from '@/components/XimuComponents/xm-goods-box/XmGoodsBox.vue'; //块状显示的商品卡片，携带对比功能
import PagTools from '@/components/XimuComponents/pag-tools/PagTools.vue'; //分页器
import PreviewImg from '@/components/PreviewImg.vue'; //临时图片预览要换
import XmPreviewImage from '@/components/XimuComponents/XmPreviewImage.vue'; // 鼠标悬浮显示大图
import XmCategory from '@/components/XimuComponents/xm-category/XmCategory.vue'; // 类目筛选
import XmSelect from '@/components/XimuComponents/xm-select/XmSelect.vue'; // 带下拉框的搜索
import RemoveDialog from '@/components/comme/RemoveDialog.vue'; //删除确认提示框
import deleteConfig from '@/components/comme/deleteConfig.vue'; // 确认删除弹框
import XmUpload from '@/components/XimuComponents/XmUpload.vue'; //上传组件()
import XmUploadFileName from '@/components/XimuComponents/xm-upload-file-name/XmUploadFileName.vue'; //上传组件(按钮，拖拽)
import XmUploader from '@/components/XimuComponents/XmUploader.vue';
import XmTip from '@/components/XimuComponents/XmTip.vue'; //提示
import XmCardTabs from '@/components/XimuComponents/XmCardTabs.vue'; //切换标签
import XmPreviewMedia from '@/components/XimuComponents/xm-preview-media/XmPreviewMedia.vue'; //预览大图可切换
import XmShowFileBox from '@/components/XimuComponents/XmShowFileBox.vue'; //附件展示

import XimuNoTitleConfig from '@/components/XimuComponents/ximu-no-title-config/XimuNoTitleConfig.vue'; //没有title的确认框
import XmPreviewImageTable from '@/components/XimuComponents/XmPreviewImageTable.vue'; //(预览大图的组件，貌似有听多个的 )//TODO 预览大图组件到时统一处理
import XmHoverPreviewImage from '@/components/XimuComponents/xm-hover-preview-image/XmHoverPreviewImage.vue'; //带用户上传文件名的上传组件
import XmClose from '@/components/XimuComponents/ximu-close/XimuClose.vue'; // 关闭弹框
import XmProtocolDialog from '@/components/XimuComponents/XimuProtocolDialog.vue'; // 协议弹窗
import XmPaged from '@/components/XimuComponents/xm-paged/XmPaged.vue'; // 协议弹窗
import XmPreviewdoc from '@/components/XimuComponents/XmPreviewdoc.vue'; // 预览文件
import XmPreviewimg from '@/components/XimuComponents/XmPreviewimg.vue'; // 预览图片/视频
import XmRadioCascade from '@/components/XimuComponents/XmRadioCascade.vue'; // 选择类目(一级、一二级、一二三级)
import XmReconfirm from '@/components/XimuComponents/XmReconfirm.vue'; // 二次确认弹窗
import XmGoodsMark from '@/components/XimuComponents/XmGoodsMark.vue'; // 商品信息标识

import XmCarousel from '@/components/XimuComponents/XmCarousel.vue'; // 轮播图组件
import XmTeleport from '@/components/XimuComponents/XmTeleport.vue'; // 传送门组件
import ContactBackend from '@/components/comme/contactBackend.vue'; // 联系客服组件
import XmDialog from '@/components/XimuComponents/XimuDialog.vue'; // 弹窗组件
import XmSkuCompareColumn from '@/components/XimuComponents/xm-sku-compare-column/index.vue'; // 商品对比组件
import XmVideo from '@/components/XimuComponents/XmVideo.vue'; // 视频组件
import NewDemand from '@/components/comme/NewDemand.vue'; // 新品需求组件
import XmInCountrySelect from '@/components/XimuComponents/XmInCountrySelect.vue'; // 中国的省市区选择器
import XmAreaSelectAll from '@/components/XmAreaSelectAll.vue'; // 下单页,地址选择器

import XmNewAreaSelect from '@/components/xm-new-area-select/XmNewAreaSelect.vue'; //新地址选择
import XmExportButton from '@/components/XimuComponents/xm-export/XmExportButton.vue'; // 导出按钮（订单页）
import XmExportHistory from '@/components/XimuComponents/xm-export/XmExportHistory.vue'; // 导出列表弹窗（订单页）
import XmPageTitle from '@/components/XmPageTitle.vue'; // 页面头部标题
import XmMoreOperations from '@/components/XimuComponents/XmMoreOperations.vue'; // 表格中的“更多”
import XmSteps from '@/components/XimuComponents/xm-steps/index.vue'; // 进度条

const components = {
  XmHeader,
  XmTopNav,
  XmFooter,
  XmContact,
  XmInput,
  XmDatePicker,
  XmEmpty,
  XmGoodsBox,
  PagTools,
  XmCarousel,
  XmTeleport,
  ContactBackend,
  XmDialog,
  NewDemand,
  XmSkuCompareColumn,
  XmVideo,
  PreviewImg,
  XmPreviewImage,
  XmCategory,
  XmSelect,
  RemoveDialog,
  deleteConfig,
  XmUpload,
  XimuNoTitleConfig,
  XmPreviewImageTable,
  XmClose,
  XmProtocolDialog,
  XmPaged,
  XmPreviewdoc,
  XmPreviewimg,
  XmRadioCascade,
  XmReconfirm,
  XmUploadFileName,
  XmUploader,
  XmHoverPreviewImage,
  XmTip,
  XmCardTabs,
  XmPreviewMedia,
  XmShowFileBox,
  XmInCountrySelect,
  XmAreaSelectAll,
  XmGoodsMark,
  XmExportButton,
  XmExportHistory,
  XmPageTitle,
  XmMoreOperations,
  XmNewAreaSelect,
  XmSteps,
};

Object.keys(components).forEach((component_name) => {
  Vue.component(component_name, components[component_name]);
});
