
// 配置
import { STATUS_CONFIG } from '../js/status_config';
export default {
  name: 'ProcessStep',
  props: {
    // 当前纠纷状态
    disputeStatus: {
      type: String,
      default: '',
    },
    // 状态记录
    record: {
      type: String,
      default: '',
    },
    // 纠纷结束时间
    disputeEndTime: {
      type: String,
      default: '',
    },
    // 用户物流信息
    userLogiInfo: {
      type: [Object, String],
      default: '',
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    // 步骤条状态
    stepsList() {
      if (this.record) {
        let recordIds = this.record?.split(',');
        const stepsList = recordIds.map((id, index) => {
          const status = STATUS_CONFIG.find((item) => item.id === id);
          return {
            id: index,
            title: status?.text || '--',
          };
        });
        return stepsList;
      }
    },
    // 当前高亮步骤id
    currentStep() {
      if (this.disputeStatus != '6') return this.stepsList?.length - 2;
      return this.stepsList?.length - 1;
    },
  },
  methods: {
    // 打开物流详情弹窗
    openlogiDetail() {
      this.$emit('look-logistics');
    },
  },
};
