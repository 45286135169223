
import InfoHeader from './components/InfoHeaderItem.vue';
import SearchItem from './components/SearchItem.vue';
import SessionListItem from './components/SessionListItem.vue';
import {
  getValidId,
  CS_ROUTE_NAME,
  getCustomerServiceId,
} from '@/utils/chat_tools.js';
import { getCSAccid, getDistributorInfo } from '@/api/client-api/chat/chat.js';

import { mapGetters } from 'vuex';

export default {
  components: { InfoHeader, SearchItem, SessionListItem },
  data() {
    return {
      csRouteName: CS_ROUTE_NAME, // 客服的路由标记文本

      tipContent: [''],
      reconnectCount: 0, // 重连次数
      maxReconnectCount: 5, // 最大重连次数

      myName: '',
      myAvatar: '',
      otherId: '',

      searchSessionList: null, // 为什么放在data，不放在 vuex？
      updatedSession: null, // 当前更新的会话对象，为了处理偶现的会话资料更新不及时问题
      // 为了在刷新页面时，不记录用户的搜索结果数据，直接清空搜索框，展示所有会话列表

      hasAccount: true, // 对话对象是否有云信账号
      hasNimConnected: false, // nim 实例是否连接上服务器
      hasUserInfosInited: false, // 用户信息是否初始化成功
      hasSyncInited: false, // 数据是否初始化成功（成功后才能调用数据库）

      otherInfo: null,

      loadingSessions: false, // 会话列表加载动效
      showConnectDialog: false,
      extraConnectDialogText: '',
    };
  },
  watch: {
    // 监听路由变化，变换会话窗口标题
    '$route.params.otherId': {
      handler: async function (newVal, oldVal) {
        if (!newVal) return;
        let currOtherId = newVal;
        try {
          currOtherId = await this.getOtherId(newVal);
        } catch (err) {
          console.error(err);
        }
        // 退出前一个会话
        if (oldVal && oldVal !== currOtherId) {
          this.$nim.resetCurrSession(getValidId(oldVal));
        }
        this.otherId = getValidId(currOtherId);

        if (this.hasSyncInited) {
          this.initMessageWindow(this.otherId);
        }
      },
    },
  },
  computed: {
    ...mapGetters('chat', ['sessionById', 'sessionsByNick', 'hasSession']),
    orderedSessions() {
      const list = this.$store.state.chat.orderedSessions;
      const searchList = this.searchSessionList;
      return searchList ? searchList : list ? list : [];
    },
    // 当前的会话id
    currSessionId() {
      return !this.otherId ? '' : `p2p-${this.otherId}`;
    },
    // 当前会话对象
    currSession() {
      let cachedSession = this.sessionById(this.otherId);
      let cachedTime = cachedSession?.updateTime || 0;
      let updatedTime = this.updatedSession?.updateTime || 0;
      if (this.updatedSession?.to !== this.otherId) {
        // 保证更新的会话对象数据是当前对话的
        updatedTime = 0;
      }
      // 需要数据更新时间更新的一个
      return cachedTime > updatedTime ? cachedSession : this.updatedSession;
    },
  },
  methods: {
    getOtherId(routeId) {
      return new Promise(async (resolve, reject) => {
        let currOtherId = routeId;
        if (routeId === this.csRouteName) {
          // 如果是客服，请求获取分配客服id的接口
          try {
            const res = await getCSAccid();
            if (res?.errcode == 0) {
              currOtherId = getValidId(res.data?.id);
              localStorage.setItem('xm_csid', currOtherId); // 保存在本地缓存
              return resolve(currOtherId);
            }
            return reject(res);
          } catch (err) {
            return reject(err);
          }
        }
        return resolve(currOtherId);
      });
    },
    async handleSearchNick(target) {
      if (!target || target.length <= 0) {
        this.searchSessionList = null;
        return;
      }
      this.loadingSessions = true;
      this.searchSessionList = this.sessionsByNick(target);
      this.loadingSessions = false;
    },
    // 清空会话搜索
    handleClearSearchNick() {
      this.searchSessionList = null;
    },
    // 点击会话小框
    handleClickSessionListItem(to) {
      let url = to;
      if (to === getCustomerServiceId()) {
        url = this.csRouteName;
      } else if (this.hasAccountLimited()) {
        this.$message.error('您的账号已被限制访问，暂时只能与平台客服联系');
        return;
      }
      this.$router.push(`/chat/` + url);
      this.hasAccount = true; // 出现在左侧会话列表的，都是有账号的
    },
    // 更新当前会话对象资料
    updateCurrSession(session) {
      if (
        !this.updatedSession ||
        session.updateTime > this.updatedSession?.updateTime
      ) {
        this.updatedSession = session;
      }
    },
    /**
     * 初始化聊天窗口
     */
    async initMessageWindow(to) {
      if (!to) return;
      this.otherId = to;
      this.updatedSession = null;
      try {
        // 检查对话对象是否有云信账号
        this.otherInfo = await this.$store.dispatch(
          'chat/doesUserHaveNimAccount',
          to
        );
        this.hasAccount = !!this.otherInfo;
        if (!this.hasAccount) {
          this.otherName = to;
          return;
        }
        this.otherName = this.otherInfo.nick;
        // 如果有，则检查ta是否在当前会话列表中
        let hasSession = await this.hasSession(to);
        // 如果有，则更新数据；如果无，则把当前对象加入会话列表
        if (!hasSession) {
          const res = await this.$nim.insertLocalSession(to);
          if (this.isLocalEnv()) {
            console.log('【新会话插入数据库】', res);
            this.updateCurrSession(res.session);
          }
        }
        await this.$nim.setCurrSession(to);
      } catch (err) {
        console.error(err);
        this.reconnect();
      }
    },

    /**
     * NIM 监听函数
     */

    // 连接回调
    onConnect(res) {
      if (this.isLocalEnv()) {
        console.log('【连接成功】', res);
      }

      this.showConnectDialog = false;
      this.extraConnectDialogText = '';
      this.hasNimConnected = true;
    },
    // 初始化会话列表
    onSessions(res) {
      if (this.isLocalEnv()) {
        console.log('【初始化会话列表】', res);
      }
      for (let session of res) {
        this.$nim.updateLocalSession(session.id);
      }
      this.$store.dispatch('chat/updateSessions', res);
    },
    // 同步成功
    async onSyncDone() {
      try {
        if (this.isLocalEnv()) {
          console.log('【初始化同步成功】');
        }

        this.hasSyncInited = true;
        this.loadingSessions = true;

        await this.$store.dispatch('chat/initSessions');
        // 获取当前会话列表中所有用户的个人信息（昵称和头像）
        await this.$store.dispatch(
          'chat/fetchUserInfos',
          this.orderedSessions?.map((x) => x.to)
        );
        this.hasUserInfosInited = true;
        this.initMessageWindow(this.otherId); // 初始化聊天窗口

        this.loadingSessions = false;
      } catch (err) {
        console.error(err);
        this.loadingSessions = false;
      }
    },
    // 置顶会话回调
    async onStickTopSessions(res) {
      try {
        if (this.isLocalEnv()) {
          console.log('【置顶会话更新】', res);
        }
        this.$store.dispatch('chat/updateSessions', res);
        // 如果会话列表不为空，则获取其中相应的用户的信息
        if (res?.length > 0) {
          await this.$store.dispatch(
            'chat/fetchUserInfos',
            res?.map((x) => getValidId(x.to))
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 会话列表更新
    async onUpdateSessions(res) {
      try {
        if (this.isLocalEnv()) {
          console.log('【会话更新】', res);
        }
        this.$store.dispatch('chat/updateSessions', res);

        // 如果会话列表不为空，则获取其中相应的用户的信息
        if (res && res.length > 0) {
          await this.$store.dispatch(
            'chat/fetchUserInfos',
            res.map((x) => x.to)
          );
        }
        // 如果更新会话中有当前会话，且更新了未读数，则更新
        const index = res.findIndex((x) => x?.to === this.otherId);
        if (index >= 0) {
          this.updatedSession = res[index];
          this.$nim.sendMsgReadReceipt(res[index].lastMsg);
        }
      } catch (err) {
        console.error(err);
        this.reconnect();
      }
    },
    onUsers(res) {
      if (this.isLocalEnv()) {
        console.log('【初始化用户资料】', res);
      }
      if (res?.length > 0) {
        this.$store.commit('chat/updateUsersInfo', res);
      }
    },
    onUpdateUser(res) {
      if (this.isLocalEnv()) {
        console.log('【用户资料更新】', res);
      }
      if (res) {
        this.$store.commit('chat/setUserInfoById', res);
      }
    },
    onMyInfo(res) {
      if (this.isLocalEnv()) {
        console.log('【初始化我的资料】', res);
      }
      if (res) {
        this.myName = res.nick;
        this.myAvatar = res.avatar;
        this.$store.commit('chat/setMyInfo', res);
      }
    },
    onUpdateMyInfo(res) {
      if (this.isLocalEnv()) {
        console.log('【我的资料更新】', res);
      }
      if (res) {
        this.myName = res.nick;
        this.myAvatar = res.avatar;
        this.$store.commit('chat/setMyInfo', res);
      }
    },
    onDisconnect(res) {
      if (this.isLocalEnv()) {
        console.log('【连接断开】', res);
      }
      this.hasNimConnected = false;
      // 如果是被踢，则显示连接错误窗口
      if (res?.code === 'kicked') {
        this.showConnectDialog = true;
        if (res?.message) {
          this.extraConnectDialogText = res.message;
        }
        return;
      }
      this.reconnect();
    },
    // 重新连接
    reconnect() {
      if (
        this.$nim &&
        this.$nim.nim &&
        this.reconnectCount < this.maxReconnectCount
      ) {
        this.reconnectCount++;
        this.$nim.nim.connect();
      } else if (this.reconnectCount >= this.maxReconnectCount) {
        let currPath = this.$route.path;
        if (currPath !== '/chat' && currPath !== '/chat/') {
          this.$router.replace({ path: '/chat' }, () => {
            location.reload();
          });
        }
      }
    },

    /**
     * 请求接口
     */
    // 获取分销商的信息
    async updateUserInfo() {
      try {
        const res = await getDistributorInfo();
        let im_token = res?.data?.im_token;
        if (im_token) {
          this.tipContent = [''];
          console.log('获取到新的云信账号', im_token);
          const userdetail = JSON.parse(localStorage.getItem('userdetail'));
          userdetail.im_token = im_token;
          localStorage.setItem('userdetail', JSON.stringify(userdetail));
          this.$cookies.set('userdetail', JSON.stringify(userdetail), {
            maxAge: 60 * 60 * 24 * 30,
            path: '/',
          });

          this.chatWithCustomerService('_self'); // 跳转到与客服聊天的页面
        } else {
          console.log('未注册云信账号');
          this.tipContent = [
            '当前账号暂未开通聊天功能',
            '新注册账号的聊天功能将于数小时内自动开通，请耐心等候',
          ];
          let currPath = this.$route.path;
          if (currPath !== '/chat' && currPath !== '/chat/') {
            this.$router.replace({ path: '/chat' }, () => {
              location.reload();
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    if (!this.$nim || !this.$nim.isValid) {
      // 检查是否已更新 im_token
      this.updateUserInfo();
      return;
    }
    this.myName = localStorage.getItem('real_name');
    if (this.$route?.params?.otherId) {
      try {
        this.otherId = await this.getOtherId(this.$route.params.otherId);
      } catch (err) {
        console.error(err);
      }
    }
    /**
     * 初始化 NIM 的回调函数
     * 设置监听，改变页面相关数据
     */

    this.$nextTick(() => {
      if (!this.otherId) {
        const nimOptions = {
          onconnect: this.onConnect,
          onsyncdone: this.onSyncDone,
          onupdatesessions: this.onUpdateSessions,
          onStickTopSessions: this.onStickTopSessions,
          onsessions: this.onSessions,
          onusers: this.onUsers,
          onmyinfo: this.onMyInfo,
          onupdatemyinfo: this.onUpdateMyInfo,
          onupdateuser: this.onUpdateUser,
          ondisconnect: this.onDisconnect,
        };
        this.$nim.init(nimOptions);
      }
    });
  },
};
