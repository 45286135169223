export default ({ $axios }, inject) => {
  inject('addNewItemNeed', (data) =>
    $axios({
      url: 'ItemNeed/addNewItemNeed',
      method: 'post',
      apiModule: 'goods',
      data: data,
    })
  );
};
// import request from '@/utils/request.js';
// // 商品需求
//  //现有列表
//  export function getList(data) {
//     return request({
//       url: 'ItemNeed/getList',
//       method: 'get',
//       params:data
//     });
//   }
//   //新品列表
//  export function getNewList(data) {
//   return request({
//     url: 'ItemNeedNew/getList',
//     method: 'get',
//     params:data
//   });
// }

// // 通过商品ID获取商品标题和ID
// export function getItemIdTitleList(data) {
//   return request({
//     url: 'item/getItemIdTitleList',
//     method: 'get',
//     params:data
//   });
// }

// // 新增现有商品需求
// export function addItemNeed(data) {
//   return request({
//     url: 'ItemNeed/addItemNeed',
//     method: 'get',
//     params:data
//   });
// }
// // 新增新品商品需求
// export function addNewItemNeed(data) {
//   return request({
//     url: 'ItemNeed/addNewItemNeed',
//     method: 'get',
//     params:data
//   });
// }

// // 接受/不接受（完成开发状态）
// export function dealNeedResult(data) {
//   return request({
//     url: 'ItemNeed/dealNeedResult',
//     method: 'get',
//     params:data
//   });
// }
