/**
 * 通知详情动态配置
 * @description 部分通知详情包含可点击跳转页面的字符串，需要解析和配置
 * @date 2024-07-08
 */
import { getValidId } from '@/utils/chat_tools.js';

function getDynamicDomId(rule) {
  return `xm-notice-detail-${rule.jump_type}-${rule.jump_id}`;
}

/**
 * 打开聊天界面
 * @param {*} id 聊天对象id
 * @param {*} target
 */
function chatWith(id, target = '_blank', context) {
  let routeData = context.$router.resolve({
    path: '/chat/' + getValidId(id),
  });
  context.$store.commit('clearUnread');
  window.open(routeData.href, target);
}

/**
 *
 * @param { String } content 待解析的通知详情字符串（new_content）
 * @param { Array } rules 解析规则（jumpInfo）
 */
export function generateNoticeHtmlStr(content, rules) {
  let str = content;
  for (let rule of rules) {
    let replaceTargetStr = `${rule.jump_mark}${rule.jump_content}${rule.jump_mark}`;
    let replaceDomId = getDynamicDomId(rule);
    str = str.replace(
      replaceTargetStr,
      `<span style="cursor: pointer;" id="${replaceDomId}">${rule.jump_content}</span>`
    );
  }
  return str;
}

/**
 * 通知详情点击的统一处理
 * @param { Array } rules 解析规则（jumpInfo）
 * @return { Function } 返回一个点击处理函数
 */
export function generateNoticeClickHandler(rules, context = this) {
  let that = context;
  return function (e) {
    const elemId = e.target.id;
    for (let rule of rules) {
      const domId = getDynamicDomId(rule);
      if (elemId === domId) {
        if (rule.jump_type === 'CHAT_FRAME') {
          // 聊天跳转
          chatWith(rule.jump_id, '_blank', context);
        } else {
          // 订单详情跳转
          that.$router.push(rule.jump_url);
        }
      }
    }
  };
}
