
import { rechargeDetail, reUpload } from '@/api/client-api/fund/fundCenter.js';
export default {
  props: {
    // 币种 1人民币 2美元
    currency: {
      type: [Number, String],
      default: '1',
    },
  },
  data() {
    return {
      recodeList: [],
      pageSize: 8,
      total: 0,
      page_num: 1,
      rejectShow: false,
      refuse_reason: '',
      uploadShow: false, // 重新上传
      banlimgList: [], // 转账记录
      currentID: '', //当前id
    };
  },
  mounted() {
    this.rechargeDetail();
  },
  methods: {
    //重新上传
    newUploadFunc(id) {
      this.uploadShow = true;
      this.currentID = id;
    },
    rechargeDetail() {
      let option = {
        page_size: this.pageSize,
        page_num: this.page_num,
        currency: this.currency,
      };
      rechargeDetail(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.recodeList = res?.data?.list || [];
            this.total = Number(res?.data?.count) || 0;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    closeDetail() {
      this.$emit('closeRecode');
      this.rejectShow = false;
    },
    digpagechange(index) {
      this.page_num = Number(index);
      this.rechargeDetail();
    },
    onreject(detail) {
      this.rejectShow = true;
      this.refuse_reason = detail;
    },
    closeReject() {
      this.rejectShow = false;
    },
    closeReUpload() {
      this.uploadShow = false;
    },
    reUpload() {
      if (this.banlimgList.length == 0) {
        this.$message.warning('请选择转账凭证');
      }
      let option = {
        id: this.currentID,
        img: this.banlimgList.join(','),
      };
      reUpload(option)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.uploadShow = false;
            this.rechargeDetail();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFileFunc(fileArray) {
      this.banlimgList = fileArray;
    },
  },
};
