
export default {
  name: 'PreviewImgsBlock',
  props: {
    imgs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currImgIndex: -1, // 当前预览的图片索引
      showPreview: false,
    };
  },
  computed: {
    previewImgs() {
      return this.imgs.map((x) => {
        if (this.matchType(x) == 'image') {
          return {
            url: x, // #fixme 假数据，后面记得删掉
            type: 'image',
          };
        }
        if (this.matchType(x) == 'video') {
          return {
            url: x, // #fixme 假数据，后面记得删掉
            type: 'video',
          };
        }
      });
    },
  },
  methods: {
    // 点击图片，切换预览的大图
    handleClickImg(index) {
      this.currImgIndex = index;
      this.showPreview = true;
    },
    // 关闭预览弹窗
    handleClosePreview() {
      this.showPreview = false;
    },
  },
};
