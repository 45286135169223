
export default {
  data() {
    return {};
  },
  props: {
    selectedWarehouseNotice: {
      type: Array,
      default: () => [],
    },
  },
};
