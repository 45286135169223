



export default {
  name:'RemoveDialog',
  data(){
    return{
      delAccountOrRoleVisible:false
    };
  },
  props:{
    show: {
      type: Boolean,
      default: false,
    },
    title:{
      type:String,
      default:''
    }
  },
  watch:{
    show(n,o){
      this.delAccountOrRoleVisible = n;
      console.log(n,o);
    },
  },
  mounted(){
    // this.delAccountOrRoleVisible = this.show
  },
  activated(){

  },
  methods:{
    sureDel(){
      this.$emit('suredel');
    },
    closedel(){
      this.$emit('closedel',this.delAccountOrRoleVisible == false);
    }
  }
  
};
