// 账单明细
export const billDetailsTable = [
  {
    filed: 'transaction_over',
    text: '交易完成时间',
    width: '128',
    isEdit: true,
    isHeaderEdit: true,
  },
  {
    filed: 'order_id',
    text: '订单编号',
    width: '168',
    isEdit: true,
  },
  {
    filed: 'tran_id',
    text: '交易号',
    width: '182',
  },
  {
    filed: 'transaction_type',
    text: '交易类型',
    width: '76',
  },
  {
    filed: 'payment_channel_name',
    text: '支付方式',
    width: '76',
  },
  {
    filed: 'currencyChinese',
    text: '币种',
    width: '86',
  },
  {
    filed: 'deduction_amount',
    text: '优惠券抵扣金额',
    width: '118',
  },
  {
    filed: 'actual_amount',
    text: '实际收支金额',
    width: '144',
    isEdit: true,
  },
  {
    filed: 'business_description',
    text: '业务描述',
    width: '160',
    isEdit: true,
  },
];

// 保证金账户
export const depositAccount = [
  {
    filed: 'transaction_over',
    text: '交易完成时间',
    width: '128',
    isEdit: true,
    isHeaderEdit: true,
  },
  {
    filed: 'tran_id',
    text: '交易号',
    width: '200',
  },
  {
    filed: 'transaction_type',
    text: '交易类型',
    width: '104',
  },
  {
    filed: 'margin_type',
    text: '保证金类型',
    width: '126',
  },
  {
    filed: 'currencyChinese',
    text: '币种',
    width: '112',
  },
  {
    filed: 'actual_amount',
    text: '实际收支金额',
    width: '200',
    isEdit: true,
  },
  {
    filed: 'business_description',
    text: '业务描述',
    width: '150',
    isEdit: true,
  },
];
