import Vue from 'vue';
import {
  getValidId,
  getOrderedSessions,
  fuzzySearchObject,
  updateMsgsTimestamps,
  insertNewMessage,
  insertHistoryMessage,
  initMessageList,
  replaceOldMessage,
  getCustomerServiceId,
} from '@/utils/chat_tools.js';

export const state = () => ({
  orderedSessions: null, // 保存已排序的会话列表，根据最新消息的时间排序
  sessionMap: {}, // 所有会话信息映射表，id => session
  sessionMsgs: {}, // 所有会话历史消息映射表，id => { msgs: [...], fetching: false, complete: false }

  myInfo: {}, // 保存“我”的个人信息
  userInfos: {}, // 保存会话对象的信息

  unread: 0, // 未读数
});
export const getters = {
  // 总未读数
  totalUnread: (state) => {
    return state.unread;
  },
  /**
   * 会话模块
   */
  // 根据会话对象的id，获取会话实例
  sessionById: (state) => (id) => {
    let target = getValidId(id);
    if (!target) return null;
    const sessionId = `p2p-${target}`;
    return state.sessionMap?.[sessionId];
  },
  // 根据会话id，获取会话实例
  session: (state) => (sessionId) => {
    return state.sessionMap?.[sessionId];
  },
  // 用用户id搜索用户会话列表
  sessionsByUserId: (state) => (target) => {
    if (!target) return [];
    return state.orderedSessions?.filter((x) => x?.to === target) || [];
  },
  // 根据会话对象id，判断当前会话列表是否有与该对象的会话
  hasSession: (state) => (to) => {
    if (!to) return false;
    return state.orderedSessions?.findIndex((x) => x.to === to) >= 0;
  },

  /**
   * 用户信息模块
   */
  myAvatar: (state) => {
    return state.myInfo?.avatar || '';
  },
  myNickName: (state) => {
    return state.myInfo?.nick || '';
  },
  userInfos: (state) => {
    return state.userInfos;
  },
  userInfoById: (state) => (id) => {
    return state.userInfos?.[id] || {};
  },
  // 用昵称搜索用户，返回用户id（accid/account）数组
  userIdsByNick: (state) => (target) => {
    const targetKey = 'nick',
      targetVal = target,
      list = state.userInfos;
    let res = fuzzySearchObject(list, targetKey, targetVal);
    res = res.map((x) => x.account);
    return res;
  },
  // 用昵称搜索会话，返回会话对象数组
  sessionsByNick: (state, getters) => (target) => {
    if (!target || target.length == 0) {
      return [];
    }
    // 使用 target 获取搜索到的用户 id 列表
    const idList = getters.userIdsByNick(target);
    // 用户 id 列表搜索到目标会话列表
    let res = [];
    if (idList && idList.length > 0) {
      for (const item of idList) {
        res = res.concat(getters.sessionsByUserId(item));
      }
    }
    let set = new Set(res);
    // 会话列表排序，保证客服会话置顶
    res = getOrderedSessions([...set]);
    return res || [];
  },

  /**
   * 历史消息模块
   */

  // 指定会话的历史消息数组
  msgs: (state) => (sessionId) => {
    if (!sessionId) {
      return null;
    }
    return state.sessionMsgs?.[sessionId]?.msgs || [];
  },
  // 指定会话的最远的一条消息
  lastMsg: (state) => (sessionId) => {
    if (!sessionId) {
      return null;
    }
    let msgs = state.sessionMsgs?.[sessionId]?.msgs;
    // 找到第一条非时间戳消息
    for (let msg of msgs) {
      if (msg.from !== 'sys-custom-time') {
        return msg;
      }
    }
    return null;
  },
};
export const mutations = {
  /**
   * 用户数据模块
   */
  // 未读数递增
  addUnread(state) {
    state.unread++;
  },
  // 更新未读数
  updateUnread(state, num) {
    state.unread = num;
  },
  // 清空未读数
  clearUnread(state) {
    state.unread = 0;
  },
  // 设置我的资料
  setMyInfo(state, info) {
    if (!info) return;
    state.myInfo = info;
  },
  // 根据用户id更新用户信息数据
  setUserInfoById(state, info) {
    if (!info?.account) return;
    state.userInfos[info.account] = info;
  },
  // 整个更新用户列表
  updateUsersInfo(state, infos) {
    if (!infos || infos.length === 0) return;
    for (const item of infos) {
      state.userInfos[item.account] = item;
    }
  },

  /**
   * 会话模块
   */

  // 更新会话
  // 将传入的新会话、会话列表与原有的会话列表合并
  updateOrderedSessions(state, list) {
    if (list && list.length > 0) {
      list = list.map((x) => {
        if (x.to) {
          x.to = getValidId(x.to);
        }
        return x;
      });
    }

    const oldSessionList = state.orderedSessions;
    const newSessionList = Vue.prototype.$nim.nim.mergeSessions(
      oldSessionList,
      list
    );
    // 客服置顶
    const csIndex = newSessionList.findIndex(
      (x) => x.to?.toLowerCase() == getCustomerServiceId()
    );
    if (csIndex >= 0) {
      newSessionList[csIndex].isTop = true;
    }
    state.orderedSessions = getOrderedSessions(newSessionList);
  },
  // 更新会话映射表
  updateSessionMap(state, sessions) {
    if (sessions?.length > 0) {
      for (let session of sessions) {
        state.sessionMap[session.id] = session;
      }
    }
  },

  /**
   * 历史消息模块
   */

  // 初始化聊天记录列表，间隔超过 5 分钟的消息之间插入自定义时间戳消息
  initMsgs(state, { msgs, sessionId, complete = true }) {
    let msgArr = msgs;
    let msgList = initMessageList(msgArr);
    if (!state.sessionMsgs?.[sessionId]) {
      state.sessionMsgs[sessionId] = {
        msgs: [],
        fetching: false,
        complete: false,
      };
    }
    state.sessionMsgs[sessionId].msgs = msgList;
    state.sessionMsgs[sessionId].complete = complete;
  },
  // 用于合并加载更多历史聊天消息
  mergeOldMsgs(state, msgs) {
    if (msgs?.length === 0) return;
    let sessionId = msgs[0]?.sessionId;
    if (!state.sessionMsgs?.[sessionId]) {
      state.sessionMsgs[sessionId] = {
        msgs: [],
        fetching: false,
        complete: false,
      };
    }
    let msgList = state.sessionMsgs[sessionId].msgs;
    let lastMsg = null,
      lastTimeMsgStamp = 0;
    for (let msg of msgList) {
      if (msg.from === 'sys-custom-time') {
        lastTimeMsgStamp = msg.time;
        break;
      }
    }
    for (let i = msgs.length - 1; i >= 0; i--) {
      let msg = msgs[i];
      // 跳过时间比 msgList 最旧消息时间更新的
      if (msg.time >= msgList?.[0].time) continue;

      /**
       * 插入时间戳消息的条件：
       * 第一条消息 或
       * 上一条消息不是时间戳消息 且满足以下任一条件：
       *    1. 最后一条消息
       *    2. 与上一条消息间隔 2min 以上
       *    3. 与上一个时间戳消息间隔 2min 以上
       */
      let insertTimeMsg =
        !lastMsg ||
        (lastMsg.from !== 'sys-custom-time' &&
          (i === 0 ||
            lastMsg.time - msg.time > 2 * 60 * 1000 ||
            lastTimeMsgStamp - msg.time > 2 * 60 * 1000));

      insertHistoryMessage(
        msgList,
        msg,
        insertTimeMsg,
        i === msgs.length - 1 || i === 0
      );
      if (insertTimeMsg) lastTimeMsgStamp = msg.time;
      lastMsg = msg;
    }
  },
  // 插入新消息
  insertMsg(state, msg) {
    let sessionId = msg?.sessionId;
    if (!state.sessionMsgs?.[sessionId]) {
      state.sessionMsgs[sessionId] = {
        msgs: [],
        fetching: false,
        complete: false,
      };
    }
    let msgList = state.sessionMsgs[sessionId].msgs;
    insertNewMessage(msgList, msg);
  },
  // 更新聊天消息列表中的时间戳
  updateMsgsTimeStamp(state, sessionId) {
    if (!state.sessionMsgs?.[sessionId]) {
      state.sessionMsgs[sessionId] = {
        msgs: [],
        fetching: false,
        complete: false,
      };
    }
    let msgList = state.sessionMsgs[sessionId].msgs;
    updateMsgsTimestamps(msgList);
  },
  // 替换掉某条消息（比如撤回消息时）
  replaceMsgById(state, oldMsg, newMsg) {
    let sessionId = oldMsg?.sessionId;
    let msgList = state.sessionMsgs[sessionId].msgs;
    replaceOldMessage(msgList, oldMsg, newMsg);
  },
};

export const actions = {
  /**
   * 会话相关
   */

  updateSessions({ commit }, sessions) {
    if (sessions?.length > 0) {
      commit('updateOrderedSessions', sessions);
      commit('updateSessionMap', sessions);
    }
  },
  // 进入聊天窗口时，初始化会话列表（从本地数据库中获取最近20个会话对象)
  initSessions({ dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await Vue.prototype.$nim.getLatestNLocalSessions(20);
        let list = res.sessions || [];
        dispatch('updateSessions', list);
        resolve(list);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },
  // 添加置顶会话（细目客服）
  async addStickTopSession({}, data) {
    return Vue.prototype.$nim.setStickTopSession(getCustomerServiceId());
  },

  /**
   * 用户信息相关模块
   */

  // 根据id从服务器获取用户资料
  async fetchUserInfo({ commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Vue.prototype.$nim.getUserInfoById(id);
        if (res) {
          commit('setUserInfoById', res);
        }
        resolve(res);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },
  // 根据id数组，从服务器获取相应的用户资料
  async fetchUserInfos({ state, commit }, ids) {
    return new Promise(async (resolve, reject) => {
      try {
        // 只获取本地无资料的
        const params = ids.filter((x) => !state.userInfos?.[x]);
        if (params?.length === 0) {
          return resolve([]);
        }
        const res = await Vue.prototype.$nim.getUsersInfoByIds(params);
        if (res) {
          commit('updateUsersInfo', res);
        }
        resolve(res);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },
  // 查询用户是否有云信账号
  async doesUserHaveNimAccount({ state, dispatch }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        // 先从本地的 infos 中查询
        let checkInLocalInfos = !!state.userInfos?.[id];
        if (checkInLocalInfos) {
          return resolve(state.userInfos[id]);
        }
        // 如果本地无，则从服务器获取
        let checkInServer = await dispatch('fetchUserInfo', id);
        if (checkInServer) {
          return resolve(checkInServer);
        }
        // 如果本地和服务器都无，则该用户无云信账号
        return resolve();
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },
  /**
   * 获取聊天历史记录
   * @param { String } id 会话对象id
   * @param { String } type 'local'(default) or 'cloud'
   */
  async fetchHistoryMsgs(
    { getters, commit },
    { id, type = 'local', isInit = false, limit = 30 }
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        let lastMsg = isInit ? null : getters.lastMsg(`p2p-${id}`);
        let fetchFuncName =
          type === 'local'
            ? 'getLocalHistoryMsgsById'
            : 'getCloudHistoryMsgsById';
        const res = await Vue.prototype.$nim[fetchFuncName](id, lastMsg, limit);
        if (res) {
          if (isInit) {
            commit('initMsgs', {
              msgs: res,
              sessionId: `p2p-${id}`,
              complete: res.length < limit, // 如果获取的消息数量小于limit，说明已经获取完毕
            });
          } else {
            commit('mergeOldMsgs', res);
          }
          if (type === 'cloud') {
            // 如果从云端获取历史聊天记录，则更新本地数据库
            await Vue.prototype.$nim.updateLocalMsgs(res);
          }
        }
        resolve(res);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },

  /**
   * 收发消息相关模块
   */

  sendMessage({ commit }, msg) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Vue.prototype.$nim.sendMessage(msg);
        if (res) {
          if (res.type !== 'tip') {
            commit('insertMsg', res);
          } else {
            commit('replaceMsgById', msg.originalMsg, res);
          }
        }
        resolve(res);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },
  receiveMessage({ commit }, msg) {
    if (msg) {
      if (msg.type !== 'tip') {
        commit('insertMsg', msg);
      } else {
        const originalMsg = JSON.parse(msg.custom || '{}')?.originalMsg;
        // #memo 这里需要特殊处理一下 sessionId
        // 因为分销商会涉及同步 pc 和 app 消息列表的问题
        // 假设在 app 发送了一条撤回消息，originalMsg 中的 sessionId 将会置为消息接收方端的会话id
        // 这是为了保证在接收端正常显示；而会导致 pc 端的会话id错误
        msg.time = originalMsg.time;
        Vue.prototype.$nim.resetMsgSessionId(originalMsg);
        commit('replaceMsgById', originalMsg, msg);
      }
    }
  },
};
