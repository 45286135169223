/**
 * 纠纷接口
 */
import request from '@/utils/request.js';

// 申请退款
export function applyRefund(data) {
  return request({
    apiModule: 'order',
    url: '/dispute/applyRefund',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 纠纷详情
export function getDisputeDetail(data) {
  return request({
    apiModule: 'order',
    url: '/orderRefund/getIntervention',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 响应(同意/拒绝)平台方案
export function responsePlatformScheme(data) {
  return request({
    apiModule: 'order',
    url: '/order/responsePlatformScheme',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 拒绝供应商方案并提出新方案
export function refuseSupplierPlan(data) {
  return request({
    apiModule: 'order',
    url: '/dispute/applyNewPlan',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 同意供应商方案
export function agreeSupplierPlan(data) {
  return request({
    apiModule: 'order',
    url: '/dispute/agreePlan',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 撤销纠纷
export function cancelRefund(data) {
  return request({
    apiModule: 'order',
    url: '/Order/cancelRefund',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 申请平台介入
export function applyPlatform(data) {
  return request({
    apiModule: 'order',
    url: '/dispute/applyPlatform',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 提交退货凭证
export function submitReturnProof(data) {
  return request({
    apiModule: 'order',
    url: '/dispute/submitRefund',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
