/**
 * 中英文标签配置
 * DetailFormAside.vue
 */
export const FORM_ASIDE_LABELS = {
  'zh-cn': {
    follow: '收藏',
    followed: '已收藏',
    compare: '对比',
    report: '举报',
    'sale-policy': '销售政策',
    'prohibition-platform': '禁售平台',
    'sample-policy': '样品政策',
    'lock-inventory-tag': '接受锁货',
    'prediction-tag': '接受预测',
    'sample-policy-tag': '接受样品政策',
    'wholesale-tag': '支持批发',
    'suggested-min-price-tag': '建议最低零售价',
    inventory: '库存资料',
    information: '资料',
    'lowest-retail-price': '最低零售价资料',
    'freight-template': '查看运费模版',
    'operating-fee-template': '查看操作费模版',
    preview: '预览',
    down: '下载',
    tips: '温馨提示：',
    'not-support-return': '本商品不支持无理由退货；',
    'after-sales': '售后服务：',
    'supplier-issues': '供应商问题',
    'distributor-issues': '分销商问题',
    'supplier-issues-tip': '供应商问题包括：产品质量问题；发货错误；货不对版等',
    'distributor-issues-tip': '分销商问题包括：客服问题；夸大宣传；下单错误等',
    'sample-policy-content': (rate) =>
      `往返运费由分销商承担，商品费用供应商承担${rate}%`,
  },
  en: {
    follow: 'Follow',
    followed: 'Followed',
    compare: 'Compare',
    report: 'Report',
    'sale-policy': 'Sale policy',
    'prohibition-platform': 'Prohibition platform',
    'sample-policy': 'Sample policy',
    'lock-inventory-tag': 'Lock inventory',
    'prediction-tag': 'Prediction',
    'sample-policy-tag': 'Sample Policy',
    'wholesale-tag': 'Wholesale',
    'suggested-min-price-tag': 'Suggested minimum retail price',
    inventory: 'Inventory',
    information: 'Information',
    'lowest-retail-price': 'Lowest retail price',
    'freight-template': 'Freight template',
    'operating-fee-template': 'Operating fee template',
    preview: 'Preview',
    down: 'Download',
    tips: 'Tips：',
    'not-support-return':
      'This product does not support returns without reason',
    'after-sales': 'After sales：',
    'supplier-issues': 'Supplier issues',
    'distributor-issues': 'Distributor issues',
    'supplier-issues-tip':
      'Supplier issues include：goods quality issues；shipment error；unmatched version',
    'distributor-issues-tip':
      'Distributor issues include：customer service issues；ballyhoos；ordering error',
    'sample-policy-content': (rate) =>
      `The round-trip cost shall be borne by the distributor，suppliers bear ${rate}% of the cost of the goods`,
  },
};
