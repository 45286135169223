
import { mapGetters } from 'vuex';
import FilePreviewDialog from './components/FilePreviewDialog';
import FilesPreviewDialog from './components/FilesPreviewDialog';
import DownloadDialog from './components/DownloadDialog.vue';
import ImagesPreviewDialog from './components/ImagesPreviewDialog.vue';
/**
 * 六大模块的分区文件引入
 */
import DetailHeader from './DetailHeader'; // 【1】顶部标题区域
import DetailFormAside from './DetailFormAside'; // 【2-1】商品选择表单区域左侧
import DetailFormMain from './DetailFormMain'; // 【2-2】商品选择表单区域右侧
import DetailRemoved from './DetailRemoved.vue'; // 【2-3】商品下架提示信息
import DetailAds from './DetailAds'; // 【3】中部推荐广告区域
import DetailInfoAside from './DetailInfoAside'; // 【4-1】商品信息区域左侧
import DetailInfoMain from './DetailInfoMain'; // 【4-2】商品信息区域右侧
import DetailFooter from '@/components/indexbottom.vue'; // 【5】商品底部区域
import BackToTop from '@/components/BackToTop.vue'; // // 【6】固定区域

/**
 * api
 */
import { getShopGoodsList } from '@/api/client-api/goods/goodsClass';
import { visitsLimit } from '@/api/client-api/api.js';
import {
  getPopularItems,
  getSimilarRecommendedItems,
} from '@/api/goods/good_detail.js';
// import { itemdetail, getGoodScores } from '@/api/shopindex';

/**
 * 工具函数
 */
import { previewExcelUrl, previewOffice } from './js/preview-url-files';

/**
 * 多语言配置
 */
import { CURRENCY_CONFIG, COMMON_LABELS } from './js/label_config_common';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  components: {
    FilePreviewDialog,
    FilesPreviewDialog,
    ImagesPreviewDialog,
    DownloadDialog,
    /**
     * 六大模块的分区组件
     */
    DetailHeader,
    DetailFormAside,
    DetailFormMain,
    DetailRemoved,
    DetailAds,
    DetailInfoAside,
    DetailInfoMain,
    DetailFooter,
    BackToTop,
  },
  head() {
    return {
      title: this.tdkTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.tdkDescription,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.tdkKeywords,
        },
      ],
    };
  },
  async asyncData(app) {
    function getLowestPriceSku(skuList) {
      let skuIdx = 0,
        wareIdx = 0, // 仓库默认取第一个
        minPrice = Infinity;
      const list = skuList;
      for (let i = 0; i < list?.length; i++) {
        let wareList = list[i]?.ware_info_list || [];
        // 跳过无库存的 sku
        if (Number(list[i]?.store) === 0 || wareList.length === 0) continue;
        let p = parseFloat(list[i]?.price || minPrice);
        if (p < minPrice) {
          skuIdx = i;
          minPrice = p;
        }
      }
      return [
        skuList?.[skuIdx],
        skuList?.[skuIdx]?.ware_info_list?.[wareIdx]?.ware_id || '',
        skuList?.[skuIdx]?.ware_info_list?.[wareIdx]?.ware_type || '',
      ];
    }
    // TDK
    let tdkTitle = '电商供应链平台-细目商城';
    let tdkDescription =
      '细目商城致力于构建全球分销供应链平台，为您提供商品信息，精选好品，共建美好生活。';
    let tdkKeywords = '电商供应链平台,细目商城';
    try {
      // 获取商品基本信息
      let itemId = app.params.id;
      // let lang = app.store.state.common.currLanguage;
      let lang = 'zh-cn';
      let basicInfo = await app.$itemdetail({
        itemId,
        API_LANG_TYPE: lang,
      });
      let goodInfo,
        skuList,
        goodImages,
        currSkuInfo,
        currWarehouseId,
        currWarehouseType,
        hotGoodsList,
        recommendGoodsList;
      if (basicInfo?.errcode == 0) {
        goodInfo = basicInfo.data.itemInfo;
        skuList = basicInfo.data.skuList?.filter(
          (x) => Number(x?.store || 0) > 0
        ); // 过滤掉库存为空的 sku
        hotGoodsList = basicInfo.data.hotItemList?.filter(Boolean)?.map((x) => {
          x.alt = x.title;
          return x;
        });
        recommendGoodsList = basicInfo.data.recommendItemList
          ?.filter(Boolean)
          ?.map((x) => {
            x.alt = x.title;
            return x;
          });
        [currSkuInfo, currWarehouseId, currWarehouseType] =
          getLowestPriceSku(skuList);
        app.store.dispatch('good/setGoodMainImg', {
          itemId,
          imgUrl: currSkuInfo?.sku_image,
        });
        if (currSkuInfo?.sku_image) {
          goodImages = [
            {
              url: currSkuInfo?.sku_image,
              type: 'image',
              alt: goodInfo?.title || '',
            },
          ];
        } else {
          goodImages = [
            {
              url: goodInfo.image_default_id,
              type: 'image',
              alt: goodInfo?.title || '',
            },
          ];
        }
        tdkTitle = goodInfo.title + '_电商供应链平台-细目商城';
        tdkDescription =
          '细目商城致力于构建全球分销供应链平台，为您提供' +
          goodInfo.title +
          '商品信息，精选好品，共建美好生活。';
        tdkKeywords = goodInfo.title + ',电商供应链平台,细目商城';
      } else if (basicInfo?.errcode == 80204) {
        // 当用户试图在中文界面访问英文商品 or 英文界面访问中文商品，就会返回 80204，强行跳转到首页
        app.redirect('/');
        return {};
      } else if (basicInfo?.errcode == 80205) {
        // 商品 id 不存在，返回 80205
        app.redirect('/');
        return {};
      }
      return {
        itemId,
        goodInfo,
        skuList,
        hotGoodsList,
        recommendGoodsList,
        goodImages,
        currSkuInfo,
        currWarehouseId,
        currWarehouseType,
        tdkTitle,
        tdkDescription,
        tdkKeywords,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log('good-detail/index.vue', error?.status, error?.statusText);
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        itemId: '',
        goodInfo: null,
        skuList: [],
        hotGoodsList: [],
        recommendGoodsList: [],
        goodImages: [],
        currSkuInfo: null,
        currWarehouseId: '',
        currWarehouseType: '',
      };
    }
    return { tdkTitle, tdkDescription, tdkKeywords };
  },
  data() {
    return {
      /**
       * 基本数据
       */
      // itemId: this.$route.params.id,
      // currWarehouseId: '', // 当前仓库ID

      // goodInfo: {}, // info.itemInfo
      // skuList: [], // 当前sku列表
      // hotGoodsList: [], // 人气单品列表
      // recommendGoodsList: [], // 同款推荐列表
      // currSkuInfo: null, // 当前选中的sku对象
      lang: 'zh-cn', // 当前页面语言，默认为简体中文

      goodScores: [], // 商品的评分（商品、服务和物流）
      evaluationCount: '', // 评价数

      /**
       * 其他页面数据
       */
      // isFollowed: false, //是否收藏
      showCompareCol: false, // 是否显示商品对比栏
      focusedTabName: 'good-introduce', //tabs绑定值
      // choiceList: [], // 精选商品
      hotSaleStoreList: [], // 店铺热销
      hotAttentionList: [], // 热门收藏

      /**
       * 新品需求弹窗
       */
      dialogVisible: false, //控制新品需求弹窗
      //开发新品需求表单
      formobj: {
        pic: '', // 商品图片
        name: '',
        reacpe: '1', // 是否接收相似产品
        pubilc: '1', // 是否接收公开展示
        suppername: '',
        suppertel: '',
        brandName: '',
        sites: '', // 网址链接
        remark: '',
        uploadshopimg: [],
      },

      /**
       * 埋点
       */
      portNum: 0, // 此页面埋点需要整合2个接口的数据
      allList: [], // 获取到的2个接口的数据
      enterList: [], //进入页面埋点的参数

      /**
       * 资源下载与预览
       */
      previewType: '', // 预览的文件类型，pdf, word, excel
      showPreviewDialog: false, // 预览资源弹窗
      showPreviewFileDialog: false,
      showPreviewImageDialog: false,
      showDownloadDialog: false, // 下载资源弹窗
      showLowPriceFileDialog: false, // 最低零售价资料预览
      // cnProfile: '',
      // enProfile: '',
      // lowestPriceFiles: [], // 最低零售价资料
      previewImgs: [],
      previewImgIndex: -1,
      jszipInstance: null,
      isPreviewImgsInZip: false,
    };
  },
  computed: {
    ...mapGetters({
      getGoodMainImgByItemId: 'good/getGoodMainImgByItemId',
      getGoodsCompareItemIndexBySkuId: 'good/getGoodsCompareItemIndexBySkuId',
      currLang: 'common/currLang',
    }),
    // 当前货币标志
    currencyMark() {
      return CURRENCY_CONFIG[this.lang || 'zh-cn'];
    },
    // 标签多语言配置
    labelConfig() {
      return COMMON_LABELS[this.lang || 'zh-cn'];
    },
    isFollowed() {
      return this.goodInfo?.is_fav == 1;
    },
    cnProfile() {
      return this.goodInfo?.cn_resource
        ? process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + this.goodInfo?.cn_resource
        : '';
    },
    enProfile() {
      return this.goodInfo?.en_resource
        ? process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + this.goodInfo?.en_resource
        : '';
    },
    lowestPriceFiles() {
      const fileArr = JSON.parse(this.goodInfo?.retail_price_url || '[]');
      return fileArr
        ? fileArr.map((x) => {
            const fileInfo = {
              fileName: x,
              fileUrl: process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + x,
            };
            return fileInfo;
          })
        : [];
    },
    // 当前商品，用于商品对比的商品信息对象
    currSku() {
      if (!this.currWarehouseId) {
        return;
      }
      let wareId = this.currWarehouseId;
      if (!this.currSkuInfo) {
        return;
      }
      let wareInfo = this.currSkuInfo.ware_info_list.find(
        (x) => x.ware_id == wareId
      );
      // 计算价格，如有优惠价格，则用优惠价格
      let goodPrice = Math.min(
        parseFloat(wareInfo?.price || Infinity),
        parseFloat(wareInfo?.favorable_price || Infinity)
      );
      if (!isFinite(goodPrice)) goodPrice = 0;
      const info = {
        sku_id: this.currSkuInfo.sku_id,
        item_id: this.goodInfo?.item_id,
        title: this.goodInfo?.title,
        price: goodPrice,
        imgUrl: this.currSkuInfo.sku_image || this.goodInfo?.image_default_id, // 首选sku图片，若无，则用商品主图
        mainImgUrl: this.goodInfo?.image_default_id,
      };
      return info;
    },
    // 当前选择的skuId
    currSkuId() {
      return this.currSkuInfo?.sku_id || '';
    },
    // 当前商品失效状态 0 未失效  1 供应商已退店  2 仅下架
    failureState() {
      return this.goodInfo?.failure_state?.toString() || '0';
    },
    // 当前供应商退店剩余天数
    exitStoreDays() {
      return this.goodInfo?.exit_store_rest_days?.toString() || '0';
    },
    // 退店公式提示文案
    exitStoreTipFont() {
      if (this.lang == 'zh-cn') {
        return `${'该店铺将于' + this.exitStoreDays + '天后退出细目平台'}`;
      }
      if (this.lang == 'en') {
        return `${
          'The store will exit the platform in ' + this.exitStoreDays + ' days'
        }`;
      }
    },
    // 禁售平台
    prohibitionPlatform() {
      let str = this.goodInfo?.no_saleable_platform;
      if (
        this.goodInfo?.other_no_saleable_platform &&
        str?.indexOf('其他') > -1
      ) {
        let arr = str.split(',')?.filter((item) => {
          return item != '其他';
        });
        let arr2 = this.goodInfo.other_no_saleable_platform
          ?.split(',')
          .concat(arr);
        str = arr2.join(',');
      }
      return str;
    },
  },
  watch: {
    // 埋点
    portNum(val) {
      if (val == 2) {
        this.allList = [
          this.goodInfo,
          // ...this.choiceList,
          ...this.hotSaleStoreList,
          ...this.hotAttentionList,
          ...this.hotGoodsList,
          ...this.recommendGoodsList,
        ];
        this.enterList = [];
        for (let i = 0; i < this.allList.length; i++) {
          let item = {};
          item['item_id'] = this.allList[i].item_id;
          item['time_in'] = Date.now();
          item['route'] = this.getStayRoute(this.$route);
          item['operating_system'] = this.getOperatingSystem();
          item['action_type'] = 1;
          this.enterList[i] = item;
        }
        this.enterList[0].action_type = 2;
        this.burialPoint(this.enterList);
      }
    },
  },

  methods: {
    // 访问限制接口
    visitsLimit() {
      visitsLimit().then((res) => {
        // console.log(res, 'res');
      });
    },
    /**
     * 数据处理
     */

    // 处理轮播图的视频和图片列表数据
    // ['xx.jpg', 'yyy.mp4'] 处理为
    // [{url: 'xx.jpg', type: 'image'}, {url: 'yyy.mp4', type: 'video'}]
    normalizeImgs(medias) {
      if (!medias) {
        return [];
      }
      let arr = medias?.filter(Boolean) || [];
      return arr.map((x) => {
        let type = this.matchType(x);
        return {
          url: x,
          type: type && type !== 'other' ? type : 'image',
          alt: this.goodInfo?.title || '',
        };
      });
    },
    // 更新商品的评价数
    handleUpdateEvaluationCount(count) {
      this.evaluationCount = count;
    },
    /**
     * 更新当前选中的仓库ID和仓库类型
     * @param id 仓库id
     * @param type 仓库类型
     */
    handleUpdateWarehouse(id, type = '') {
      this.currWarehouseId = id;
      this.currWarehouseType = type;
    },
    /**
     * 更新当前选中的sku对象
     * @param { String } skuId
     */
    handleUpdateSku(skuId) {
      this.currSkuInfo = this.skuList.find((x) => x.sku_id === skuId);
    },
    // 控制商品对比栏的显隐
    showGoodCompare() {
      this.showCompareCol = !this.showCompareCol;
    },
    /**
     * 页面触发
     */
    /**
     * 点击tabs里的立即购买
     */
    handleClickBuyNow() {
      this.$refs.detailFormMain?.handleClickPurchase();
      this.scrollToTop();
    },
    /**
     * 点击商品对比图标
     * 1. 如果当前商品对比栏打开，则执行添加或删除当前sku
     * 2. 如果当前商品对比栏关闭，则先打开对比栏并添加当前sku
     */
    handleClickCompare() {
      if (!this.currSkuId) {
        // 当前无sku被选中，则点击对比按钮执行显隐对比栏操作
        this.showGoodCompare();
        this.$nextTick(() => {
          this.$refs?.skuCompareCol?.showTip('请选中一个规格');
        });
        return;
      }
      let hasCurrSku =
        this.getGoodsCompareItemIndexBySkuId(this.currSkuId) >= 0;
      // 如果当前对比栏打开且当前sku在商品对比列表中，则执行删除
      if (this.showCompareCol && hasCurrSku) {
        this.$refs.skuCompareCol?.deleteSku(this.currSkuId);
      } else {
        this.showCompareCol = true;
        this.$nextTick(() => {
          // 如果当前对比栏关闭或者sku不在商品对比列表中，则执行插入
          this.$refs.skuCompareCol?.insertSku(this.currSku);
        });
      }
    },
    /**
     * 更新轮播图的主图
     * @param url 目标显示的图片url
     */
    handleUpdateCarouselImg(url) {
      this.$refs.detailFormAside?.handleUpdateCarouselImage(url);
    },
    /**
     * 页面滚动聚焦到置顶的标签页
     * @param tabKey 标签页的键值
     */
    handleFocusTab(tabKey) {
      this.$refs.detailInfoMain.focusedTabName = tabKey;
    },
    /**
     * 打开新品弹窗
     * #TODO 商品需求功能先注释或关闭
     */
    handleOpenNewDemandDialog() {
      return;
      this.$refs.newDemand?.openNew();
    },
    /**
     * 打开文件预览弹窗
     */
    handleOpenPreviewFileDialog(show, type) {
      if (show === false) {
        this.showPreviewFileDialog = false;
        return;
      }
      this.previewType = type === 'pdf' ? 'header' : 'no-header';
      this.showPreviewFileDialog = true;
    },
    /**
     * 打开图片预览弹窗
     */
    handleOpenPreviewImageDialog(params) {
      let { imgs, zip, imgIndex } = params;
      this.previewImgs = imgs ? imgs.slice() : [];
      this.previewImgIndex = imgIndex;
      this.jszipInstance = zip;
      this.isPreviewImgsInZip = !!zip;
      this.showPreviewImageDialog = true;
    },
    /**
     * 资源文件预览弹窗
     */
    /**
     * 点击库存资料预览按钮，预览库存资料文件
     */
    handlePreviewStockFile() {
      if (!process.client) {
        return;
      }
      this.showPreviewFileDialog = true;
      this.previewType = 'no-header';
      this.$nextTick(() => {
        let containerId = 'preview-container';
        let previewDom = document.getElementById(containerId);
        previewDom.innerHTML = '';
        let url = `/shopapi/item/exportInventoryResource?item_id=${
          this.goodInfo?.item_id
        }&token=${this.$cookies.get(CUR_TOKEN_NAME)}`;
        // previewOffice(url, containerId)
        previewExcelUrl(url, containerId)
          .then((res) => {
            console.log('预览库存资料成功');
          })
          .catch((err) => {
            const errorTipDom = document.createElement('p');
            errorTipDom.innerHTML = 'Unknown Error - 发生未知错误，预览失败';
            errorTipDom.style =
              'width: 100%; height: 100px; line-height: 100px; text-align: center; color: #d22316; font-size: 16px;';
            previewDom.innerHTML = '';
            previewDom.appendChild(errorTipDom);
            console.error(err);
          });
      });
    },
    // 控制【资料】压缩包文件列表预览的弹窗显隐
    handleMaterialPreview(val) {
      this.showPreviewDialog = val;
    },
    // 控制【资料】下载弹窗显隐
    // handleDownloadDialog(val) {
    //   this.showDownloadDialog = val;
    // },
    // 下载表格
    downloadTabulation() {
      this.downloadFile(
        '/item/itemInfoDownload?itemId=' + this.itemId,
        `${this.itemId}商品基本信息.xlsx`
      );
    },
    // 点击下载【资料】
    handleDownloadMaterial() {
      let url = this.lang === 'en' ? this.enProfile : this.cnProfile;
      let fileName = `${
        this.lang === 'en' ? 'English_Materials' : '中文资料'
      }_${Date.now()}`;

      // 下载资料时同时下载一个 商品基本信息 表格
      // #todo 暂时中英文资料下载的是同一个表格，后续可能会区分中文表格或英文表格
      this.downloadTabulation();
      // 中英文资料
      this.down(url, fileName);
    },
    // 控制【最低零售价资料】文件列表预览的弹窗显隐
    handleLowPriceMaterialPreview(val) {
      this.showLowPriceFileDialog = val;
    },
    // 点击【最低零售价资料】预览弹窗上的下载按钮
    handleClickLowPriceDownload() {
      this.$refs.detailFormAside?.handleDownloadLowPrice();
    },
    /**
     * 回到页面顶部
     */
    scrollToTop() {
      if (!process.client) {
        return;
      }
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },

    /**
     * 请求后端接口
     */

    async fetchGoodScores() {
      try {
        if (!this.goodInfo || !this.goodInfo?.supplier_id) {
          return;
        }
        const params = {
          supplier_id: this.goodInfo?.supplier_id,
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        const res = await this.$getGoodScores(params);
        if (res.errcode == 0) {
          this.goodScores = res.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
    initGoodDetail() {
      let id = this.itemId;
      // 初始化默认选中价格最低的sku
      this.$nextTick(() => {
        this.$refs?.detailFormMain?.selectDefaultSpecOption(
          !!this.goodInfo?.goods_video_path
        );
      });

      /**
       * 轮播图图片列表
       */
      let images = this.goodInfo?.new_list_images || [];
      // 把商品主图放到轮播图列表
      images.unshift(this.goodInfo?.image_default_id);
      // 把商品视频放到轮播图列表第一个
      images.unshift(this.goodInfo?.goods_video_path);
      this.goodImages = this.normalizeImgs(images);
      // 缓存商品主图
      this.$store.dispatch('good/setGoodMainImg', {
        itemId: id,
        imgUrl: this.currSkuInfo?.sku_image,
      });
    },
    // 获取店铺热销、热门收藏
    async fetchHotShopGoods() {
      try {
        const params = {
          supplier_id: this.goodInfo?.supplier_id,
          page_size: '3',
          page_num: '1',
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        const res = await getShopGoodsList(params);
        if (res.errcode == 0) {
          this.hotSaleStoreList = res.data.hot_sale_list
            ?.filter(Boolean)
            ?.map((x) => {
              x.alt = x.title;
              return x;
            });
          this.hotAttentionList = res.data.hot_fav_list
            ?.filter(Boolean)
            ?.map((x) => {
              x.alt = x.title;
              return x;
            });
          if (!this.hotSaleStoreList) {
            this.hotSaleStoreList = [];
          }
          if (!this.hotAttentionList) {
            this.hotAttentionList = [];
          }
          this.portNum++;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取人气单品
    async fetchHotGoods() {
      try {
        const params = {
          itemId: this.itemId,
          MUTE_WARNING: 1,
          API_LANG_TYPE: this.lang,
        };
        const res = await getPopularItems(params);
        if (res?.errcode == 0) {
          this.hotGoodsList =
            res.data?.filter(Boolean)?.map((x) => {
              x.alt = x.title;
              return x;
            }) || [];
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取同款推荐
    async fetchSimilarRecommendedGoods() {
      try {
        const params = {
          itemId: this.itemId,
          MUTE_WARNING: 1,
          API_LANG_TYPE: this.lang,
        };
        const res = await getSimilarRecommendedItems(params);
        if (res?.errcode == 0) {
          this.recommendGoodsList =
            res.data?.filter(Boolean)?.map((x) => {
              x.alt = x.title;
              return x;
            }) || [];
        }
      } catch (err) {
        console.error(err);
      }
    },

    /**
     * 埋点
     */
    leavePage() {
      for (let i = 0; i < this.enterList.length; i++) {
        this.enterList[i].time_out = Date.now();
      }
      this.burialPoint(this.enterList);
    },
  },
  async mounted() {
    this.visitsLimit();
    this.scrollToTop(); // 打开商品详情自动到顶部
    // this.lang = this.currLang;
    this.initGoodDetail();
    this.fetchHotShopGoods(); // 获取店铺热门
    this.fetchHotGoods(); // 获取人气单品
    this.fetchSimilarRecommendedGoods(); // 获取同款推荐
    this.fetchGoodScores();
  },
  beforeDestroy() {
    this.leavePage();
  },
};
