
export default {
  name: 'OperationGroup',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    // 触发 op 按钮对应的事件
    handleClick(item) {
      this.$emit(item.event);
    },
  },
};
