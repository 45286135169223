// 公用的才放在这里！！！！！！！！！！例如地址，分类等
export default ({ $axios }, inject) => {
  // 获取省份
  inject('getStates', (data) =>
    $axios({
      url: 'user/getStates',
      method: 'get',
      params: data,
    })
  );
  // 获取类目
  inject('getAllCategoryInfo', (data) =>
    $axios({
      url: '/Category/getAllCategoryInfo',
      method: 'get',
      params: data,
      headers: {
        non_empty_targets: 'data',
      },
    })
  );
  // 获取用户地址列表
  inject('getSupAddrList', (data) =>
    $axios({
      apiModule: 'user',
      url: '/address/distributorAddrList',
      method: 'get',
      params: data,
    })
  );
  // 获取国家列表
  inject('getCountries', (data) =>
    $axios({
      url: 'user/getCountries',
      method: 'get',
      params: data,
    })
  );

  // 获取可售平台
  inject('getSlaePlatformList', (data) =>
    $axios({
      url: '/BrandAuthorization/getPlatformList',
      apiModule: 'goods',
      method: 'get',
      params: data,
    })
  );
};

// TODO 可能这个方法还有救
// import request from '@/utils/request.js';

// //地址管理列表
// export function getSupAddrList(data) {
//   return request({
//     url: 'UserAddr/getSupAddrList',
//     method: 'get',
//     params: data
//   });
// }

// // 地址管理详情
// export function getSupAddrInfo(data) {
//   return request({
//     url: 'UserAddr/getSupAddrInfo',
//     method: 'get',
//     params: data
//   });
// }

// // 地址管理新增
// export function addSupAddr(data) {
//   return request({
//     url: 'UserAddr/addSupAddr',
//     method: 'post',
//     data: data
//   });
// }

// // 地址管理编辑
// export function editSupAddr(data) {
//   return request({
//     url: 'UserAddr/editSupAddr',
//     method: 'post',
//     data: data
//   });
// }

// // 地址删除
// export function delSupAddr(data) {
//   return request({
//     url: 'UserAddr/delSupAddr',
//     method: 'post',
//     data: data
//   });
// }

// // 设置默认地址
// export function setSupAddr(data) {
//   return request({
//     url: 'UserAddr/setSupAddr',
//     method: 'post',
//     data: data
//   });
// }

// // 品牌授权列表
// export function authList(data) {
//   return request({
//     url: '/BrandAuthorization/getAuthInfoList',
//     method: 'get',
//     params: data
//   });
// }

// // 详情
// export function getInfo(data) {
//   return request({
//     url: '/BrandAuthorization/getInfo',
//     method: 'get',
//     params: data
//   });
// }

// // 产品ID搜索
// export function getItemInfo(data) {
//   return request({
//     url: 'AuthBookRe/getItemInfo',
//     method: 'get',
//     params: data
//   });
// }

// // 申请品牌授权
// export function authAdd(data) {
//   return request({
//     url: '/BrandAuthorization/addAuth',
//     method: 'post',
//     data: data
//   });
// }
// // 获取可售所有平台
// export function getSlaePlatformList(data) {
//   return request({
//     url: '/BrandAuthorization/getPlatformList',
//     method: 'get',
//     params: data
//   });
// }
// // 获取供应商id和供应商名称
// export function getSupplierBaseInfo(data) {
//   return request({
//     url: '/BrandAuthorization/getSupplierBaseInfo',
//     method: 'get',
//     params: data
//   });
// }

// // 获取供应商id和供应商名称
// export function getBrandList(data) {
//   return request({
//     url: '/BrandAuthorization/getBrandList',
//     method: 'get',
//     params: data
//   });
// }

// // 获取国家列表
// export function getCountries(data) {
//   return request({
//     url: 'user/getCountries',
//     method: 'get',
//     params: data
//   });
// }
// // 获取省列表
// export function getStates(data) {
//   return request({
//     url: 'user/getStates',
//     method: 'get',
//     params: data
//   });
// }
// // 获取市列表
// export function getCity(data) {
//   return request({
//     url: 'user/getCity',
//     method: 'get',
//     params: data
//   });
// }
// // 获取区列表
// export function getRegions(data) {
//   return request({
//     url: 'user/getRegions',
//     method: 'get',
//     params: data
//   });
// }

// // 获取洲列表
// export function getContinents(data) {
//   return request({
//     url: '/user/getContinents',
//     method: 'get',
//     params: data
//   });
// }
// // 删除授权书
// export function distributorAuthdelete(data) {
//   return request({
//     url: '/AuthBookRe/distributorAuthdelete',
//     method: 'get',
//     params: data
//   });
// }
// // 编辑授权书
// export function authEdit(data) {
//   return request({
//     url: '/AuthBookRe/authEdit',
//     method: 'post',
//     data: data
//   });
// }
