
const tabName = {
  ALREADY: '1', //已收藏
  BEENACHANGE: '2', //有变动
  PREREMOVEDFROMSHELVES: '3', //预下架
  BEENREMOVED: '4', //已下架
  RECYCLEBIN: '5', //回收站
  BLACKLIST: '6', //黑名单
};
export default {
  data() {
    return {
      tabName,
      activeName: '1', //一级标签
      isActive: '-1', //判断是否继续筛选
      secondTabs: '4', //二级标签
    };
  },
  props: {
    tabNum: {
      type: Object,
      default: {},
    },
    active: {
      type: [Number, String],
      default: '1',
    },
    // 二级标签页
    secondTabNum: {
      type: [Number, String],
      default: '4',
    },
  },
  computed: {
    Num() {
      return this.tabNum;
    },
  },
  watch: {
    // 一级标签
    active(n) {
      this.activeName = n;
    },
    // 二级标签
    secondTabNum(n) {
      this.secondTabs = n;
    },
  },
  methods: {
    handleClick() {
      if (this.isActive == this.activeName) {
        return;
      }
      this.isActive = this.activeName;
      this.secondTabs = '-1';
      if (
        this.activeName === this.tabName.ALREADY ||
        this.activeName === this.tabName.BEENREMOVED
      ) {
        this.secondTabs = '4';
      }
      this.$emit('handleClick', this.activeName, this.secondTabs);
    },
    // 切换二级标签
    changeTabs(n) {
      if (this.secondTabs === n) {
        return;
      }
      this.secondTabs = n;
      this.$emit('handleClick', this.activeName, this.secondTabs);
    },
  },
};
