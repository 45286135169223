
export default {
  name: 'Evidence',
  props: {
    // 证据信息
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
