
import { exportTaskList } from '@/api/client-api/api.js';
export default {
  props: {
    // 这是后端决定的
    type: {
      type: [Number, String],
      default: 23,
    },
    // 每页大小
    pageSize: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      exportList: [], // 导出列表
      tableConfig: [
        {
          label: '任务创建时间',
          prop: 'creation_time',
          width: '171px',
        },
        {
          label: '任务ID',
          prop: 'task_id',
          width: '148px',
        },
        {
          label: '导出申请人',
          prop: 'export_applicant',
          width: '102px',
        },
        {
          label: '任务状态',
          prop: 'status_name',
          width: '102px',
        },
        {
          label: '操作',
          prop: 'operations',
          width: '88px',
          isSlot: true,
        },
      ],
      total: 0, // 总数
      pages: 1, // 当前页码
    };
  },
  methods: {
    exportTaskList() {
      let option = {
        type: this.type,
        pageSize: this.pageSize,
        pageNum: this.pages,
        MUTE_WARNING: 1,
      };
      exportTaskList(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.exportList = res.data?.list || [];
            this.total = Number(res.data?.total) || 0;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    goPage(page) {
      this.pages = Number(page) || 1;
      this.exportTaskList();
    },
    handleClose() {
      this.$emit('close');
    },
  },
  mounted() {
    this.exportTaskList();
  },
};
