
export default {
  name: 'refundIndex',
  props: {
    // 账号相关信息
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      accountNameList: [
        { name: '可用余额' },
        { name: '活动保证金' },
        { name: '运费保证金' },
      ],
      infoData: {}, // 用户账号信息
      bankInfo: {}, // 银行卡信息
      accountList: [], // 账户余额明细
    };
  },
  mounted() {},
  watch: {
    // 账号相关信息
    info: {
      immediate: true,
      deep: true,
      handler(val) {
        this.infoData = JSON.parse(JSON.stringify(val || {}));
        this.bankInfo = JSON.parse(JSON.stringify(val?.bank_card || {}));

        if (this.infoData?.fund_details) {
          this.getDetailMoney();
        }
      },
    },
  },
  methods: {
    getDetailMoney() {
      let accountMoneyList = Object.entries(this.infoData?.fund_details);
      this.accountList = accountMoneyList?.map((item) => {
        return { ...item[1] };
      });
    },
  },
};
