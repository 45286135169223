
export default {
  data() {
    return {};
  },
  props: {
    tableTitle: {
      type: Array,
      default: () => [],
    },
    tableList: {
      type: Array,
      default: () => [],
    },
    ischeck: {
      type: Boolean,
      default: false,
    },
    activeName: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    handleSelectionChange(selection, row) {
      let selectArr = [];
      let selected = selection.length && selection.indexOf(row) !== -1;
      selectArr = selection; // 拿到全部数据
      // 拿到当前数据所对应的另外一条数据
      let operaArr = this.tableList.filter((item) => {
        return item.tid == row.tid && item.attribution != row.attribution;
      });
      // 判断另外一条数据是否在已选中列表中
      let flag = false; // 默认不在
      if (operaArr.length == 0) {
        this.$emit("tableCheck", selectArr);
        return;
      }
      selectArr.forEach((item) => {
        if (
          item.tid == operaArr[0]?.tid &&
          item.attribution == operaArr[0]?.attribution
        ) {
          flag = true;
        }
      });
      if (selected && !flag) {
        // 选中
        selectArr = selectArr.concat(operaArr);
        // 操作当前选中的数据
        operaArr.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        // 取消
        if (flag) {
          // 在选中数据中，去掉数据
          for (let i = 0; i < selectArr.length; i++) {
            if (
              selectArr[i].tid == operaArr[0]?.tid &&
              selectArr[i].attribution == operaArr[0]?.attribution
            ) {
              selectArr.splice(i, 1);
              break;
            }
          }
          operaArr.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        }
      }
      this.$emit("tableCheck", selectArr);
    },
    selectAll(selection) {
      this.$emit("tableCheck", selection);
    },
    selectionChange(val) {
      // 将选中的数据存储起来
      // this.selectData = val
      console.log(val);
    },
  },
};
