
import {
  getRegions,
  getCity,
  getStates,
} from '@/api/client-api/mine/mineapi.js';
export default {
  props: [
    'areaData',
    'continentId',
    'langType',
    'placeholderKey',
    'isEn',
    'en_areaData',
    'isLiveUpdate', // 是否实时更新，每选择一次地址就更新父组件数据
  ],

  data() {
    return {
      showDropdown: false,
      tabName: '',
      province_list: [],
      city_list: [],
      region_list: [],
      /**
       * 需要传出的数据：
       */
      addressData: {
        province: '',
        province_id: '',
        city: '',
        city_id: '',
        region: '',
        region_id: '',
      },
      en_addressData: {
        en_province: '',
        en_city: '',
        en_region: '',
      },
    };
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      const addr = `
      ${this.addressData.province}${
        this.addressData.city && this.addressData.city.length > 0 ? '/' : ''
      }${this.addressData.city}${
        this.addressData.region && this.addressData.region.length > 0 ? '/' : ''
      }${this.addressData.region}
      `;
      return addr.trim();
    },
  },
  methods: {
    //点击x清空选择地址
    clearAddressFunc() {
      Object.keys(this.addressData).forEach((item) => {
        this.addressData[item] = '';
      });
      this.en_addressData = this.$options.data().en_addressData;
      this.$emit('update', this.addressData, this.en_addressData);
    },
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 0：国家菜单；1：省份菜单；2：城市菜单；3：区菜单
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      if (index == 1) {
        this.city_list = [];
        this.region_list = [];
        // 选择省份
        this.addressData.province =
          this.langType == 'en' ? item.name : item.cname;
        this.addressData.province_id = item.id;
        // 清空状态
        this.addressData.city = '';
        this.addressData.city_id = '';
        this.addressData.region = '';
        this.addressData.region_id = '';

        if (this.isEn) {
          this.en_addressData.en_province = item.name;
          this.en_addressData.en_city = '';
          this.en_addressData.en_region = '';
        }
        if (this.isLiveUpdate) {
          this.$emit('update', this.addressData, this.en_addressData, false);
        }
        this.getCityList();
      } else if (index == 2) {
        this.region_list = [];
        // 选择城市
        this.addressData.city = this.langType == 'en' ? item.name : item.cname;
        this.addressData.city_id = item.id;
        // 清空状态
        this.addressData.region = '';
        this.addressData.region_id = '';
        if (this.isEn) {
          this.en_addressData.en_city = item.name;
          this.en_addressData.en_region = '';
        }
        if (this.isLiveUpdate) {
          this.$emit('update', this.addressData, this.en_addressData, false);
        }
        this.getRegionList();
      } else if (index == 3) {
        this.addressData.region =
          this.langType == 'en' ? item.name : item.cname;
        this.addressData.region_id = item.id;
        if (this.isEn) {
          this.en_addressData.en_region = item.name;
        }
        // 可以传出数据了
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.$emit('update', this.addressData, this.en_addressData, true);
      this.showDropdown = false;
      this.$refs.selector && this.$refs.selector.blur(); // 选择器的尖角符号向下
    },

    async getProvinceList() {
      try {
        const params = {
          id: 44,
        };
        const res = await getStates(params);
        if (res?.errcode == 0) {
          this.province_list = res?.data;
          this.changeTab('province');
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getCityList() {
      try {
        // 上级id：没有省份的国家，其城市的上级id就是国家id；否则为省份id

        const params = {
          id: this.addressData.province_id,
        };
        const res = await getCity(params);
        if (res?.errcode == 0) {
          this.city_list = res?.data;
          if (this.city_list.length > 0) {
            this.changeTab('city');
          } else {
            this.closeDropdown();
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getRegionList() {
      try {
        const params = {
          id: this.addressData.city_id,
        };
        const res = await getRegions(params);
        if (res?.errcode == 0) {
          this.region_list = res?.data;
          if (this.region_list.length > 0) {
            this.changeTab('region');
          } else {
            this.closeDropdown();
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        province: '',
        province_id: '',
        city: '',
        city_id: '',
        region: '',
        region_id: '',
      };
      this.en_addressData = this.$options.data().en_addressData;
      this.searchTarget = '';
      this.tabName = '';
      this.province_list = [];
      this.city_list = [];
      this.region_list = [];
    },
  },
  async mounted() {
    this.getProvinceList();
    if (this.province_id) {
      // 使用 provide-inject 传参
      this.addressData.province = this.province || '';
      this.addressData.province_id = this.province_id || '';
      this.addressData.city = this.city || '';
      this.addressData.city_id = this.city_id || '';
      this.addressData.region = this.region || '';
      this.addressData.region_id = this.region_id || '';
    } else {
      // 若父组件传入的地址数据，是在其组件渲染后（比如，在父组件的mounted函数中向后端请求数据）
      // 才获取的，那么就应当使用props传参，参数名为 `areaData`
      this.addressData.province = this.areaData
        ? this.areaData.province || ''
        : '';
      this.addressData.province_id = this.areaData
        ? this.areaData.province_id
        : '';
      this.addressData.city = this.areaData ? this.areaData.city || '' : '';
      this.addressData.city_id = this.areaData ? this.areaData.city_id : '';
      this.addressData.region = this.areaData ? this.areaData.region || '' : '';
      this.addressData.region_id = this.areaData ? this.areaData.region_id : '';
    }
    if (this.addressData.city_id && this.addressData.city_id != '0') {
      const res = await this.getCityList();
    }
    if (this.addressData.region_id && this.addressData.region_id != '0') {
      const res = await this.getRegionList();
    }

    // console.log(this.addressData, '-----------------------------');
  },
  beforeDestroy() {
    this.resetData();
  },
};
