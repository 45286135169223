import request from '@/utils/request.js';

// 获取通知模块选项列表
export function getNoticeModules(data) {
  return request({
    url: '/Notice/moduleType',
    method: 'get',
    params: data,
  });
}
// 获取通知设置列表
export function getNoticeSettings(data) {
  return request({
    url: '/Notice/notificationsSettings',
    apiModule: 'notify',
    method: 'get',
    params: data,
  });
}
// 保存编辑后的通知设置
export function postNoticeSettings(data) {
  return request({
    url: '/Notice/editInbox',
    apiModule: 'notify',
    method: 'post',
    data: data,
    dataType: 'json',
  });
}
