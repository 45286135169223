
export default {
  name: 'waitEvaluateTable',
  data() {
    return {
      colors: ['#FF7802', '#FF7802', '#FF7802'],
    };
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  methods: {
    // 点击跳转 商品详情
    handleClickToGoodsDetail(item) {
      let currency =
        item?.currency == '￥' || item?.currency == '¥' ? '2' : '3';
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img,
        '_blank',
        currency
      );
    },
    // 点击跳转订单详情
    handleClickToOrderDetail(tid) {
      this.openNewPage('/trade/orderdetails', { item_id: tid });
    },
    // 点击跳转供应商店铺
    handleClickToSupplier(supplierId) {
      window.open('/shop_' + supplierId + '.html', '_blank');
    },
    // 点击评价 跳转评价页面
    goEvalueate(tid) {
      this.openNewPage('/trade/orderevaluation', { id: tid });
    },
    // 跳转新开页面方法
    openNewPage(path, query) {
      let pathHerf = this.$router.resolve({
        path, // 跳转的路径
        query,
      });
      window.open(pathHerf.href, '_blank');
    },
  },
};
