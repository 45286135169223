
export default {
  name: '',
  props: {
    label: {
      type: String,
      default: '上传图片',
    },
    // 上传图片限制的提示
    tip: {
      type: String,
      default: '',
    },
    // 图片回显
    fileUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    echoImgUrl() {
      return this.fileUrl ? this.fileUrl?.split(',') : [];
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    uploadImg(file) {
      this.$emit('upload-img', file);
    },
  },
};
