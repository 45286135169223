import request from '@/utils/request.js';
//  获取账号管理列表
export function getAdminUserList(data) {
  return request({
    apiModule: 'user',
    url: 'AdminUser/getAdminUserList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 账号设置页信息
export function getBasicInformation(data) {
  return request({
    apiModule: 'user',
    url: 'user/getBasicInformation',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
//  获取所有角色名称
export function getUserRoleAll(data) {
  return request({
    url: 'AdminUser/getUserRoleAll',
    method: 'get',
    params: data,
  });
}
//  编辑角色名称
export function editRole(data) {
  return request({
    url: 'AdminUser/editRole',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
//  新增账号
export function addAdminUser(data) {
  return request({
    apiModule: 'user',
    url: 'AdminUser/addAdminUser',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
//  删除账号
export function delAdminUser(data) {
  return request({
    apiModule: 'user',
    url: 'AdminUser/delAdminUser',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
//  新增角色
export function addRole(data) {
  return request({
    url: 'AdminUser/addRole',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
//  删除角色
export function delRole(data) {
  return request({
    url: 'AdminUser/delRole',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
//  获取角色权限
export function getJurisdictionInfo(data) {
  return request({
    url: 'AdminUser/getJurisdictionInfo',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
//  修改角色权限
export function editJurisdictionInfo(data) {
  return request({
    url: 'AdminUser/editJurisdictionInfo',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
//  获取角色列列表
export function getUserRoleList(data) {
  return request({
    url: 'AdminUser/getUserRoleList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
//  禁用角色
export function disableRole(data) {
  return request({
    url: 'AdminUser/disableRole',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
//  获取店铺列表
export function getShopList(data) {
  return request({
    url: 'AdminUser/getShopList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
//  获取验证码
export function sendVerifyCode(data) {
  return request({
    url: 'user/sendVerifyCode',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
//  编辑子账号信息
export function editAdminUser(data) {
  return request({
    apiModule: 'user',
    url: 'AdminUser/editAdminUser',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 修改子账号启用/禁用状态
export function disableAdminUser(data) {
  return request({
    apiModule: 'user',
    url: 'AdminUser/disableAdminUser',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
//  获取基本资料
export function getUserBasicinfo(data) {
  return request({
    url: '/user/getUserBasicinfo',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 编辑基础信息
export function editUserBasicInfo(data) {
  return request({
    apiModule: 'user',
    url: '/info/editUserBaseInfo',
    method: 'post',
    data: data,
  });
}
