
/**
 * 多语言配置
 */
import { PLATE_CORRELATION } from './js/label_config_board';
import PlateTitle from './components/PlateTitle.vue';
import BasicGoodsBox from './components/BasicGoodsBox.vue';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'IntelligentRecommend',
  components: {
    PlateTitle,
    BasicGoodsBox,
  },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 推荐列表
    goodsList: {
      type: Array,
      default: () => [],
    },
    // 没有下一页
    noMore: {
      type: Boolean,
      default: false,
    },
    // 查看更多按钮是否出现
    lookMoreBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      token: '',
      pic: '',
      // goodsList: [], // 智能推荐商品列表
      goodsMoreList: [], // 点击查看更多暂存
      // noMore: false, // 没有下一页
    };
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return PLATE_CORRELATION[this.lang || 'zh-cn'];
    },
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
    // this.getIntelligentList();
    this.lookMoreGoods = this._debounce(this.lookMore, 300); // 防抖
  },
  methods: {
    // 获取智能推荐商品列表
    // getIntelligentList() {
    //   let options = {
    //     page_size: this.pageSize, // 页大小
    //     page_num: this.pageNum, // 页号
    //   };
    //   getIntelligentRecommend(options).then((res) => {
    //     if (res.errcode == 0) {
    //       // this.goodsList = res.data;

    //       this.goodsList = res.data.map((obj) => {
    //         return { ...obj, collectFont: false };
    //       });
    //     } else {
    //       this.$message.warning(res.msg);
    //     }
    //   });
    // },
    // 点击商品收藏
    clickCollect(item) {
      this.$emit('collect-goods', item);
    },
    // 点击跳转商品详情页
    goGoodsDetail(item) {
      // 注释掉的原因：
      // 路由拦截器 beforeEach 会把无token的用户强制弹回 /login，不需要在这里进行判断
      // if (!this.token) {
      //   this.$router.push('/login');
      //   return;
      // }
      // 跳转商品详情的公共函数
      // this.toCachedShopdetail(item.item_id, item.image_default_id, '_self');
      this.toCachedShopdetail(item.item_id, item.image_default_id); //新页签打开
    },
    // 点击查看更多
    // lookMore() {
    //   this.pageNum++;
    //   let options = {
    //     page_size: this.pageSize, // 页大小
    //     page_num: this.pageNum, // 页号
    //   };
    //   getIntelligentRecommend(options).then((res) => {
    //     if (res.errcode == 0) {
    //       if (res.data.length !== 0) {
    //         this.goodsMoreList = res.data.map((obj) => {
    //           return { ...obj, collectFont: false };
    //         });
    //         this.goodsList = [...this.goodsList, ...this.goodsMoreList];
    //       }
    //       if (res.data.length === 0 || res.data.length < 20) {
    //         this.noMore = true;
    //       }
    //     } else {
    //       this.$message.warning(res.msg);
    //     }
    //   });
    // },
    lookMore() {
      this.$emit('get-more-intelligent');
    },
  },
};
