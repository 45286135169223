
export default {
  props: {
    // 搜索框为空时的文字占位符
    placeholder: {
      type: String,
      default: '请搜索',
    },
    // 搜索框宽度
    width: {
      type: String,
      default: '230px',
    },
  },
  data() {
    return {
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标
      value: '',
    };
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.value);
    },
    handleClear() {
      this.$emit('clear');
    },
  },
};
