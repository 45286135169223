
// 带倒计时的成功提示弹窗
export default {
  methods: {
    // 点击弹窗按钮
    handleClickBtn() {
      this.$emit('click-btn');
    },
  },
  mounted() {},
};
