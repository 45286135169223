
import LookContent from './components/LookContent.vue'; // 展示发票设置信息
import EditContent from './components/EditContent.vue'; // 编辑发票设置信息
import VerifyPhoneNum from '../components/verifyPhoneNum.vue'; // 验证手机号弹窗
import {
  getInvoiceMessage,
  editInvoiceMessage,
} from '@/api/client-api/account/invoiceSetting.js';
export default {
  name: 'InvoiceSetting',
  components: { LookContent, EditContent, VerifyPhoneNum },
  data() {
    return {
      lookOREdit: 'look', // 查看or编辑 look or edit
      // 发票类型
      invoiceTypeOptions: [
        { name: '普通发票', value: '1' },
        { name: '专用发票', value: '2' },
      ],
      // 发票内容
      invoiceContentOptions: [
        {
          name: '商品明细',
          value: '1',
          tip: '发票内容将显示详细商品名称与价格信息',
        },
        {
          name: '商品类别',
          value: '2',
          tip: '发票内容将显示本单商品所属类别（日用品）及价格信息',
        },
      ],
      invoiceMeaasge: {}, // 发票设置信息

      verifyDialog: false, // 验证信息弹窗状态
    };
  },
  mounted() {
    this.getInvoiceMsg(); // 获取当前账号的发票设置信息
  },
  methods: {
    // 获取当前账号的发票设置信息
    getInvoiceMsg() {
      getInvoiceMessage()
        .then((res) => {
          if (res?.errcode == 0) {
            this.invoiceMeaasge = JSON.parse(JSON.stringify(res?.data || {}));

            this.invoiceMeaasge.payee_address = {
              province: res?.data?.province_name, // 省
              province_id:
                res?.data?.province == '0' ? '' : res?.data?.province || '', // 省id
              city: res?.data?.city_name, // 市
              city_id: res?.data?.city == '0' ? '' : res?.data?.city || '', // 市id
              area: res?.data?.area_name, // 区
              area_id: res?.data?.area == '0' ? '' : res?.data?.area || '', // 区id
            };
            this.invoiceMeaasge.address =
              res?.data?.province_name +
              res?.data?.city_name +
              res?.data?.area_name +
              res?.data?.address_detail;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击编辑按钮
    goEdit() {
      this.lookOREdit = 'edit';
    },
    // 在编辑页面点击返回上一级
    goBack() {
      this.lookOREdit = 'look';
    },
    // 点击保存按钮
    saveMessage(options) {
      editInvoiceMessage(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.$message.success('操作成功');
            this.getInvoiceMsg(); // 重新获取更改后的数据
            this.lookOREdit = 'look';
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击升级按钮
    goVerifyInfo() {
      let status = localStorage.getItem('statusShow');
      if (status == '1' || status == '5') {
        this.$message.warning('待审核状态下不可升级');
        return;
      }
      if (status == '4') {
        this.$message.warning('拉黑状态下不可升级');
        return;
      }

      this.verifyDialog = true; // 打开手机号验证弹窗
    },
    // 点击关闭手机号验证弹窗按钮
    closeVerifyDialog() {
      this.verifyDialog = false; // 关闭手机号验证弹窗
    },
  },
};
