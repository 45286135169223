import request from '@/utils/request.js';

// 提现汇总数据
export function getWithdrawTotal(data) {
  return request({
    url: '/Withdraw/getWithdrawTotal',
    method: 'get',
    params: data,
  });
}

// 提现列表
export function getWithdrawList(data) {
  return request({
    url: '/Withdraw/getWithdrawList',
    method: 'get',
    params: data,
  });
}
