
export default {
  name: 'RefundMessageDialog',
  props: {
    refundAmountMsg: {
      type: Object,
      default: () => {},
    },
    // 币种符号
    currency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      scrollPadding: false,
    };
  },
  watch: {},
  mounted() {
    if (
      Number(document.getElementsByClassName('content')[0].scrollHeight) <= 444
    ) {
      this.scrollPadding = true;
    }
  },
  filters: {
    emptyValue(val) {
      if (val) return val;
      return '--';
    },
  },
  methods: {
    // 关闭弹窗
    closeDialog() {
      this.$emit('close-dialog');
    },
  },
};
