
import ChallengeDetailBox from './components/ChallengeDetailBox.vue';
import {
  abandonChallenge,
  closeSmallPopUpWindow,
  takeChallenge,
} from '@/api/client-api/activity/challenge';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'ChallengeActivity',
  components: {
    ChallengeDetailBox,
  },
  props: {
    // 小弹窗状态
    smallShow: {
      type: Boolean,
      default: false,
    },
    // 大弹窗状态
    bigShow: {
      type: Boolean,
      default: false,
    },
    // 放弃挑战按钮状态
    giveUpButtonShow: {
      type: Boolean,
      default: false,
    },
    // 当前挑战类型id
    challengeTypeId: {
      type: [Number, String],
      default: '',
    },
    // 爆单挑战内容
    challengeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      picUrl: 'test_upload_CN02401040001_1705043371.png', // 图片地址
      sureGiveUpDialog: false, // 二次确认是否放弃挑战弹窗
    };
  },
  computed: {},
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
  },
  methods: {
    // 改变某个挑战条件状态
    changeSelected(id, status) {
      this.$emit('change-selected', id, status);
    },
    // 点击关闭小图
    closeSmall() {
      closeSmallPopUpWindow({ MUTE_WARNING: 1 })
        .then((res) => {
          this.$emit('close-all');
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击小图片
    zoomPic() {
      this.$router.push('/activity');
    },
    // 点击关闭大图
    closeBig() {
      this.$emit('close-big');
    },
    // 跳转权益介绍
    goExplain() {
      window.open('/explain/challenge', '_blank');
    },
    // 确认放弃挑战
    giveUpChallenge() {
      abandonChallenge({ explosive_id: this.challengeTypeId })
        .then((res) => {
          if (res?.errcode == 0) {
            this.sureGiveUpDialog = false;
            this.$emit('close-all');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击打开二次确认弹窗
    openTwiceDialog() {
      this.sureGiveUpDialog = true;
    },
    // 点击取消放弃挑战按钮
    closeDialog() {
      this.sureGiveUpDialog = false;
    },
    // 点击接受挑战
    acceptChallenge() {
      const selectChallenge = this.challengeList.find(
        (i) => i.selected == true
      );
      if (!selectChallenge) return;
      takeChallenge({ challenge_id: selectChallenge.chal_id })
        .then((res) => {
          if (res?.errcode == 0) {
            this.$emit('accept-challenge');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
