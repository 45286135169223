// plugins/vue-lazyload.js
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  // 配置项
  preLoad: 1, // 预加载高度比例
  error: require('../assets/lazy.png'), // 图片加载失败时的默认图片
  loading: require('../assets/lazy.png'), // 图片加载过程中的显示的默认图片
  attempt: 3,
})
