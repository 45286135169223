
import recodeLink from "./recodeLink";
import { getFundInfo } from "@/api/client-api/fund/fundCenter.js";
// import { getBasicInformation } from "@/api/client-api/account/accountmanage.js";
export default {
  components: { recodeLink },
  mounted() {
    this.getCapitalInfo();
    // this.getBaseDeatl();
  },
  props: {
    realName: {
      type: String,
      default: "",
    },
    activeTab: {
      type: String,
      default: "1",
    },
  },
  data() {
    const minAmount = "100.00"; // 提现最小金额
    const maxAmount = "50000.00"; // 最大提现金额
    const rulerNum = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入提现金额"));
      } else if (Number(value < Number(minAmount))) {
        const tipString = `提现金额不小于${minAmount}元`;
        callback(new Error(tipString));
      } else if (Number(value > Number(maxAmount))) {
        const tipString = `提现金额不大于${maxAmount}元`;
        callback(new Error(tipString));
      } else {
        callback();
      }
    };
    return {
      rules: {
        withdrawalAccount: [
          { required: true, message: "请选择提现账户", trigger: "change" },
        ],
        currency: [
          { required: true, message: "请选择收款币种", trigger: "change" },
        ],
        receivingAccount: [
          { required: true, message: "请选择收款账户", trigger: "change" },
        ],
        AlipayAccount: [
          { required: true, message: "请输入支付宝账号", trigger: "blur" },
        ],
        withdrawalAmount: [
          { validator: rulerNum, required: true, trigger: "blur" },
        ],
        accoundType: [
          { required: true, message: "请选择账号类型", trigger: "change" },
        ],
      },
      ruleForm: {
        withdrawalAccount: "", // 提现账户
        currency: "1", // 币种
        receivingAccount: "1", // 收款账户
        AlipayName: "", // 支付宝姓名
        AlipayAccount: "", // 支付宝账户
        withdrawalAmount: "", // 提现金额
        accoundType: 1,
      },
      currencyList: [
        // 币种数据
        {
          label: "人民币",
          value: "1",
        },
      ],
      availableNum: "", // 可用账户
    };
  },
  computed: {
    // 收款账户选项数组
    accountList() {
      return [
        {
          label: `人民币(￥${this.availableNum}）`,
          value: `人民币(￥${this.availableNum}）`,
        },
      ];
    },
    // 人民币 0.3% 美元 1.5%
    commissionRate() {
      return 0.75;
    },
    // 手续费
    commission() {
      if (!isNaN(Number(this.ruleForm.withdrawalAmount))) {
        let num =
          Math.round(
            Number(this.ruleForm.withdrawalAmount * this.commissionRate)
          ) / 100;
        if (this.ruleForm.withdrawalAmount && num < 2 && num >= 0) {
          num = 2;
        }
        return num.toFixed(2);
      } else {
        return "0.00";
      }
    },
    // 净金额
    netAmount() {
      if (!isNaN(Number(this.ruleForm.withdrawalAmount))) {
        let amount =
          Number(this.ruleForm?.withdrawalAmount || 0) -
          Number(this.commission || 0);
        return amount.toFixed(2);
      } else {
        return "0.00";
      }
    },
  },
  methods: {
    // 获取人民币资金账户
    async getCapitalInfo() {
      try {
        const options = {
          currency: "1",
        };
        // 当前页面点击的币种资金账户（比如人民币账户）
        let res = await getFundInfo(options);

        if (res?.errcode == 0) {
          // 修改页面信息
          if (res?.data?.available_balance_CNY && res?.data?.reward_amount) {
            this.availableNum =
              Number(res.data?.available_balance_CNY) -
              Number(res.data?.reward_amount);
          }

          // 默认选中第一条
          this.ruleForm.withdrawalAccount = `人民币(￥${this.availableNum}）`;
        }
      } catch (err) {
        console.error(err);
      }
    },

    blurUsername() {
      let inputAmount = Number(this.ruleForm.withdrawalAmount || 0),
        maxAmount = Number(this.availableNum || 0);
      if (inputAmount > maxAmount) {
        this.ruleForm.withdrawalAmount = Math.floor(this.availableNum); // 向下取整
      }
    },
    // 校验数据，到第二步
    goSecond() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit(
            "active-second",
            this.ruleForm,
            this.netAmount,
            this.commission
          );
        }
      });
    },
    // 跳转资金中心
    tofundCent() {
      this.$router.push("/fund/fundcenter/1");
    },
    // 选择历史账户
    choseAccount(obj) {
      this.ruleForm.AlipayAccount = obj.out_account;
    },
  },
};
