
export default {
  name: 'CapitalInfoItem',
  props: {
    // 标签
    label: {
      type: String,
      default: '默认标签',
    },
    // 问号悬浮后展示的内容；如果不传或传值为空，则不展示问号
    popoverContent: {
      type: String,
      default: '',
    },
    // 货币符号
    currency: {
      type: String,
      default: '¥',
    },
    // 金额数值
    value: {
      type: [String, Number],
      default: 0,
    },
    // 是否展示奖励金额
    showReward: {
      type: Boolean,
      default: false,
    },
    // 奖励金额
    rewardAmount: {
      type: [String, Number],
      default: 0,
    },
  },
};
