
export default {
  data() {
    return {
      isImg: false, //控制预览营业执照弹框
      degNum: 0, //旋转度数
    };
  },
  props: {
    supplierInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    cssVars() {
      return {
        '--degNum': this.degNum + 'deg',
      };
    },
  },
  methods: {
    // 页面触发
    // 旋转
    rotateRight() {
      this.degNum = this.degNum + 90;
    },
  },
};
