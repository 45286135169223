
import {
  authList,
  cancelBrand,
  getUserInfo,
} from '@/api/client-api/mine/mineapi.js';
export default {
  data() {
    return {
      searchObj: {
        // 搜索对象
        key: '',
        value: '',
        timeData: '',
        timeKey: '',
        status: '',
      },
      keywordOptions: [
        {
          label: '供应商',
          value: 'shop_name',
        },
        {
          label: '品牌',
          value: 'brand_name',
        },
        {
          label: '所属平台',
          value: 'auth_platform',
        },
      ],
      timeOption: [
        {
          label: '申请时间',
          value: '1',
        },
        {
          label: '授权截止时间',
          value: '2',
        },
        {
          label: '审核时间',
          value: '3',
        },
      ],
      statusLsit: [
        {
          label: '全部状态',
          value: '',
        },
        {
          label: '待审核',
          value: '0',
        },
        {
          label: '已取消',
          value: '3',
        },
        {
          label: '已授权',
          value: '1',
        },
        {
          label: '已驳回',
          value: '2',
        },
        {
          label: '已过期',
          value: '4',
        },
        {
          label: '续期待审核',
          value: '5',
        },
        {
          label: '续期已取消',
          value: '6',
        },
        {
          label: '续期已驳回',
          value: '7',
        },
      ],
      tableData: [],
      pageNum: 1, // 页码
      pageSize: 10,
      tabletotal: 0,
      isEvaVisible: false, //确认弹框
      currentId: '', // 当前品牌授权
      showPreviewdoc: false,
      showPreviewimg: false, //预览弹窗标识
      nameDigShow: false,
    };
  },
  mounted() {
    this.getAuthList();
  },
  methods: {
    // 获取品牌授权列表
    async getAuthList() {
      try {
        let option = {
          status: this.searchObj.status,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        if (this.searchObj.key && this.searchObj.value) {
          option[this.searchObj.key] = this.searchObj.value;
        }
        if (this.searchObj.timeData.length > 0) {
          if (this.searchObj.timeKey == '1') {
            // 申请时间
            option.create_time_start = this.searchObj.timeData[0];
            option.create_time_end = this.searchObj.timeData[1];
          } else if (this.searchObj.timeKey == '2') {
            // 授权截止时间
            option.end_time_start = this.searchObj.timeData[0];
            option.end_time_end = this.searchObj.timeData[1];
          } else if (this.searchObj.timeKey == '3') {
            // 审核时间
            option.examine_time_start = this.searchObj.timeData[0];
            option.examine_time_end = this.searchObj.timeData[1];
          }
        }
        const res = await authList(option);
        if (res?.errcode == 0) {
          this.tableData = res?.data?.list;
          this.tabletotal = Number(res?.data?.count) || 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 关键字搜索输入框的值
    handleChangeKeyword(key, value) {
      this.searchObj.key = key;
      this.searchObj.value = value;
      this.pageNum = 1;
      this.getAuthList();
    },
    // 下拉框加时间范围搜索
    handleChangeTimeData(key) {
      this.searchObj.timeKey = key;
      this.pageNum = 1;
      this.getAuthList();
    },
    // 重置
    resetdata() {
      this.searchObj = this.$options.data().searchObj;
      this.$refs.XmSelectOption.reVal();
      this.$refs.timeSel.clearVal();
      this.pageNum = 1;
      this.getAuthList();
    },
    // 申请品牌授权
    application() {
      getUserInfo()
        .then((res) => {
          if (res?.errcode == 0) {
            this.$router.push(`/mine/nweEmpower/1`);
          } else if (res?.errcode == 110) {
            this.nameDigShow = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 关闭实名认证弹框
    closeNameDig() {
      this.nameDigShow = false;
    },
    // 去实名认证
    goName() {
      this.$router.push('/account');
    },
    // 取消授权
    revoke() {
      let option = {
        id: this.currentId,
      };
      cancelBrand(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.isEvaVisible = false;
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.pageNum = 1;
            this.getAuthList();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 关闭确认弹框
    closeDelEvaDialog() {
      this.isEvaVisible = false;
    },
    // 取消申请
    cancelApply(row) {
      this.currentId = row.id;
      this.isEvaVisible = true;
    },
    // 查看详情
    viewDeatil(row) {
      this.currentId = row.id;
      this.$router.push(`/mine/brandDetails/${this.currentId}`);
    },
    statusCnage() {
      this.pageNum = 1;
      this.getAuthList();
    },
    //页数改变触发
    goPage(val) {
      this.pageNum = val;
      this.getAuthList();
    },
    //关闭预览回调
    previewCloseFunc() {
      this.imgUrl = '';
      this.showPreviewimg = false;
      this.showPreviewdoc = false;
    },
    //下载授权证书
    downloadBook(url) {
      url.forEach((item) => {
        if (item) {
          this.down(item, item);
        }
      });
    },
    // 授权续期申请，再次申请授权
    applyAgain(type, id) {
      if (type == 1) {
        // 授权续期申请，
        this.$router.push(`/mine/nweEmpower/2/${id}`);
      } else if (type == 2) {
        // 再次申请授权
        this.$router.push(`/mine/nweEmpower/3/${id}`);
      }
    },
  },
};
