
import { getOrderProgressList } from '@/api/client-api/trade/orderlist.js';
import axios from 'axios';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'OrderProgressDialog',
  props: {
    // 导入id
    importId: {
      type: String,
      default: '',
    },
    // 导入状态
    importStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      successCount: 0, // 成功条数
      failCount: 0, // 失败条数

      // 表格配置
      tableConfig: [
        {
          label: '序号',
          prop: 'id',
          width: '82px',
          paddingClass: 'padding-wide',
        },
        {
          label: '订单来源',
          prop: 'order_source',
          width: '150px',
          paddingClass: 'padding-wide',
        },
        {
          label: '订单ID',
          prop: 'order_id',
          width: '168px',
          paddingClass: 'padding-wide',
        },
        {
          label: 'SKU编号',
          prop: 'sku_id',
          width: '164px',
          paddingClass: 'padding-narrow',
        },
        {
          label: '下单状态',
          prop: 'statusName',
          width: '135px',
          isSlot: true,
          paddingClass: 'padding-wide',
        },
      ],

      // 下单状态的样式配置
      orderStatusStyleMap: {
        3: {
          label: '下单成功',
          color: '#07C160',
        },
        2: {
          label: '下单失败',
          color: '#D22316',
        },
        1: {
          label: '等待中',
          color: '#333333',
        },
      },

      tableList: [],

      // 分页器数据
      pageNum: 1,
      pageSize: 8,
      total: 0,
    };
  },
  computed: {
    // 弹窗标题
    dialogTitle() {
      return this.importStatus == '3' ? '查看下单结果' : '查看下单进度';
    },
  },
  methods: {
    /**
     * 工具函数
     */
    // 过滤字符串，处理掉 \t \n 等空白符号
    normalizeText(str) {
      if (typeof str !== 'string') return str;
      // 去除 \t 等符号，并处理空值
      return str.replace(/\t|\n/g, '') || '--';
    },
    /**
     * 页面触发
     */
    // 关闭弹窗
    handleClose() {
      this.$emit('close');
    },
    // 点击切换页码
    handleChangePage(page = 1) {
      this.pageNum = Number(page) || 1;
      this.fetchOrderStatusList();
    },
    // 点击下载失败数据
    async handleClickDownloadFailData() {
      if (process.client) {
        const formData = new FormData();
        formData.append('recordId', this.importId);
        axios({
          url: process.env.NUXT_ENV.VUE_APP_API + '/order/exportFailOrderData',
          method: 'post',
          headers: { token: this.$cookies.get(CUR_TOKEN_NAME) },
          responseType: 'blob',
          data: formData,
        }).then((res) => {
          const blob = new Blob([res.data]);
          const objectURL = window?.URL.createObjectURL(blob);
          const link = document?.createElement('a');
          link.href = objectURL;
          link.download = `下单失败_${Date.now()}.xlsx`;
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(objectURL);
        });
      }
    },

    /**
     * 请求接口
     */

    // 获取下单进度列表
    async fetchOrderStatusList() {
      try {
        const params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          recordId: this.importId,
        };
        let res = await getOrderProgressList(params);
        if (res?.errcode == 0) {
          this.tableList = res.data?.list || [];
          this.total = Number(res.data?.count) || 0;
          this.successCount = Number(res.data?.successNum) || 0;
          this.failCount = Number(res.data?.failNum) || 0;
          this.importStatus = res.data?.status;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.handleChangePage();
  },
};
