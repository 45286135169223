/**
 * 中英文配置
 * 公共配置
 */
// hello world
// 货币标志配置
export const CURRENCY_CONFIG = {
  'zh-cn': '¥',
  en: '$',
};

export const BLOCK_LABELS = {
  'zh-cn': {
    'lock-inventory-tag': '接受锁货',
    'prediction-tag': '接受预测',
    'sample-policy-tag': '接受样品政策',
    'wholesale-tag': '支持批发',
    'suggested-min-price-tag': '建议最低零售价',
    all: '全部',
    'view-image': '查看图片',
  },
  en: {
    'lock-inventory-tag': 'Lock inventory',
    'prediction-tag': 'Prediction',
    'sample-policy-tag': 'Sample Policy',
    'wholesale-tag': 'Wholesale',
    'suggested-min-price-tag': 'Suggested minimum retail price',
    all: 'all',
    'view-image': 'view picture',
  },
};

export const COMMON_LABELS = {
  'zh-cn': {
    'no-data': '暂无数据',
    country: '国家',
    'province-state': '省/州',
    'search-country': '搜索国家',
    saleable_area: '可售区域',
    preview: '预览',
    download: '下载',
  },
  en: {
    'no-data': 'No Data',
    country: 'Country',
    'province-state': 'Province/State',
    'search-country': 'Search for countries',
    saleable_area: 'Sellable Area',
    preview: 'Preview',
    download: 'download',
  },
};

// 单位的中英文配置
export const UNIT_LABELS = {
  'zh-cn': {
    piece: '件',
    packet: '包',
  },
  en: {
    piece: 'piece',
    packet: 'packet',
  },
};
