
export default {
  name: 'Solution',
  props: {
    // 币种
    currency: {
      type: String,
      default: '',
    },
    // 处理归属 1分销商 2供应商 3平台 4分销商和供应商
    handler: {
      type: String,
      default: '',
    },
    // 当前纠纷状态
    disputeStatus: {
      type: String,
      default: '',
    },
    // 经历状态
    statusStepRecord: {
      type: String,
      default: '',
    },
    // 分销商方案
    shopSolution: {
      type: [Object, String],
      default: () => {},
    },
    // 供应商方案
    supplierSolution: {
      type: [Object, String],
      default: '',
    },
    // 平台方案
    platformSolution: {
      type: [Object, String],
      default: '',
    },
    // 最终方案
    finalSolution: {
      type: [Object, String],
      default: () => {},
    },
    // 退货凭证信息
    returnGoodsInfo: {
      type: [Object, String],
      default: '',
    },
    // 拒付退款原因信息
    refusalRefundInfo: {
      type: [Object, String],
      default: '',
    },
    // 是否存在平台方案 1存在 -1不存在
    hasPlatformPlan: {
      type: String,
      default: '-1',
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    // 解决方案盒子的title
    titleFont() {
      if (this.disputeStatus == '3' && this.platformSolution)
        return '平台提供解决方案';
      if (this.disputeStatus == '7') return '解决方案/退货物流信息';
      return '解决方案';
    },
    // 是否可以响应供应商方案
    handleSupplierPlan() {
      // 处理人是(分销商 || 分销商/供应商)且没有平台方案
      if (
        (this.handler == '1' || this.handler == '4') &&
        !this.platformSolution
      )
        return true;
      return false;
    },
    // 是否可以响应平台方案
    handlePlatformPlan() {
      // 处理人是(分销商 || 分销商/供应商) && 平台处理中的状态
      if (
        (this.handler == '1' || this.handler == '4') &&
        this.disputeStatus == '2'
      )
        return true;
      return false;
    },
    // 是否可以申请平台介入
    handleApplyPltform() {
      let hasNumber2 = this.statusStepRecord.split(',').includes('2'); // 是否有 平台介入中 状态
      let hasNumber7 = this.statusStepRecord.split(',').includes('7'); // 是否有 供应商拒付退款 状态
      // 处理人不是平台 && 平台没有给出方案 && 没有平台介入中状态 && 没有供应商拒付退款状态
      if (
        this.handler != '3' &&
        !this.platformSolution &&
        !hasNumber2 &&
        !hasNumber7
      )
        return true;
      return false;
    },
  },
  methods: {
    // 申请平台介入
    applyPlatform() {
      this.$emit('apply-platform');
    },
    /**
     * 打开二次确认弹窗
     * @param {String} dialog 要打开的弹窗字段
     * @param {String} belong 对应方案所属方 supplier供应商 platform平台
     */
    openDialog(dialog, belong) {
      this.$emit('second-confirm', dialog, belong);
    },
    /**
     * 拒绝方案
     * @param {String} type 对应方案所属方 supplier拒绝供应商 platform拒绝平台
     */
    refusePlan(type) {
      this.$emit('refuse', type);
    },
  },
};
