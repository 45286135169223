/**
 * 注册模块
 *
 */
import Register from '@/pages/register/RegisterHome.vue'; //注册页面
import RegisterShop from '@/pages/register/RegisterShop.vue'; //分销商注册页面
import RegisterSupplier from '@/pages/register/RegisterSupplier.vue'; //供应商注册页面

const registerRoutes = [
  {
    path: '/register',
    name: 'Register',
    meta: {
      isAuth: false,
    },
    // redirect: '/register/shop',
    component: Register,
    children: [
      {
        path: 'shop',
        name: 'RegisterShop',
        meta: {
          isAuth: false,
        },
        component: RegisterShop,
      },
      {
        path: 'supplier',
        name: 'RegisterSupplier',
        meta: {
          isAuth: false,
        },
        component: RegisterSupplier,
      },
    ],
  },
];

export default registerRoutes;
