
import {
  getReplaceLogistics,
  setReplaceLogistics,
} from '@/api/client-api/fund/invoice.js';
export default {
  data() {
    return {
      logisticsObj: {
        company: '', // 物流公司
        oddNumbers: '', // 物流单号
      },
      rules: {
        company: [
          { required: true, message: '请输入物流公司', trigger: 'blur' },
        ],
        oddNumbers: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
        ],
      },
      logObj: {},
    };
  },
  props: {
    tid: {
      type: String,
      default: '',
    },
  },
  watch: {
    tid: {
      handler(val) {
        this.getDetail(val);
      },
      immediate: true,
    },
  },
  methods: {
    closeLogistics() {
      this.$emit('closelog');
    },
    configlog() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let option = {
            application_id: this.tid,
            logistics_id: this.logisticsObj.oddNumbers,
            logistics_name: this.logisticsObj.company,
          };
          setReplaceLogistics(option)
            .then((res) => {
              if (res?.errcode == 0) {
                this.$emit('closelog');
                if (res?.msg) {
                  this.$message.success(res?.msg);
                }
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
    getDetail(id) {
      let option = {
        application_id: id,
      };
      getReplaceLogistics(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.logObj = res.data;
            this.logisticsObj.company = res?.data?.return_logistics_name;
            this.logisticsObj.oddNumbers = res?.data?.return_logistics_id;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
