
// api
import {
  inboxMessageDisplay,
  readMark,
} from '@/api/client-api/notify/allnotify.js';
// 组件
import NotifyTable from '../components/NotifyTable';
export default {
  components: { NotifyTable },
  data() {
    return {
      // 筛选
      statusOptions: [
        { label: '全部', value: '' },
        { label: '已读', value: '1' },
        { label: '未读', value: '-1' },
      ],
      informStatus: '', //已经选中的状态
      // 数据
      alreadyRead: '0', //已读
      notRead: '0', //未读
      tableData: [], //列表数据
      pageNum: 1, //页数
      pageSize: 17, //每页数量
      total: 0, //总数
      // 复选相关
      slectedRow: [], //被选中的列表
    };
  },
  computed: {
    btnTitle() {
      if (this.slectedRow?.length > 0) {
        return '设为已读';
      }
      return '全部已读';
    },
  },
  mounted() {
    if (process.client) {
      this.getInboxMessageDisplay();
    }
  },
  methods: {
    // 页面触发
    // 复选
    selectTable(arr) {
      this.slectedRow = JSON.parse(JSON.stringify(arr || [])) || [];
    },
    // ---------------------------------------------------------------
    // 设为标记按钮
    async handleReadMark() {
      // 被选中的id
      let idArr = [];
      this.slectedRow?.forEach((item) => {
        idArr.push(item.id);
      });
      const options = {
        message_type: '1',
      };
      if (idArr?.length > 0) {
        options.id = idArr.join(',');
        options.read_all = '0';
      } else {
        options.read_all = '1';
      }

      try {
        let res = await readMark(options);
        if (res?.errcode == 0) {
          this.$message.success(res.msg);
          this.slectedRow = [];
          this.getInboxMessageDisplay();
        }
      } catch (error) {
        console.error(error);
      }
    },
    // --------------------------------------------------------
    // 获取列表数据
    async getInboxMessageDisplay() {
      const options = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        message_type: '1',
      };
      // 已读/未读
      if (this.informStatus) {
        options.is_read = this.informStatus;
      }
      try {
        let res = await inboxMessageDisplay(options);
        if (res?.errcode == 0) {
          this.alreadyRead =
            Number(res?.data?.totalCount || '0') -
            Number(res?.data?.notReadCount || '0'); //已读
          this.notRead = res?.data?.notReadCount || '0'; //未读

          this.total = Number(res?.data?.totalCount || '0');
          this.tableData = res?.data?.data || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 分页
    goPage(val) {
      this.pageNum = val;
      this.getInboxMessageDisplay();
    },
    // 筛选等重置页数
    rePageList() {
      this.pageNum = 1;
      this.getInboxMessageDisplay();
    },
  },
};
