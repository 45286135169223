
export default {
  name: 'OrderGoodsTable',
  props: {
    // 订单商品
    orderGoodsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showPreviewimg: false, //预览弹窗标识
      imgUrl: '', //预览图片

      delOrderId: '', // 选择的要移除的订单id
      delDialog: false, // 二次确认要删除的弹窗状态
    };
  },
  computed: {},
  mounted() {
    this.$refs.tableList.bodyWrapper.addEventListener(
      'scroll',
      this.throttle(this.tableListScroll, 500)
    );
  },
  methods: {
    tableListScroll() {
      if (this.$store.state.pay.choseOrdersId.length > 4) {
        this.$refs.child.handleUpdateBigImgPosition();
      }
    },
    //预览图片回调
    showPreviewimgFunc(url, isImg) {
      this.imgUrl = this.$options.filters.imgbaseurl(url);
      if (isImg) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    // 点击打开是否删除弹窗
    delGood(tid) {
      this.delDialog = true;
      this.delOrderId = tid;
    },
    // 关闭二次确认删除弹窗
    closeDelDialog() {
      this.delDialog = false;
    },
    // 确认移除订单
    sureDelOrder() {
      this.delDialog = false;
      this.$emit('del-order-msg', this.delOrderId);
    },
    // 跳转订单详情
    goOrderDetail(tid) {
      let routeData = this.$router.resolve({
        path: '/trade/orderdetails',
        query: { item_id: tid },
      });
      window.open(routeData.href);
    },
    // 跳转商品详情页
    goGoodsDetail(itemId, imgUrl) {
      this.toCachedShopdetail(itemId, imgUrl);
    },
  },
};
