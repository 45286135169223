
export default {
  props: {
    width: {
      type: String,
      default: '380px',
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
