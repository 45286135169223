// 我发起的投诉/举报（首次投诉）
export const launchReportTableConfig = [
  {
    label: '投诉/举报ID',
    prop: 'complaint_id',
    width: '152px',
  },
  {
    label: '投诉/举报标题',
    prop: 'title',
    width: '230px',
  },
  {
    label: '所属类别',
    prop: 'cate_text',
    width: '104px',
  },
  {
    label: '被投诉/举报方',
    prop: 'name',
    width: '152px',
  },
  {
    label: '投诉/举报时间',
    prop: 'create_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '资料附件',
    prop: 'attachment',
    width: '76px',
    isSlot: true,
  },
  {
    label: '处理状态',
    prop: 'deal_status_text',
    width: '110px',
  },
  {
    label: '处理人员',
    prop: 'reviewer_name',
    width: '118px',
  },
  {
    label: '处理结果',
    prop: 'is_establish_text',
    width: '90px',
  },
  {
    label: '评价',
    prop: 'review',
    width: '170px',
    isSlot: true,
  },
  {
    label: '操作',
    prop: 'operations',
    width: '92px',
    fixed: 'right',
    isSlot: true,
  },
];

// 我收到的投诉/举报（首次投诉）
export const receiveReportTableConfig = [
  {
    label: '投诉/举报ID',
    prop: 'complaint_id',
    width: '152px',
  },
  {
    label: '投诉/举报标题',
    prop: 'title',
    width: '230px',
  },
  {
    label: '投诉/举报时间',
    prop: 'create_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '所属类别',
    prop: 'cate_text',
    width: '104px',
  },
  {
    label: '资料附件',
    prop: 'attachment',
    width: '76px',
    isSlot: true,
  },
  {
    label: '申诉剩余时间',
    prop: 'remain_second',
    width: '126px',
    isSlot: true,
  },
  {
    label: '申诉时间',
    prop: 'appeal_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '申诉状态',
    prop: 'appeal_status_text',
    width: '90px',
    isSlot: true,
  },
  {
    label: '处理状态',
    prop: 'deal_status_text',
    width: '110px',
  },
  {
    label: '处理人员',
    prop: 'reviewer_name',
    width: '120px',
  },
  {
    label: '处理结果',
    prop: 'is_violate_text',
    width: '76px',
    isSlot: true,
  },
  {
    label: '操作',
    prop: 'operations',
    width: '104px',
    fixed: 'right',
    isSlot: true,
  },
];

// 我发起的投诉/举报（二次投诉）
export const twiceLaunchReportTableConfig = [
  {
    label: '投诉ID',
    prop: 'complaint_id',
    width: '152px',
  },
  {
    label: '投诉标题',
    prop: 'title',
    width: '230px',
  },
  {
    label: '所属类别',
    prop: 'cate_text',
    width: '104px',
  },
  {
    label: '被投诉方',
    prop: 'name',
    width: '152px',
  },
  {
    label: '二次投诉时间',
    prop: 'create_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '资料附件',
    prop: 'attachment',
    width: '76px',
    isSlot: true,
  },
  {
    label: '处理状态',
    prop: 'deal_status_text',
    width: '110px',
  },
  {
    label: '处理人员',
    prop: 'reviewer_name',
    width: '118px',
  },
  {
    label: '处理结果',
    prop: 'is_establish_text',
    width: '90px',
  },
  {
    label: '评价',
    prop: 'review',
    width: '170px',
    isSlot: true,
  },
  {
    label: '操作',
    prop: 'operations',
    width: '92px',
    fixed: 'right',
    isSlot: true,
  },
];

// 我收到的投诉/举报（二次投诉）
export const twiceReceiveReportTableConfig = [
  {
    label: '投诉ID',
    prop: 'complaint_id',
    width: '152px',
  },
  {
    label: '投诉标题',
    prop: 'title',
    width: '230px',
  },
  {
    label: '二次投诉时间',
    prop: 'create_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '所属类别',
    prop: 'cate_text',
    width: '104px',
  },
  {
    label: '资料附件',
    prop: 'attachment',
    width: '76px',
    isSlot: true,
  },
  {
    label: '申诉剩余时间',
    prop: 'remain_second',
    width: '126px',
    isSlot: true,
  },
  {
    label: '申诉时间',
    prop: 'appeal_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '申诉状态',
    prop: 'appeal_status_text',
    width: '90px',
    isSlot: true,
  },
  {
    label: '处理状态',
    prop: 'deal_status_text',
    width: '110px',
  },
  {
    label: '处理人员',
    prop: 'reviewer_name',
    width: '120px',
  },
  {
    label: '处理结果',
    prop: 'is_violate_text',
    width: '76px',
  },
  {
    label: '操作',
    prop: 'operations',
    width: '104px',
    fixed: 'right',
    isSlot: true,
  },
];

