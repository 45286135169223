
export default {
  data() {
    return {
      passwordInput: ['', '', '', '', '', ''], // 存储输入密码内容
    };
  },
  computed: {
    // 密码计算属性
    pwInput() {
      if (
        this.passwordInput &&
        Array.isArray(this.passwordInput) &&
        this.passwordInput.length === 6
      ) {
        return this.passwordInput;
      } else if (/^\d{6}$/.test(this.passwordInput.toString())) {
        return this.passwordInput.toString().split('');
      } else {
        return new Array(6);
      }
    },
    // 检测密码是否输入完整
    bCheckCodeInputComplete() {
      return this.passwordInput.some((item) => item === '');
    },
  },
  methods: {
    inputred() {
      this.$refs.keyPw1.style =
        'border: 1px solid red !important;border-right:none !important';
      this.$refs.keyPw2.style =
        'border: 1px solid red !important;border-right:none !important';
      this.$refs.keyPw3.style =
        'border: 1px solid red !important;border-right:none !important';
      this.$refs.keyPw4.style =
        'border: 1px solid red !important;border-right:none !important';
      this.$refs.keyPw5.style =
        'border: 1px solid red !important;border-right:none !important';
      this.$refs.keyPw6.style = 'border: 1px solid red !important';
    },
    inputbordernone() {
      this.$refs.keyPw1.style =
        'border: 1px solid #D9D9D9 !important;border-right:none !important';
      this.$refs.keyPw2.style =
        'border: 1px solid #D9D9D9 !important;border-right:none !important';
      this.$refs.keyPw3.style =
        'border: 1px solid #D9D9D9 !important;border-right:none !important';
      this.$refs.keyPw4.style =
        'border: 1px solid #D9D9D9 !important;border-right:none !important';
      this.$refs.keyPw5.style =
        'border: 1px solid #D9D9D9 !important;border-right:none !important';
      this.$refs.keyPw6.style = 'border: 1px solid #D9D9D9 !important';
    },
    // 输入数据
    pwKeyup(e) {
      // 限制数字键盘输入
      if (
        e.code == 'NumpadEnter' ||
        e.code == 'NumpadAdd' ||
        e.code == 'NumpadSubtract' ||
        e.code == 'NumpadMultiply' ||
        e.code == 'NumpadDivide' ||
        e.code == 'NumpadDecimal'
      ) {
        return;
      }
      let index = e.target.dataset.index * 1;
      let el = e.target;
      // 解决输入e的问题
      el.value = el.value.replace(/Digit|Numpad/i, '').slice(0, 1);
      if (/Digit|Numpad/i.test(e.code)) {
        // 必须在这里赋值，否则输入框会是空值
        this.passwordInput.splice(
          index,
          1,
          e.code.replace(/Digit|Numpad/i, '')
        );
        el.nextElementSibling && el.nextElementSibling.focus();
        if (index === 5) {
          if (this.passwordInput.join('').length === 6)
            document.activeElement.blur();
        }
      }
    },
    // 输入验证码，检测位置变化
    pwKeydown(e) {
      let index = e.target.dataset.index * 1;
      let el = e.target;
      if (e.key === 'Backspace') {
        if (this.passwordInput[index].length > 0) {
          this.passwordInput.splice(index, 1, '');
        } else {
          if (el.previousElementSibling) {
            el.previousElementSibling.focus();
            this.passwordInput[index - 1] = '';
          }
        }
      }
    },
    showpw(index) {
      let setPw = [...this.passwordInput].toString().replaceAll(',', '');
      this.$emit('getPw', setPw);

      /* if((index == 1 && setPw.length != 1) || (index == 2 && setPw.length != 2) || (index == 3 && setPw.length != 3) ||
       (index == 4 && setPw.length != 4) || (index == 5 && setPw.length != 5) || (index == 6 && setPw.length != 6)) {

      } */
    },
    getfoc() {
      // 获取焦点隐藏提示语
      this.inputbordernone();
      this.$emit('tiphide');
    },
  },
};
