
export default {
  data() {
    return {};
  },
  props: {
    limitTip: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleColoe() {
      this.$emit('closeShopLimt');
    },
  },
};
