
// api
import { inboxMessageContent } from '@/api/client-api/notify/allnotify.js';
import {
  generateNoticeHtmlStr,
  generateNoticeClickHandler,
} from '@/utils/dynamic_notice.js';
export default {
  data() {
    return {
      id: '', //当前详情的id
      content: null, //内容
    };
  },
  mounted() {
    const params = this.$route.params;
    if (params?.id) {
      this.id = params?.id;
      this.getNotifyDetail();
    }
  },
  methods: {
    // 页面触发
    handleClick() {},
    // 获取公告详情
    async getNotifyDetail() {
      const options = {
        id: this.id,
      };
      try {
        let res = await inboxMessageContent(options);
        if (res?.errcode == 0) {
          this.content = res?.data?.content;
          if (res.data?.jumpInfo?.length > 0) {
            // 如果带有可解析字符规则
            this.content = generateNoticeHtmlStr(
              res.data.new_content,
              res.data.jumpInfo
            );
            this.handleClick = generateNoticeClickHandler(
              res.data.jumpInfo,
              this
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 返回对应的列表页
    goBack() {
      let route = this.$route;
      switch (route.name) {
        case 'OrderNotifyDetails':
          this.$router.push('/notify/ordernotify');
          break;
        case 'SystemNotifyDetails':
          this.$router.push('/notify/systemnotify');
          break;
        default:
          break;
      }
    },
  },
};
