import request from '@/utils/request.js';

// 订单发票列表
export function orderInvoiceList(data) {
  return request({
    url: '/InvoiceManage/orderInvoiceList',
    method: 'get',
    params: data,
  });
}
// 发起申请订单金额计算
export function invoiceAmount(data) {
  return request({
    url: '/InvoiceManage/invoiceAmount',
    method: 'post',
    data: data,
  });
}
// 分销商发票设置信息详情
export function invoiceSettingsDetail(data) {
  return request({
    url: '/InvoiceManage/invoiceSettingsDetail',
    method: 'get',
    params: data,
  });
}
// 发起开票申请
export function applyInvoice(data) {
  return request({
    url: '/InvoiceManage/applyInvoice',
    method: 'post',
    data: data,
  });
}
// 开票申请详情
export function getInvoicingInfo(data) {
  return request({
    url: '/InvoiceManage/getInvoicingInfo',
    method: 'get',
    params: data,
  });
}
// 发票信息
export function getInvoiceInfo(data) {
  return request({
    url: '/InvoiceManage/getInvoiceInfo',
    method: 'get',
    params: data,
  });
}
// 获取驳回原因
export function getRejectReason(data) {
  return request({
    url: '/InvoiceManage/getRejectReason',
    method: 'get',
    params: data,
  });
}
// 申请发票换开
export function applyReplaceInvoice(data) {
  return request({
    url: '/InvoiceManage/applyReplaceInvoice',
    method: 'get',
    params: data,
  });
}
// 获取回退物流收件信息
export function getReplaceLogistics(data) {
  return request({
    url: '/InvoiceManage/getReplaceLogistics',
    method: 'get',
    params: data,
  });
}
// 换开驳回原因
export function getReplaceRejectReason(data) {
  return request({
    url: '/InvoiceManage/getReplaceRejectReason',
    method: 'get',
    params: data,
  });
}
// 上传物流信息
export function setReplaceLogistics(data) {
  return request({
    url: '/InvoiceManage/setReplaceLogistics',
    method: 'get',
    params: data,
  });
}
// 发票邮件
export function invoiceEmail(data) {
  return request({
    url: '/InvoiceManage/invoiceEmail',
    method: 'get',
    params: data,
  });
}
// 获取指定申请单的开票方发票设置
export function supplierInvoiceSetting(data) {
  return request({
    url: '/InvoiceManage/supplierInvoiceSetting',
    method: 'post',
    data: data,
  });
}
