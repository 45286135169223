import { LogoutDetector } from './logout_detector';

const EXPIRED_TIME = 30 * 24 * 60 * 60 * 1000; // 分销商账号：同一IP同一浏览器距上次输入密码1个月自动退出

let detector = new LogoutDetector(EXPIRED_TIME, 1000);
let detectedOperationList = []; // 要检测的动态操作的事件名称

export function startDetector() {
  if (!process.client) {
    return;
  }
  let recorder = detector.getRecorder();

  /**
   * 绑定目标事件的监听
   */
  for (const eventName of detectedOperationList) {
    window.addEventListener(eventName, recorder);
  }

  detector.start();

  return detector;
}
