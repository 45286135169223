// 一次投诉列表的次级标签页配置
export const reportBlockConfig = [
  { name: '我收到的投诉&举报', key: 'receive' },
  { name: '我发起的投诉&举报', key: 'launch' },
];
// 二次投诉列表的次级标签页配置
export const twiceReportBlockConfig = [
  { name: '我收到的二次投诉', key: 'receive' },
  { name: '我发起的二次投诉', key: 'launch' },
];
