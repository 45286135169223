
import { mapGetters } from 'vuex';
import { getBulletinList } from '@/api/client-api/notify/platformannouncement.js';
export default {
  name: 'BulletinList',
  data() {
    return {
      lang: '', // 语言版本
      pageNum: 1,
      pageSize: 20,
      total: 0,
      bulletinList: [], // 公告列表
    };
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
  },
  mounted() {
    this.lang = this.currLang;
    this.getBulletin(); // 获取公告列表
  },
  methods: {
    // 获取公告列表
    getBulletin() {
      let options = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        openLoading: true,
        API_LANG_TYPE: this.lang,
      };
      getBulletinList(options)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.data?.list) {
              this.bulletinList = res?.data?.list;
              this.total = Number(res?.data?.total) || 0;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击每行跳转公告详情
    handleRowClick(row) {
      this.$router.push('/notify/bulletindetail/' + row.id);
    },
    // 分页
    goPage(val) {
      if (val) {
        this.pageNum = val;
      }
      this.getBulletin();
    },
  },
};
