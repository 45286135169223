
// 组件
import RollGoodscard from './components/RollGoodscard.vue';
export default {
  components: { RollGoodscard },
  data() {
    return {
      time: null, //计时器
      curTime: '', //当前时间
    };
  },
  props: {
    // 获取到的商品补贴列表
    goodsDataList: {
      type: Array,
      default: null,
    },
  },
  mounted() {
    if (process.client) {
      this.getTime();
    }
  },
  methods: {
    // 倒计时相关
    getTime() {
      if (this.time) {
        clearInterval(this.time);
        this.time = null;
      }
      this.time = setInterval(() => {
        this.curTime = new Date();
      }, 1000);
    },
    // 倒计时相关
    countdownToMonthEnd(date, onlyDay) {
      // 如果没有提供日期，则使用当前日期
      date = date || new Date();

      // 当前月份的最后一天
      var monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      monthEnd.setHours(23, 59, 59);

      // 计算剩余时间（单位：秒）
      var secondsLeft = (monthEnd - date) / 1000;

      const days = Math.floor(secondsLeft / 86400); //天
      if (onlyDay) {
        let dayStr = days.toString().padStart(2, '0');
        return dayStr;
      }

      const hours = Math.floor((secondsLeft % 86400) / 3600); //时
      const minutes = Math.floor((secondsLeft % 3600) / 60); //分
      const seconds = Math.floor(secondsLeft % 60); //秒

      // 返回 对应倒计时
      let timeStr =
        // days.toString().padStart(2, '0') +
        // '天 ' +
        hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ':' +
        seconds.toString().padStart(2, '0');
      return timeStr;
    },
    // --------------------------------------------
    goGoodsSubsidy() {
      window.open('/activity/goods');
    },
  },
  destroyed() {
    if (process.client) {
      if (this.time) {
        clearInterval(this.time);
        this.time = null;
      }
    }
  },
};
