
import {
  getOrderPayment,
  jumpPayment,
  getRateAndPrice,
  weiPayStatus,
  getBankCard,
} from '@/api/client-api/goods/pay.js';
import { getOrdeDetailInfo } from '@/api/client-api/trade/orderlist.js';
import payBottom from '@/components/indexbottom.vue';
// import payBottom from '@/components/paybottom.vue';
import inputPassword from '@/pages/back-stage/account-module/components/inputPassword.vue';
import OrderGoodsTable from './components/OrderGoodsTable.vue'; // 批量支付展示商品表格
import PaymentMethod from './components/PaymentMethod.vue'; // 选择支付方式盒子
import DollerPayMethod from './components/DollerPayMethod.vue'; // 美元选择支付方式
export default {
  components: {
    payBottom,
    inputPassword,
    OrderGoodsTable,
    PaymentMethod,
    DollerPayMethod,
  },
  data() {
    return {
      userdetail: {},
      paytype: null, // 支付方式
      orderdetail: {}, // 订单收货人详情
      paydialogshow: false, // 支付密码弹框
      bandcardpassword: [], // 支付密码
      errtipshow: false, // 密码错误提示语，默认不显示
      orderNum: '', // 订单号

      payInfo: {
        priceCNY: '', // 支付金额 人民币
        priceUSD: '', // 支付金额 美元
      }, // 支付信息 包括 平台银行卡信息、订单号、支付金额

      CNYPay: {},
      USDPay: {},
      tradeInfo: {
        countdown: '', // 过期时间
        total_fee: '', // 价格
      },
      // channelInfo: [] // 支付渠道
      hour: 0, // 订单倒计时
      minute: 0, // 订单倒计分
      second: 0, // 订单倒计秒
      paychose: {},
      priceRate: [], // （每项）订单信息
      rateUpdate: 3600, // 汇率更新时间
      // currency:1,  // 当前币种
      weChatTimer: null, // 微信支付倒计时
      weChatShow: false,
      version: 6, // 二维码生成的版本，数字越大，二维码越复杂
      out_trade_no: '',
      stateTimer: null, // 获取微信支付状态
      payTime: 1800, // 30分钟
      isBatch: false, // 是否是批量支付进来的
      codeUrl: '', // 二维码url
      weChatUrl: '', // 后端传过来的url
      balanceValue: '', // 余额
      balanceUSD: '', // 美元余额

      refreshSeconds: null, // 会有汇率问题的秒数
      rateTimer: null, // 汇率的倒计时
      isFixedRate: false, // 是否固定汇率
    };
  },
  async fetch() {
    // 通过访问 this.$store.state 来获取 store 中的数据
    this.orderNum = this.$store.state.pay.choseOrdersId.toString();
  },
  mounted() {
    // 判断是否是批量支付进来的
    if (this.$route.query.tid.split(',').length === 1) {
      this.isBatch = false;
    } else {
      this.isBatch = true;
    }
    this.orderNum = this.$store.state.pay.choseOrdersId.toString();
    this.paytype = this.$route.query.currency;

    if (this.orderNum.split(',').length == 1) {
      this.getOrdeDetailInfo(this.orderNum);
    }
    this.getPaymentList();
    this.getPrice();
    // this.getCountTime()
    // this.getRateUpdateTime();
    this.userdetail = JSON.parse(this.$store.state.login.loginInfo.userdetail);

    this.getBankCardInfo(); // 获取平台银行卡信息
  },
  beforeDestroy() {
    if (this.stateTimer) {
      clearInterval(this.stateTimer);
    }
    if (this.weChatTimer) {
      clearInterval(this.weChatTimer);
    }
    if (this.rateTimer) {
      clearInterval(this.rateTimer);
    }
  },
  filters: {},
  computed: {
    currLanguage() {
      return this.$store.state.common.currLanguage;
    },
  },
  methods: {
    // 切换支付方式
    checkpay() {
      // this.paytype = !this.paytype;
      if (this.paytype == 1) {
        this.paytype = 2;
      } else if ((this.paytype = 2)) {
        this.paytype = 1;
      }
      this.select({});
    },

    // 获取订单详情
    getOrdeDetailInfo(id) {
      let option = {
        tid: id,
        API_LANG_TYPE: this.currLanguage,
        MUTE_WARNING: 1,
      };
      getOrdeDetailInfo(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.orderdetail = res.data.list;
            // console.log(this.orderdetail);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 人民币立即支付
    paybank() {
      if (this.paychose.id) {
        this.paydialogshow = true;
      }
    },
    // 获取支付密码
    getbankpass(arr) {
      this.bandcardpassword = arr;
      if (arr.length < 6) {
        this.errtipshow = true;
      }
    },
    tiphide() {
      // 隐藏提示语
      this.errtipshow = false;
    },
    // 汇率更新一分钟倒计时
    getRateUpdateTime() {
      setInterval(() => {
        this.getPrice();
      }, 3600000);
      setInterval(() => {
        this.rateUpdate--;
        if (this.rateUpdate == 0) {
          this.rateUpdate = 3600;
        }
      }, 1000);
    },
    // 订单结束倒计时
    getCountTime() {
      let flag = setInterval(() => {
        if (Math.sign(this.tradeInfo.countdown) == -1) {
          clearInterval(flag);
          this.$message.warning('订单已失效');
          this.$router.push('./');
          return;
        }
        if (this.tradeInfo.countdown === 0) {
          clearInterval(flag);
          return;
        }
        this.tradeInfo.countdown--;
        let h = parseInt((this.tradeInfo.countdown / 60 / 60) % 24);
        this.hour = h < 10 ? '0' + h : h;
        let m = parseInt((this.tradeInfo.countdown / 60) % 60);
        this.minute = m < 10 ? '0' + m : m;
        let s = parseInt(this.tradeInfo.countdown % 60);
        this.second = s < 10 ? '0' + s : s;
      }, 1000);
    },
    // 获取信息
    getPaymentList() {
      let parme = {
        tid: this.orderNum,
        MUTE_WARNING: 1,
      };
      getOrderPayment(parme)
        .then((res) => {
          if (res.errcode == 0) {
            // console.log(res.data);

            this.tradeInfo = res.data.tradeInfo; // 截止日期信息
            res.data.channelInfo.CNY.bank_payment.forEach((item) => {
              item.checked = false;
            });
            res.data.channelInfo.USD.third_payment.forEach((item) => {
              item.checked = false;
            });
            res.data.channelInfo.USD.bank_payment.forEach((item) => {
              item.checked = false;
            });
            this.CNYPay = res.data.channelInfo.CNY;
            this.USDPay = res.data.channelInfo.USD;

            // 保留银行卡后四位
            this.CNYPay.bank_payment.forEach((element) => {
              element.card_no = element.card_no.slice(-4);
            });
            // this.USDPay.third_payment.forEach(element => {
            //   element.card_no = element.card_no.slice(-4)
            // });

            // 过期时间
            let nowTime = parseInt(new Date().getTime() / 1000) + ''; // 当前时间
            this.tradeInfo.countdown = this.tradeInfo.deadline_time - nowTime;
            this.getCountTime();
            // this.tradeInfo.countdown = 3999999999 - nowTime
            this.paytype = res.data.currency;
            this.balanceValue = res.data.balance_CNY;
            this.balanceUSD = res.data.balance_USD;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取订单金额和实时汇率
    getPrice() {
      let parme = {
        tid: this.orderNum,
        currency_type: 'USD/CNY',
        API_LANG_TYPE: this.currLanguage,
        MUTE_WARNING: 1,
      };
      getRateAndPrice(parme)
        .then((res) => {
          if (res?.errcode === 0) {
            this.priceRate = res?.data?.list;
            this.payInfo.priceCNY = res?.data?.total_fee_CNY || '0.00';
            this.payInfo.priceUSD = res?.data?.total_fee_USD || '0.00';

            // 获取汇率倒计时秒数
            if (res?.data?.refresh_countdown) {
              this.refreshSeconds = Number(res?.data?.refresh_countdown);
              this.changePriceTime();
            }

            // console.log(this.priceRate, 'xiangqing ');

            // this.priceRate.forEach(list => {
            //   let leftIndex = list.packing_size.indexOf('(');
            //   list.packing_size = list.packing_size.substring(0, leftIndex);
            // });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 汇率刷新倒计时
    changePriceTime() {
      if (this.rateTimer) {
        clearInterval(this.rateTimer);
        this.rateTimer = null;
      }
      this.rateTimer = setInterval(() => {
        this.refreshSeconds--;
        if (this.refreshSeconds == 0) {
          clearInterval(this.rateTimer);
          this.rateTimer = null;

          if (!this.isFixedRate) {
            this.getPrice();
          }
        }
      }, 1000);
    },
    // 打开/关闭弹窗固定汇率
    fixedRate(status) {
      this.isFixedRate = status;
      if (!status && !this.refreshSeconds) {
        this.getPrice();
      }
    },
    // 选择银行卡
    select(item, type) {
      this.CNYPay.bank_payment.forEach((res) => {
        if (res.id != item.id || type == 2) {
          res.checked = false;
        }
      });
      // this.USDPay.bank_payment.forEach( res =>{
      //   if(res.id != item.id){
      //     res.checked =false
      //   }
      // })
      this.USDPay.third_payment.forEach((res) => {
        if (res.id != item.id || type == 1) {
          res.checked = false;
        } else {
          res.checked = true;
        }
      });
      this.paychose = item;
    },
    // 添加银行卡
    addWay() {
      this.$router.push('../shophome/shophomecapital/bankAdd');
    },

    // 提交银行卡支付订单
    immPay() {
      // console.log(this.paychose); //当前选择的银行卡
      if (this.paychose == '') {
        this.$message.warning('请选择支付方式');
        return;
      }
      let option = {
        tid: this.orderNum,
        payment_type: this.paychose.id,
        is_card: 1,
      };
      jumpPayment(option)
        .then((res) => {
          if (res?.errcode == 0) {
            let str = res?.data?.trim();
            if (str != '') {
              const div = document.createElement('div');
              div.innerHTML = res?.data;
              document.body.appendChild(div);
              document.forms[0].submit();
            } else {
              this.$message.warning('支付异常，请重试');
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 第三方支付(人民币第三方支付固定为支付宝和微信，前端写死)
    thirdPay(id) {
      let option = {
        tid: this.orderNum,
        payment_type: id,
        is_card: 0,
      };
      jumpPayment(option)
        .then((res) => {
          if (res?.errcode == 0) {
            let str = res?.data?.trim();
            if (str != '') {
              const div = document.createElement('div');
              div.innerHTML = res?.data;
              document.body.appendChild(div);
              document.forms[0].submit();
            } else {
              this.$message.warning('支付异常，请重试');
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    weChartPay() {
      let option = {
        tid: this.orderNum,
        payment_type: 6,
        is_card: 0,
      };
      jumpPayment(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.payTime = 1800;
            this.weChatShow = true;
            this.weChatUrl = res?.data?.code_url;
            this.out_trade_no = res?.data?.out_trade_no;
            this.handleClickCreateQRcode();
            // 获取支付状态
            if (this.stateTimer) {
              clearInterval(this.stateTimer);
            }
            this.stateTimer = setInterval(this.weiPayStatus, 1000);
            // 支付倒计时
            if (this.weChatTimer) {
              clearInterval(this.weChatTimer);
            }
            this.weChatTimer = setInterval(this.payTimeNum, 1000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    payTimeNum() {
      if (this.payTime > 0) this.payTime--;
      if (this.payTime == 0 && this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
    },

    weiPayStatus() {
      let option = {
        out_trade_no: this.out_trade_no,
        MUTE_WARNING: 1,
      };
      weiPayStatus(option)
        .then((res) => {
          if (res.errcode == 0 && res.data.status == 'SUCCESS') {
            if (this.stateTimer) {
              clearInterval(this.stateTimer);
            }
            this.$router.push({
              path: '/paySuccess',
              query: { tid: this.orderNum },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 点击生成二维码
    async handleClickCreateQRcode() {
      try {
        const options = {
          text: this.weChatUrl,
          version: this.version,
          margin: 0,
        };
        let that = this; // 必须要，否则 this 指向会错误
        function callbackFn(err, url) {
          if (err) {
            console.error(err);
            return;
          }
          that.codeUrl = url;
        }
        await this.createQRcode(options, callbackFn);
      } catch (err) {
        console.error(err);
      }
    },
    // 点击网站头logo跳转首页
    jumpindex() {
      this.$router.push('/');
    },
    getHour(time) {
      let mine = time % 60; // 分钟
      let hour = parseInt(time / 60);
      return [hour, mine];
    },

    closeweChat() {
      this.weChatShow = false;
      if (this.stateTimer) {
        clearInterval(this.stateTimer);
      }
      if (this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
    },

    order() {
      this.$router.push('/trade');
    },
    // 移除订单
    delOrderId(tid) {
      this.$store.commit('delBulkPaymentId', tid);

      this.priceRate = this.priceRate.filter((item) => item.tid !== tid);
      this.orderNum = this.priceRate.map((item) => item.tid);

      if (this.priceRate.length == 0) {
        this.$router.push('/trade/nopay');
      }
    },

    // 银行卡转账 获取平台银行卡信息
    async getBankCardInfo() {
      try {
        let res = await getBankCard();

        if (res.errcode == 0) {
          // console.log('银行卡信息', res.data);
          this.payInfo.accountNameOfPayee = res.data?.ACCOUNT_NAME_OF_PAYEE; // 收款方户名
          this.payInfo.payeeAccountNumber = res.data?.PAYEE_ACCOUNT_NUMBER; // 收款方账号
          this.payInfo.payeeBank = res.data?.PAYEE_BANK; // 收款方银行
          this.payInfo.SwiftBIC = res.data?.SWIFT_BIC; // Swift BIC
          this.payInfo.openingBankAddress = res.data?.OPENING_BANK_ADDRESS; // 开户银行地址
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
