
import inputPassword from '@/pages/back-stage/account-module/components/inputPassword.vue';
import { encryptor } from '@/utils/XmAES128Encryptor.js';
import { jumpPayment } from '@/api/client-api/goods/pay.js';
export default {
  name: 'balancePayment',
  components: {
    inputPassword,
  },
  data() {
    return {
      passWorldTip: 1, // 余额支付错误提示语，1不展示错误提示，2展示密码位数少，3展示业务提示错误
      passErrTip: '',
      orderId: this.$store.state.pay.choseOrdersId.toString(), // 订单号
    };
  },
  methods: {
    // 取消余额支付
    closeBalance() {
      this.$emit('closebalance');
    },
    // 获取余额支付密码
    getbankpass(item) {
      this.passWord = item;
    },
    // 设置密码
    setPassWord() {
      this.$router.push('/account');
    },
    // 确认余额支付
    balancePay() {
      if (this.passWord.length < 6) {
        this.passWorldTip = 2;
        return;
      }
      let option = {
        tid: this.orderId,
        payment_type: 2,
        password: encryptor.encrypt(this.passWord),
        MUTE_WARNING: 1,
      };
      jumpPayment(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.passWorldTip = 1;
            this.passwordShow = false;
            if (res?.msg) {
              this.$message.success(res?.msg);
            }

            this.$router.push({
              path: '/paysuccess',
              query: { tid: this.orderId },
            });
          } else if (res.errcode == 1004) {
            this.passWorldTip = 3;
            this.passErrTip = `The payment password is incorrect. You can also enter it${
              5 - res.data.num
            }times，After${5 - res.data.num}errors，he password will be locked${
              res.data.time
            }minute`;
          } else if (res.errcode == 1001) {
            this.passWorldTip = 3;
            this.passErrTip = res.msg;
          } else if (res.errcode == 1002) {
            this.passWorldTip = 3;
            this.passErrTip = res.msg;
          } else if (res.errcode == 1003) {
            this.passWorldTip = 3;
            this.passErrTip = res.msg;
          } else {
            if (res?.msg) {
              this.$message.warning(res?.msg);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
