import request from '@/utils/request.js';

//  获取分销商发票设置信息详情
export function getInvoiceMessage(data) {
  return request({
    url: '/InvoiceManage/invoiceSettingsDetail',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

//  编辑分销商发票设置信息
export function editInvoiceMessage(data) {
  return request({
    url: '/InvoiceManage/invoiceSettings',
    method: 'post',
    data: data,
    controller: {
      openLoading:true
    }
  });
}
