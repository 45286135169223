
import { mapGetters } from 'vuex';
import { getBulletinList } from '@/api/client-api/notify/platformannouncement.js';

export default {
  name: 'BulletinDetail',
  data() {
    return {
      lang: '', // 语言版本
      bulletinID: '', // 公告详情ID
      bulletinDetail: '', // 公告详情
    };
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
  },
  mounted() {
    this.lang = this.currLang;
    this.bulletinID = this.$route.params.id;
    this.getBulletinDetail();
  },
  methods: {
    // 根据ID获取公告详情
    getBulletinDetail() {
      let options = {
        id: this.bulletinID,
        openLoading: true,
        API_LANG_TYPE: this.lang,
      };
      getBulletinList(options)
        .then((res) => {
          if (res?.errcode == 0 && res?.data?.list) {
            this.bulletinDetail = res?.data?.list[0]?.notice_detail;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 回到上一页
    goBack() {
      this.$router.push('/notify/bulletinlist');
    },
  },
};
