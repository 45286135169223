
import SeachList from './components/SeachList.vue';
import TableList from './components/TableList.vue';
import {
  getPanelData,
  getExplosiveOrderList,
} from '@/api/client-api/activity/challenge';
export default {
  name: 'OrderChallenge',
  components: {
    SeachList,
    TableList,
  },
  data() {
    return {
      // 国内外挑战列表切换
      langActive: '1',
      langList: [
        { title: '国内爆单挑战', id: '1' },
        // { title: '国外爆单挑战', id: '2' },
      ],
      // tab面板的数据
      tabNum: {
        notPart: '0', // 未参加
        proceed: '0', // 进行中
        end: '0', // 已结束
      },
      activeTab: '1',

      tableKey: 0, // 表格刷新
      challengeList: [], // 挑战列表
      searchOptions: {}, // 搜索列表

      /**
       * 分页相关
       */
      pageSize: 10,
      pageNum: 1,
      total: 0,
    };
  },
  computed: {
    // tab面板展示数据
    tabList() {
      let tabArr = [
        { name: '未参加', sign: 'notPart', key: '1' },
        { name: '进行中', sign: 'proceed', key: '2' },
        { name: '已结束', sign: 'end', key: '3' },
      ];
      return tabArr.map((tab) => {
        let count = this.tabNum[tab.sign];
        let countText = count > 999 ? '999+' : count;
        return {
          name: `${tab.name}(${countText})`,
          key: tab.key,
        };
      });
    },
  },
  mounted() {
    if (this.$route.query?.status == '2') {
      this.activeTab = '2';
    }
    this.getTabData(); // 获取tab面板数据
    this.getChallengeList(); // 获取挑战列表
  },
  methods: {
    changeLang(id) {
      this.langActive = id;
      this.getTabData();
      this.resetSearch();
    },
    // 获取不同状态的列表tab数量
    getTabData() {
      getPanelData()
        .then((res) => {
          if (res?.errcode == 0) {
            this.tabNum = res?.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取不同状态的活动列表
    changeActivityStatus() {
      this.tableKey++;
      this.challengeList = [];
      this.getTabData();
      this.$refs.searchList.reset();
    },
    // 获取挑战列表
    getChallengeList() {
      let options = {
        status: this.activeTab,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        MUTE_WARNING: 1,
        ...this.searchOptions,
      };
      getExplosiveOrderList(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.challengeList = res?.data?.list;
            this.total = Number(res?.data?.total) || 0;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 自定义搜索挑战列表
    searchChallengeList(options) {
      this.searchOptions = options;
      this.resetSearch();
    },
    // 点击分页器
    goPages(n, pageSize) {
      this.pageNum = Number(n);
      if (pageSize) {
        this.pageSize = pageSize;
      }
      this.getChallengeList(); // 重新获取列表
    },
    // 从第一页搜索
    resetSearch() {
      this.pageNum = 1;
      this.getChallengeList(); // 重新获取列表
    },
    // 成功结束挑战/成功接受挑战
    changeTabStatus(id) {
      this.activeTab = id;
      this.changeActivityStatus();
    },
    // 放弃挑战
    successAbandon() {
      if (this.challengeList.length == 1) {
        this.pageNum--;
      }
      this.getTabData();
      this.getChallengeList();
    },
  },
};
