
// 新版
// import AttractBanner from './home/AttractBanner.vue';
import SearchBox from '@/components/comme/searchBox.vue';
import PlateJoinContainer from './home/PlateJoinContainer';
// import HotRecommend from './home/HotRecommend.vue';
import HighProfit from './home/HighProfit';
import HotCommodity from './home/HotCommodity.vue';
import IntelligentRecommend from './home/IntelligentRecommend';
// 优惠券
import CouponBoxShow from './home/CouponBoxShow.vue';
// 爆单挑战活动
import ChallengeActivity from './home/ChallengeActivity.vue';

// 商品补贴相关
import GoodsSubsidy from './home/GoodsSubsidy.vue';

// 专区板块
import SpecialSection from './home/SpecialSection.vue';

import IndexBottom from '@/components/indexbottom.vue';
import BackToTop from '@/components/BackToTop.vue';
import { mapGetters } from 'vuex';
import { sendAbnormalAlarm } from '@/utils/warning.js';
import {
  getIntelligentRecommend,
  haveNewUserCoupon,
  getActivePlateName,
} from '@/api/client-api/mall-home/api.js';
import {
  showLargePopUpWindow,
  getExplosiveContent,
} from '@/api/client-api/activity/challenge';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  head() {
    return {
      title: '全球电商分销平台_全品类供应链商城-细目商城',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            '细目商城通过链接全球供应商和分销商，为供应商提供全球分销平台，提升知名度、销售额和市场份额。为分销商提供全品类商品供应链，一站式服务为电商提效降本！',
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: '全球分销平台,全品类供应链,供应链商城,细目商城',
        },
      ],
    };
  },
  name: 'shopIndex',
  components: {
    // topheader,
    // indexdetail,
    // 新版
    // AttractBanner,
    SearchBox,
    PlateJoinContainer,
    // HotRecommend,
    HighProfit,
    HotCommodity,
    IntelligentRecommend,
    IndexBottom,
    BackToTop,

    GoodsSubsidy,
    CouponBoxShow,
    ChallengeActivity,
    SpecialSection,
  },
  // 需要seo的
  async asyncData(app) {
    const startTime = new Date();
    let isCategory = false;
    let lang = app.store.state.common.currLanguage;
    let CategoryTree = []; //类目
    let highProfitList = []; //高利润商品
    // let intelligentReList = []; //智能推荐
    let hotCommodityList = []; //热销商品列表
    let searchRecommend = []; //热搜词
    let carouselList = []; //轮播图
    let loading = true; //控制骨架屏
    let goodsDataList = []; //商品补贴模块的商品列表
    let activeSpecialCateId = ''; // 选中的专区分类id
    let specialCateList = {}; // 专区列表
    let specialGoodsList = {}; // 专区商品列表
    try {
      const [
        resHighProfitList,
        resHotCommodityList,
        resSearchRecommend,
        resCategoryTree,
        // resIntelligentReList
        resCarouselList,
        resGoodsSubsidy,
        resSpecialZone,
        resSpecialGoods,
      ] = await Promise.all([
        // 高利润
        app.$getHighProfitItemList({
          API_LANG_TYPE: lang,
          MUTE_WARNING: 1,
        }),
        // 热销商品
        app.$getHotSaleList({
          API_LANG_TYPE: lang,
          MUTE_WARNING: 1,
        }),
        // 热搜词
        app.$getHotKeyword({
          type: 2,
          MUTE_WARNING: 1,
          NON_EMPTY_TARGETS: 'data',
        }),
        // 类目
        app.$getCategoryClass({
          API_LANG_TYPE: lang,
          MUTE_WARNING: 1,
        }),
        // 智能推荐
        // app.$getIntelligentRecommend({
        //   page_size: 20, // 页大小
        //   page_num: 1, // 页号
        //   API_LANG_TYPE: lang,
        // })
        // 轮播图
        app.$getCarouselList({
          API_LANG_TYPE: lang,
          MUTE_WARNING: 1,
        }),
        // 商品补贴
        app.$getMallSubsidyItemList({
          region: '1',
          plate: '7',
          API_LANG_TYPE: lang,
          MUTE_WARNING: 1,
        }),
        // 专区列表
        app.$getSpecialZoneList({
          API_LANG_TYPE: lang,
          MUTE_WARNING: 1,
        }),
        // 专区商品列表
        app.$getPromotionItemList({
          API_LANG_TYPE: lang,
          MUTE_WARNING: 1,
        }),
      ]);
      // 高利润
      if (resHighProfitList?.errcode == 0 && resHighProfitList?.data) {
        highProfitList =
          resHighProfitList?.data?.slice(0, 24)?.map((obj) => {
            return { ...obj, collectFont: false };
          }) || [];
      }
      // 热销商品
      if (resHotCommodityList?.errcode == 0 && resHotCommodityList?.data) {
        hotCommodityList =
          resHotCommodityList?.data?.slice(0, 24)?.map((obj) => {
            return { ...obj, collectFont: false };
          }) || [];
      }
      // 热搜词
      if (resSearchRecommend?.errcode === 0) {
        searchRecommend = resSearchRecommend?.data?.map((item) => item.words);
      }
      // 类目
      if (resCategoryTree?.errcode == 0 && resCategoryTree?.data) {
        CategoryTree = resCategoryTree?.data;
      }

      // 智能推荐
      // if (resIntelligentReList?.errcode == 0 && resIntelligentReList?.data) {
      //   intelligentReList = resIntelligentReList?.data?.map((obj) => {
      //       return { ...obj, collectFont: false };
      //     });
      // }
      // 轮播图
      if (resCarouselList?.errcode == 0) {
        carouselList = resCarouselList?.data?.list;
        app.store.dispatch('loading/setLoading', false);
      }

      // 商品补贴
      if (resGoodsSubsidy?.errcode == 0) {
        goodsDataList = resGoodsSubsidy?.data?.subsidyItemList || [];
      }
      // 专区列表
      if (resSpecialZone?.errcode == 0) {
        specialCateList = resSpecialZone?.data || {};
        activeSpecialCateId = resSpecialZone?.data?.item?.[0]?.id;
      }
      // 专区商品列表
      if (resSpecialGoods?.errcode == 0) {
        specialGoodsList = resSpecialGoods?.data || {};
      }
      const endTime = new Date();
      const duration = endTime - startTime;
      // console.log(`[Page] Index rendered in ${duration}ms`);
      return {
        CategoryTree,
        highProfitList,
        // intelligentReList,
        hotCommodityList,
        searchRecommend,
        isCategory,
        carouselList,
        loading,
        goodsDataList,
        specialCateList,
        specialGoodsList,
        activeSpecialCateId,
      };
    } catch (error) {
      console.error(error, 'error');
      if (error?.status) {
        // 请求报错
        console.log('mall-home/MallHome.vue', error?.status, error?.statusText);
        return {
          CategoryTree,
          highProfitList,
          // intelligentReList,
          hotCommodityList,
          searchRecommend,
          carouselList,
          loading,
          goodsDataList,
          specialCateList,
          specialGoodsList,
          activeSpecialCateId,
        };
      } else {
        // 代码报错
        console.log(error);
        return {
          CategoryTree,
          highProfitList,
          // intelligentReList,
          hotCommodityList,
          searchRecommend,
          carouselList,
          loading,
          goodsDataList,
          specialCateList,
          specialGoodsList,
          activeSpecialCateId,
        };
      }
    }
  },
  data() {
    return {
      token: '',
      lang: '',
      attractBoxView: true, // 招商banner是否显示

      bulletinList: [], // 公告列表数据

      hotRecommendList: [], // 热门推荐商品数据

      highProfitList: [], // 高利润商品数据
      hotCommodityList: [], // 热销商品数据
      searchRecommend: [], //热搜词
      carouselList: [], //轮播图
      loading: true, //控制骨架屏
      isCategory: false, //判断规格是否能选

      pageSize: 20,
      pageNum: 1,
      intelligentReList: [], // 智能推荐数据
      intelligentReMoreList: [], // 智能推荐数据
      noMore: false, // 智能推荐没有下一页
      lookMoreBtn: false, // 查看更多按钮
      busy: false, // 无限加载的变量
      reqNum: 0, // 请求次数

      highProfitListPoint: [], // 埋点高利润商品数据
      hotCommodityListPoint: [], // 埋点热销商品数据
      intelligentReListPoint: [], // 埋点智能推荐数据
      intelligentReMoreListPoint: [], // 埋点更多智能推荐数据
      specialGoodsListPoint: [], // 专区商品埋点数据

      CategoryTree: [],

      searchShowTop: false, // 搜索是否悬浮

      couponShow: false, // 优惠券展示状态

      goodsDataList: [], //商品补贴模块的商品列表

      /**
       * 爆单挑战弹窗相关
       */
      challengeTypeId: '', // 爆单挑战类型id
      challengeComponent: false, // 爆单挑战组件状态
      challengeList: [], // 爆单挑战内容
      challengeSmallDialog: false, // 小弹窗状态
      challengeBigDialog: false, // 大弹窗状态
      giveUpButton: false, // 放弃挑战按钮
      activeOneName: '', // 活动一名称
      activeTwoName: '', // 活动二名称

      activeSpecialCateId: '', // 选中的专区分类id
      specialCateList: {}, // 专区列表
      specialGoodsList: {}, // 专区商品列表
    };
  },
  computed: {
    ...mapGetters({
      currLoading: 'loading/currLoading',
      currLang: 'common/currLang',
    }),
  },
  mounted() {
    this.scrollToTopPage(); // 初次打开页面为顶部
    if (process.client) {
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
      this.lang = this.currLang;
      this.getBulletinList(); // 获取公告列表
      this.getHighProfitItem(); // 获取高利润商品列表
      this.getIntelligentList(); // 获取智能推荐商品列表
      this.getHotCommodityList(); // 获取热销商品列表
      this.getHotKeyword(); // 获取热搜词
      this.getCategoryTree(); // 类目
      this.getActivePlateNameData(); // 获取活动标题

      this.getGoodsDataList(); // 获取商品补贴展示的商品
      this.getSpecialCateData(); // 获取专区类别数据

      window.addEventListener(
        'scroll',
        this.throttle(this.scrollToTopNav, 200)
      );

      // if (this.lang == 'zh-cn') {
      //   if (this.token) {
      //     this.judgeHasCoupon(); // 获取用户是否出现优惠券
      //   } else this.couponShow = true;
      // } else {
      //   this.couponShow = false;
      // }
    }
  },
  beforeDestroy() {
    // this.leavePagePoint();
    window.removeEventListener(
      'scroll',
      this.throttle(this.scrollToTopNav, 50)
    );
  },
  methods: {
    /**
     * 页面触发
     */
    //获取商品补贴展示的商品
    async getGoodsDataList() {
      if (this.goodsDataList?.length > 0) {
        return;
      }
      try {
        // 商品补贴
        let res = await this.$getMallSubsidyItemList({
          region: '1',
          plate: '7',
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        });
        if (res?.errcode == 0) {
          this.$nextTick(() => {
            this.goodsDataList = res?.data?.subsidyItemList || [];
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取热搜词
    async getHotKeyword() {
      try {
        if (this.searchRecommend && this.searchRecommend?.length > 0) {
          return;
        }
        let res = await this.$getHotKeyword({ type: 2, MUTE_WARNING: 1 });
        if (res?.errcode == 0) {
          this.searchRecommend = res?.data?.map((item) => item.words);
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取类目
    async getCategoryTree() {
      try {
        if (this.lang == 'zh-cn') {
          // 中文类目
          if (
            this.$store.state.category.categoryData &&
            this.$store.state.category.categoryData.length > 0
          ) {
            if (this.lang == 'zh-cn') {
              this.CategoryTree = this.$store.state.category.categoryData;
              this.isCategory = true;
            }
          } else if (
            this.$store.state.category.categoryData &&
            this.$store.state.category.categoryData.length == 0
          ) {
            // 类目
            let resCategoryTree = await this.$getAllCategoryInfo({
              MUTE_WARNING: 1,
            });
            this.$store.dispatch(
              'category/setCategoryData',
              resCategoryTree.data
            );
            if (resCategoryTree.errcode == 0 && resCategoryTree.data) {
              this.CategoryTree = resCategoryTree.data;
              this.isCategory = true;
            }
          }
        }
        // 英文类目
        if (this.lang == 'en') {
          if (
            this.$store.state.category.categoryEnData &&
            this.$store.state.category.categoryEnData.length > 0
          ) {
            if (this.lang == 'en') {
              this.CategoryTree = this.$store.state.category.categoryEnData;
              this.isCategory = true;
            }
          } else if (
            this.$store.state.category.categoryEnData &&
            this.$store.state.category.categoryEnData.length == 0
          ) {
            // 类目
            let resCategoryTree = await this.$getAllCategoryInfo({
              language: '3',
              MUTE_WARNING: 1,
            });
            this.$store.dispatch(
              'category/setCategoryEnData',
              resCategoryTree.data
            );
            if (resCategoryTree.errcode == 0 && resCategoryTree.data) {
              this.CategoryTree = resCategoryTree.data;
              this.isCategory = true;
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 关闭招商banner
    closeAttract() {
      this.attractBoxView = false;
    },

    // 监听搜索栏的滚动
    scrollToTopNav() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 230) {
        this.searchShowTop = true;
      } else {
        this.searchShowTop = false;
      }
    },

    // 获取公告列表
    async getBulletinList() {
      let options = {
        page_size: 12,
        pageNum: 1,
        API_LANG_TYPE: this.lang,
      };
      try {
        let res = await this.$getBulletinList(options);
        if (res?.errcode == 0 && res?.data?.list) {
          if (res?.data?.list?.length > 12) {
            this.bulletinList = res?.data?.list?.slice(0, 12);
          } else {
            this.bulletinList = res?.data?.list;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    // 获取热门推荐商品列表

    // 获取高利润商品列表
    getHighProfitItem() {
      // if (this.highProfitList && this.highProfitList?.length > 0) {
      //   return;
      // }
      this.$getHighProfitItemList({ API_LANG_TYPE: this.lang })
        .then((res) => {
          if (res?.errcode == 0 && res?.data) {
            this.highProfitList =
              res?.data?.slice(0, 24)?.map((obj) => {
                return { ...obj, collectFont: false };
              }) || [];

            // 埋点上报
            let dataList = JSON.parse(
              JSON.stringify(this.highProfitList || [])
            );
            this.highProfitListPoint = this.handlePointData(dataList);
            //  上报埋点
            this.burialPoint(this.highProfitListPoint);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取热销商品列表
    getHotCommodityList() {
      // if (this.hotCommodityList && this.hotCommodityList?.length > 0) {
      //   return;
      // }
      this.$getHotSaleList({ API_LANG_TYPE: this.lang })
        .then((res) => {
          if (res?.errcode == 0 && res?.data) {
            this.hotCommodityList =
              res?.data?.slice(0, 24)?.map((obj) => {
                return { ...obj, collectFont: false };
              }) || [];

            // 埋点上报
            let dataList = JSON.parse(
              JSON.stringify(this.hotCommodityList || [])
            );
            this.hotCommodityListPoint = this.handlePointData(dataList);
            this.burialPoint(this.hotCommodityListPoint);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取智能推荐商品列表
    async getIntelligentList() {
      let options = {
        page_size: this.pageSize, // 页大小
        page_num: this.pageNum, // 页号
        API_LANG_TYPE: this.lang,
      };
      try {
        let res = await getIntelligentRecommend(options);
        if (res?.errcode == 0 && res?.data) {
          this.intelligentReList = res?.data?.map((obj) => {
            return { ...obj, collectFont: false };
          });
          // 埋点上报
          if (this.intelligentReList) {
            let dataList = JSON.parse(
              JSON.stringify(this.intelligentReList || [])
            );
            this.intelligentReListPoint = this.handlePointData(dataList);
            this.burialPoint(this.intelligentReListPoint);
          }

          // this.hotRecommendList = res.data.map((obj) => {
          //   return { ...obj, collectFont: false };
          // });
        } else {
          // ??? 智能推荐 errcode 不等于 0 也要报警吗
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 获取更多的智能推荐数据
     * action: 有值为点击按钮加载，无值为触底加载
     * canAddNum: 是否可以增加请求次数计数
     * reqNum： 请求次数计数
     * noMore：默认值false, 为true时智能推荐没有下一页
     * lookMoreBtn： 默认值false, 为true时没有查看更多按钮
     * busy: 无限加载的变量，为true时加载函数不触发
     */
    getMoreIntelligent(action) {
      let canAddNum = true;
      if (action) {
        this.reqNum++;
        canAddNum = false;
      }
      if (this.noMore == true) return;
      this.pageNum++;
      let options = {
        page_size: this.pageSize, // 页大小
        page_num: this.pageNum, // 页号
        API_LANG_TYPE: this.lang,
      };
      this.$getIntelligentRecommend(options)
        .then((res) => {
          if (res.errcode == 0) {
            if (
              this.reqNum == 8 ||
              (this.reqNum > 8 && (this.reqNum - 8) % 4 == 0)
            ) {
              this.lookMoreBtn = true;
              return;
            }
            if (res.data.length !== 0) {
              this.intelligentReMoreList = res.data.map((obj) => {
                return { ...obj, collectFont: false };
              });
              this.intelligentReList = [
                ...this.intelligentReList,
                ...this.intelligentReMoreList,
              ];
              this.busy = false;
              if (canAddNum) {
                this.reqNum++;
              }
            }
            if (res.data.length === 0 || res.data.length < 20) {
              this.noMore = true;
              this.busy = true;
            }
            this.lookMoreBtn = false;

            // 埋点上报
            let dataList = JSON.parse(
              JSON.stringify(this.intelligentReMoreList || [])
            );
            this.intelligentReMoreListPoint = this.handlePointData(dataList);
            this.intelligentReListPoint = [
              ...this.intelligentReListPoint,
              ...this.intelligentReMoreListPoint,
            ];
            this.burialPoint(this.intelligentReMoreListPoint);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 收藏商品
    collectGoods(item) {
      if (!this.token) {
        this.$router.push('login');
        return;
      }

      // 4个板块中有可能会有一样的商品,现循环替换
      // const hotRecommendGoods = this.hotRecommendList.find(
      //   (i) => i.item_id === item.item_id
      // );
      const highProfitGoods = this.highProfitList.find(
        (i) => i.item_id === item.item_id
      );
      const hotCommodityGoods = this.hotCommodityList.find(
        (i) => i.item_id === item.item_id
      );
      const intelligentReGoods = this.intelligentReList.find(
        (i) => i.item_id === item.item_id
      );
      let options = {
        item_id: item.item_id,
      };
      // 商品为已收藏状态
      if (item.is_fav == 1) {
        this.$delFav(options)
          .then((res) => {
            if (res?.errcode == 0) {
              item.is_fav = 0;
              item.collectFont = true;

              // hotRecommendGoods.is_fav = 0;
              if (highProfitGoods !== undefined) {
                highProfitGoods.is_fav = 0;
              }
              if (hotCommodityGoods !== undefined) {
                hotCommodityGoods.is_fav = 0;
              }
              if (intelligentReGoods !== undefined) {
                intelligentReGoods.is_fav = 0;
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      // 商品为未收藏状态
      else {
        this.$addFav(options)
          .then((res) => {
            if (res?.errcode == 0) {
              item.is_fav = 1;
              item.collectFont = true;

              // hotRecommendGoods.is_fav = 1;
              if (highProfitGoods !== undefined) {
                highProfitGoods.is_fav = 1;
              }
              if (hotCommodityGoods !== undefined) {
                hotCommodityGoods.is_fav = 1;
              }
              if (intelligentReGoods !== undefined) {
                intelligentReGoods.is_fav = 1;
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      setTimeout(() => {
        item.collectFont = false;
      }, 1000);
    },
    // 处理埋点数据
    handlePointData(dataList) {
      for (let i = 0; i < dataList.length; i++) {
        let item = {};
        item['item_id'] = dataList[i].item_id;
        item['time_in'] = Date.now();
        item['route'] = this.getStayRoute(this.$route);
        item['operating_system'] = this.getOperatingSystem();
        item['action_type'] = 1;
        dataList[i] = item;
      }
      return dataList;
    },
    // 离开页面时候埋点数据处理
    leavePagePoint() {
      let dataPointList = [
        ...this.highProfitListPoint,
        ...this.hotCommodityListPoint,
        ...this.intelligentReListPoint,
        ...this.specialGoodsListPoint,
      ];
      for (let i = 0; i < dataPointList.length; i++) {
        dataPointList[i].time_out = Date.now();
      }
      this.burialPoint(dataPointList);
    },
    loadMore() {
      if (!this.busy) {
        this.busy = true;

        this.getMoreIntelligent();
      }
    },
    // 获取用户是否出现优惠券
    judgeHasCoupon() {
      let options = {
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      haveNewUserCoupon(options)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.data?.is_have == '1') {
              this.couponShow = true;
            } else {
              this.couponShow = false;
              this.getChallengeDialogStatus(); // 获取是否出现挑战活动弹窗
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取是否出现挑战活动弹窗
    getChallengeDialogStatus() {
      showLargePopUpWindow({ API_LANG_TYPE: this.lang, MUTE_WARNING: 1 })
        .then((res) => {
          if (res?.errcode == 0) {
            this.challengeSmallDialog = res?.data?.is_show_small; // 小弹窗状态
            this.challengeBigDialog = res?.data?.is_show_large; // 大弹窗状态
            this.giveUpButton = res?.data?.is_abandon; // 放弃挑战按钮

            if (res?.data?.is_show_large) {
              this.getChallengeContent(); // 大弹窗存在获取爆单挑战内容
            } else {
              this.challengeComponent = true;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取爆单挑战内容
    getChallengeContent() {
      getExplosiveContent()
        .then((res) => {
          if (res?.errcode == 0 && res?.data?.list?.length != 0) {
            this.challengeComponent = true;
            this.challengeTypeId = res?.data?.exp_id;
            this.challengeList = res?.data?.list?.map((obj, index) => {
              return { ...obj, selected: false, title: '挑战' + (index + 1) };
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 改变某个挑战条件状态
    changeSelected(id, status) {
      this.challengeList = this.challengeList.map((challenge) => {
        // 将不是指定ID的selected属性设置为false
        if (challenge.chal_id != id && !status) {
          challenge.selected = false;
        }
        // 将指定ID的selected属性取反
        if (challenge.chal_id == id) {
          challenge.selected = !status;
        }
        return challenge;
      });
    },
    // 成功接受挑战
    acceptChallenge() {
      this.closeAllChallenge();
      this.$router.push('/activity?status=2');
    },
    // 关闭所有挑战弹窗
    closeAllChallenge() {
      this.challengeTypeId = '';
      this.challengeList = [];
      this.challengeSmallDialog = false;
      this.challengeBigDialog = false;
    },
    // 关闭大挑战弹窗
    closeBigChallenge() {
      this.challengeSmallDialog = true;
      this.challengeBigDialog = false;
    },
    // 获取活动名称
    getActivePlateNameData() {
      let options = {
        client: 'PC',
        language_type: this.lang == 'en' ? '3' : '2',
      };
      getActivePlateName(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.activeOneName = res.data?.active_one?.title;
            this.activeTwoName = res.data?.active_two?.title;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * 专区板块
     */
    // 获取专区类别数据
    async getSpecialCateData() {
      try {
        let options = {
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        const res = await this.$getSpecialZoneList(options);
        if (res?.errcode == 0) {
          this.specialCateList = res?.data || {};
          this.activeSpecialCateId = res?.data?.item?.[0]?.id;
          this.getSpecialGoodsData();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取专区商品数据
    async getSpecialGoodsData() {
      try {
        let options = {
          id: this.activeSpecialCateId,
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        const res = await this.$getPromotionItemList(options);
        if (res?.errcode == 0) {
          this.specialGoodsList = res?.data || {};

          // 埋点上报
          let dataList = JSON.parse(
            JSON.stringify(this.specialGoodsList?.item?.slice(0, 5) || [])
          );
          this.specialGoodsListPoint = this.handlePointData(dataList);
          //  上报埋点
          this.burialPoint(this.specialGoodsListPoint);
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 切换专区类别
     * @param {String} id 要切换到的专区类别id
     */
    handleChangeSpecialCate(id) {
      this.activeSpecialCateId = id;
      this.getSpecialGoodsData();
    },
  },
};
