
export default {
  name: 'XmMoreOperations',
  props: {
    // 操作列表
    operations: {
      type: Array,
      default: () => [
        // {
        //   label: '立即审核',
        //   eventName: 'audit', // 绑定的会触发的父组件事件名称
        // },
        // {
        //   label: '查看详情',
        //   eventName: 'detail', // 绑定的会触发的父组件事件名称
        // },
        // {
        //   label: '备注',
        //   eventName: 'edit-memo', // 绑定的会触发的父组件事件名称
        // },
      ],
    },
    // 不显示“更多”的最大操作数
    // 比如，表格最多显示两个操作，limit 就置2
    limit: {
      type: Number,
      default: 2,
    },
    // “更多”标签的文本
    moreText: {
      type: String,
      default: '更多',
    },
  },
  computed: {
    // 显示在外的操作
    outsideList() {
      const needMore = this.operations.length > this.limit;
      if (this.operations) {
        return needMore
          ? this.operations.slice(0, this.limit - 1)
          : this.operations.slice();
      } else {
        return [];
      }
    },
    // 被包裹在“更多”的下拉菜单中的操作
    insideList() {
      const needMore = this.operations.length > this.limit;
      if (this.operations && needMore) {
        return this.operations.slice(this.limit - 1);
      } else {
        return [];
      }
    },
  },
  methods: {
    // 点击操作链接会触发事件
    handleClick(item) {
      this.$emit(item.eventName);
    },
  },
};
