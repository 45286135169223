
import Cashier from '@/pages/goods-pay/components/Cashier.vue'; // 收银台
import PayBottom from '@/components/indexbottom.vue'; // 页脚

import CNYPayMethods from './components/CNYPayMethods.vue'; // 人民币付款
import USDPayMethods from './components/USDPayMethods.vue'; // 美元付款
import ArrearageInfo from './components/ArrearageInfo.vue'; // 欠费详情

import WeChatPayDialog from '../components/WeChatPayDialog.vue'; // 微信支付弹窗
import NoPasswordDialog from '../components/NoPasswordDialog.vue'; // 没有设置过支付密码提示弹窗
import BalancePayDialog from '../components/BalancePayDialog.vue'; // 余额支付密码弹窗

// api
import {
  getArrearsInfo,
  arrearsJumpPayment,
} from '@/api/client-api/fund/fundCenter.js';
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
// 加密
import { encryptor } from '@/utils/XmAES128Encryptor.js';
/**
 * 多语言配置
 */
import { BALANCE_PAYMENT } from '../js/label_config_public';
export default {
  name: 'disburse',
  components: {
    Cashier,
    PayBottom,
    CNYPayMethods,
    USDPayMethods,
    ArrearageInfo,
    WeChatPayDialog,
    NoPasswordDialog,
    BalancePayDialog,
  },
  data() {
    return {
      currency: '1', // 币种 1人民币 2美元
      currentBalance: '', // 账号当前余额

      currentId: '', // 欠费id
      arrearageInfo: {}, // 欠费信息

      canClickPay: true, // 用于控制接口请求不能连续点击按钮
      weChatShow: false, // 微信支付弹窗
      outTradeNo: '',
      weChatUrl: '',

      errTip: '', // 不可支付提示文案
      cannotPayTipDialog: false, // 不可支付提示弹窗

      hasPayPassword: false, // 是否设置了支付密码
      noPayPasswordDialog: false, // 没有支付密码提示弹窗
      balancePayDialog: false, // 余额支付弹窗
      canClickBalancePay: true, // 余额支付密码弹窗提交支付密码
      balanceErrTip: '', // 余额支付错误提示
      balanceErrType: '1', // 余额支付错误提示语类型，1不展示错误提示，2展示密码位数少，3展示业务提示错误
    };
  },
  computed: {
    // 页面语言
    lang() {
      if (this.currency && this.currency == '1') {
        return 'zh-cn';
      }
      if (this.currency && this.currency == '2') {
        return 'en';
      }
    },
    // 当前展示人民币/美元支付方式组件
    payCurrencyBox() {
      if (this.currency == '1') {
        return CNYPayMethods;
      }
      if (this.currency == '2') {
        return USDPayMethods;
      }
    },
    // 标签多语言配置
    langConfig() {
      return BALANCE_PAYMENT[this.lang || 'zh-cn'];
    },
  },
  mounted() {
    this.currency = this.$route.params.currency; // 币种
    this.currentId = this.$route.params.id; // 支付唯一id
    this.getArrearageInfo(); // 获取欠费信息
  },
  methods: {
    // 获取欠费信息
    async getArrearageInfo() {
      try {
        const res = await getArrearsInfo({
          arrears_id: this.currentId,
        });
        if (res?.errcode == 0) {
          this.arrearageInfo = res?.data || {};
          if (this.currency != res?.data?.currency)
            this.currency = res?.data?.currency;
          if (this.currency == '1')
            this.currentBalance = res?.data?.available_balance_CNY;
          if (this.currency == '2')
            this.currentBalance = res?.data?.available_balance_USD;
          return;
        }
        // 出错就返回资金中心
        this.$router.push('/fund/fundcenter/' + this.currency);
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 选择了支付方式点击立即支付
     * @param {String} type 1是微信, 2是支付宝，3是余额支付
     */
    async chosePayMethod(type) {
      // 不能点击时return
      if (!this.canClickPay) return;
      this.canClickPay = false;

      if (type == '1') {
        this.weChatPay();
      }
      if (type == '2') {
        this.linkAlipayPay();
      }
      if (type == '3') {
        await this.getBasicInformation();
        if (!this.hasPayPassword) {
          this.noPayPasswordDialog = true;
          return;
        }
        // 余额支付
        this.balancePayDialog = true;
      }
    },
    // 点击微信支付
    async weChatPay() {
      try {
        let options = {
          arrears_id: this.currentId, // 欠费id
          payment_type: 6, // 支付类型 1人民币余额 2美元余额 3支付宝 6微信
          MUTE_WARNING: 1,
        };
        const res = await arrearsJumpPayment(options);
        this.canClickPay = true; // 可以点击立即支付了
        if (res?.errcode == 0) {
          this.weChatShow = true;
          this.weChatUrl = res?.data?.code_url;
          this.outTradeNo = res?.data?.out_trade_no;
        } else {
          this.errTip = res?.msg;
          this.cannotPayTipDialog = true; // 打开错误提示弹窗
        }
      } catch (err) {
        this.canClickPay = true; // 可以点击立即支付了
        console.error(err);
      }
    },
    // 跳转支付宝链接支付
    async linkAlipayPay() {
      try {
        let options = {
          arrears_id: this.currentId,
          payment_type: 3,
          MUTE_WARNING: 1,
        };
        const res = await arrearsJumpPayment(options);
        if (res?.errcode == 0) {
          let str = res?.data?.trim();
          if (str != '') {
            const div = document.createElement('div');
            div.innerHTML = res?.data;
            document.body.appendChild(div);
            document.forms[0].submit();
          } else {
            this.canClickPay = true; // 可以点击立即支付了
          }
        } else {
          this.errTip = res?.msg;
          this.cannotPayTipDialog = true;
          this.canClickPay = true; // 可以点击立即支付了
        }
      } catch (err) {
        this.canClickPay = true; // 可以点击立即支付了
        console.error(err);
      }
    },
    // 获取是否有支付密码
    async getBasicInformation() {
      try {
        const res = await getBasicInformation({ MUTE_WARNING: 1 });
        if (res?.errcode == 0) {
          this.hasPayPassword = res?.data?.pay_password;
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 余额支付提交密码
     * @param {String} password 用户输入的密码
     */
    async sureBalancePay(password) {
      try {
        if (password.length < 6) {
          this.balanceErrType = '2';
          return;
        }
        // 不能点击时return
        if (!this.canClickBalancePay) return;
        this.canClickBalancePay = false;
        let options = {
          arrears_id: this.currentId,
          payment_type: this.currency, // payment_type 1人民币余额 2 美元余额 3 支付宝 6微信
          password: encryptor.encrypt(password),
          MUTE_WARNING: 1,
          API_LANG_TYPE: this.lang,
        };
        const res = await arrearsJumpPayment(options);
        this.canClickBalancePay = true;
        if (res?.errcode == 0) {
          this.balanceErrType = '1';
          this.balancePayDialog = false; // 关闭余额支付弹窗
          if (res?.msg) this.$message.success(res?.msg);
          this.$router.replace({
            path: `/fund/fundcenter/${this.currency}`,
          });
        } else if (res?.errcode == 1004) {
          this.balanceErrType = '3';
          this.balanceErrTip = this.langConfig?.['err-tip'](
            5 - Number(res?.data?.num),
            res?.data?.time
          );
          console.log(this.balanceErrType, 'this.balanceErrType');
          console.log(this.balanceErrTip, 'this.balanceErrTip');
        } else if (
          res?.errcode == 1001 ||
          res?.errcode == 1002 ||
          res?.errcode == 1003
        ) {
          this.balanceErrType = '3';
          this.balanceErrTip = res?.msg;
        } else {
          if (res?.msg) {
            this.$message.warning(res?.msg);
          }
        }
      } catch (err) {
        this.canClickBalancePay = true; // 可以点击了
        console.error(err);
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.balanceErrType = '1'; // 余额支付错误提示语类型，1不展示错误提示
      this.canClickPay = true;
      this.cannotPayTipDialog = false; // 关闭错误提示弹窗
      this.noPayPasswordDialog = false; // 关闭没有设置支付密码弹窗
      this.balancePayDialog = false; // 关闭余额支付密码弹窗
    },
    // 关闭微信支付弹窗
    closeWeChatDialog() {
      this.weChatShow = false;
    },
    // 微信支付成功
    paySuccessWechat() {
      // 返回资金中心
      this.$router.push('/fund/fundcenter/' + this.currency);
    },
  },
};
