// 工具函数
import tools from '@/utils/tool-function/tool.js';
// ---------------------------------------------------------------------------
/**
 * 跨域名发送登录信息
 */
// 跨域名登录
let time = null;

/**
 *
 * @param {String} id 父节点的id
 */
export async function crossDomainLogin(id = 'iframe-box') {
  return
  if (!process.client) {
    return;
  }
  const loginData = await tools.getLoginInfo();
  // 节点初始化，以免造成重复
  let iframeMax = document.getElementById('iframe-max' + '-' + id);
  if (iframeMax) {
    iframeMax.remove();
  }
  let targetUrl = process.env.NUXT_ENV.VUE_JUMP_SHOP_Local + '/login';
  if (process.env.NUXT_ENV.NODE_ENV == 'production') {
    targetUrl = process.env.NUXT_ENV.VUE_JUMP_SHOP_Local + 'login';
  }
  const redirectIframe = document.createElement('iframe');
  redirectIframe.id = 'iframe-max' + '-' + id;
  redirectIframe.style = 'position: fixed; opacity: 0;';
  let times = setTimeout(() => {
    redirectIframe.style = 'display: none;';
    clearTimeout(times);
    times = null;
  }, 3000);
  redirectIframe.src = targetUrl;
  let containerDom = document.getElementById(id);
  containerDom?.appendChild(redirectIframe);
  redirectIframe.onload = function (event) {
    redirectIframe.contentWindow.postMessage(loginData, targetUrl);
  };
}

// 清除节点并清除计时器，以免造成内存溢出
export function removeDom(id = 'iframe-box') {
  return
  if (!process.client) {
    return;
  }
  let iframeMax = document.getElementById('iframe-max' + '-' + id);
  if (iframeMax) {
    iframeMax.remove();
  }
  if (time) {
    clearInterval(time);
    time = null;
  }
}

// -----------------------------------------------------------------------
/**
 * 接收跨域名传来的消息
 */

// 只接收这里设置的域名传来的消息
const passUrl = new Set([process.env.NUXT_ENV.VUE_JUMP_SHOP_Local]);
// 接收白名单域名传来的登录信息
export function getCrossDomainLoginInfo(app) {
  return
  if (!process.client) {
    return;
  }
  let time = null;
  window.addEventListener('message', (e) => {
    // 客户端才能执行
    // 不被允许的域名发起信息传递
    if (!passUrl.has(e.origin)) return;
    let data = e?.data;
    // 如果没有拿到数据则轮询
    time = setInterval(() => {
      // 接收到了值则关闭轮询
      if (data?.isReceived) {
        acheLoginInfo(app, data);
        clearInterval(time);
        time = null;
      }
    }, 50);
  });
}

/**
 *
 * @param {Object} app 相当于VUE实例
 * @param {Object} data 收到的登录需要的信息
 */
function acheLoginInfo(app, data) {
  return
  if (!process.client) {
    return;
  }
  localStorage.setItem('token1', data?.token || '');
  localStorage.setItem('userdetail', data?.userdetail || '');
  localStorage.setItem('statusShow', data?.statusShow);
  app.store.commit('login/setUserInfo', {
    key: 'userdetail',
    value: data?.userdetail || '',
  });
  app.store.commit('login/setUserInfo', {
    key: 'statusShow',
    value: data.statusShow,
  });
  app.store.commit('login/setToken', data.token || '');
}
