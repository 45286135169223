
export default {
  name: 'History',
  props: {
    // 币种
    currency: {
      type: String,
      default: '',
    },
    // 历史信息
    info: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
