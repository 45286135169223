
export default {
  name: 'CategoryBreadcrumb',
  props: {
    list: {
      type: Array,
      default: () => [
        // {
        //   level: '1', // 第几级类目
        //   name: '第一级类目', // 类目名
        //   supplier_class_id: '123', // 类目id
        // },
        // {
        //   level: '2',
        //   name: '第二级类目',
        //   supplier_class_id: '345',
        // },
        // {
        //   level: '3',
        //   name: '第三级类目',
        //   supplier_class_id: '567',
        // },
      ],
    },
  },
  methods: {
    /**
     * 点击类目文本跳转列表链接
     * @param lastIdx 点击的类目在list的索引
     */
    handleClick(lastIdx) {
      let queryIds = this.list
        .map((x) => x.supplier_class_id)
        .slice(0, lastIdx + 1)
        .join(',');
      this.$router.push('/list_' + queryIds + '.html');
    },
  },
};
