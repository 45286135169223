
import {
  getCountries,
  getStates,
  getCity,
  getContinents,
} from '@/api/client-api/mine/mineapi.js';
export default {
  data() {
    return {
      chau: '', //记录当前选中洲
      continents: [], //洲数组
      currentCountries: { id: '', has_state: '' }, //记录当前选中国家,包含是否有省
      countriesKey: '', //记录当前国家搜索词
      countries: [], //国家数组
      currentProvince: '', //记录当前选中省
      provinceKey: '', //记录当前省搜索字
      province: [], //省数组
      currentCity: '', //记录当前选中市
      cityKey: '', //记录当前市搜索词
      city: [], //市数组
      returnArray: [], //记录返回数据
      isProvince: false, //判断是否有省
    };
  },
  props: {
    is_search: {
      type: Boolean,
      default: false,
    },
    echo_data: {
      type: Array,
      default: () => [],
    },
    select_one: {
      type: Number,
      default: 150,
    },
    select_two: {
      type: Number,
      default: 200,
    },
    select_three: {
      type: Number,
      default: 200,
    },
    select_four: {
      type: Number,
      default: 200,
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getContinents();
    this.is_search && this.searchFunc(1);
    if (this.is_edit) {
      this.returnArray = this.echo_data.slice();
      if (this.echo_data.length === 3) {
        this.isProvince = false;
        this.chau = this.echo_data[0];
        this.currentCountries = this.echo_data[1];
        this.currentCity = this.echo_data[2];
      } else if (this.echo_data.length === 4) {
        this.isProvince = true;
        this.chau = this.echo_data[0];
        this.currentCountries = this.echo_data[1];
        this.currentProvince = this.echo_data[2];
        this.currentCity = this.echo_data[3];
      } else if (this.echo_data.length == 2) {
        this.chau = this.echo_data[0];
        this.currentCountries = this.echo_data[1];
      }
      this.editShowAddress(this.echo_data.length, this.echo_data);
    }
  },
  methods: {
    searchFunc(number) {
      if (number == 1) {
        getCountries({ id: this.chau, name: this.countriesKey })
          .then((res) => {
            if (res?.errcode == 0) {
              this.countries = res?.data; //将搜索到的结果显示
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (number == 2) {
        getStates({
          id: this.currentCountries.id,
          name: this.provinceKey,
        })
          .then((res) => {
            if (res?.errcode == 0) {
              this.province = res.data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (number == 3) {
        getCity({ id: this.currentProvince, name: this.cityKey })
          .then((res) => {
            if (res?.errcode == 0) {
              this.city = res.data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    //获取大洲
    async getContinents() {
      try {
        let res = await getContinents();
        if (res?.errcode == 0) {
          this.continents = res?.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 页面回显
    editShowAddress(numberFlag, echoObj) {
      getCountries({ id: this.chau })
        .then((countriesRes) => {
          this.countries = countriesRes.errcode == 0 ? countriesRes.data : [];
        })
        .catch((err) => {
          console.error(err);
        });
      if (numberFlag == 4 || (numberFlag == 3 && echoObj[1].has_state == 1)) {
        getStates({ id: this.currentCountries.id })
          .then((provinceRes) => {
            this.province = provinceRes.errcode == 0 ? provinceRes.data : [];
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (numberFlag > 2) {
        getCity({
          id: numberFlag == 4 ? this.currentProvince : this.currentCountries.id,
          type: numberFlag == 3 ? 'country' : '',
        })
          .then((cityRes) => {
            this.city = cityRes.errcode == 0 ? cityRes.data : [];
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.city = [];
      }
    },

    // 州
    async selectContinents(chau) {
      // 清空国/省/市
      this.currentCountries = { id: '', has_state: '' };
      this.currentProvince = '';
      this.currentCity = '';
      this.countries = [];
      this.province = [];
      this.city = [];
      this.returnArray = [];
      if (chau) {
        this.returnArray.push(chau);
        // 拉取国家数据
        try {
          const res = await getCountries({ id: chau });
          this.countries = res?.errcode == 0 ? res?.data : [];
        } catch (error) {
          console.error(error);
        } finally {
          this.$emit(
            'returnAddressEven',
            this.returnArray,
            this.countries.slice()
          );
          return;
        }
      }
      this.$emit('returnAddressEven', this.returnArray, this.countries.slice());
    },
    // 国家
    async selectCountries(countries) {
      // 清空省/市
      this.currentProvince = '';
      this.currentCity = '';
      this.province = [];
      this.city = [];
      try {
        if (countries) {
          let flag =
            this.countries.filter((item) => item.id == countries)[0]
              .has_state == 1;
          this.returnArray = [this.returnArray[0]];
          let obj = { id: countries, has_state: '' };
          if (flag) {
            this.isProvince = true;
            const res = await getStates({ id: countries });
            this.currentCountries.has_state = 1;
            this.province = res.errcode == 0 ? res.data : [];
            obj.has_state = 1;
          } else {
            this.isProvince = false;
            this.currentCountries.has_state = '';
            obj.has_state = '';
            const res = await getCity({ id: countries, type: 'country' });
            this.city = res.errcode == 0 ? res.data : [];
          }
          this.returnArray.push(obj);
        } else {
          this.returnArray = [this.returnArray[0]];
        }
        this.$emit(
          'returnAddressEven',
          this.returnArray,
          this.isProvince ? this.province.slice() : this.city.slice()
        );
      } catch (error) {
        console.error(error);
      }
    },
    // 省
    async selectProvince(province) {
      // 清空市
      this.currentCity = '';
      this.city = [];
      this.returnArray = this.returnArray.slice(0, 2);
      if (province) {
        try {
          const res = await getCity({ id: province });
          this.city = res.errcode == 0 ? res.data : [];
          this.returnArray.push(province);
        } catch (error) {
          console.error(error);
        }
      }
      this.$emit(
        'returnAddressEven',
        this.returnArray,
        province ? this.city.slice() : this.province.slice()
      );
    },
    // 市
    selectCity(city) {
      if (city) {
        if (this.returnArray[1].has_state) {
          if (this.returnArray.length == 3) {
            this.returnArray.push(city);
          } else {
            this.returnArray = this.returnArray.slice(
              0,
              this.returnArray.length - 1
            );
            this.returnArray.push(city);
          }
        } else {
          if (this.returnArray.length == 3) {
            this.returnArray = this.returnArray.slice(
              0,
              this.returnArray.length - 1
            );
            this.returnArray.push(city);
          } else {
            this.returnArray.push(city);
          }
        }
      } else {
        this.returnArray = this.returnArray.slice(
          0,
          this.returnArray.length - 1
        );
      }
      this.$emit('returnAddressEven', this.returnArray, this.city.slice());
    },
  },
};
