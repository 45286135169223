/**
 * 商品模块
 * - 商品详情
 */

import GoodDetail from '@/pages/good-detail/index.vue'; //商品详情

// 后台管理架子
import shophome from '@/pages/back-stage/index.vue';
// 商品模块
import GoodsLayout from '@/pages/back-stage/goods-module/GoodsLayout.vue'; //分销商后台商品模块布局
import Attention from '@/pages/back-stage/goods-module/goods-altention/GoodsAttention.vue'; //分销商后台关注商品
import contrast from '@/pages/back-stage/goods-module/goods-contrast/goodsContrast.vue'; //商品对比
import goodsDemand from '@/pages/back-stage/goods-module/goods-demand/goodsDemand.vue'; //商品需求 #TODO 这个功能有问题，先注释关闭掉

const goodRoutesDetault = [
  {
    path: '/:id.html',
    name: 'GoodDetail',
    component: GoodDetail,
    meta: {
      isAuth: false,
      isRecode: true,
    },
  },
  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: true,
    },
    children: [
      // 商品模块的
      {
        path: 'goods',
        component: GoodsLayout,
        meta: {
          isAuth: true,
        },
        children: [
          {
            path: '',
            component: Attention,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'attention',
            name: 'Attention',
            component: Attention,
            meta: {
              isAuth: true,
            },
          },
          //   {
          //     path: 'shop',
          //     name: 'shop',
          //     component: shop,
          //     meta: {
          //       isAuth: true,
          //     },
          //   },
          {
            path: 'contrast',
            name: 'contrast',
            component: contrast,
            meta: {
              isAuth: true,
            },
          },
          // {
          //   path: 'goodsDemand',
          //   name: 'goodsDemand',
          //   component: goodsDemand,
          //   meta: {
          //     isAuth: true,
          //   },
          // },
          //   {
          //     path: 'degreeheat',
          //     name: 'degreeheat',
          //     component: degreeheat,
          //     meta: {
          //       isAuth: true,
          //     },
          //   },
        ],
      },
    ],
  },
];

const goodRoutesRelease = [
  {
    path: '/:id.html',
    name: 'GoodDetail',
    component: GoodDetail,
    meta: {
      isAuth: false,
      isRecode: true,
    },
  },
  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: true,
    },
    children: [
      // 商品模块的
      {
        path: 'goods',
        component: GoodsLayout,
        meta: {
          isAuth: true,
        },
        children: [
          {
            path: '',
            component: Attention,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'attention',
            name: 'Attention',
            component: Attention,
            meta: {
              isAuth: true,
            },
          },
          //   {
          //     path: 'shop',
          //     name: 'shop',
          //     component: shop,
          //     meta: {
          //       isAuth: true,
          //     },
          //   },
          {
            path: 'contrast',
            name: 'contrast',
            component: contrast,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'goodsDemand',
            name: 'goodsDemand',
            component: goodsDemand,
            meta: {
              isAuth: true,
            },
          },
          //   {
          //     path: 'degreeheat',
          //     name: 'degreeheat',
          //     component: degreeheat,
          //     meta: {
          //       isAuth: true,
          //     },
          //   },
        ],
      },
    ],
  },
];

let goodRoutes =
  process?.env?.NUXT_ENV?.NODE_ENV === 'release'
    ? goodRoutesRelease
    : goodRoutesDetault;

export default goodRoutes;
