
/**
 * 多语言配置
 */
import { PLATE_CORRELATION } from '../js/label_config_board';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'BasicGoodsBox',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 商品信息
    goodsMsg: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return PLATE_CORRELATION[this.lang || 'zh-cn'];
    },
    imgURL() {
      if (this.goodsMsg.image_default_id) {
        // return 'https://img.ximu.cn/' + this.goodsMsg.image_default_id;
        this.showLoading = false;
        return this.$options.filters.settingDistinctDegree(
          this.goodsMsg.image_default_id
        );
      }
    },
  },
  data() {
    return {
      token: '',
      showLoading: true,
    };
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
  },
  methods: {
    // 点击收藏商品
    isCollectGoods(e, item) {
      if (e.target.tagName !== 'A') {
        e.preventDefault();
        // 处理点击子元素的逻辑
        e.stopPropagation();
        if (!this.token) {
          this.$router.push('/login');
          return;
        }
        this.$emit('click-collect', item);
      }
    },
  },
};
