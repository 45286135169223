
export default {
  data() {
    return {};
  },
  methods: {
    goFundCenter() {
      this.$router.push('/fund/fundcenter/1');
    },
  },
};
