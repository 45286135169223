
import {
  sendRechargeSmsCode,
  resetPassword,
} from '@/api/client-api/login/api.js';
import { encryptor } from '@/utils/XmAES128Encryptor';
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import { flatten } from 'mathjs';
import closePass from './closePass.vue';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  components: { closePass },
  data() {
    // 校验验证码
    const checkPassword = (rule, value, callback) => {
      // const reg = /^[0-9A-Za-z]{8,20}$/;
      const reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
      const isValidated = reg.test(this.passWordObj.password);
      if (this.passWordObj.password.trim() == '') {
        callback(new Error('请输入密码'));
      } else if (!isValidated) {
        callback(new Error('密码必须符合数字与字母组合共8-20位字符'));
      } else {
        callback();
      }
    };
    const checkCode = (rule, value, callback) => {
      let code = Number(this.passWordObj.code.trim());
      if (this.passWordObj.code.trim() == '') {
        callback(new Error('请输入验证码'));
      } else if (isNaN(code)) {
        callback(new Error('请输入正确的验证码'));
      } else {
        callback();
      }
    };
    return {
      passWordObj: {
        code: '', // 验证码
        password: '', // 密码
        configPassword: '', // 确认密码
      },
      codeTime: 60, // 倒计时
      phoneShow: '', // 展示的电话
      phone: '', // 电话
      timer: null, // 倒计时
      rules: {
        code: [
          {
            validator: checkCode,
            required: true,
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, validator: checkPassword, trigger: 'blur' },
        ],
        configPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
        ],
      },
      showPassword: true, // 展示密码
      showConfigPassword: true, // 展示确认密码
      successShow: false,
    };
  },
  mounted() {
    this.getuserInfo();
  },
  methods: {
    getuserInfo() {
      let option = {};
      getBasicInformation(option).then((res) => {
        if (res?.errcode == 0) {
          this.phoneShow = res.data.phone_show;
          this.phone = res.data.phone;
        }
      });
    },
    // 获取验证码
    getCode() {
      let option = {
        phone: this.phone,
      };
      sendRechargeSmsCode(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.timer = setInterval(() => {
              this.codeTime = this.codeTime - 1;
              if (this.codeTime == 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.codeTime = 60;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    },
    // 确认修改密码
    editPassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (
            this.passWordObj.password.trim() !=
            this.passWordObj.configPassword.trim()
          ) {
            this.$message.warning('两次输入的密码不一致，请确认');
            return;
          }
          let option = {
            password: encryptor.encrypt(this.passWordObj.password),
            phone: this.phone,
            code: this.passWordObj.code,
            type:
              this.$store.state.login.forgetPasswordForm.type || 'distributor',
            MUTE_WARNING: 1,
          };
          resetPassword(option)
            .then((res) => {
              if (res.errcode == 0) {
                this.successShow = true;
              } else {
                this.$message.warning(res.msg);
              }
            })
            .catch((err) => {
              console.log(err, 'err');
            });
        }
      });
    },
    // 密码的展示，隐藏
    checkPasswordShow(type) {
      if (type == 1) {
        this.showPassword = true;
      } else if (type == -1) {
        this.showPassword = false;
      }
    },
    // 确认密码展示框
    checkConfigPasswordShow(type) {
      if (type == 1) {
        this.showConfigPassword = true;
      } else if (type == -1) {
        this.showConfigPassword = false;
      }
    },
    // 修改成功，退出登录
    closeConfig() {
      localStorage.removeItem(CUR_TOKEN_NAME);
      localStorage.removeItem('userdetail');
      localStorage.removeItem('statusShow');
      this.$cookies.remove(CUR_TOKEN_NAME);
      this.$cookies.remove('userdetail');
      this.$cookies.remove('statusShow');
      localStorage.removeItem('my-app-state');
      this.$cookies.remove('is_limited_ximu_account');
      localStorage.removeItem('is_limited_ximu_account');
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push('/login');
    },
  },
};
