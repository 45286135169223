/**
 * 解压缩工具
 * @author hukeyi
 * @description 使用 jszip 库，实现解压 zip 文件功能
 */

import JSZipUtils from 'jszip-utils';
import iconv from 'iconv-lite'; // 解决中文文件名乱码问题

/**
 * 筛选出不是文件夹的文件名列表
 * @param {*} zipFiles jszip 解压缩后获得的文件列表
 * @returns 去除文件夹前缀的文件名和其文件路径列表
 */
function filterNonDirFiles(zipFiles) {
  const nonDirFiles = []; // 非文件夹的文件列表
  for (const [fileName, fileInfo] of Object.entries(zipFiles)) {
    if (!fileInfo.dir) {
      let fPath = fileName.split('/');
      let fName = fPath[fPath.length - 1];
      nonDirFiles.push({ fileName: fName, filePath: fileName });
    }
  }
  return nonDirFiles;
}

/**
 * 解压缩远程文件
 * @param JSZip jszip jszip压缩工具实例
 * @param {*} url 远程文件地址
 * @returns Promise 非文件夹的纯文件名和其在压缩包中的文件路径列表
 * （保留文件路径的原因是为了 zip.file(filePath) 获取BLOB文件流
 */
function unzip(jszip, url) {
  return new Promise((resolve, reject) => {
    // 通过远程URL加载压缩包文件
    JSZipUtils.getBinaryContent(url, (err, data) => {
      if (err) {
        reject(err);
        return;
      }

      jszip
        .loadAsync(data, {
          decodeFileName: function (bins) {
            // 解决中文文件名乱码问题
            return iconv.decode(bins, 'gbk');
          },
        })
        .then(function (zip) {
          // 获取压缩包中的文件列表
          const files = filterNonDirFiles(zip.files);
          resolve(files);
        })
        .catch(err => {
          reject(err);
        });
    });
  });
}

export { unzip };
