
import {
  getFundInfo,
  signAgreement,
} from '@/api/client-api/fund/fundCenter.js';
import RechargeDetailDialog from './components/RechargeDetailDialog';
import NoticeDialog from './components/NoticeDialog.vue';
import CapitalInfoItem from './components/CapitalInfoItem.vue';
import CapitalTipItem from './components/CapitalTip.vue';
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import { getUserDetailInfo } from '@/api/client-api/account/information';
export default {
  name: 'fundCenter',
  components: {
    RechargeDetailDialog,
    CapitalInfoItem,
    CapitalTipItem,
    NoticeDialog,
  },
  data() {
    return {
      activeName: this.$route.params.currency, // 当前用户点击的账户id

      // 当前账户的数据
      currentAccountInfo: {
        available_balance: 0,
        total_balance: 0,
        available_coupon_amount: 0,
        coupon_usage_amount: 0,
        coupon_deduction_amount: 0,
        activity_deposit: 0,
        freight_deposit: 0,
        reward_amount: 0,
      },

      /**
       * 币种账户信息
       */

      // 当前所有币种账户列表
      currentAccountList: {
        1: {
          id: '1',
          title: '人民币账户',
          mark: '¥',
          active: '1',
          imgUrl: require('@/assets/capital/ZG.svg'),
          prop: 'available_balance_CNY',
        },
        2: {
          id: '2',
          title: '美元账户',
          mark: '$',
          active: '2',
          imgUrl: require('@/assets/capital/MG.svg'),
          prop: 'available_balance_USD',
        },
      },
      // 当前账户详细信息列表，页面“币种账户”右侧
      accountDetailList: [
        {
          id: '1',
          title: '可用余额',
          balance: 0,
          prop: 'available_balance',
          showPopover: true,
          popoverContent: '可用于下单抵扣的金额',
        },
        {
          id: '2',
          title: '总金额',
          balance: 0,
          prop: 'total_balance',
          showPopover: true,
          popoverContent: '总金额=可用余额+活动保证金+运费保证金',
          footerId: '1',
        },
        {
          id: '3',
          title: '冻结金额',
          balance: 0,
          prop: 'freeze_amount',
          showPopover: true,
          popoverContent: '暂时不可用的资金',
          footerId: '2',
        },
        // {
        //   id: '3',
        //   title: '可用优惠券',
        //   balance: 0,
        //   value: 'available_coupon_amount',
        //   showPopover: false,
        //   popoverContent: '',
        // },
        // {
        //   id: "4",
        //   title: "优惠劵使用余额",
        //   balance: 0,
        //   value: "coupon_usage_amount",
        //   showPopover: false,
        //   popoverContent: "",
        // },
        // {
        //   id: "5",
        //   title: "优惠劵抵扣余额",
        //   balance: 0,
        //   value: "coupon_deduction_amount",
        //   showPopover: false,
        //   popoverContent: "",
        // },
      ],
      currRechargeRecordCount: 0, // 当前处理中的充值记录条数

      /**
       * 保证金
       */

      // 保证金配置
      securityMoney: [
        {
          type: 'activity',
          prop: 'activity_deposit',
          children: [
            {
              label: '活动保证金',
              prop: 'recharge', // 对应 currentAccountInfo 里的字段名
            },
            {
              label: '应收活动保证金',
              prop: 'need',
            },
            {
              label: '缺少活动保证金',
              prop: 'lack',
            },
          ],
        },
        {
          type: 'freight',
          prop: 'freight_deposit',
          children: [
            {
              label: '运费保证金',
              prop: 'recharge',
            },
            {
              label: '应收运费保证金',
              prop: 'need',
            },
            {
              label: '缺少运费保证金',
              prop: 'lack',
            },
          ],
        },
      ],

      /**
       * 弹窗
       */
      showDetailDialog: false, // 充值记录详情弹窗是否显示
      showNoticeDialog: false, // 用户充值告知书弹窗
      arrearageList: [], // 欠费数据
      costPayInfo: {}, // 当前费用支付 信息
      currArrearageId: '', // 当前欠费id
      showCostPayDialog: false, // 费用支付弹窗
      isArrearage: false,
      arrearsInfoType: 'success',
      arrearsInfoContent: '恭喜，暂无欠款信息，请继续保持！',
      arrearsNum: 5,
      realNameShow: false, // 实名认证弹框
      activeTab: '-1',
      isSignRecharge: '', // 是否签署了账户余额充值、使用与提现协议
    };
  },
  computed: {
    // 当前点击的账户对应的货币标志
    currentCurrencyMark() {
      if (this.currentAccountList[this.activeName]) {
        return this.currentAccountList[this.activeName].mark;
      }
    },
  },
  watch: {
    isArrearage(val) {
      if (val) {
        this.arrearsInfoType = 'warning';
        this.arrearsInfoContent = `共${this.arrearsNum}条欠费信息，请及时处理！`;
      } else {
        this.arrearsInfoType = 'success';
        this.arrearsInfoContent = '恭喜，暂无欠款信息，请继续保持！';
      }
    },
  },
  filters: {
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  methods: {
    // 是否展示奖励金
    showRewardAmount(amount) {
      return Number(amount) > 0;
    },
    // 点击欠费信息里的 支付 打开费用支付弹窗
    handleClickPay(item) {
      this.costPayInfo = item;
      this.currArrearageId = item?.id;
      this.showCostPayDialog = true;

      // console.log('this.costPayInfo.arrears_detail', this.costPayInfo);
      // this.costPayInfo.arrears_detail = item?.arrears_detail?.join(',');
    },
    // 跳转欠费支付页面
    goToArrearagePay() {
      this.$router.push({
        path: `/disburse/arrearage/${this.costPayInfo?.currency}/${this.currArrearageId}`,
      });
    },
    // 关闭费用支付弹窗
    closeCostPayDialog() {
      this.showCostPayDialog = false;
    },
    /**获取欠费信息列表 */

    /**
     * 工具函数，处理数据
     */

    // 保留两位小数
    toFixedTwo(val) {
      if (!val) return Number(0).toFixed(2);
      return Number.parseFloat(val).toFixed(2);
    },

    /**
     * 页面触发函数
     */

    // 切换账户标签页
    handleChangeTab(e) {
      this.$nextTick(() => {
        this.activeName = e;
        this.getCapitalInfo();
        this.getUserInfo();
        this.$router.push('/fund/fundcenter/' + e);
      });
    },
    // 点击充值按钮
    handleClickBalanceRecharge(flag) {
      // 判断是否签署了协议，签署了直接跳转充值页面，未签署则打开告知书弹窗 isSignRecharge 1：签署，-1：未签署
      // 美元充值不需要弹协议
      if (this.isSignRecharge == '1' || this.activeName == '2') {
        this.goRecharge(flag);
      } else {
        this.showNoticeDialog = true;
      }
    },
    // 跳转充值页面
    goRecharge(flag) {
      this.$router.push(`/recharge/${flag}/${this.activeName}`);
    },
    // 跳充值页面
    async handleClickWithDrawal() {
      if (this.activeTab == 1) {
        this.$message.warning(
          '企业账户暂不能提现，给您带来的不便，请谅解，后续会推出企业账户提现功能，谢谢。'
        );
        return;
      }
      // 校验用户是否实名认证
      try {
        let res = await getBasicInformation();
        if (res.errcode == 0) {
          if (res.data.status == 2) {
            // 实名认证通过
            this.$router.push({
              path: '/withdrawal',
            });
          } else {
            // 未实名认证
            this.realNameShow = true;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 立即实名认证
    toReal() {
      this.$router.push('/account');
    },
    // 关闭实名认证弹框
    handRealName() {
      this.realNameShow = false;
    },
    // 点击查看详情
    handleClickDetail() {
      this.showDetailDialog = true;
    },
    // 点击查看原因 #todo 暂时无此功能
    handleClickReason() {},
    // 点击充值按钮
    handleCloseRechargeDialog() {
      this.showDetailDialog = false;
    },

    /**
     * 请求接口
     */

    // 获取人民币资金账户
    async getCapitalInfo() {
      try {
        const options = {
          currency: this.activeName,
        };
        // 当前页面点击的币种资金账户（比如人民币账户）
        let res = await getFundInfo(options);
        if (res?.errcode == 0) {
          // 修改页面信息
          this.currRechargeRecordCount = res.data?.num;
          this.currentAccountInfo = res.data;
          this.arrearageList = res.data?.arrears;

          // 根据有无欠费数据判断用户是否欠费
          this.isArrearage = this.arrearageList?.length > 0;
          this.arrearsNum = res.data?.arrears_num;
        }
        // else {
        //   throw new Error(
        //     `Something wrong. Server returns error code of ${res.errcode}`
        //   );
        // }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取认证信息
    async getUserInfo() {
      try {
        const res = await getUserDetailInfo();
        if (res?.errcode == 0) {
          // 1是企业信息，-1不是企业信息
          this.activeTab = res.data?.is_enterprise;
          //  是否签署账户余额充值、使用与提现协议【1：是 -1：否】
          this.isSignRecharge = res.data?.is_sign_agreement;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 签署账户余额充值、使用与提现协议
    async signAgreementPost(flag) {
      const options = {
        id: '9',
      };
      try {
        const res = await signAgreement(options);
        if (res?.errcode == 0) {
          // 跳转充值页面
          this.goRecharge(flag);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.activeName = this.$route.params.currency;
    this.getCapitalInfo();
    this.getUserInfo();
  },
};
