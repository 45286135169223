
export default {
  name: 'SpecificationItem',
  props: {
    // imgUrl: {
    //   type: String,
    //   default: require('@/assets/shopindex/car_1.png'),
    // },
    label: {
      type: String,
      default: '默认规格',
    },
    isHighlight: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
