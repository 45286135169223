
import {
  getComplaintCategoryList,
  submitComplaint,
  getDataIdInfo,
} from '@/api/client-api/complaints/complaints.js';
import GoodsInfo from './GoodsInfo';
import { COMPLAINT_SCENARIO } from '@/pages/back-stage/complaints-module/complaints-report/js/complaint_type.js'; //发起场景
export default {
  components: {
    GoodsInfo,
  },
  data() {
    return {
      COMPLAINT_SCENARIO,
      // 弹框的值
      ruleForm: {
        complaintsTitle: '', //投诉/举报标题
        complaintsType: '', //投诉/举报分类
        data_id: this.dataName, //这是一个统一变量（根据选中的分类才出现的输入框）
        newData_id: '', //这是上一个变量的中转
        problemText: '', //问题描述
        file: '', //上传的文件
      },
      isSelect: false,
      selectList: [], //搜索得到的列表
      rules: {
        complaintsTitle: [
          { required: true, message: '投诉/举报问题不能为空', trigger: 'blur' },
        ],
        complaintsType: [
          { required: true, message: '请选择分类', trigger: 'change' },
        ],
        problemText: [
          { required: true, message: '问题描述不能为空', trigger: 'blur' },
        ],
      },
      omplaintsOptions: [], //分类
      label: '', //选中相关分类展示的label
      // 上传资料相关
      acceptFile: '.jpg,.png,.jpeg,.mp4,.pdf',
    };
  },
  props: {
    /**
     * 投诉/举报的场景 1-在投诉/举报列表处发起 2-在商品详情页发起举报 3-在订单详情页发起 4-在商品详情页--评价发起 5-订单列表页的'我已发布的订单'
     */
    scene: {
      type: [Number, String],
      default: 1,
    },
    // 是否能修改
    isModification: {
      type: Boolean,
      default: false,
    },
    dataName: {
      type: String,
      default: '',
    },
    dataId: {
      type: String,
      default: '',
    },
    // 标题是否自定义
    isTitle: {
      type: Boolean,
      default: true,
    },
    // 为商品详情特定的
    goodsInfo: {
      type: Object,
      default: () => {},
    },
    // 选中的数据
    currComplaintInfo: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.getSceneCategory();
    this.searchWord = this._debounce(this.getSearchValue, 500);
    if (process.client) {
      document.addEventListener('click', this.bodyCloseMenus);
    }
  },
  beforeDestroy() {
    if (process.client) {
      document.removeEventListener('click', this.bodyCloseMenus);
    }
  },
  computed: {
    isEditor() {
      if (this.currComplaintInfo) {
        this.ruleForm.complaintsTitle = this.currComplaintInfo?.title || '';
        this.ruleForm.complaintsType = this.currComplaintInfo?.cate_id || '';
        this.ruleForm.newData_id = this.currComplaintInfo?.data_id || '';
        this.ruleForm.problemText =
          this.currComplaintInfo?.complaint_details || '';
        this.ruleForm.file = this.currComplaintInfo?.attachment || '';
        this.label = this.currComplaintInfo?.show_text || '';
      }
      return !this.currComplaintInfo;
    },
  },
  methods: {
    // 搜索相关
    // 点击空白关闭价格弹框
    bodyCloseMenus(e) {
      const self = this;
      if (this.$refs.inputBox && !this.$refs.inputBox.contains(e.target)) {
        if (self.isSelect === true) {
          // 如果筛选框只有一个，输入的值名字完美匹配的情况
          if (this.selectList && this.selectList?.length == 1) {
            if (this.selectList[0].name === this.ruleForm.data_id) {
              this.ruleForm.newData_id = this.selectList[0].id;
            }
          }
          self.isSelect = false;
        }
      }
    },
    // 点击列表相应操作
    /**
     * this.ruleForm.newData_id  存储的是要传给后端的值
     * 因为前端展示跟传给后端的不一样
     */
    handleSelectList(item) {
      if (item) {
        this.ruleForm.data_id = item.name;
        this.ruleForm.newData_id = item.id;
      }
      this.isSelect = false;
    },
    // 获取相对应分类的数据（搜索的）
    async getSearchValue() {
      try {
        this.ruleForm.newData_id = '';
        if (this.ruleForm.data_id.trim() === '') {
          this.selectList = [];
          this.isSelect = false;
          return;
        }
        const options = {
          cate_id: this.ruleForm.complaintsType, //所属问题分类
          key: this.ruleForm.data_id, //所属问题分类
        };
        if (this.ruleForm.complaintsType != '') {
          const res = await getDataIdInfo(options);
          if (res) {
            if (res?.errcode == 0) {
              let list = res.data?.list || [];
              if (list && list.length == 0) {
                this.selectList = [];
                this.isSelect = false;
              }
              if (!list) {
                this.selectList = [];
                this.isSelect = false;
              }
              if (list && list.length > 0) {
                this.selectList = list;
                this.isSelect = true;
              }
            } else {
              this.selectList = [];
              this.isSelect = false;
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 所属分类相关
    // 获取相对应scene下的分类
    async getSceneCategory() {
      try {
        const options = {
          scene: this.scene,
        };
        let res = await getComplaintCategoryList(options);
        if (res) {
          if (res?.errcode == 0) {
            this.omplaintsOptions = res?.data?.list || [];
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 所属分类选择事件
    complaintsChange() {
      // 初始化
      this.ruleForm.newData_id = '';
      this.ruleForm.data_id = '';
      this.isSelect = false;
      this.selectList = [];
      if (this.omplaintsOptions && this.omplaintsOptions?.length > 0) {
        if (this.ruleForm.complaintsType != '') {
          this.ruleForm.data_id = this.dataName;
          this.ruleForm.newData_id = this.dataId;
          let selectInfo = this.omplaintsOptions.filter(
            (item) => item.id == this.ruleForm.complaintsType
          )[0];
          // 给出现的输入赋上神圣的标题-_-
          this.label = selectInfo.show_text;
        } else {
          this.label = '';
          this.ruleForm.data_id = '';
        }
      }
    },
    // 上传资料事件
    uploadFile(list) {
      this.ruleForm.file = list;
    },
    // 弹窗确认按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            !this.ruleForm.file ||
            this.ruleForm.file == {} ||
            this.ruleForm.file == ''
          ) {
            this.$message.warning('上传的文件不能为空');
            return;
          }
          /**
           * 这里是只要能搜索的都嘚校验，目前就物流单号不用
           */
          if (this.ruleForm.newData_id == '') {
            this.$message.warning('请输入正确的' + this.label);
            return;
          }
          // 校验成功走这
          this.lodgeComplaint();
        } else {
          // 校验失败走这
          return false;
        }
      });
    },
    // 发起投诉
    async lodgeComplaint() {
      try {
        const options = {
          cate_id: this.ruleForm.complaintsType, //所属问题分类
          data_id:
            this.ruleForm.newData_id == ''
              ? this.ruleForm.data_id
              : this.ruleForm.newData_id, //选择所属分类出现的输入框需要传的值
          title: this.ruleForm.complaintsTitle,
          complaint_details: this.ruleForm.problemText, //问题描述
          attachment: this.ruleForm.file, //上传的资料
          scene: this.scene,
        };
        if (this.currComplaintInfo) {
          options.pid = this.currComplaintInfo?.complaint_id;
        } else {
        }
        // 这一个是商品详情的举报
        if (this.scene == COMPLAINT_SCENARIO.GOODS_DETAIL) {
          options.title = '商品举报';
          options.data_id = this.goodsInfo.item_id;
        }
        let res = await submitComplaint(options);
        if (res) {
          if (res?.errcode == 0) {
            this.$emit('submitForm');
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 取消按钮事件
    cancelBtn() {
      this.$emit('cancelBtn');
    },
  },
};
