// 页面浏览记录保存
import { getBrowseRecord } from '@/api/client-api/api.js';
// 获取用户使用的浏览器
import { browser, getOS, getDevice } from '@/utils/browser-tool/getsbrowser.js';
// 工具函数
import tool from '@/utils/tool-function/tool.js';

// 随机生成用户ID
function generateUserId(length) {
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // 包含大小写字母和数字的所有字符集合
  var userId = '';

  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length); // 获取随机索引值
    userId += characters[randomIndex]; // 将对应位置上的字符添加到userId变量中
  }

  return userId;
}
/**
 *
 * @param {any} to 路由信息
 */
async function getBrowseRecordInfo(toUrl) {
  let options = {
    MUTE_WARNING: 1,
  };
  let userInfo = {};

  // 路径有的情况下
  if (toUrl?.path) {
    options.url = toUrl.path;
  }

  // 如果有缓存看看是否过期
  tool.checkTheCache('ximu-statistics', 'statistics_time');
  tool.checkTheCache('ximu-office-uid', 'statistics_time');

  // 没有ximu-office-uid的情况,并且存在来源传参
  // 不是从官网来的
  if (!localStorage.getItem('ximu-office-uid')) {
    const info = {};
    // 有来源的情况（小红书等）
    if (toUrl?.query?.source) {
      info.source = toUrl?.query?.source;
      info.uid = generateUserId(10);
      info.statistics_time = +new Date() + 2592000000; //缓存1个月
      localStorage.setItem('ximu-office-uid', JSON.stringify(info));
    } else {
      if (!localStorage.getItem('ximu-statistics')) {
        info.statistics_time = +new Date() + 2592000000; //缓存1个月
        info.uid = generateUserId(10);
        // 没有缓存
        localStorage.setItem('ximu-statistics', JSON.stringify(info));
      }
    }
  }
  if (localStorage.getItem('ximu-statistics')) {
    userInfo = JSON.parse(localStorage.getItem('ximu-statistics'));
    // 本站落地页来源，由于uid跟外部跳转进来的区分
    if (toUrl?.query?.landing) userInfo.source = toUrl?.query?.landing;
  }
  // localStorage 有 ximu-office-uid 的情况
  // 从官网来的更大
  if (localStorage.getItem('ximu-office-uid')) {
    userInfo = JSON.parse(localStorage.getItem('ximu-office-uid'));
    // 如果来源跟链接不对，替换成链接上的
    if (toUrl?.query?.source && userInfo?.source != toUrl?.query?.source) {
      userInfo.source = toUrl?.query?.source
      localStorage.setItem('ximu-office-uid', JSON.stringify(userInfo));
    }
  }

  if (userInfo?.source) {
    // 来源
    options.source = userInfo?.source;
  }

  if (userInfo?.uid) {
    // 来自官网的用户ID
    options.uid = userInfo?.uid;
  }

  // 获取操作系统
  options.operating_system = getOS || navigator.userAgent;
  // 获取浏览器
  options.browser = browser || navigator.userAgent;
  // 获取设备类型
  options.equipment_type = getDevice || navigator.userAgent;
  // 设备品牌

  try {
    getBrowseRecord(options);
  } catch (error) {
    console.error(error);
  }
}
// 可以被跨域消息传递的链接
const passUrl = new Set([
  process.env.NUXT_ENV.VUE_APP_OFFICE_HOST,
  'http://192.168.2.111:3001',
]);
/**
 *
 * 跨域消息传递
 * e.origin 接收链接
 */
export function getPostMessage(toUrl) {
  if (!process.client) {
    return;
  }
  // 客户端才能执行
  window.addEventListener('message', (e) => {
    // 不被允许的域名发起信息传递
    if (!passUrl.has(e.origin)) return;
    if (e?.data) {
      const data = JSON.stringify(e?.data || {});
      localStorage.setItem('ximu-office-uid', data);
    }
  });
  let times = {};
  // 这里设置定时器的原因是，要比发送信息速度慢
  times[toUrl.path] = setTimeout(() => {
    getBrowseRecordInfo(toUrl);

    times[toUrl.path] = null;
    clearTimeout(times[toUrl.path]);
    if (!times[toUrl.path]) {
      delete times[toUrl.path];
    }
  }, 2000);
}
