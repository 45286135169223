/**
 * 资金模块
 *
 */

// 后台管理架子
import shophome from '@/pages/back-stage/index.vue';
// 资金模块
import FundLayout from '@/pages/back-stage/fund-module/FundLayout.vue'; //分销商后台资金模块布局
import FundCenter from '@/pages/back-stage/fund-module/fund-centre/FundCenter.vue'; //资金中心
// 优惠券
import Coupon from '@/pages/back-stage/fund-module/coupon/Coupon.vue'; // 优惠券列表
import withdrawal from '@/pages/goods-pay/withdrawal/withdrawal.vue'; // 充值页面
// -------------------------------------------------------------------------------------------------------------------------------------
// 交易记录
import Transactions from '@/pages/back-stage/fund-module/transaction-records/Transactions.vue'; //交易记录
// ----------------------------------------------------------------------------------------------------------------------------------
// 发票
import InvoiceList from '@/pages/back-stage/fund-module/invoice/invoice.vue'; //发票列表
import invoicingInformation from '@/pages/back-stage/fund-module/invoice/invoicingInformation.vue'; //发票详情
import inviceDetail from '@//pages/back-stage/fund-module/invoice/inviceDetail.vue'; //发票详情
// ----------------------------------------------------------------------------------------------------------------------------------
// 提现记录
import WithdrawList from '@/pages/back-stage/fund-module/withdraw/index.vue'; // 提现记录

const fundRoutes = [
  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: true,
    },
    children: [
      // 资金模块的
      {
        path: 'fund',
        component: FundLayout,
        meta: {
          isAuth: true,
        },
        children: [
          // 资金中心
          {
            path: '',
            redirect: 'fundcenter/1',
          },
          {
            path: 'fundcenter',
            redirect: 'fundcenter/1',
          },
          {
            path: 'fundcenter/:currency?',
            name: 'FundCenter',
            component: FundCenter,
            meta: {
              isAuth: true,
            },
          },
          /**
           * 提现页面
           */
          {
            path: '/withdrawal',
            name: 'withdrawal',
            component: withdrawal,
            meta: {
              isAuth: true,
            },
          },
          // 优惠券
          {
            path: 'coupon',
            name: 'coupon',
            component: Coupon,
            meta: {
              isAuth: true,
            },
          },
          // 交易记录
          {
            path: 'transactionrecords',
            name: 'Transactions',
            component: Transactions,
            meta: {
              isAuth: true,
            },
          },
          // 发票列表
          {
            path: 'invoice/:type?/:tid?',
            name: 'Invoice',
            component: InvoiceList,
            meta: {
              isAuth: true,
            },
          },
          // 发票详情
          {
            path: 'invoicingInformation/:applyId',
            name: 'invoicingInformation',
            component: invoicingInformation,
            meta: {
              isAuth: true,
            },
          },
          // 发票详情
          {
            path: 'inviceDetail/:type/:applyId',
            name: 'inviceDetail',
            component: inviceDetail,
            meta: {
              isAuth: true,
            },
          },
          // 提现记录列表
          {
            path: 'withdraw',
            name: 'Withdraw',
            component: WithdrawList,
            meta: {
              isAuth: true,
            },
          },
        ],
      },
    ],
  },
];

export default fundRoutes;
