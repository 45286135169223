
import { assess } from '@/api/client-api/complaints/complaints.js';
export default {
  data() {
    // 自定义校验规则
    let checkProcessingSpeed = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请给处理速度打个分吧'));
      }
      if (value) {
        if (value == 0) {
          return callback(new Error('请给处理速度打个分吧'));
        } else {
          callback();
        }
      }
    };
    let checkOverallEvaluation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请给整体评价打个分吧'));
      }
      if (value) {
        if (value == 0) {
          return callback(new Error('请给整体评价打个分吧'));
        } else {
          callback();
        }
      }
    };
    return {
      evaluation: {
        processingSpeed: 0, //处理速度
        overallEvaluation: 0, //整体评价
        evaluationText: '',
      },
      rules: {
        processingSpeed: [
          { validator: checkProcessingSpeed, trigger: 'change' },
        ],
        overallEvaluation: [
          { validator: checkOverallEvaluation, trigger: 'change' },
        ],
      },
      // icon 颜色
      colors: ['#FF7802', '#FF7802', '#FF7802'],
      // 文字颜色
      textColor: '#FF7802',
      // 不同分数展示的文字
      texts: ['非常不满意', '不满意', '一般', '满意', '非常满意'],
    };
  },
  props: {
    complaintId: {
      type: String,
      default: '',
    },
  },
  mounted() {},
  methods: {
    // 弹窗确认按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 校验成功走这
          this.lodgeComplaint();
        } else {
          // 校验失败走这
          return false;
        }
      });
    },
    // 评价
    async lodgeComplaint() {
      try {
        const options = {
          complaint_id: this.complaintId,
          comment: this.evaluation.evaluationText, //留言
          deal_speed: this.evaluation.processingSpeed, //处理速度
          overall_evaluation: this.evaluation.overallEvaluation, //整体评价
        };
        let res = await assess(options);
        if (res) {
          if (res?.errcode == 0) {
            this.$emit('submit');
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 取消按钮事件
    cancelBtn() {
      this.$emit('cancel');
    },
  },
};
