/**
 * 记录用户被弹到登录界面的前一次浏览的页面路径
 * @author hukeyi
 * @description 【供应商、分销商，保存登录前的页面地址，登录成功后跳转至之前浏览的页面】https://www.tapd.cn/38265139/prong/stories/view/1138265139001000289
 */

const CACHE_KEY = 'xm_last_visited_route_path';

/**
 * 判断是否是空对象 {}
 * @author hukeyi
 * @param { Object } obj
 * @returns { Boolean }
 */
function isEmptyObject(obj) {
  return obj && Object.keys(obj)?.length === 0;
}

/**
 * 记录用户最新访问的路由
 * @param { Object } routeObj 完整的可以用 this.$router.push() 直接打开的路径
 */
function setLastVisitedRoute(routeObj) {
  if (!routeObj || isEmptyObject(routeObj) || routeObj.path === '/login') {
    return;
  }
  let route = {
    path: routeObj.path,
    query: routeObj.query,
  };
  sessionStorage.setItem(CACHE_KEY, JSON.stringify(route));
}

/**
 * 获取用户最后访问的路由路径
 * @returns 用户最后访问的路由路径
 */
function getLastVisitedRoute() {
  if (!sessionStorage.getItem(CACHE_KEY)) {
    return '/';
  }
  return JSON.parse(sessionStorage.getItem(CACHE_KEY));
}

/**
 * 清除最后访问的路由路径缓存
 */
function clearLastVisitedRoute() {
  sessionStorage.removeItem(CACHE_KEY);
}

export { setLastVisitedRoute, getLastVisitedRoute, clearLastVisitedRoute };
