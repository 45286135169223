
// import { addNewItemNeed } from '@/utils/api/Goods/GoodsDemand.js';
export default {
  name: 'NewDemand',
  data() {
    return {
      formobj: {
        pic: '', // 商品图片
        name: '',
        reacpe: '1', // 是否接收相似产品
        pubilc: '1', // 是否接收公开展示
        suppername: '',
        suppertel: '',
        brandName: '',
        sites: '', // 网址链接
        remark: '',
        uploadshopimg: [],
      }, //开发新品需求表单
      dialogVisible: false,
      uploadDialogVisible: false,
    };
  },
  methods: {
    // 增加新品商品事件
    async addItemNewNeed() {
      try {
        const options = {
          item_name: this.formobj.name, //商品名称
          accept_similar_item: this.formobj.reacpe, //0-不接受,1-接受
          supplier_name: this.formobj.suppername, //供应商名称
          supplier_phone: this.formobj.suppertel, //供应商电话
          brand_name: this.formobj.brandName, //品牌名称
          develop_item_link: this.formobj.sites, //网站链接
          description: this.formobj.remark, //详细描述
        };
        if (this.formobj.uploadshopimg.length > 0) {
          options.list = this.formobj.uploadshopimg.join(',');
        }
        if (this.formobj.name && this.formobj.name == '') {
          this.$message.warning('请输入商品名称');
          return;
        }
        if (this.formobj.reacpe && this.formobj.reacpe == '') {
          this.$message.warning('是否接受相似产品');
          return;
        }
        const test = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (
          this.formobj.suppertel != '' &&
          !test.test(this.formobj.suppertel)
        ) {
          this.$message.warning('手机格式不对');
          return;
        }
        const res = await this.$addNewItemNeed(options);
        if (res?.errcode == 0) {
          if (res?.msg) {
            this.$message.success(res?.msg);
          }

          this.dialogVisible = !this.dialogVisible;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 点击删除新品需求图片
    delpic(item) {
      for (let i = 0; i < this.formobj.uploadshopimg.length; i++) {
        if (this.formobj.uploadshopimg[i] == item) {
          this.formobj.uploadshopimg.splice(i, 1);
        }
      }
    },
    // 打开素材
    picgoods() {
      this.uploadDialogVisible = true;
    },
    // 关闭素材
    closepublic() {
      this.uploadDialogVisible = false;
    },
    // 上传
    closeDialogFunc(falseAndTrue, flag, file) {
      if (!falseAndTrue || file == '') {
        this.uploadDialogVisible = false;
        return;
      }
      this.formobj.uploadshopimg.push(file.key);
      this.uploadDialogVisible = false;
    },
    openNew() {
      this.dialogVisible = true;
    },
  },
};
