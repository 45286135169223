
import BasicGoodsBox from '@/pages/mall-home/home/components/BasicGoodsBox.vue';
export default {
  name: 'GuessPreferences',
  components: {
    BasicGoodsBox,
  },
  props: {
    // 商品列表
    goodsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 点击收藏商品
    clickCollect(item) {
      this.$emit('collect-goods', item);
    },
    // 点击跳转商品详情页
    goGoodsDetail(item) {
      //新页签打开
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img
      );
    },
    // 点击换一批
    changeBatch() {
      this.$emit('change-batch');
    },
  },
};
