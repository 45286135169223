
import { NO_PASSWORD_TIP } from '../js/label_config_public';
export default {
  name: 'NoPasswordDialog',
  props: {
    // 语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
  },
  data() {
    return {};
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return NO_PASSWORD_TIP[this.lang || 'zh-cn'];
    },
  },
  mounted() {},
  methods: {
    // 关闭弹窗
    closeDialog() {
      this.$emit('close-dialog');
    },
    // 跳转去设置支付密码
    goSetting() {
      this.closeDialog();
      this.$router.push('/account');
    },
  },
};
