
import FormTitle from './component/FormTitleItem.vue';
import UpInput from './component/UpInputItem.vue';
import WideButton from './component/WideButtonItem.vue';
import ErrorTip from './component/ErrorTipItem.vue';
import { resetPassword, resetPwCheck } from '@/api/client-api/login/api.js';
import { encryptor } from '@/utils/XmAES128Encryptor';
import { mapMutations } from 'vuex';

export default {
  components: { FormTitle, UpInput, WideButton, ErrorTip },
  data() {
    return {
      // userId: '', // 用户名或手机号
      phone: '', // 手机号
      password: '', // 第一次输入密码
      password2: '', // 二次输入密码
      code: '', // 验证码
      type: '', // 分销商 or 供应商

      showErrorTip: false,
      errorTipContent: '',

      currentStep: 1,
      labelMap: {
        // 0: {
        //   title: '找回密码',
        //   subTitle: '请输入用户信息',
        //   btnLabel: '下 一 步',
        // },
        1: {
          title: '找回密码',
          subTitle: '请输入用户信息',
          btnLabel: '下 一 步',
        },
        2: {
          title: '重置密码',
          subTitle: '密码由8-20位字母和数字组成，不能纯数字或纯字母。',
          btnLabel: '下 一 步',
        },
        3: {
          title: '重置密码',
          subTitle: '',
          btnLabel: '返 回 登 录',
        },
      },
    };
  },
  methods: {
    ...mapMutations('login', ['setForgetPasswordByKey']),
    async nextStep() {
      try {
        this.handleHideErrorTip();
        let res = true;
        if (this.currentStep == 1) {
          // 验证验证码（已发送验证码后）
          res = await this.handleValidateCode();
        } else if (this.currentStep == 2) {
          // 发送重置密码请求
          res = await this.handlePostNewPassword();
        } else if (this.currentStep >= 3) {
          // 返回登录页面
          // this.currentStep = 0;
          this.$store.commit('login/clearForgetPasswordForm'); // 清空 vuex 的缓存
          this.$router.push('/login');
          return;
        }
        if (res) this.currentStep++;
      } catch (err) {
        console.error(err);
      }
    },
    // 密码验证函数
    handleValidatePassword() {
      if (this.password != this.password2) {
        this.handleShowErrorTip('两次输入密码不相同，请再次检查');
        return false;
      } else {
        const reg = /^[0-9A-Za-z]{8,20}$/;
        const isValidated = reg.test(this.password);
        if (!isValidated) {
          this.handleShowErrorTip('新密码必须符合数字与字母组合共8-20位字符');
        }
        return isValidated;
      }
    },
    // 验证手机号验证码
    async handleValidateCode() {
      try {
        const postData = {
          phone: this.phone,
          code: this.code,
          type: this.$store.state.login.forgetPasswordForm.type,
          MUTE_WARNING: 1,
        };

        const res = await resetPwCheck(postData);
        if (res.errcode == 0) {
          this.$message.success('验证成功');
          return true;
        } else {
          this.handleShowErrorTip(res.msg);
          return false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 向后端发送新密码
    async handlePostNewPassword() {
      try {
        if (!this.handleValidatePassword()) {
          return;
        }
        const postData = {
          password: encryptor.encrypt(this.password),
          phone: this.phone,
          code: this.code,
          type:
            this.$store.state.login.forgetPasswordForm.type || 'distributor',
          MUTE_WARNING: 1,
        };
        const res = await resetPassword(postData);
        if (res.errcode == 0) {
          // this.currentStep++;
          return true;
        } else {
          this.handleShowErrorTip(res.msg);
          return false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 展示错误提示
    handleShowErrorTip(content) {
      // 如果无内容，则隐藏错误提示
      if (!content) {
        this.showErrorTip = false;
        this.errorTipContent = '';
        return;
      }
      this.showErrorTip = true;
      this.errorTipContent = content;
    },
    // 隐藏错误提示
    handleHideErrorTip() {
      this.showErrorTip = false;
    },
    handleUpdateStore(val, key) {
      this.setForgetPasswordByKey({ key: key, value: val });
    },
    // 返回登录
    backLogin() {
      this.$router.push('/login');
    },
  },
  mounted() {
    // 记住用户上次的输入
    this.phone = this.$store.state.login.forgetPasswordForm.phone;
    // this.password = this.$store.state.login.forgetPasswordForm.password;
    // this.password2 = this.$store.state.login.forgetPasswordForm.password2;
  },
};
