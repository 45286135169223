import request from '@/utils/request.js';

// 站内信通知内容获取
export function inboxMessageDisplay(data) {
  return request({
    url: '/InboxMessage/inboxMessageDisplay',
    apiModule: 'notify',
    method: 'get',
    params: data,
  });
}

// 标记为已读
export function readMark(data) {
  return request({
    url: '/InboxMessage/readMark',
    apiModule: 'notify',
    method: 'post',
    data: data,
  });
}

// 获取公告详情
export function inboxMessageContent(data) {
  return request({
    url: '/InboxMessage/inboxMessageContent',
    apiModule: 'notify',
    method: 'get',
    params: data,
  });
}
