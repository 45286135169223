
import GoodCardsRow from './components/GoodCardsRow';
import { ADS_LABELS } from './js/label_config_ads';
export default {
  name: 'DetailAds',
  components: { GoodCardsRow },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    currency: {
      type: String,
      default: '¥',
    },
    // 人气单品列表
    hotItemList: {
      type: Array,
      default: () => [],
    },
    // 同款推荐列表
    recommendItemList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // 页面多语言配置
    labelConfig() {
      return ADS_LABELS[this.lang || 'zh-cn'];
    },
  },
};
