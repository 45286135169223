/**
 * 投诉&举报模块
 */

// 后台管理架子
import shophome from '@/pages/back-stage/index.vue';
// 投诉&举报模块
import ComplaintsLayout from '@/pages/back-stage/complaints-module/ComplaintsLayout.vue'; //分销商后台投诉&举报模块布局
// 投诉管理
import ComplaintsReport from '@/pages/back-stage/complaints-module/complaints-report/index.vue'; //投诉&举报列表
import ReportDetail from '@/pages/back-stage/complaints-module/complaints-report/report-detail/ReportDetail.vue'; //投诉&举报详情
import ReviewReportDetail from '@/pages/back-stage/complaints-module/complaints-report/review-report-detail/ReviewReportDetail.vue'; //评价详情

// 违规管理
import IllegalList from '@/pages/back-stage/complaints-module/illegal-management/index.vue'; //违规列表
import IllegalDetail from '@/pages/back-stage/complaints-module/illegal-management/illegal-detail/IllegalDetail.vue'; //违规详情


const complaintsRoutes = [
  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: true,
    },
    children: [
      // 投诉&举报模块的
      {
        path: 'complaints',
        component: ComplaintsLayout,
        meta: {
          isAuth: true,
        },
        children: [
          // 投诉&举报列表
          {
            path: '',
            redirect: 'complaintslist',
          },
          {
            path: 'complaintslist',
            name: 'ComplaintsReport',
            component: ComplaintsReport,
            meta: {
              isAuth: true,
            },
          },
          // 普通详情
          {
            path: 'detail/:id?',
            name: 'ReportDetail',
            component: ReportDetail,
            meta: {
              isAuth: true,
            },
          },
          // 评价详情
          {
            path: 'report/detail/:id?',
            name: 'ReviewReportDetail',
            component: ReviewReportDetail,
            meta: {
              isAuth: true,
            },
          },
// ——————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
          // 违规相关
          // 违规列表
          {
            path: 'illegallist',
            name: 'IllegalList',
            component: IllegalList,
            meta: {
              isAuth: true,
            },
          },
          // 违规详情
          {
            path: 'illegaldetail/:id?',
            name: 'IllegalDetail',
            component: IllegalDetail,
            meta: {
              isAuth: true,
            },
          },
        ],
      },
    ],
  },
];

export default complaintsRoutes;
