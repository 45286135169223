
export default {
  name: 'PersonInfo',
  props: {
    personMsg: {
      type: Object,
      default: () => {},
    },
    // 页面语言
    lang: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userdetail: {}, // 用户信息
      // personMsg: {
      //   headPic:
      //     'https://img.ximu.cn/test_upload_ZH22304010001_1683855985.png',
      //   name: 'wenhqq',
      //   phone: '12345678965',
      //   email: '125632@ss.com',
      //   integralRule: '接接口接接口接接口',
      //   wallet: '￥00000000000.00', // 我的钱包
      //   inventory: '222', // 库存提醒
      //   needSuccess: '22222', // 需求成功
      //   orderNotice: '2222', // 订单通知
      // },
    };
  },
  mounted() {
    this.userdetail = JSON.parse(this.$store.state.login.loginInfo.userdetail);
  },
  methods: {
    // 跳转修改个人信息页面
    editUserMsg() {
      this.$router.push('/account');
    },
    // 跳转页面
    jumpPage(type) {
      // 我的钱包跳转资金中心
      if (type == 'wallet') {
        let link = this.lang == 'en' ? '2' : '1';
        this.openNewWindow('/fund/fundcenter/' + link);
      }
      // 库存提醒跳转收藏商品列表
      if (type == 'inventory') {
        this.openNewWindow('/goods/attention');
      }
      // 需求成功跳转商品需求列表
      // if (type == 'needSuccess') {
      //   this.openNewWindow('/goods/goodsDemand');
      // }
      // 爆单挑战
      if (type == 'activity') {
        this.openNewWindow('/activity?status=2');
      }
      // 订单通知跳转订单列表
      if (type == 'notice') {
        this.openNewWindow('/trade');
      }
    },
  },
};
