
import { complaintDetails } from '@/api/client-api/complaints/complaints.js';
import AppealForm from '../components/AppealForm';
import InitiateComplaints from '../components/InitiateComplaints';
import { isReport } from '../js/utils';
export default {
  name: 'ReportDetail',
  components: { AppealForm, InitiateComplaints },
  props: {
    type: {
      type: String,
      default: 'receive', // 收到 - receive；发起 - launch
    },
  },
  data() {
    return {
      complainId: '', // 投诉id
      detailInfo: {}, // 投诉详情

      showAppealDialog: false, // 是否展示申诉弹窗
      showSecondReportDialog: false, //二次投诉
    };
  },
  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  computed: {
    // 是否“我收到的投诉”
    isReceive() {
      return this.detailInfo?.identity == 'accused';
    },
    // 是否“我发起的投诉”
    isLaunch() {
      return this.detailInfo?.identity == 'complainant';
    },
    // 是否已经处理
    hasResult() {
      return this.detailInfo?.deal_status == 2;
    },
    // 是否二次投诉
    lastDetails() {
      return this.detailInfo?.last_details;
    },
    // 二次投诉的展示
    // 是否“我收到的投诉”
    lastIsReceive() {
      return this.lastDetails?.identity == 'accused';
    },
    // 是否“我发起的投诉”
    lastIsLaunch() {
      return this.lastDetails?.identity == 'complainant';
    },
    // 是否已经处理
    lastHasResult() {
      return this.lastDetails?.deal_status == 2;
    },
    // 上一次投诉是否已申诉
    hasAppealedLastTime() {
      return this.lastDetailInfo?.appeal_status == 2;
    },
    // 当前投诉（一次）是否已二次投诉
    hasSecondReport() {
      return this.detailInfo?.is_complaint == 1;
    },
    // 是否显示二次投诉按钮
    showSecondReportBtn() {
      // 显示的条件：
      // 1）我发起的投诉；2）还没有进行二次投诉；3）当前不是二次投诉详情
      // 4）当前投诉已有处理结果；5）当前处理结果为不违规；6）当前投诉是投诉不是举报
      return (
        this.isLaunch &&
        !this.hasSecondReport &&
        !this.lastDetails &&
        this.hasResult &&
        this.detailInfo?.is_violate == 2 &&
        !isReport(this.detailInfo?.cate_id)
      );
    },
  },
  methods: {
    // 点击查看
    handleClickOrderId(item) {
      // console.log(item, 'detail');
      if (item) {
        if (item.cate_text == '订单问题') {
          this.$router.push({
            path: '/trade/orderdetails',
            query: { item_id: item.data_id },
          });
        }
      }
    },
    // 点击返回按钮
    goBack() {
      this.$router.replace('/complaints/complaintslist');
    },
    // 获取投诉详情
    getDetailInfo() {
      const options = {
        complaint_id: this.complainId,
      };
      complaintDetails(options)
        .then((res) => {
          if (res) {
            if (res?.errcode == 0) {
              this.detailInfo = JSON.parse(JSON.stringify(res?.data));
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击“申诉”按钮
    handleClickAppeal() {
      this.showAppealDialog = true;
    },
    // 关闭申诉弹窗
    handleCloseAppealDialog() {
      this.showAppealDialog = false;
    },
    // 申诉弹窗确认按钮
    handleSubmitAppealDialog() {
      this.handleCloseAppealDialog();
      this.getDetailInfo();
    },
    // 点击“二次投诉”
    handleClickSecondReport() {
      this.showSecondReportDialog = true;
    },
    // 二次投诉弹窗关闭
    handleCloseSecondReportDialog() {
      this.showSecondReportDialog = false;
    },
    // 二次投诉弹窗确认按钮
    handleSubmitSecondReportDialog() {
      this.handleCloseSecondReportDialog();
      this.getDetailInfo();
    },
  },
  mounted() {
    if (this.$route.params?.id) {
      this.complainId = this.$route.params?.id?.toString();
      this.getDetailInfo(); // 获取投诉详情
    } else {
      this.$router.push('/error');
    }
  },
};
