
import { REMOVED_CONFIG } from './js/label_config_form_main';
export default {
  name: 'DetailRemoved',
  components: {},
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 当前商品失效状态 0 未失效  1 供应商已退店  2 仅下架
    goodsStatus: {
      type: [String, Number],
      default: '0',
    },
  },
  computed: {
    // 页面多语言配置
    labelConfig() {
      return REMOVED_CONFIG[this.lang || 'zh-cn'];
    },
    // 商品下架/店铺不存在的展示文案
    suggestList() {
      if (this.goodsStatus == '2') {
        return this.labelConfig['can-do'];
      }
      if (this.goodsStatus == '1') {
        return this.labelConfig['can-do'].slice(1);
      }
    },
  },
};
