
export default {
  name: 'SuccessDialog',
  data() {
    return {
      timeCount: 3,
    };
  },
  watch: {
    // 3秒后自动关闭
    timeCount: function (val) {
      if (val <= 0) {
        this.handleClickClose();
      }
    },
  },
  methods: {
    // 倒计时
    countdown() {
      if (this.timeCount < 0) return;

      const timer = setInterval(() => {
        if (this.timeCount >= 0) {
          --this.timeCount;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    handleClickClose() {
      this.$emit('close');
    },
  },
  mounted() {
    this.countdown(); // 开启倒计时
  },
};
