
import { sendSmsCode } from "@/api/client-api/api.js";
import { initiatingWithdrawal } from "@/api/client-api/fund/fundCenter.js";
export default {
  props: {
    netAmount: {
      type: String,
      default: "0.00",
    },
    commission: {
      type: String,
      default: "0.00",
    },
    withdrawObj: {
      type: Object,
      default: () => {},
    },
    realName: {
      type: String,
      default: "",
    },
    phoneShow: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      withdraw: {
        code: "",
      },
      timer: null, // 定时器
      codeTime: 60,
    };
  },
  methods: {
    // 获取验证码
    getCode() {
      let option = {
        phone: this.phone,
        type: "distributorTransfer",
      };

      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }

      sendSmsCode(option)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.timer = setInterval(() => {
              this.codeTime--;
              if (this.codeTime == 0) {
                clearInterval(this.timer);
                this.timer == null;
                this.codeTime = 60;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 提现按钮
    withdrawBtn() {
      let option = {
        amount: this.withdrawObj.withdrawalAmount, // 提现金额
        collection_account_type: "3", // 收款账户类型
        collection_account: this.withdrawObj.AlipayAccount, // 收款账号
        collection_account_name: this.realName, // 收款账号姓名
        sms_code: this.withdraw.code,
        currency: "1",
      };
      initiatingWithdrawal(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.$emit("active-three");
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    // 返回上一步
    backpro() {
      this.$emit("backpro");
    },
  },
};
