
export default {
  name: 'CopyItem',
  props: {
    // 可复制的字符串
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    // 展示的字符串，空值按规范处理为“--”
    copyString() {
      if (!this.value) return '--';
      return this.value;
    },
  },
  methods: {
    // 点击复制icon，将字符串复制到剪切板
    handleClickCopy() {
      this.copyText(this.value);
    },
  },
};
