
export default {
  data() {
    return {
      activeName: this.value,
      listTabConfig: this.tabConfig,
    };
  },
  props: {
    // 选中值
    value: {
      type: [Number, String],
      default: 1,
    },
    // 标签配置
    tabConfig: {
      type: Array,
      default: [],
    },
  },
  watch: {
    value(n) {
      if (n) {
        this.activeName = n;
      }
    },
  },
  methods: {
    // 切换标签
    choseTab(id) {
      this.$emit('chose-tab', id);
    },
  },
};
