/**
 * 中英文标签配置
 * DetailAds.vue
 */
// hello world
export const ADS_LABELS = {
  'zh-cn': {
    'popular': '人气单品',
    'same-style': '同款推荐',
  },
  en: {
    'popular': 'Popular Item',
    'same-style': 'Same Style Recommendation',
  },
};
