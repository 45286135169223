/**
 * 用于商品操作费计算的工具函数
 * @author hukeyi
 */

import Decimal from 'decimal.js';

/**
 * 计算操作费，按数量计费
 * @param rule 操作费计算规则
 * @param currNum 当前商品数量
 * @return 计算后的操作费
 */
function getOpFeeByNum(rule, currNum) {
  let totalFee = new Decimal(0);
  if (currNum == 0) {
    return totalFee;
  }
  currNum = new Decimal(currNum);
  if (rule && !Array.isArray(rule)) {
    let price = new Decimal(rule?.price || 0),
      num = new Decimal(rule?.num || 0),
      perPrice = new Decimal(rule?.perPrice || 0);

    totalFee = price; // 首件费用件数内费用
    currNum = currNum.minus(num);

    // 续件费用
    if (currNum.greaterThan(0)) {
      totalFee = totalFee.add(currNum.times(perPrice));
    }
  }
  return totalFee;
}
/**
 * 计算操作费，按重量计费
 * @param rule 操作费计算规则
 * @param currNum 当前商品数量
 * @return 计算后的操作费
 */
function getOpFeeByWeight(rule, currNum) {
  let totalFee = new Decimal(0);
  if (currNum == 0) {
    return totalFee;
  }
  currNum = new Decimal(currNum);
  if (rule && !Array.isArray(rule)) {
    let firstPrice = new Decimal(rule?.firstPrice || 0),
      continuePrice = new Decimal(rule?.continuePrice || 0);
    totalFee = firstPrice;
    currNum = currNum.minus(1);
    if (currNum.greaterThan(0)) {
      totalFee = totalFee.add(continuePrice.times(currNum));
    }
  }
  return totalFee;
}
/**
 * 获取操作费
 * @param type 操作费计费类型 1：按数量计费；2：按重量计费
 * @param rule 操作费计算规则
 * @param num 当前商品的数量
 * @param commission 操作费佣金比例
 */
export function getOperationFee(type, rule, num, commission) {
  let fee = new Decimal(0);
  let isRuleValid = rule && !Array.isArray(rule);
  if (type && isRuleValid) {
    if (type == '1') {
      // 按数量计费
      fee = getOpFeeByNum(rule, num);
    } else if (type == '2') {
      // 按重量计费
      fee = getOpFeeByWeight(rule, num);
    }
  }
  let com = new Decimal(0);
  if (commission) {
    // 操作费佣金
    com = new Decimal(commission).times(0.01);
  }
  fee = fee.times(com.add(1));
  return !fee.equals(0) ? fee.toFixed(2, 1) : 0;
}
