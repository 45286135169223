// 变量
export default (app) => {
  let lang = 'zh-cn';
  if (app.$cookies.get('lang') != 'zh-cn') {
    lang = 'zh-cn';
    // lang = app.$cookies.get('lang');
    app.store.dispatch('common/setCurrLang', lang);
  }
  // 如果cookies没有  默认中文
  if (!app.$cookies.get('lang')) {
    app.$cookies.set('lang', 'zh-cn', {
      path: '/',
    });
    app.store.dispatch('common/setCurrLang', lang);
  }
};
