
export default {
  name: 'GoodFormLine',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 标签文本内容
    label: {
      type: String,
      default: '默认标签',
    },
    lineHeight: {
      // 为了标签与右边第一行垂直对齐
      type: String,
      default: 'auto',
    },
    // 标签是否垂直居中
    isCenter: {
      type: Boolean,
      default: true,
    },
    // 小蓝色问号的提示文本
    popoverContent: {
      type: String,
      default: '',
    },
    // 输入框宽度
    inputWidth: {
      type: String,
      default: '100%',
    },
    // ---------------------------
  },
  computed: {
    inputStyle() {
      return {
        '--input-width': this.inputWidth,
      };
    },
  },
};
