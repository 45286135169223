/**
 * 零散的工具函数
 * @author hukeyi
 */

import request from '@/utils/request.js';

/**
 * 评价数量展示：
 * 0~100展示具体数值；
 * 100~9999以100为单位展示，比如2986展示为2900+；
 * 1万整展示为1万；
 * 1万~10万以万为单位精确到千，比如29860展示为2.9万+；
 * 10万以上以万为单位精确到万，比如298600展示为29万+
 * @param {Number} count 评论数量
 */
function formatEvaluationCountLabel(count) {
  if (count === '') return '';

  count = Number(count || 0);
  let num = 0,
    unit = '',
    showPlus = false;
  if (count < 0) {
    num = 0;
  } else if (count <= 100) {
    num = count;
  } else if (count < 10000) {
    num = Math.floor(count / 100) * 100;
    showPlus = count > num;
  } else if (count < 100000) {
    num = Math.floor(count / 1000) / 10;
    unit = '万';
    showPlus = count > num * 10000;
  } else {
    num = Math.floor(count / 10000);
    unit = '万';
    showPlus = count > num * 10000;
  }
  return `${num}${unit}${showPlus ? '+' : ''}`;
}

/**
 * 上传文件
 * @param {*} blob
 * @returns { String } 文件路径
 */
function uploadFile(blob, fileName) {
  if (!blob) return '';
  let blobFile = new File([blob], fileName, {
    type: 'application/vnd.ms-excel',
  });
  return request({
    url: '/Material/addMaterial',
    method: 'post',
    dataType: 'form-data',
    data: {
      file: blobFile,
    },
  });
}

/**
 * 是否是空对象或空数组或空值
 * @author hukeyi
 * @date 2023-09-08
 * @param { * } obj
 * @returns { Boolean }
 */
function isEmptyObject(obj) {
  if (obj == null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  for (let i in obj) {
    if (Object.hasOwnProperty.call(obj, i)) {
      return false;
    }
  }
  return true;
}

/**
 * 过滤掉对象数组中，对象字段key为空的元素
 *
 * @param {*} arr 目标对象数组
 * @param {*} key 检查是否为空的字段名
 * @returns 过滤后的有字段 key 有值的对象数组
 */
function filterEmptyData(arr, key) {
  if (!Array.isArray(arr)) {
    return arr;
  }
  let emptySet = new Set(['', '--', null, undefined, '0']);
  return arr.filter(
    (obj) => !emptySet.has(obj[key]) && !isEmptyObject(obj[key])
  );
}

export { formatEvaluationCountLabel, uploadFile, filterEmptyData };
