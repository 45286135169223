/**
 * 中英文标签配置
 * DetailFormMain.vue
 */
export const FORM_MAIN_LABELS = {
  'zh-cn': {
    reviews: '累计评价',
    'mouthly-sales': '月销量',
    'distributor-exclusive-info': '查看分销商专属信息',
    'copy-order-info': '复制下单信息',
    price: '价格',
    'low-price': '补贴后价格',
    MSRP: '建议零售价',
    'MSRP-tip': '该商品有最低零售价限制，请在购买前查看最低零售价详细信息',
    coupons: '优惠券',
    'change-notice': '变更通知',
    warehouse: '仓库信息',
    service: '服务',
    'commodity-info': '商品信息',
    'log-info': '物流信息',
    'view-more': '查看更多',
    'pull-off': '收起更多',
    'pls-select': '请选择',
    'send-to': '发往',
    number: '数量',
    'tax-rate': '税率',
    'order-type': '下单类型',
    'total-price': '总价',
    inventory: '库存',
    'buy-now': '立即购买',
    'tax-rate-tip': '该税率为供应商商品费税率，平台服务费税率以实际为准',
    'wholesale-tag': (discount) => `已达批发标准，享受${discount}优惠`,
    'sample-policy-content': (rate) =>
      `样品单：往返运费由分销商承担，商品费用供应商承担${rate}%`,
    'sample-store': (sampleCount, orderCount, unit) =>
      `剩余<span class="red-text">${sampleCount}</span>${unit}可作为样品购买，可下<span class="red-text">${orderCount}</span>单作为样品单。`,
  },
  en: {
    reviews: 'Reviews',
    'mouthly-sales': 'Monthly sales',
    'distributor-exclusive-info': 'Distributor exclusive information',
    'copy-order-info': 'Copy order information',
    price: 'Price',
    'low-price': 'Low price',
    MSRP: 'MSRP',
    'MSRP-tip':
      'The goods have a minimum retail price limit. Please check the detailed information of the minimum retail price before purchasing',
    coupons: 'Coupons',
    'change-notice': 'Change notice',
    warehouse: 'Warehouse',
    service: 'Service',
    'commodity-info': 'Commodity information',
    'log-info': 'Logistics information',
    'view-more': 'View more',
    'pull-off': 'Put away',
    'pls-select': 'Please select',
    'send-to': 'Send to',
    number: 'Number',
    'tax-rate': 'Tax rate',
    'order-type': 'Order type',
    'total-price': 'Total price',
    inventory: 'Inventory',
    'buy-now': 'Buy now',
    'tax-rate-tip':
      "This tax rate is the supplier's goods tax rate, the platform service fee tax rate shall be based on the actual situation",
    'wholesale-tag': (discount) =>
      `Wholesale standards have been met, enjoy ${discount}`,
    'sample-policy-content': (rate) =>
      `Sample order，the round-trip cost shall be borne by the distributor，suppliers bear ${rate}% of the cost of the goods`,
    'sample-store': (sampleCount, orderCount, unit) =>
      `Available <span class="red-text">${sampleCount}</span> ${unit} can be purchased as samples, Can place <span class="red-text">${orderCount}</span> orders as sample orders.`,
  },
};

// 下单类型选择器的选项标签配置
export const ORDER_TYPE_OPTIONS = {
  'zh-cn': [
    { label: '普通单', value: '2' },
    { label: '样品单', value: '1' },
  ],
  en: [
    { label: 'Ordinary order', value: '2' },
    { label: 'Sample order', value: '1' },
  ],
};

// 仓库信息表格配置
export const WAREHOUSE_TABLE_CONFIG = {
  'zh-cn': [
    // 仓库表格格式
    {
      prop: 'war_name',
      label: '仓库信息',
      width: '148px',
      slotName: 'warehouseInfo',
    },
    {
      prop: 'delivery_day_max',
      label: '承诺发货天数',
      width: '104px',
      formatter: (row, col) => {
        if (col.prop === 'delivery_day_max') {
          let val = '';
          if (!row.delivery_day_max) {
            val = '--';
          } else if (row.delivery_day_max !== '--') {
            val = row.delivery_day_max + '天';
          }
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'operation_fee_price',
      label: '操作费',
      width: '80px',
      formatter: (row, col) => {
        if (col.prop === 'operation_fee_price') {
          let val = '¥' + row.operation_fee_price;
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'store',
      label: '库存',
      width: '70px',
    },
    {
      prop: 'latest_arrival_time',
      label: '最近到货',
      width: '94px',
    },
    {
      prop: 'latest_arrival_num',
      label: '数量',
      width: '70px',
    },
    {
      prop: 'ware_logistics_total',
      label: '到仓物流费',
      // width: '164px',
      formatter: (row, col) => {
        if (col.prop === 'ware_logistics_total') {
          let val = '¥' + row.ware_logistics_total?.toFixed(2);
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    // {
    //   prop: 'next_arrival_time',
    //   label: '下批到货',
    //   width: '94px',
    // },
    // {
    //   prop: 'next_arrival_num',
    //   label: '数量',
    //   width: '70px',
    // },
  ],
  en: [
    // 仓库表格格式
    {
      prop: 'war_name',
      label: 'Warehouse',
      width: '148px',
      slotName: 'warehouseInfo',
    },
    {
      prop: 'delivery_day_max',
      label: 'Committed delivery days',
      width: '104px',
      formatter: (row, col) => {
        if (col.prop === 'delivery_day_max') {
          let val = '';
          if (!row.delivery_day_max) {
            val = '--';
          } else if (row.delivery_day_max !== '--') {
            val = row.delivery_day_max + 'days';
          }
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'operation_fee_price',
      label: 'Operating fee',
      width: '80px',
      formatter: (row, col) => {
        if (col.prop === 'operation_fee_price') {
          let val = '$' + row.operation_fee_price;
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'store',
      label: 'Inventory',
      width: '70px',
    },
    {
      prop: 'latest_arrival_time',
      label: 'Latest Arrival Date',
      width: '94px',
    },
    {
      prop: 'latest_arrival_num',
      label: 'Number',
      width: '70px',
    },
    {
      prop: 'ware_logistics_total',
      label: 'Warehouse Delivery Fee',
      // width: '164px',
      formatter: (row, col) => {
        if (col.prop === 'ware_logistics_total') {
          let val = '$' + row.ware_logistics_total;
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    // {
    //   prop: 'next_arrival_time',
    //   label: 'Next batch arrival date',
    //   width: '94px',
    // },
    // {
    //   prop: 'next_arrival_num',
    //   label: 'Number',
    //   width: '70px',
    // },
  ],
};

// 物流信息表格配置
export const LOG_TABLE_CONFIG = {
  'zh-cn': [
    // {
    //   prop: 'pro_name',
    //   label: '物流方案名称',
    //   width: '182px',
    // },
    {
      prop: 'full_name',
      label: '物流公司',
      width: '150px',
      copyId: '1',
      hasTitle: true,
      formatter: (row, col) => {
        if (col.prop === 'full_name') {
          return row.full_name ? row.full_name : '--';
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'logistics_partners',
      label: '物流合作方',
      width: '116px',
      formatter: (row, col) => {
        if (col.prop === 'logistics_partners') {
          return row.supplier_id == '0' ? '平台' : '供应商';
        } else {
          return row[col.prop];
        }
      },
    },
    // {
    //   prop: 'samedays',
    //   label: '中转时效',
    //   width: '76px',
    //   formatter: (row, col) => {
    //     if (col.prop === 'samedays') {
    //       let val = '';
    //       if (!row.samedays) {
    //         val = '--';
    //       } else if (row.samedays !== '--') {
    //         // 中转时效如果非空，则加上单位
    //         val = row.samedays + '天';
    //       }
    //       return val;
    //     } else {
    //       return row[col.prop];
    //     }
    //   },
    // },
    {
      prop: 'carriage_days',
      label: '运输时效',
      width: '116px',
      sortable: 'custom',
      sortMethod: (a, b) => {
        let strA = a.carriage_days.split('-')[0],
          strB = b.carriage_days.split('-')[0];
        let numA = Number(strA),
          numB = Number(strB);
        return numA - numB;
      },
      formatter: (row, col) => {
        if (col.prop === 'carriage_days') {
          let val = '';
          if (!row.carriage_days) {
            val = '--';
          } else if (row.carriage_days !== '--') {
            val = row.carriage_days + '天';
          }
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'price',
      label: '物流费',
      width: '116px',
      // sortable: 'custom',
      // sortMethod: (a, b) => {
      //   let numA = a.price,
      //     numB = b.price;
      //   return parseFloat(numA) - parseFloat(numB);
      // },
      formatter: (row, col) => {
        if (col.prop === 'price') {
          let val = '¥' + row.price;
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'insurance_charge',
      label: '保价费',
      width: '116px',
      formatter: (row, col) => {
        if (col.prop === 'insurance_charge') {
          let val = '¥' + row.insurance_charge;
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'arrivalTime',
      label: '预计到货',
      width: '116px',
    },
  ],
  en: [
    // {
    //   prop: 'pro_name',
    //   label: 'Logistics plan name',
    //   width: '182px',
    // },
    {
      prop: 'full_name',
      label: 'Logistics company',
      width: '150px',
      hasTitle: true,
      copyId: '1', // 对应的 FoldTable 的复制 id
      formatter: (row, col) => {
        if (col.prop === 'full_name') {
          return row.full_name ? row.full_name : '--';
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'logistics_partners',
      label: 'Logistics partners',
      width: '116px',
      formatter: (row, col) => {
        if (col.prop === 'logistics_partners') {
          return row.supplier_id == '0' ? 'Platform' : 'Supplier';
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'samedays',
      label: 'Transit time limit',
      width: '116px',
      formatter: (row, col) => {
        if (col.prop === 'samedays') {
          let val = '';
          if (!row.samedays) {
            val = '--';
          } else if (row.samedays !== '--') {
            // 中转时效如果非空，则加上单位
            val = row.samedays + 'days';
          }
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'carriage_days',
      label: 'Transportation time limit',
      width: '116px',
      sortable: 'custom',
      sortMethod: (a, b) => {
        let strA = a.carriage_days.split('-')[0],
          strB = b.carriage_days.split('-')[0];
        let numA = Number(strA),
          numB = Number(strB);
        return numA - numB;
      },
      formatter: (row, col) => {
        if (col.prop === 'carriage_days') {
          let val = '';
          if (!row.carriage_days) {
            val = '--';
          } else if (row.carriage_days !== '--') {
            val = row.carriage_days + 'days';
          }
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'price',
      label: 'Logistics expenses',
      width: '116px',
      // sortable: 'custom',
      // sortMethod: (a, b) => {
      //   let numA = a.price,
      //     numB = b.price;
      //   return parseFloat(numA) - parseFloat(numB);
      // },
      formatter: (row, col) => {
        if (col.prop === 'price') {
          let val = '$' + row.price;
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'insurance_charge',
      label: 'Insurance Fee',
      width: '116px',
      formatter: (row, col) => {
        if (col.prop === 'insurance_charge') {
          let val = '$' + row.insurance_charge;
          return val;
        } else {
          return row[col.prop];
        }
      },
    },
    {
      prop: 'arrivalTime',
      label: 'Expected delivery date',
      width: '116px',
    },
  ],
};

// 商品信息配置
export const INFO_CONFIG = {
  'zh-cn': {
    size: '物流尺寸',
    weight: '物流重量',
    features: '商品特性',
  },
  en: {
    size: 'Size',
    weight: 'Weight',
    features: 'Features',
  },
};

// 商品评价板块标签配置
export const EVALUATION_BLOCK_LABELS = {
  'zh-cn': {
    all: '全部评价',
    pic: '晒图',
    good: '好评',
    mid: '中评',
    bad: '差评',
    'no-reviews': '暂无评价',
    report: '举报',
    reply: '回复：',
  },
  en: {
    all: 'All Reviews',
    pic: 'Pictures',
    good: 'Good rating',
    mid: 'Mid rating',
    bad: 'Bad rating',
    'no-reviews': 'No reviews',
    report: 'report',
    reply: 'reply:',
  },
};

// 下架商品展示配置
export const REMOVED_CONFIG = {
  'zh-cn': {
    pic: require('@/assets/shopdetail/removed-zh-cn.png'),
    sorry: '很抱歉，您查看的商品已下架，无法查看',
    'you-can': '您可以：',
    'can-do': [
      '联系店铺客服询问商品详细信息。',
      '在顶部搜索框重新输入搜索词搜索。',
      '为您推荐一些可能喜欢的商品。',
    ],
  },
  en: {
    pic: require('@/assets/shopdetail/removed-en.png'),
    sorry:
      'Sorry, the goods you are viewing has been taken off the shelves and cannot be viewed',
    'you-can': 'You can:',
    'can-do': [
      'Contact store customer service to inquire about goods details.',
      'Reenter the search term in the top search box to search.',
      'Recommend some goods that you may like.',
    ],
  },
};
