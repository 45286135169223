//订单
import request from '@/utils/request.js';

//获取订单列表
export function getOrderPanelData(data) {
  return request({
    url: 'order/getOrderPanelData',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

//获取订单列表
export function getUserOrderList(data) {
  return request({
    url: 'Order/getUserOrderList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 订单详情
export function getOrdeDetailInfo(data) {
  return request({
    url: 'Order/getOrdeDetailInfo',
    method: 'get',
    params: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}

// 检测批量支付订单状态
export function checkOrderPay(data) {
  return request({
    url: '/order/checkOrderPay',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 纠纷列表
export function getDisputeList(data) {
  return request({
    url: 'Order/getDisputeList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 纠纷详情
export function getIntervention(data) {
  return request({
    url: 'Order/getIntervention',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取纠纷列表看板
export function getDisputePanel(data) {
  return request({
    url: '/Order/getDisputePanel',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 拒绝并新增方案接口
export function applyNewPlan(data) {
  return request({
    url: 'Order/applyNewPlan',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 同意方案接口
export function agreePlan(data) {
  return request({
    url: 'Order/agreePlan',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取退货款详情
export function getRefundDetail(data) {
  return request({
    url: 'Order/getRefundDetail',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 申请退款
export function applyRefund(data) {
  return request({
    url: 'Order/applyRefund',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 上传图片
export function updataImg(data) {
  return request({
    url: 'info/upload',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 修改备注
export function addOrderMemo(data) {
  return request({
    url: 'Order/addOrderMemo',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
//取消订单
export function closeOrder(data) {
  return request({
    url: 'Order/closeOrder',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 等待我评价的列表
export function getUserEvaluateList(data) {
  return request({
    url: 'Order/getUserEvaluateList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取评价页面信息
export function getEvaluateDetail(data) {
  return request({
    url: '/order/getEvaluateInfo',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取评论详情
export function getEvaluateReplyDetail(data) {
  return request({
    url: '/Order/getReplyDetail',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取评论面板数据
export function getEvaluatePanel(data) {
  return request({
    url: '/order/getEvaluatePanel',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 提交评价
export function putReply(data) {
  return request({
    url: 'Order/putReply',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 确认收货
export function confirmReceipt(data) {
  return request({
    url: '/order/confirmReceipt',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 获取物流信息
export function getOrderTrajectory(data) {
  return request({
    url: '/expressDelivery/getOrderTrajectory',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 延长收货时间
export function setExtendDeliveryTime(data) {
  return request({
    url: '/order/setExtendDeliveryTime',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 获取评论详情
export function getReplyDetail(data) {
  return request({
    url: 'Order/getReplyDetail',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 删除评论
export function delReply(data) {
  return request({
    url: 'order/delReply',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 申请平台介入
export function applyPlatfrom(data) {
  return request({
    url: 'Order/applyPlatfrom',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 取消退款
export function cancelRefund(data) {
  return request({
    url: 'Order/cancelRefund',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 提交退货凭证
export function submitRund(data) {
  return request({
    url: 'Order/submitRund',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 提交退货凭证
export function ExportDispute(data) {
  return request({
    url: 'ExportDispute/export',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 获取退货/款 支付明细
export function getPayDetail(data) {
  return request({
    url: '/Order/getPayDetail',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 修改订单地址
export function changeOrderAddress(data) {
  return request({
    url: '/order/changeOrderAddress',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 订单导出
export function exportOrderList(data) {
  return request({
    url: '/exportTask/orderListExport',
    method: 'post',
    data: data,
  });
}

// 获取导入记录列表数据
export function getImportOrderList(data) {
  return request({
    url: '/order/importRecordList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 获取查看下单进度数据
export function getOrderProgressList(data) {
  return request({
    url: '/order/importOrderRecordList',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 获取订单来源选项数据
export function getOrderSourceList(data) {
  return request({
    url: '/order/orderSelectList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 处理(同意/拒绝)供应商延长发货申请
export function examineExtendDeliveryTime(data) {
  return request({
    url: '/order/examineExtendDeliveryTime',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
