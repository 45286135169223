
import {
  putReply,
  getEvaluateDetail,
} from '@/api/client-api/trade/orderlist.js';
export default {
  name: 'OrderEvaluation',
  data() {
    return {
      tid: '', // 订单id
      evaluateOrderInfo: {}, // 评价订单信息
      updateFile: [], // 上传的图片或视频
      evaluateDeadline: '', // 评价剩余时间
      timer: null,
      fore: {
        evaluateContent: '', //评价内容
        goodScore: 5, // 商品得分
        logisticsScore: 5, // 物流得分
      }, //评价的所有信息
      colors: ['#ff7802', '#ff7802', '#ff7802'],
      isAnonymously: false, // 是否匿名评价 true匿名,false不匿名
    };
  },
  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  mounted() {
    this.tid = this.$route.query.id;
    this.getOrderInfo(); // 获取评价信息
  },
  methods: {
    // 上传图片、视频
    uploadImg(file) {
      this.updateFile = file;
    },

    // 点击订单编号 跳转订单详情
    handleClickToOrderDetail() {
      this.$router.push({
        path: '/trade/orderdetails',
        query: {
          item_id: this.tid,
        },
      });
    },
    // 点击跳转 商品详情
    handleClickToGoodsDetail() {
      let currency =
        this.evaluateOrderInfo?.currency == '￥' ||
        this.evaluateOrderInfo?.currency == '¥'
          ? '2'
          : '3';

      this.toCachedShopdetail(
        this.evaluateOrderInfo.item_id,
        this.evaluateOrderInfo.goods_image,
        this.evaluateOrderInfo.sku_lowest_price_img,
        '_blank',
        currency
      );
    },
    // 获取订单信息
    async getOrderInfo() {
      try {
        let option = {
          tid: this.tid,
          MUTE_WARNING: 1,
        };
        let res = await getEvaluateDetail(option);
        // 倒计时
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }

        if (res?.errcode == 0) {
          this.evaluateOrderInfo = res.data;

          const t1 = Date.parse(new Date());
          this.evaluateDeadline =
            res.data?.deadline * 1000 - t1 > 0
              ? res.data?.deadline * 1000 - t1
              : 0;

          this.timer = setInterval(() => {
            const t1 = Date.parse(new Date());
            this.evaluateDeadline =
              res.data?.deadline * 1000 - t1 > 0
                ? res.data?.deadline * 1000 - t1
                : 0;
          }, 1000);
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 发表评价
    async submitEva() {
      try {
        const options = {
          tid: this.tid, // 订单号
          item_id: this.evaluateOrderInfo?.item_id, // 商品ID
          score: this.fore?.goodScore, // 商品评分
          logistics_score: this.fore?.logisticsScore, // 物流得分
          is_anonymous: this.isAnonymously ? 1 : 2, // 是否匿名 1 匿名  2 不匿名
        };
        if (this.fore.evaluateContent != '') {
          options.reply_content = this.fore?.evaluateContent; // 评价内容
        }
        if (this.updateFile?.length > 0) {
          let videoData = this.updateFile?.filter((item) => {
            return item.includes('.mp4');
          });
          let imageData = this.updateFile?.filter((item) => {
            return !item.includes('.mp4');
          });
          options.img = imageData.join(',');
          options.video = videoData.join(',');

          // console.log('videoData', videoData);
          // console.log('imageData', imageData);
        }
        let res = await putReply(options);
        if (res.errcode == 0) {
          this.$router.push('/trade/noevaluate');
          if (res?.msg) {
            this.$message.success(res?.msg);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
