
import payHeader from '@/components/payHeader.vue';
import Cashier from '@/pages/goods-pay/components/Cashier.vue';
import rechargeMethod from './components/rechargeMethod.vue';
import USDRecharge from './components/USDRecharge.vue';
import payBottom from '../../../components/indexbottom.vue';
/**
 * 多语言配置
 */
import { BALANCE_RECHARGE } from './js/label_config_balance';
import { getFundInfo } from '@/api/client-api/fund/fundCenter.js';

export default {
  components: {
    payHeader,
    Cashier,
    rechargeMethod,
    USDRecharge,
    payBottom,
  },
  data() {
    return {
      paytype: '1', // 当前父组件控制显示币种
      topUpType: '', //充值类型(可用余额/活动保证金/运费保证金)
      amount: '', // 充值金额
      currentBalance: '', // 账号当前余额
      typeObj: {
        balance: '1',
        freight: '2',
        activity: '3',
      },
      currentId: '', // 支付的对应id
    };
  },
  computed: {
    // 页面语言
    lang() {
      if (this.paytype && this.paytype == '1') {
        return 'zh-cn';
      }
      if (this.paytype && this.paytype == '2') {
        return 'en';
      }
    },
    // 标签多语言配置
    langConfig() {
      return BALANCE_RECHARGE[this.lang || 'zh-cn'];
    },
  },
  mounted() {
    this.paytype = this.$route.params.currency;
    this.topUpType = this.$route.params.type;
    this.currentId = this.$route.params.id;
    this.getCapitalInfo();
  },
  methods: {
    inputChange(val) {
      this.amount = val.replace(/[^\d.]/g, ''); // 只能输入数字和.
      this.amount = this.amount.replace(/^\./g, ''); //第一个字符不能是.
      this.amount = this.amount.replace(/\.{2,}/g, '.'); // 不能连续输入.
      this.amount = this.amount.replace(/(\.\d+)\./g, '$1'); // .后面不能再输入.
      this.amount = this.amount.replace(/^0+(\d)/, '$1'); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.amount = this.amount.replace(/(\d{10})\d*/, '$1'); // 最多保留10位整数
      this.amount = this.amount.replace(/(\.\d{2})\d*/, '$1'); // 最多保留2位小数
    },
    // 获取账号资金数据
    getCapitalInfo() {
      let option = {
        currency: this.paytype,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      getFundInfo(option)
        .then((res) => {
          if (res?.errcode == 0) {
            if (this.topUpType == 'balance') {
              this.currentBalance = Number(res?.data?.total_balance) || 0;
            }
            // 保证金充值暂时没有，freight_deposit和activity_deposit是对象
            // else if (this.topUpType == 'freight') {
            //   this.currentBalance = Number(res?.data?.freight_deposit) || 0;
            // } else if (this.topUpType == 'activity') {
            //   this.currentBalance = Number(res?.data?.activity_deposit) || 0;
            // }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击 打开协议内容页面
    openProtocolDetail() {
      window.open('/protocol?id=9', '_blank');
    },
  },
};
