
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  ssr: false,
  data() {
    return {
      currentPath: '', // 移入‘你好请登录’是否高亮
      menuList: [
        {
          name: '您好，请登录',
          link: '/login',
          isHighlight: false,
          hideDivider: true,
        },
        {
          name: '免费注册',
          link: '/register',
          isHighlight: true,
        },
        {
          name: '用户协议',
          link: 'https://home.ximu.cn/PlatRule/79',
          isOutside: true,
          isHighlight: false,
        },
        // {
        //   name: '帮助中心',
        //   link: '',
        //   isOutside: true,
        //   isHighlight: false,
        // },
      ],
    };
  },
  // 监听路由变化
  watch: {
    $route: 'getPath',
  },
  methods: {
    //已经登录处理
    actionIfLoged() {
      if (!this.$cookies.get(CUR_TOKEN_NAME)) {
        return false;
      }
      // const account = localStorage.getItem('uid');
      // this.initNimChat(uid, '123456');
      // this.$store.commit('initNimChat', { account: account, token: '123456' });
      // // console.log('this.$store', this.$store);

      // this.$toast('...自动登录中...');
      // this.reloginFlag = true;
      this.gotoIndex();
      return true;
    },
    gotoIndex() {
      this.$router.push('/');
    },
    getPath() {
      this.currentPath = this.$route.path;
      // console.log(this.currentPath);
    },
  },
  mounted() {
    if (process.client) {
      this.getPath();
      this.$store.dispatch('login/clearRegisterForm'); // 清空注册缓存
    }
  },
};
