
import { blogrollDisplay } from '@/api/client-api/friendlink/friendlink.js';
export default {
  data() {
    return {
      linkList: [],
    };
  },
  mounted() {
    this.blogrollDisplay();
  },
  methods: {
    blogrollDisplay() {
      blogrollDisplay({ MUTE_WARNING: 1 })
        .then((res) => {
          if (res.errcode == 0) {
            this.linkList = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    gourl(url) {
      window.open(url, '_blank');
    },
  },
};
