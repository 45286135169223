
export default {
  name: 'PhraseManageDialog',
  directives: {
    focus: {
      inserted: function (el) {
        // 进入编辑模式自动聚焦 el-input 输入框
        el?.children[0]?.focus();
      },
    },
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    // 常用语数量限制
    phrasesLimit: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      editTarget: -1, // 当前处于编辑模式的行的索引
      editContent: '', // 当前编辑的行的内容
      phraseList: [], // 常用语列表
      displayedPhraseList: [], // 软删除字段（is_del）为 -1 的展示的常用语列表
    };
  },
  watch: {
    list: {
      handler: function (val) {
        this.phraseList = JSON.parse(JSON.stringify(val));
        this.updateDisplayData();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    /**
     * 工具函数
     */

    // 清空编辑模式的数据
    clearEditData() {
      this.editTarget = -1;
      this.editContent = '';
    },
    // 更新列表的排序字段
    updateSort(list) {
      if (!list) return [];
      let count = 0;
      list.forEach((x) => {
        if (x.is_del == -1) {
          x.sort = ++count;
        }
      });
      return list;
    },
    // 更新展示的列表数据
    updateDisplayData() {
      let list = JSON.parse(JSON.stringify(this.phraseList));
      list?.forEach((x, idx) => {
        x.original_idx = idx; // 增加一个 displayedList 到 phraseList 的索引映射
      });
      this.displayedPhraseList = list?.filter((x) => x.is_del == -1) || [];
    },
    /**
     * 页面触发
     */

    // 点击添加常用语按钮
    handleClickAdd() {
      if (this.phraseList?.length == 0) {
        this.phraseList = [];
      }
      // 当前已有新增的一条无内容的常用语，则聚焦到它的输入框
      if (
        this.editTarget >= 0 &&
        this.phraseList[this.editTarget]?.id == 0 &&
        this.editContent == ''
      ) {
        this.$refs.phraseEditElInput?.[0]?.focus();
        return;
      }
      // 如果有未保存的修改，直接默认保存
      if (this.editTarget >= 0) {
        this.handleClickSave(this.editTarget);
      }
      if (this.displayedPhraseList?.length >= this.phrasesLimit) {
        this.$message.warning(`最多添加${this.phrasesLimit}条常用语`);
        return;
      }
      this.phraseList.push({
        id: 0, // 新增常用语 id 统一为 0
        content: '',
        sort: this.displayedPhraseList.length + 1,
        is_del: -1,
      });
      this.editTarget = this.phraseList.length - 1;
      this.editContent = '';
      this.updateDisplayData();
    },
    // 点击某行常用语的编辑按钮
    handleClickEdit(content, index) {
      // 如果有未保存的修改，直接默认保存
      if (this.editTarget >= 0 && this.editTarget !== index) {
        this.handleClickSave(this.editTarget);
      }
      this.editTarget = index;
      this.editContent = content;
    },
    // 点击保存按钮
    handleClickSave(index) {
      if (index < 0) return;
      // 如果是新增的常用语点击保存且保存内容为空，则执行删除
      if (this.phraseList[index]?.id == 0 && !this.editContent) {
        this.handleClickDelete(index);
      } else {
        this.phraseList[index].content = this.editContent;
      }
      this.updateDisplayData();
      this.clearEditData();
    },
    // 点击删除按钮
    handleClickDelete(index) {
      if (index < 0) return;
      // 如果有未保存的修改，直接默认保存
      if (this.editTarget >= 0 && this.editTarget !== index) {
        this.handleClickSave(this.editTarget);
      } else {
        // this.phraseList?.splice(index, 1); // #memo 改为软删除
        this.phraseList[index].is_del = 1;
        this.updateDisplayData();
        if (this.editTarget === index) {
          this.clearEditData();
        }
      }
    },
    // 点击置顶按钮
    handleClickStickTop(index) {
      if (index < 0) return;
      // 如果有未保存的修改，直接默认保存
      if (this.editTarget >= 0) {
        this.handleClickSave(this.editTarget);
      }
      let ps = this.phraseList?.splice(index, 1);
      if (ps.length > 0) {
        this.phraseList?.unshift(ps[0]);
        this.updateSort(this.phraseList);
        this.updateDisplayData();
      }
    },
    // 点击确定按钮
    handleConfirm() {
      // 如果有未保存的修改，直接默认保存
      if (this.editTarget >= 0) {
        this.handleClickSave(this.editTarget);
      }
      // 先过滤掉内容空的，然后更新排序 sort 字段的索引值
      let confirmedList = this.updateSort(
        this.phraseList?.filter((x) => !!x.content)
      );
      this.$emit('confirm', confirmedList?.slice());
    },
    // 点击取消按钮
    handleCancel() {
      // 如果有未保存的修改，直接默认保存
      if (this.editTarget >= 0) {
        this.handleClickSave(this.editTarget);
      }
      // 先过滤掉内容空的，然后更新排序 sort 字段的索引值
      let confirmedList = this.updateSort(
        this.phraseList?.filter((x) => !!x.content)
      );
      this.$emit('cancel', confirmedList?.slice());
    },
  },
};
