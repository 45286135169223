
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';

export default {
  name: 'UploadPic',
  components: {},
  props: {
    // 最多有几张图
    maxNum: {
      type: [Number, String],
      default: 1,
    },
    // 是否需要查看大图
    isLarger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPreviewimg: false, //预览弹窗标识
      imgUrl: '', //预览图片
      picArr: [],
      baseURL: '',
      userinfo: {
        is_not_material: 1,
        token: this.$cookies.get(CUR_TOKEN_NAME),
      },
      upProgress: false, // 上传中提示
      upError: false, // 上传失败提示
    };
  },
  mounted() {
    this.baseURL = process.env.NUXT_ENV.VUE_APP_API;
  },
  methods: {
    //预览图片回调
    showPreviewimgFunc(url, isImg) {
      this.imgUrl = this.$options.filters.imgbaseurl(url);
      if (isImg) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    // 上传前
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
        return false;
      }
      if (file.type != 'image/jpeg' && file.type != 'image/png') {
        this.$message.error('暂不支持上传该类型图片');
        return false;
      }
      if (file.name.split('.')[1] == 'jfif') {
        this.$message.error('暂不支持上传该类型图片');
        return false;
      }
    },
    // 上传中
    picProgress() {
      this.upProgress = true;
      this.upError = false;
    },
    // 上传成功
    picSuccess(res) {
      this.picArr.push(res.data.link);
      this.$emit('already-upload', this.picArr.toString());
      this.upProgress = false;
      this.upError = false;
    },
    // 上传失败
    picError() {
      this.upError = true;
      this.upProgress = false;
    },
    // 移除图片
    delPic(index) {
      this.picArr.splice(index, 1);
      this.$emit('already-upload', this.picArr.toString());
    },
  },
};
