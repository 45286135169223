
import GoodListItem from './GoodListItem.vue';
import QuoteBlock from './QuoteBlock.vue';
import { mapGetters } from 'vuex';
import {
  getMsgText,
  getMsgContent,
  getMsgContentData,
  getMsgType,
} from '@/utils/chat_temp_tools.js';
export default {
  components: { GoodListItem, QuoteBlock },
  props: {
    // 当前会话对象
    sessionInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 当前会话对象的id
    otherId: {
      type: String,
      default: '',
    },
    // 消息item的方向
    direction: {
      type: String,
      default: 'left',
    },
    name: {
      type: String,
      default: '--',
    },
    message: {
      type: String,
      default: `--`,
    },
    s_id: {
      type: Number,
      default: 0,
    },
    sender: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    status: {
      type: String,
      default: 'unread',
    },
    time: {
      // 消息收发的时间戳
      type: Number,
      default: undefined,
    },
    content: {
      type: [String, Object],
      default: () => {},
    },
    avatar: {
      type: String,
      default: '',
    },
    // 可撤回消息的最大时限
    recallTimeLimit: {
      type: Number,
      default: 2,
    },
    // 引用的消息对象
    quoteMsg: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      defaultAvatar: require('@/assets/images/chat/default-avatar-03.png'), // 用户头像
      showBigImg: false, // 是否展示大图
    };
  },
  computed: {
    ...mapGetters('chat', ['sessionById']),
    // 是否展示撤回按钮
    showRecallBtn() {
      return Date.now() - this.time < this.recallTimeLimit * 60 * 1000;
    },
    // 是否是我发的消息
    isMyMessage() {
      return this.direction === 'right';
    },
    // 不是消息（时间戳消息 or 撤回提示）
    isNotMessage() {
      return this.type === 'time' || this.type === 'recall';
    },
    // 是否展示发送失败图标
    showFailedIcon() {
      return this.isMyMessage && this.status === 'fail';
    },
    // 当前会话的最新已读时间
    isRead() {
      return this.time <= this.sessionInfo?.msgReceiptTime || 0;
    },
    contentData() {
      if (this.content) {
        return this.content.content;
      }
    },
    msgPrice() {
      if (this.content) {
        return this.content.price;
      }
    },
    msgItemId() {
      if (this.content) {
        let id = this.content.content?.item_id;
        if (!id) {
          id = this.content.content?.sitem_id;
        }
        return id;
      }
    },
    msgOrderId() {
      if (this.content) {
        return this.content?.content?.tid;
      }
    },
    /**
     * 引用消息
     */
    // 引用消息类型
    quoteMsgType() {
      return getMsgType(this.quoteMsg);
    },
    // 引用消息信息
    quoteMsgInfo() {
      let info = Object.assign({}, getMsgContentData(this.quoteMsg));
      // 特殊处理商品卡片的 price 字段
      let price = getMsgContent(this.quoteMsg)?.price;
      if (price) {
        info.price = price;
      }
      return info;
    },
    // 引用消息内容
    quoteMsgContent() {
      if (this.quoteMsgType === 'text') return getMsgText(this.quoteMsg);
      else if (this.quoteMsgType === 'image') return this.quoteMsgInfo?.url;
      else '';
    },
    userInfoList() {
      return this.$store.getters.userInfos;
    },
    theAvatar() {
      let avatar = this.avatar;
      let imgHost = 'https://img.ximu.cn/';
      // 如果头像图片地址错误，则使用默认头像
      if (!avatar || avatar === imgHost) {
        avatar = this.defaultAvatar;
      }
      return avatar;
    },
    // 小菜单的配置
    moreMenuList() {
      const list = [];
      if (this.isNotMessage) {
        return list;
      }
      if (!this.quoteMsg) {
        list.push({ label: '引用', command: 'reply', icon: 'icon-yinyong' });
      }
      if (this.isMyMessage && this.showRecallBtn) {
        list.push({ label: '撤回', command: 'recall', icon: 'icon-chehui' });
      }
      if (this.isMyMessage && this.type === 'text') {
        list.push({
          label: '添加常用语',
          command: 'add-common-phrase',
          icon: 'icon-tianjiachangyongyu',
        });
      }
      return list;
    },
  },
  methods: {
    handleClickImg() {
      this.showBigImg = true;
    },
    // 点击更多菜单的某一项
    handleClickMoreMenu(command) {
      this.$emit(command);
    },
  },
};
