
export default {
  name: 'TableBox',
  components: {},
  props: {
    // 列表数据
    tableList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedList: [], // 已选中列表
    };
  },
  watch: {
    tableList: {
      deep: true,
      handler(value) {
        this.selectedRow();
      },
    },
  },
  methods: {
    handleSelect(selection, row) {
      let choseRow = this.selectedList?.findIndex(
        (item) => item?.toString() == row?.id?.toString()
      );
      // 不存在,存进去  存在,删掉
      if (choseRow == '-1') {
        this.selectedList?.push(row.id);
      } else {
        this.selectedList?.splice(choseRow, 1);
      }
    },
    handleSelectAll(selection) {
      let selectedIds = [];
      this.tableList?.forEach((item) => {
        selectedIds.push(item.id);
      });
      // 取消全选就移除当前页码表格数据
      if (selection?.length == 0) {
        this.selectedList = this.selectedList?.filter((item) => {
          return !selectedIds.includes(item);
        });
      }
      // 全选就加入
      else {
        this.selectedList = Array.from(
          new Set([...this.selectedList, ...selectedIds])
        );
      }
    },
    // 回显勾选数据
    selectedRow() {
      this.tableList.forEach((item) => {
        this.selectedList.forEach((i) => {
          if (item.id == i) {
            this.$nextTick(() => {
              this.$refs.withdrawTable.toggleRowSelection(item);
            });
          }
        });
      });
    },
    // 导出数据
    exportData() {
      if (this.selectedList?.length == 0) {
        this.$message.warning('请选择要导出数据');
        return;
      }
      this.$emit('export', this.selectedList?.toString());
    },
    // 重置选中数据
    resetSelectedList() {
      this.selectedList = [];
      this.$refs.withdrawTable.clearSelection();
    },
  },
};
