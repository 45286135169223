/**
 * chat 模块工具函数
 */

import dayjs from 'dayjs';
import { caculateChatTimeAgo } from '@/utils/time';
import { getRecallMsgTime } from '@/utils/chat_temp_tools';

/**
 * 细目客服统一的路由路径名
 * @description 与客服聊天，统一跳 /chat/${CS_ROUTE_NAME}
 * 具体的客服id在页面内通过接口获取
 */
export const CS_ROUTE_NAME = 'xmcustomerservice';

/**
 * 获取用户被分配的客服id
 * @returns 当前用户被分配的客服id
 */
export function getCustomerServiceId() {
  if (!process.client) return '';
  return localStorage.getItem('xm_csid');
}

/**
 * 对传入id做正则化，一定是字符串且字母小写
 * @param {string} id
 * @return 字符串且小写的id，如果不是字符串或者id为空，返回空字符串
 */
export function getValidId(id) {
  if (!id) return '';
  if (typeof id !== 'string') id = '' + id;

  return id.toLowerCase();
}

// 对会话进行排序
// 排序依据：
// 1. 是否为置顶会话
// 2. 根据会话的 updateTime 排序
function sortSession(sessionA, sessionB) {
  if (sessionA.isTop && !sessionB.isTop) {
    return -1;
  } else if (!sessionA.isTop && sessionB.isTop) {
    return 1;
  } else {
    return sessionB.updateTime - sessionA.updateTime;
  }
}

// 对会话排序的函数
export function getOrderedSessions(sessions) {
  return Object.keys(sessions)
    .map((id) => sessions[id])
    .sort(sortSession);
}

/**
 * 判断传入时间是否为现在的 x 天前
 * @param {*} x
 * @param {*} time 时间戳
 * @returns 是否为从当前时间算起的 x 天前
 */
function isDayXBefore(x, time) {
  // 今天为 0 天前；昨天为 1 天前；前天为 2 天前
  const targetDate = dayjs().subtract(x, 'day').format('YYYY-MM-DD');
  return targetDate === dayjs(time).format('YYYY-MM-DD');
}

/**
 * 搜索目标数组中符合条件的列表项
 *
 * @param {*} list 被搜索的目标列表
 * @param {*} targetKey 待搜索的键
 * @param {*} targetVal 待搜索的值
 * @returns
 */
export function fuzzySearchArray(list, targetKey, targetVal) {
  let res = [];
  if (list && list.length > 0 && targetKey && targetVal) {
    let str = `\S*${targetVal}\S*`;
    let reg = new RegExp(str, 'i');
    res = list.filter((item) => reg.test(item[targetKey]));
  }
  return res;
}
/**
 * 搜索目标对象中符合条件的列表项
 *
 * @param {*} list 被搜索的目标列表
 * @param {*} targetKey 待搜索的键
 * @param {*} targetVal 待搜索的值
 * @returns
 */
export function fuzzySearchObject(obj, targetKey, targetVal) {
  let res = [];
  if (obj && JSON.stringify(obj) != '{}' && targetKey && targetVal) {
    let str = `\S*${targetVal}\S*`;
    let reg = new RegExp(str, 'i');
    for (const item of Object.values(obj)) {
      if (reg.test(item.nick)) {
        res.push(item);
      }
    }
  }
  return res;
}

/**
 * 获取自定义时间戳消息对象
 *
 * @param {*} time 时间戳
 * @returns
 */
export function getCustomTimeMsg(time) {
  const timeMsg = {
    idClient: 'time-' + time,
    type: 'custom',
    from: 'sys-custom-time',
    content: JSON.stringify({
      type: 'time',
      content: {
        value: caculateChatTimeAgo(time),
      },
    }),
    status: 'success',
    time: time,
  };
  let isToday = isDayXBefore(0, time);
  // 是否需要更新时间戳
  // 此为对“一分钟定时更新时间戳”的功能的优化
  // 只有今天之内的消息需要定时更新时间戳
  timeMsg.needUpdateTime = isToday;
  return timeMsg;
}

/**
 * 更新msglist中的自定义时间戳文本
 * @param {*} msg 自定义时间消息
 * @param {*} time 时间戳
 */
export function updateCustomTimeMsgContent(msg, time) {
  let newContent = JSON.stringify({
    type: 'time',
    content: {
      value: caculateChatTimeAgo(time),
    },
  });
  let isToday = isDayXBefore(0, time);
  // 是否需要更新时间戳
  // 此为对“一分钟定时更新时间戳”的功能的优化
  // 只有今天之内的消息需要定时更新时间戳
  msg.needUpdateTime = isToday;
  msg.content = newContent;
}

/**
 * 更新消息列表中的时间戳
 * @param {*} msgList
 */
export function updateMsgsTimestamps(msgList) {
  for (let i = 0; i < msgList.length; i++) {
    let msg = msgList[i];
    let isTimeMsg = msg.from === 'sys-custom-time';
    // 不是时间戳消息或当前时间戳消息不需要更新时间
    if (!isTimeMsg || !msg.needUpdateTime) {
      continue;
    }
    let targetMsg = msgList[i + 1];
    let time = targetMsg.time;

    updateCustomTimeMsgContent(msgList[i], time);
  }
}

/**
 * 初始化消息列表
 * @param msgs
 */
export function initMessageList(msgs) {
  msgs.sort((a, b) => a.time - b.time);
  let msgArr = msgs;
  let msgList = [];
  let lastTimeMsgStamp = 0; // 上一条时间戳消息的时间
  for (let i = 0; i < msgArr?.length; i++) {
    // 两条消息超过 2 分钟，插入一条自定义时间消息msgList
    // 或者距离上一个时间戳消息 2 分钟，则插入一条自定义时间消息
    // 或者是第一条消息或最旧一条消息
    if (
      i === 0 ||
      msgArr[i].time - msgArr[i - 1].time > 2 * 60 * 1000 ||
      msgArr[i].time - lastTimeMsgStamp > 2 * 60 * 1000
    ) {
      // 插入时间文本
      let timeMsg = getCustomTimeMsg(msgArr[i].time);
      msgList.push(timeMsg);
      lastTimeMsgStamp = msgArr[i].time;
    }
    // 特殊处理撤回提示消息
    if (msgArr[i].type === 'tip') {
      msgArr[i].time = getRecallMsgTime(msgArr[i]);
    }

    //插入消息
    msgList.push(msgArr[i]);
  }
  return msgList;
}

/**
 * 插入旧的历史聊天消息
 * @param {*} list
 * @param {*} msg

 * @param { Boolean } insertTimeMsg 是否需要插入时间戳消息
 * @param { Boolean } checkIfRepeat 是否需要检查重复时间戳（一头一尾才可能有重复时间戳）
 */
export function insertHistoryMessage(
  msgList,
  msg,
  insertTimeMsg = false,
  checkIfRepeat = false
) {
  // 特殊处理撤回提示消息
  if (msg.type === 'tip') {
    msg.time = getRecallMsgTime(msg);
  }
  msgList.unshift(msg);
  if (insertTimeMsg) {
    let timeMsg = getCustomTimeMsg(msg.time);
    if (checkIfRepeat) {
      let lastTimeMsgIdx = msgList.findIndex(
        (x) => x.from === 'sys-custom-time'
      );
      let lastTimeLabel = JSON.parse(msgList[lastTimeMsgIdx].content)?.content
          ?.value,
        currTimeLabel = JSON.parse(timeMsg.content)?.content?.value;

      if (lastTimeLabel === currTimeLabel) {
        // 删掉上一个消息时间戳
        msgList.splice(lastTimeMsgIdx, 1);
      }
    }

    msgList.unshift(timeMsg);
  }
}

/**
 * 插入新的聊天消息
 * @param {*} list
 * @param {*} msg
 */
export function insertNewMessage(msgList, msg) {
  if (!msg) return;
  if (
    msgList?.length > 0 &&
    (msgList[msgList.length - 1].idClient === msg.idClient ||
      msgList[msgList.length - 1].time > msg.time)
  ) {
    // 插入消息去重 + 检查 msg 的时间是否新于 msgList 最新一条消息
    return;
  }
  // 检查待插入新消息与历史记录最新消息之间的时间间隔
  // 如果大于五分钟，则先插入一条时间戳消息
  if (
    msgList?.length == 0 ||
    msg.time - msgList[msgList.length - 1].time > 2 * 60 * 1000
  ) {
    let timeMsg = getCustomTimeMsg(msg.time);
    msgList.push(timeMsg);
  }
  // 特殊处理撤回提示消息
  if (msg.type === 'tip') {
    msg.time = getRecallMsgTime(msg);
  }
  msgList.push(msg);
}

/**
 * 用新消息对象替换掉旧消息对象
 * @param { Array } msgList
 * @param { Object } oldMsg
 * @param { Object } newMsg
 */
export function replaceOldMessage(msgList, oldMsg, newMsg) {
  if (!oldMsg || !newMsg) return;
  let targetId = oldMsg?.idClient;
  // 特殊处理撤回提示消息
  if (newMsg.type === 'tip') {
    newMsg.time = getRecallMsgTime(newMsg);
  }

  if (msgList?.length > 0) {
    let targetIndex = msgList.findIndex((x) => x.idClient === targetId);
    msgList.splice(targetIndex, 1, newMsg);
  }
}

/**
 * 生成替换的撤回消息的提示语
 * @param {*} msg 被撤回的消息
 */
export function generateRecallSysMsg(msg, currAccount) {
  let recallNick = msg?.from === currAccount ? '你' : msg.fromNick + ' ';
  const hintMsg = {
    text: `${recallNick}撤回了一条消息`,
    originalMsg: msg,
    editable: false,
    type: 'recallMsg', // 撤回的消息
    time: msg.time,
    sessionId: msg.sessionId, // 兼容撤回提示消息
    idClient: msg.idClient,
  };
  return hintMsg;
}

// 需要保留的 NimMsg 消息字段
const validNimKeys = [
  'content',
  'from',
  'fromNick',
  'idClient',
  'idServer',
  'scene',
  'sessionId',
  'text',
  'time',
  'to',
  'type',
];
// 只保留有用的消息字段（为了避免 sendMsg 超出 custom 的长度限制）
export function filterNimMsg(msg, keepContent = true) {
  const validMsg = {};
  for (let key of validNimKeys) {
    if (key === 'content' && !keepContent) continue;
    if (msg[key]) {
      validMsg[key] = msg[key];
    }
  }
  return validMsg;
}
