
import headidentifying from './components/headidentifying.vue';
import addressManage from './components/addressManage.vue';
import orderDeatil from './components/orderDeatil.vue';
import logisticsInformation from './components/logisticsInformation.vue';
import IndexBottom from '@/components/indexbottom.vue';
import bottomPayDeatil from './components/bottomPayDeatil.vue';
import coupon from './components/coupon.vue';
import { getItemFreight } from '@/api/client-api/api.js';
import { getGoodOperationFeeRule } from '@/api/client-api/api.js';
import { getPayHasSubsidy } from '@/api/client-api/goods/pay.js';
import { mapGetters } from 'vuex';
import { shopTrade } from '@/api/client-api/goods/GoodsAttention.js';
export default {
  components: {
    headidentifying,
    addressManage,
    orderDeatil,
    logisticsInformation,
    IndexBottom,
    bottomPayDeatil,
    coupon,
  },
  data() {
    return {
      lang: '',
      item_tid: '', // 当前商品id
      skuIds: '', // skuid
      warId: '', // 仓库id
      currentAddress: {}, // 当前选中的地址
      logisticslist: [], // 当前物流模板
      currentteplate: {}, // 当前物流模板
      goodNum: 0, // 商品数量
      supplierId: '', // 供应商名称
      orderType: '', // 订单类型
      orderSource: '', // 订单来源
      otherOrderSource: '', // ‘其他’订单来源，填入其他平台名称
      wareCountry: '', // 仓库地址
      wareCity: '', //仓库所在市
      endCountry: '', //目的地国
      endCity: '', // 目的地，市
      logisticsfees: '', // 物流费
      insuranceFee: '', // 保价费
      favorablePrice: 0, // 商品优惠后价格（补贴后价格）
      originalPrice: 0, // 商品原价（划线价）
      stockDetail: [], // 备货政策
      operationFeeRuleType: '', // 操作费计算规则类型
      operationFeeRule: [], // 操作费计算规则
      opCommission: '', // 操作费佣金比例
      declareValue: '', // 申报价值
      addressInfo: {
        wareType: '', // 1是国内仓，2是跨境仓，3是海外仓
        eu: null, // 是否是欧盟
        countryId: '',
        wareId: '', // 仓库ID
      },
      orderDetail: {},
      addressKey: 1,
      currentCou: '', // 优惠券id
      currentCouNum: 0, // 优惠金额
      reachStockUp: false, // 是否达到批发标准
      wareLogistics: '', // 到仓物流费
      hasSkuSubsidy: true, // 是否有补贴优惠
    };
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
    // 商品价格（非首件）
    goodPrice() {
      let price = this.originalPrice || 0;
      let stockUpPrice = this.getStockedPrice(); // 批发政策价格
      this.reachStockUp = typeof stockUpPrice !== 'undefined'; // 是否达到批发标准
      return this.reachStockUp ? Number(stockUpPrice) || 0 : Number(price) || 0;
    },
    // 首件商品价格
    firstGoodsPrice() {
      let price = this.favorablePrice || 0;
      if (!this.hasSkuSubsidy) {
        // 如果当前 sku 无法使用首件补贴后价格
        price = this.originalPrice;
      }
      let stockUpPrice = this.getStockedPrice(); // 批发政策价格
      this.reachStockUp = typeof stockUpPrice !== 'undefined'; // 是否达到批发标准
      return this.reachStockUp ? Number(stockUpPrice) || 0 : Number(price) || 0;
    },
    // 根据操作费计算规则计算操作费
    operationFee() {
      let type = this.operationFeeRuleType;
      let rule = this.operationFeeRule;
      let fee = 0;
      let isRuleValid = rule && !Array.isArray(rule);
      if (type && isRuleValid) {
        if (type == '1') {
          // 按数量计费
          fee = this.handleCalOperationFeeByNum(rule, this.goodNum);
        } else if (type == '2') {
          // 按重量计费
          fee = this.handleCalOperationFeeByWeight(rule, this.goodNum);
        }
      }
      let commission = 0;
      if (this.opCommission) {
        // 操作费佣金
        commission = this.opCommission * 0.01;
      }
      fee *= 1 + commission; // 加上佣金比例
      return fee != 0 ? Math.floor(Number(fee) * 100) / 100 : 0;
    },
  },

  mounted() {
    this.lang = this.currLang;
    this.item_tid = this.$route.query.item_id;
    this.skuIds = this.$route.query.sku_id;
    this.warId = this.$route.query.war_id;
    this.getItemInfo1();
    this.getGoodOperationFeeRule();
  },
  methods: {
    // 获取订单详情
    getItemInfo1() {
      const option = {
        item_id: this.item_tid,
        sku_id: this.skuIds,
        ware_id: this.warId,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      shopTrade(option)
        .then((res) => {
          if (res.errcode == 0) {
            res.data.num = this.$route.query.num; // 购买数量
            res.data.ordertype = this.$route.query.order_type;
            this.orderDetail = res.data;
            this.goodNum = Number(res.data.num) || 0;
            this.supplierId = res.data.supplier_id;
            this.orderType = res.data.ordertype;
            this.wareCountry = res.data.ware_info.country;
            this.wareCity = res.data.ware_info.city;
            this.favorablePrice = Number(res.data.price) || 0;
            this.originalPrice = Number(res.data.underlinedPrice) || 0;
            this.stockDetail = res.data.stock_detail;
            this.addressInfo.wareType = res.data.ware_info.ware_type;
            this.addressInfo.eu = res.data.ware_info.eu;
            this.addressInfo.countryId = res.data.ware_info.country_id;
            this.addressInfo.wareId = res.data.ware_info.ware_id;
            this.addressKey++;
            this.wareLogistics = res.data.ware_info.ware_logistic;
          } else if (res?.errcode == 80205) {
            // #TODO 遇到这个状态码跳去商品详情页（类似商品下架等，后端定的）
            this.$message.error(res.msg);
            window.open(this.item_tid + '.html', '_self');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async checkaddress(item) {
      this.currentAddress = item;
      this.endCountry = item.countries_name;
      this.endCity = item.city_name;
      this.getItemFreight(item.country_id, item.province_id, item.city_id);
      // #todo 检查当前地址是否可以享受优惠
      await this.checkIfSkuSubsidy(item);
    },

    // 获取物流信息
    getItemFreight(id, end_state, end_city) {
      this.currentteplate = {};
      // 获取物流模板
      let option = {
        start: this.$route.query.sco_id, // 发货地
        start_state: this.$route.query.sp_id,
        start_city: this.$route.query.sc_id,
        war_id: this.warId, // 仓库id
        end: id,
        supplier_id: this.supplierId, // 供应商id
        sku_id: this.skuIds, // skuId
        sku_num: this.goodNum,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      // 收货地的省
      if (end_state) {
        option.end_state = end_state;
      }
      if (end_city) {
        option.end_city = end_city;
      }
      return new Promise((resolve, reject) => {
        getItemFreight(option)
          .then((res) => {
            if (res.errcode == 0) {
              res.data.forEach((item) => {
                if (item.cha_id != this.logId) {
                  item.checked = false;
                } else {
                  item.checked = true;
                  this.currentteplate = item;
                  this.logisticsfees = item.price;
                  this.insuranceFee = item.insurance_charge;
                }
              });
              // 判断如果没有默认模板就选择第一条
              if (
                Object.keys(this.currentteplate).length == 0 &&
                res.data.length > 0
              ) {
                // 默认选中第一条
                res.data[0].checked = true;
                this.logisticsfees = res.data[0].price;
                this.insuranceFee = res.data[0].insurance_charge;
                this.currentteplate = res.data[0];
              }
              this.logisticslist = res.data;
              resolve();
            } else {
              this.logisticslist = [];
              this.logisticsfees = '0';
              this.insuranceFee = '0';
              reject();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    // 获取订单相关信息
    getOrderDeatil(obj) {
      this.goodNum = Number(obj.num);
      // this.supplierId = obj.supplier_id;
      this.orderType = obj.ordertype;
      this.orderSource = obj.order_source;
      this.otherOrderSource = obj.other_order_source;
      this.getItemFreight(
        this.currentAddress.country_id,
        this.currentAddress.province_id,
        this.currentAddress.city_id
      );
      // this.wareCountry = obj.ware_info.country;
      // this.wareCity = obj.ware_info.city;
      // this.favorablePrice = obj.price || 0;
      // this.stockDetail = obj.stock_detail;
      // this.wareType = obj.ware_type;
      // this.eu = obj.en;
    },
    // 获取物流相关信息
    getlog(obj) {
      this.currentteplate = obj; // 当前选中的物流模板
      this.logisticsfees = this.keepTwoDecimal(
        Number(this.currentteplate.price)
      ); // 物流费
      this.insuranceFee = this.keepTwoDecimal(
        Number(this.currentteplate.insurance_charge)
      ); // 保价费
    },
    // 获取批发政策价格
    getStockedPrice() {
      let price = undefined;
      if (this.stockDetail && this.stockDetail.length > 0) {
        let length = this.stockDetail.length;
        if (this.stockDetail[0].stock_quantity) {
          let allmin = Number(this.stockDetail[0].stock_quantity.split('-')[0]);
          let allmax = Number(
            this.stockDetail[length - 1].stock_quantity.split('-')[1]
          );
          if (Number(this.goodNum) >= allmin) {
            if (Number(this.goodNum) >= allmax) {
              price = Number(this.stockDetail[length - 1].discount_price);
            } else {
              for (let i = 0; i < this.stockDetail.length; i++) {
                let max = Number(
                  this.stockDetail[i].stock_quantity.split('-')[1]
                );
                let min = Number(
                  this.stockDetail[i].stock_quantity.split('-')[0]
                );
                if (
                  Number(this.goodNum) >= min &&
                  max >= Number(this.goodNum)
                ) {
                  price = Number(this.stockDetail[i].discount_price);
                }
              }
            }
          }
        }
      }
      return price;
    },

    // 获取操作费计算规则
    getGoodOperationFeeRule() {
      let option = {
        sku_id: this.skuIds,
        ware_id: this.warId,
        API_LANG_TYPE: this.lang,
      };
      getGoodOperationFeeRule(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.operationFeeRuleType = res.data.type;
            this.operationFeeRule = res.data.rule;
            this.opCommission = res.data.commission; // 操作费佣金比例
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * 按数量计费
     */
    handleCalOperationFeeByNum(rule, currNum) {
      let totalFee = 0;
      if (currNum == 0) {
        return totalFee;
      }
      if (rule && !Array.isArray(rule)) {
        let price = this.keepTwoDecimal(Number(rule.price)),
          num = Number(rule.num),
          perPrice = this.keepTwoDecimal(Number(rule.perPrice));
        totalFee = price; // 首件费用件数内费用
        currNum -= num;
        // 续件费用
        if (currNum > 0) {
          totalFee += currNum * perPrice;
        }
        // console.log('按数量计费', totalFee);
        return Number(totalFee);
      }
      return Number(totalFee);
    },
    /**
     * 按重量计费
     */
    handleCalOperationFeeByWeight(rule, currNum) {
      let totalFee = 0;
      if (currNum == 0) {
        return totalFee;
      }
      if (rule && !Array.isArray(rule)) {
        let firstPrice = this.keepTwoDecimal(Number(rule.firstPrice)),
          continuePrice = this.keepTwoDecimal(Number(rule.continuePrice));
        let totalFee = firstPrice;
        currNum--;
        if (currNum > 0) {
          totalFee += continuePrice * currNum;
        }
        return Number(totalFee);
      }
      return Number(totalFee);
    },

    // 获取申报价值
    getDeclare(value) {
      this.declareValue = value;
      // this.getItemFreight(
      //   this.currentAddress.country_id,
      //   this.currentAddress.province_id
      // );
    },
    // 获取优惠券id
    getCurrentCou(id, num) {
      this.currentCou = id;
      this.currentCouNum = num;
    },
    // 检查 sku 是否还能享受补贴
    async checkIfSkuSubsidy(addressInfo) {
      const options = {
        sku_id: this.skuIds,
        country_id: addressInfo.country_id,
        province_id: addressInfo.province_id,
        city_id: addressInfo.city_id,
        region_id: addressInfo.region_id,
        address: addressInfo.address,
      };
      const res = await getPayHasSubsidy(options);
      if (res?.errcode == 0) {
        this.hasSkuSubsidy = res.data?.is_possible ?? true;
      }
    },
  },
};
