
export default {
  name: 'successRefund',
  props: {
    // 账号相关信息
    info: {
      type: Object,
      default: () => {},
    },
    // 账号打款状态 1无需打款 2账户未确认 3未打款 4已打款
    refundStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataInfo: {}, // 账户信息
      bankInfo: {}, // 银行卡信息
    };
  },
  mounted() {},
  watch: {
    // 账号相关信息
    info: {
      immediate: true,
      deep: true,
      handler(val) {
        this.dataInfo = JSON.parse(JSON.stringify(val || {}));
        this.bankInfo = JSON.parse(JSON.stringify(val?.bank_card || {}));
      },
    },
  },
};
