
import {
  authAdd,
  getBrandList,
  getSupplierBaseInfo,
  getSlaePlatformList,
  getUserInfo,
  getInfo,
} from '@/api/client-api/mine/mineapi.js';
import { resolve } from 'mathjs';
export default {
  data() {
    const shopLinkCheck = (rule, value, callback) => {
      let seven = value?.trim()?.slice(0, 7);
      let eight = value?.trim()?.slice(0, 8);
      if (seven != 'http://' && eight != 'https://') {
        callback(new Error('请输入正确的店铺链接'));
      }
      if ('http://' === value?.trim() || 'https://' === value?.trim()) {
        callback(new Error('请输入正确的店铺链接'));
      }
      callback();
    };
    const platformCheck = (rule, value, callback) => {
      console.log(value, 'value');
      if (value?.length == 0) {
        callback(new Error('请选择所属平台'));
      }
      callback();
    };
    return {
      addobj: {
        supplier_id: '', //供应商id
        brand_id: '', // 品牌
        shopLink: [], // 店铺名称及网址
        platform: [], // 所属平台
        time: '', // 授权时间范围
        authorization: '', // 授权范围
        remark: '', // 备注信息
        marksCertification: [], // 资料附件
      },
      addobjrules: {
        supplier_id: [
          { required: true, message: '请选择供应商', trigger: 'change' },
        ],
        brand_id: [
          { required: true, message: '请选择品牌', trigger: 'change' },
        ],
        // shopLink: [{ required: true, trigger: "blur" }],
        platform: [
          { required: true, message: '请选择所属平台', trigger: 'change' },
          { validator: platformCheck, trigger: 'blur' },
        ],
        time: [{ required: true, message: '请选择授权范围', trigger: 'blur' }],
        authorization: [
          { required: true, message: '请输入授权范围', trigger: 'blur' },
        ],
        shopName: [
          { required: true, message: '请输入店铺名称', trigger: 'blur' },
        ],
        shopLink: [
          { required: true, message: '请输入店铺链接', trigger: 'blur' },
          { validator: shopLinkCheck, trigger: 'blur' },
        ],
      },
      brandlist: [], //品牌列表
      selectsupplier: [], //供应商选择
      platformlist: [], //平台列
      oteherShow: false, // 其他输入框
      otherValue: '', // 其他平台
      authorizedParty: '', // 被授权方
      currentId: '',
      pageType: '1',
      deshopName: '',
      debrand: '',
      supplierId: '',
    };
  },
  async mounted() {
    this.getSupplierBaseInfoFun();
    this.getAuthorized();
    // this.getSlaePlatformList();
    this.pageType = this.$route.params.type;
    if (this.$route.params.type == 2 || this.$route.params.type == 3) {
      // 2是续期申请，3是在申请
      await this.getDetail(this.$route.params.id);
      this.getBrandList();
      this.getSlaePlatformList();
    }
    // this.authorizedParty = this.$route.params.name;
  },
  methods: {
    getDetail(id) {
      return new Promise((resolve, reject) => {
        let option = {
          id,
        };
        getInfo(option)
          .then((res) => {
            if (res?.errcode == 0) {
              let detailObj = res?.data?.list;
              this.deshopName = detailObj?.shop_name;
              this.debrand = detailObj?.brand_name;
              this.addobj.supplier_id = detailObj?.supplier_id;
              this.supplierId = detailObj?.supplier_id;
              this.addobj.brand_id = detailObj?.brand_id;
              if (detailObj?.auth_platform) {
                this.addobj.platform = detailObj?.auth_platform?.split(',');
              }
              if (detailObj?.other_platform_text) {
                this.addobj.platform = this.addobj?.platform?.concat(
                  detailObj?.other_platform_text?.split(',')
                );
              }
              this.addobj.shopLink = detailObj?.shop_link;
              this.addobj.time = detailObj?.authorization_time?.split('至');
              this.addobj.authorization = detailObj?.auth_range;
              this.addobj.remark = detailObj?.remark;
              this.addobj.marksCertification = detailObj?.remarks_url
                ? detailObj?.remarks_url?.split(',')
                : [];
              this.currentId = detailObj?.id;
              resolve();
            } else {
              reject();
            }
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      });
    },
    // 供应商change
    supplierChange() {
      this.addobj.brand_id = '';
      this.getBrandList();
    },
    // 根据品牌获取平台
    brandChange() {
      this.addobj.platform = '';
      this.getSlaePlatformList();
    },
    //获取供应商对应品牌列表
    getBrandList() {
      let option = {
        supplier_id: this.addobj.supplier_id,
      };
      getBrandList(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.brandlist = res?.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    //获取供应商id和名字
    getSupplierBaseInfoFun() {
      getSupplierBaseInfo()
        .then((res) => {
          if (res?.errcode == 0) {
            this.selectsupplier = res?.data;
            // console.log(this.selectsupplier[0]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    //获取销售平台列表
    getSlaePlatformList() {
      let option = {
        id: this.addobj.brand_id,
      };
      getSlaePlatformList(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.platformlist = res?.data;
            this.platformlist.push({
              id: '其他',
              name: '其它（可输入，最多二十个字)',
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 平台变化
    platformChange(value) {
      let index = value.indexOf('其他');
      if (index > -1) {
        this.addobj.platform.splice(index, 1);
        this.oteherShow = true;
        this.$refs.platform.blur();
        this.otherValue = '';
      }
      // 获取已选择平台的名字
      let arr = [];
      for (let i = 0; i < this.platformlist.length; i++) {
        if (this.addobj.platform.indexOf(this.platformlist[i].id) > -1) {
          arr.push(this.platformlist[i].name);
        }
      }
      // 筛选没有加的平台加到店铺里
      for (let i = 0; i < arr.length; i++) {
        let flag = false;
        this.addobj.shopLink.forEach((item) => {
          if (item.platform == arr[i]) {
            flag = true;
          }
        });
        if (!flag) {
          // 没有
          this.addobj.shopLink.push({
            platform: arr[i],
            detail: [
              {
                shopName: '',
                shopLink: '',
              },
            ],
          });
        }
      }
    },
    //
    removeTag(value) {
      let platName = this.platformlist.filter((item) => {
        return item.id == value;
      })[0]?.name;
      if (!platName) {
        platName = value;
      }
      for (let i = 0; i < this.addobj.shopLink.length; i++) {
        if (this.addobj.shopLink[i].platform == platName) {
          this.addobj.shopLink.splice(i, 1);
          break;
        }
      }
    },
    newPlat() {
      if (this.otherValue.trim()) {
        this.addobj.platform.push(this.otherValue);
        this.addobj.shopLink.push({
          platform: this.otherValue,
          detail: [
            {
              shopName: '',
              shopLink: '',
            },
          ],
        });
      }
      this.otherValue = '';
      this.oteherShow = false;
    },
    //上传文件成功回调
    statuChangeFunc(fileArray) {
      this.addobj.marksCertification = fileArray;
    },
    // 提交
    submit() {
      this.$refs.addobj.validate((valid) => {
        if (valid) {
          let platIds = []; // 平台原有的id
          let othenPlat = []; // 其他的平台
          for (let i = 0; i < this.addobj.platform.length; i++) {
            let flag = false;
            this.platformlist.forEach((item) => {
              if (this.addobj.platform[i] == item.id) {
                flag = true;
              }
            });
            if (flag) {
              // 原有的id
              platIds.push(this.addobj.platform[i]);
            } else {
              // 其他
              othenPlat.push(this.addobj.platform[i]);
            }
          }

          let option = {
            supplier_id: this.addobj.supplier_id, // 供应商id
            brand_id: this.addobj.brand_id, // 品牌id
            shop_link: JSON.stringify(this.addobj.shopLink), // 店铺链接
            auth_platform: platIds.join(','), // 所属平台
            start_time: this.addobj.time[0], // 开始时间
            end_time: this.addobj.time[1], // 结束时间
            auth_range: this.addobj.authorization, // 授权范围
            remark: this.addobj.remark, // 备注信息
            remarks_url: this.addobj.marksCertification.join(','), // 上次附件
            auth_platform_text: othenPlat.join(','), //选择其他品牌的品牌名
            user_name: this.authorizedParty,
          };

          if (this.$route.params.type == 2 || this.$route.params.type == 3) {
            option.id = this.currentId;
          }
          authAdd(option)
            .then((res) => {
              if (res?.errcode == 0) {
                if (res?.msg) {
                  this.$message.success(res?.msg);
                }

                this.$router.push('/mine/authorizationmanage');
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
    // 返回
    goback() {
      this.$router.push('/mine/authorizationmanage');
    },
    // 增加店铺
    plusData(index) {
      this.addobj.shopLink[index].detail.push({
        shopName: '',
        shopLink: '',
      });
    },
    // 减少店铺
    minusData(index, i) {
      this.addobj.shopLink[index].detail.splice(i, 1);
    },
    // 获取被授权方
    getAuthorized() {
      getUserInfo()
        .then((res) => {
          if (res?.errcode == 0) {
            this.authorizedParty = res?.data?.user_name;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 点击清空全部所属平台
    // 全部重置
    handleClearPlatform() {
      this.addobj.platform = [];
      this.addobj.shopLink = [];
      this.oteherShow = false;
      this.otherValue = '';
    },
  },
};
