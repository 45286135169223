
export default {
  data() {
    return {};
  },
  props: {
    // 语言
    langConfig: {
      type: Object,
      default: () => {},
    },
    // 关键字展示
    keyword: {
      type: String,
      default: '',
    },
    // 品牌展示相关
    selectedBrandList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    // 页面触发
    goBack() {
      let params = this.$route.params;
      if (params && params.keyword && params.brandId) {
        this.$router.push('/keywords=' + encodeURIComponent(params.keyword));
        return;
      }
      if (params && params.brandId) {
        this.$router.push('/');
      }
    },
  },
};
