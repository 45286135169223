/**
 * 我的模块
 *
 */

// 后台管理架子
import shophome from '@/pages/back-stage/index.vue';
// 商品模块
import MineLayout from '@/pages/back-stage/mine-module/MineLayout.vue'; //分销商后台我的模块布局
import AddressMannge from '@/pages/back-stage/mine-module/mine/AddressMannge.vue'; //地址管理
import AuthorizationManage from '@/pages/back-stage/mine-module/mine/licensing/licensing.vue'; // 品牌授权列表
import nweEmpower from '@/pages/back-stage/mine-module/mine/licensing/nweEmpower.vue'; // 新建品牌授权
import brandDetails from '@/pages/back-stage/mine-module/mine/licensing/brandDetails.vue'; // 品牌详情

const mineRoutes = [
  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: true,
    },
    children: [
      // 商品模块的
      {
        path: 'mine',
        component: MineLayout,
        meta: {
          isAuth: true,
        },
        children: [
          // 地址管理列表
          {
            path: '',
            component: AddressMannge,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'addressmannge',
            component: AddressMannge,
            meta: {
              isAuth: true,
            },
          },
          // 品牌授权列表
          {
            path: 'authorizationmanage',
            name: 'AuthorizationManage',
            component: AuthorizationManage,
            meta: {
              isAuth: true,
            },
          },
          // 新建品牌授权
          {
            path: 'nweEmpower/:type/:id?',
            name: 'NweEmpower',
            component: nweEmpower,
            meta: {
              isAuth: true,
            },
          },
          // 品牌授权详情
          {
            path: 'brandDetails/:id',
            name: 'brandDetails',
            component: brandDetails,
            meta: {
              isAuth: true,
            },
          },
        ],
      },
    ],
  },
];

export default mineRoutes;
