
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
// import { sendSmsCode, sendVerificationSms } from '@/utils/api/api';
import verifyPhoneNum from '../components/verifyPhoneNum.vue';
import NotCertified from '../components/NotCertified.vue';
export default {
  name: 'accountSettings',
  components: {
    verifyPhoneNum,
    NotCertified,
  },
  filters: {
    authStatus(val) {
      switch (val) {
        case '0':
          return '待完善';
          break;
        case '1':
          return '审核中';
          break;
        case '2':
          return '已认证';
          break;
        case '3':
          return '未通过';
          break;
        case '4':
          return '已拉黑';
          break;
        case '5':
          return '已驳回';
          break;
        default:
          break;
      }
    },
  },
  data() {
    return {
      showPreviewimg: false, // 预览弹窗的状态
      previewImgUrl: '', // 需要预览的图片url

      accountname: '', //登录账号
      status: '', // 实名认证状态-1删除,0-待完善资料 1-待审核 2-已通过 3-已驳回  4-拉黑',
      //type:1, // 认证类型
      isShow: false,
      isUnAuth: false, // 未认证时的实名信息状态
      isAuth: false, // 完成认证后的实名信息状态
      isCover: false, //遮罩层状态
      baseURL: '',
      vertype: '1', // 1是跳转编辑基本信息 2是跳转升级页面
      notCertifiedDialog: false, // 进入页面时认证资料没通过/待审核的提示
      userMessage: {
        nickname: '', // 昵称
        name_show: '', // 隐藏的名字
        name: '',
        id_card_number_show: '', // 隐藏的证件号码
        id_card_number: '',
        id_card_type: '', //证件类型
        phone_show: '', // 隐藏的手机号
        phone: '',
        pay_password: false,
        is_nd_audit: '', // 是否二次审核中 1是 -1否
        reject_reason: '', // 驳回原因
        reject_picture: '', // 驳回附件
      },

      /**
       * 不强实名
       */
      isApproved: false, // 当前账号是否通过过实名认证

      cannotEditTipDialog: false, // 提示不可修改信息的弹窗状态
      cannotEditTipContent: '', // 提示不可修改信息的弹窗内容
      lastEditBasicTimes: '-1', // 当前基础信息剩余修改次数
      nextEditBasicDays: '-1', // 下次可修改基础信息天数
      realNameShow: false, // 实名认证弹框
    };
  },
  mounted() {
    this.getBasicInformation();
    if (this.$cookies.get('isMyPlatform')) {
      this.goVerifyInfo();
      this.$cookies.remove('isMyPlatform');
    }
  },
  methods: {
    // 打开预览弹窗
    openPicPreview(item) {
      this.previewImgUrl = item;
      this.showPreviewimg = true;
    },
    // 关闭图片预览
    previewCloseFunc() {
      this.showPreviewimg = false;
      this.previewImgUrl = '';
    },
    closeVerify() {
      this.isShow = false;
    },
    /* mangeaccount(index){
          this.$router.push({path:'/account/accountmanagement',query:{type:index}}) // 打开添加子账号
      }, */
    // 管理子账号
    mangeaccount() {
      if (this.status == '1') {
        this.$message.warning('资料正在审核，请您耐心等待。');
        return;
      } else {
        this.$router.push('/account/accountmanagement'); // 打开添加子账号
      }
    },
    onemail() {
      this.$router.push({ path: '/account/notification' });
    },
    async getBasicInformation() {
      try {
        let res = await getBasicInformation();
        if (res?.errcode == 0) {
          this.status = res?.data?.status?.toString();

          Object.keys(this.userMessage)?.forEach((key) => {
            this.userMessage[key] = res.data[key];
          });

          if (
            this.status == '1' ||
            this.status == '3' ||
            this.userMessage?.is_nd_audit == '1'
          ) {
            this.notCertifiedDialog = true;
          }

          this.isApproved = res?.data?.is_approved;

          // 二次审核状态
          if (this.userMessage?.is_nd_audit == '1') {
            this.status = '5';
          }

          // if (this.status == '2' || this.status == '3') {
          this.$cookies.set('statusShow', this.status, {
            path: '/',
            maxAge: 60 * 60 * 24 * 30,
          });
          localStorage.setItem('statusShow', this.status);
          // }

          let userdetail = JSON.parse(
            this.$store.state.login.loginInfo.userdetail
          );
          userdetail.is_approved = this.isApproved;
          this.$cookies.set('userdetail', JSON.stringify(userdetail), {
            path: '/',
            maxAge: 60 * 60 * 24 * 30,
          });
          this.$store.commit('login/setUserInfo', {
            key: 'userdetail',
            value: JSON.stringify(userdetail),
          });
          localStorage.setItem('userdetail', JSON.stringify(userdetail));

          /**
           * 限制修改基础信息次数相关
           */
          this.lastEditBasicTimes = res?.data?.basic_modify_num?.toString();
          this.nextEditBasicDays = res?.data?.modify_residue_day?.toString();
        }
      } catch (err) {
        console.error(err);
      }
    },
    closeproven($event) {
      this.isShow = false;
    },
    // 查看实名信息
    // lookrealAuth() {
    //   if (this.status == '0') {
    //     this.$message.warning('请先填写资料');
    //     return;
    //   }
    //   if (this.status == '1' || this.status == '2' || this.status == '3') {
    //     this.isAuth = true;
    //     this.isCover = true;
    //   }
    // },
    // 跳转去实名认证
    gotoAuth() {
      this.$router.push('/account/information/edit');
    },
    // 关闭实名信息弹窗
    closeAuth() {
      this.isAuth = false;
      this.isCover = false;
    },
    // 进入页面时的状态认证 点击按钮事件
    closeCertified() {
      if (this.status == '1' || this.userMessage.is_nd_audit == '1') {
        this.notCertifiedDialog = false;
      }
      if (this.status == '3') {
        this.notCertifiedDialog = false;
        this.isShow = true;
        this.vertype = '1';
        // localStorage.setItem('isVerify', 'true');
        // this.$router.push('/account/information/edit');
      }
    },
    /**
     * 不强实名
     * 基础信息
     */
    goBasicInfo(type) {
      if (type == 'look') {
        this.$router.push('/account/basic/information');
      }
      if (type == 'edit') {
        if (this.lastEditBasicTimes == '0') {
          this.cannotEditTipDialog = true;
          this.cannotEditTipContent =
            '基础信息每30天修改一次，您的修改次数已达上限，距下次可修改还剩' +
            this.nextEditBasicDays +
            '天。';
          return;
        }
        this.$router.push('/account/basic/information/edit');
      }
    },
    // 点击确认/取消不可修改基础信息弹窗按钮
    sureCannotDialogTip() {
      this.cannotEditTipDialog = false;
    },

    /**
     * 不强实名
     * 认证信息
     */
    goVerifyInfo(type) {
      // console.log(this.status);
      if (this.status == '0') {
        this.isShow = true;
        this.vertype = '1';
        return;
      }
      if (this.status == '1') {
        this.$message.warning('资料正在审核，请您耐心等待。');
        return;
      }

      // 通过过一次认证后才可查看实名信息
      if (type == 'look' && this.isApproved) {
        this.$router.push('/account/information');
      }
      // 点击编辑或在没通过过认证的情况下点击查看
      if (type == 'edit' || (type == 'look' && !this.isApproved)) {
        if (this.status == '5') {
          this.$message.warning('资料正在审核，请您耐心等待。');
          return;
        }
        if (this.status == '2' || this.status == '3') {
          this.isShow = true;
          this.vertype = '1';
        }
      }
    },

    /**
     * 不强实名
     * 修改手机号
     */
    toEditPhoneNum() {
      this.$router.push({ path: '/account/editphone' });
    },

    // 修改登录密码
    toEditPassword() {
      this.$router.push({ path: '/account/editpassword' });
    },

    /**
     * 不强实名
     * 设置支付密码
     */
    setPayPassword() {
      if (this.status == '0') {
        this.cannotEditTipDialog = true;
        this.cannotEditTipContent =
          '为了保障您的账户安全，请先实名认证再设置支付密码。';
        return;
      }
      if (this.status == '1' || this.status == '5') {
        this.$message.warning('资料正在审核，请您耐心等待。');
        return;
      }
      if (this.status == '3') {
        this.$message.warning('资料被驳回状态不可重新设置支付密码');
        return;
      }
      this.$router.push('/account/setpaypassword');
    },
    /**
     * 发票设置
     */
    setBill() {
      this.$router.push({ path: '/account/invoiceset' });
    },
  },
};
