/**
 * 存放各类正则表达式验证函数
 *
 * 使用方式：
 * const isValidated = validateXXXX.test(<要验证的字符串>);
 *
 * 验证通过则 isValidated 为 true；否则为 false
 */

// 银行卡号验证
export const validateBankCardNumberReg = '^([1-9]{1})(\\d{15}|\\d{18})$';

// 中国手机号验证
export const validatePhoneNumberReg = '^((\\+|00)86)?1\\d{10}$';

// 身份证号验证（支持一代和二代）(全局查了一下暂时没人用)
// export const validateIdCardNumberReg =
//   '(^\\d{8}(0\\d|10|11|12)([0-2]\\d|30|31)\\d{3}$)|(^\\d{6}(18|19|20)\\d{2}(0\\d|10|11|12)([0-2]\\d|30|31)\\d{3}(\\d|X|x)$)';

/**
 * 纯数字验证，范围闭区间 [start, end]
 * @param {*} start 数字的最小位数
 * @param {*} end 数字的最大位数
 * @returns 正则表达式验证
 */
export function validateNumberRangeReg(start, end) {
  return `^\\d{${start},${end}}$`;
}

// 居民身份证验证（为了跟账号及认证的保持一致先）
export const validateIDcardNumberReg =
  '^([1-6][1-9]|50)\\d{4}(18|19|20)\\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$';

// 护照验证
export const validatePassportNumberReg =
  '^1[45][0-9]{7}$|([P|p|S|s]d{7}$)|([S|s|G|g]d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\\d{8}$)|([H|h|M|m]\\d{8,10})$';

// 港澳居民来往内地通行证验证
export const validateHRPcardNumberReg = '^[HMhm]{1}([0-9]{10}|[0-9]{8})$';

// 台湾居民来往大陆通行证
export const validateTaiWancardNumberReg = '^(\\d{8}|[a-zA-Z0-9]{10}|\\d{18})$';

// 只能输入纯数字
export const onlyNumber = '[^\\d]';

// export default {
//   validateBankCardNumberReg,
//   validatePhoneNumberReg,
//   validateIdCardNumberReg,
//   validateNumberRangeReg,
// };
