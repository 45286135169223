
import { violationNum } from '@/api/client-api/complaints/illegal.js';
export default {
  data() {
    return {
      violationList: [], //违规项和次数列表
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      violationNum()
        .then((res) => {
          if (res) {
            if (res?.errcode == 0) {
              this.violationList = res?.data;
              // console.log(this.violationList, 'this.violationList');
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
