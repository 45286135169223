
export default {
  name: 'SearchBox',
  data() {
    return {
      // 筛选值
      search: {
        time: [],
        currency: '1',
      },
      // 币种筛选
      currencyOptions: [
        { label: '人民币', value: '1' },
        { label: '美元', value: '2' },
      ],
    };
  },
  mounted() {},
  methods: {
    // 筛选列表
    searchList() {
      let options = {
        currency: this.search?.currency,
      };
      if (this.search?.time?.length == 2) {
        options.time_type = '1'; // 1 创建时间(目前只有创建时间)
        options.start_time = this.search?.time?.[0]; // 开始时间
        options.end_time = this.search?.time?.[1]; // 结束时间
      }
      this.$emit('search', options);
    },
  },
};
