
import { Decimal } from 'decimal.js'; // 精度计算
export default {
  name: 'USDPayMethods',
  components: {},
  props: {
    // 欠费金额
    amountPayable: {
      type: [Number, String],
      default: '',
    },
    // 当前的账户余额
    balanceValue: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    // 支付后余额
    afterPayShow() {
      return new Decimal(this.balanceValue || 0)
        .minus(new Decimal(this.amountPayable || 0))
        .toFixed(2);
    },
    // 支付/去充值按钮文字
    payButtonFont() {
      if (this.afterPayShow > 0) return 'immediate payment';
      return 'Go recharge';
    },
  },
  mounted() {},
  methods: {
    // 点击立即支付按钮
    payAmount() {
      // 余额不足跳转充值页
      if (this.afterPayShow < 0) {
        this.$router.push('/recharge/balance/2'); // 跳转充值美元页面
        return;
      }
      // TODO: 美元目前只有余额支付
      this.$emit('chose-method', '3');
    },
  },
};
