/**
 * 通知模块
 * -
 */

// 后台管理架子
import shophome from '@/pages/back-stage/index.vue';
// 通知模块
import NotifyLayout from '@/pages/back-stage/notify-module/NotifyLayout.vue'; //分销商后台通知模块布局
import NoticeSetting from '@/pages/back-stage/notify-module/setting/index.vue'; // 通知设置
import BulletinList from '@/pages/back-stage/notify-module/bulletin/BulletinList.vue'; //平台公告列表
import BulletinDetail from '@/pages/back-stage/notify-module/bulletin/BulletinDetail.vue' //平台公告详情

import OrderNotify from '@/pages/back-stage/notify-module/order-notify/index.vue' //交易通知
import SystemNotify from '@/pages/back-stage/notify-module/system-notify/index.vue' //系统通知
import NotifyDetails from "@/pages/back-stage/notify-module/notify-details/index.vue";//详情

const notifyRoutes = [
  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: false,
    },
    children: [
      // 通知模块的
      {
        path: 'notify',
        component: NotifyLayout,
        meta: {
          isAuth: false,
        },
        children: [
          {
            path: '',
            component: BulletinList,
            meta: {
              isAuth: false,
            },
          },
          // 通知设置
          {
            path: 'setting',
            name: 'NoticeSetting',
            component: NoticeSetting,
            meta: {
              isAuth: true,
            },
          },
          // 平台公告列表
          {
            path: 'bulletinlist',
            name: 'BulletinList',
            component: BulletinList,
            meta: {
              isAuth: false,
            },
          },
          // 平台公告详情
          {
            path: 'bulletindetail/:id',
            name: 'BulletinDetail',
            component: BulletinDetail,
            meta: {
              isAuth: false,
            },
          },
          // 交易通知
          {
            path: 'ordernotify',
            name: 'OrderNotify',
            component: OrderNotify,
            meta: {
              isAuth: false,
            },
          },
          // 系统通知
          {
            path: 'systemnotify',
            name: 'SystemNotify',
            component: SystemNotify,
            meta: {
              isAuth: false,
            },
          },
          // 通知详情
          {
            path: 'ordernotifydetails/:id',
            name: 'OrderNotifyDetails',
            component: NotifyDetails,
            meta: {
              isAuth: false,
            },
          },
           // 系统详情
          {
            path: 'systemnotifydetails/:id',
            name: 'SystemNotifyDetails',
            component: NotifyDetails,
            meta: {
              isAuth: false,
            },
          },
        ],
      },
    ],
  },
];

export default notifyRoutes;
