
// import Video from 'video.js';
// import 'video.js/dist/video-js.css';
export default {
  props: {
    showurl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      type: '1',
      officePrefix: 'https://view.officeapps.live.com/op/view.aspx?src=',
    };
  },
  mounted() {
    let length = this.showurl.split('.').length;
    let currenttype = this.showurl.split('.')[length - 1];
    if (currenttype == 'pdf') {
      this.type = '1';
    } else {
      this.type = '2';
    }
  },
  methods: {
    closeimg() {
      this.$emit('closepredox');
    },
  },
};
