
// 组件
import EditForm from './components/EditForm.vue';
import LookForm from './components/LookForm.vue';
import VerifyPhoneNum from '../components/verifyPhoneNum.vue';
// api
import {
  getUserDetailInfo,
  editUserInfo,
} from '@/api/client-api/account/information';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'Information',
  components: { EditForm, LookForm, VerifyPhoneNum },
  data() {
    return {
      pageType: '', // 页面状态 look查看/edit编辑

      // 个人/企业认证切换tab
      tabOptions: [
        { id: '1', name: '个人认证' },
        { id: '2', name: '企业认证' },
      ],
      activeTab: '1', // 默认选中个人认证
      activeTabShow: false, //  切换tab展示状态

      status: '', // 账号状态 0未认证 1一次审核 2已通过 3已驳回 5二次审核
      userInfo: {}, // 用户信息
      rejectReason: '', // 驳回原因

      isVerifyPhoneShow: true, // 验证手机号的弹窗
      verifyType: '1', // 验证手机号弹窗类型 1 跳转编辑页, 2 跳转升级页, 3 跳转升级但按钮文字不变

      successDialog: false, // 成功提交后倒计时弹窗
      countSecond: 3, // 倒计时秒
      timer: null, // 计时器
    };
  },
  async asyncData(app) {
    let activeTabShow = false; //  切换tab展示状态
    let isVerifyPhoneShow = true; // 验证手机号的弹窗状态
    try {
      // 编辑状态 && 未完善资料 && 不是升级 存在个人/企业切换tab
      if (
        app.route.params.type == 'edit' &&
        !app.route.params.need &&
        app.$cookies.get('statusShow') == '0'
      ) {
        activeTabShow = true;
      }
      // 关闭验证手机号弹窗 已验证 || 是查看详情
      if (app.$cookies.get('isVerify') || !app.route.params.type) {
        isVerifyPhoneShow = false;
      }
    } catch (err) {
      console.error(err);
    }
    return { activeTabShow, isVerifyPhoneShow };
  },
  watch: {
    '$route.query': function (oldRoute, newRoute) {
      if (oldRoute != newRoute) {
        this.$router.go(0);
      }
    },
  },
  mounted() {
    this.judgeRoute(); // 进入页面判断路由
  },
  beforeDestroy() {
    this.$cookies.remove('isVerify');
    localStorage.removeItem('isVerify');
    this.clearTimer(); //关闭定时器
  },
  computed: {
    // 页面标题
    pageTitleType() {
      if (this.$route?.params?.type == 'edit') return '编辑实名信息';
      return '用户实名信息';
    },
  },
  methods: {
    // 进入页面判断路由
    judgeRoute() {
      // type存在但不等于edit
      if (this.$route?.params?.type && this.$route?.params?.type != 'edit') {
        this.$router.push('/error');
        return;
      }
      if (!this.$route?.params?.type) {
        this.pageType = 'look';
      }
      if (this.$route?.params?.type == 'edit') {
        this.pageType = 'edit';
      }
      // 个人/企业tab存在条件:未完善资料&&不是点击升级
      if (
        this.$route?.params?.type == 'edit' &&
        !this.$route?.params?.need &&
        this.$cookies.get('statusShow') == '0'
      ) {
        this.activeTabShow = true;
      }
      // 是否是升级/只能企业认证
      if (this.$route?.params?.need == 'upgrade') {
        this.activeTab = '2';
        this.verifyType = '3'; // 3验证手机号弹窗验证通过后跳转升级页
      }
      // 手机号码验证弹窗默认打开 (在查看详情 || 已经验证过) 可关闭
      if (!this.$route.params.type || this.$cookies.get('isVerify')) {
        this.isVerifyPhoneShow = false;
      }
      this.getUserInfo(); // 获取认证信息
    },
    // 改变认证类型
    changeActiveTab(id) {
      if (this.activeTab == id) return;
      this.activeTab = id;
    },
    // 获取认证信息
    async getUserInfo() {
      try {
        const res = await getUserDetailInfo();
        if (res?.errcode == 0) {
          this.status = res?.data?.status; // 认证状态
          if (res?.data?.is_enterprise == '1') this.activeTab = '2'; // 1企业 -1个人
          this.rejectReason = res?.data?.reject_reason; // 驳回原因
          this.userInfo = {
            status: res?.data?.status?.toString(), // 帐号状态
            name: res?.data?.name || '', // 姓名
            phone: res?.data?.phone || '', // 手机号
            idcardType: res?.data?.id_card_type?.id?.toString() || '', // 身份证类型id
            idcard: res?.data?.idcard || '', // 身份证号码
            idcardTime:
              res?.data?.expire_type == '1' ? res?.data?.idcard_time : '', // 身份证有效截止日
            expireType: res?.data?.expire_type == '1' ? false : true, // // 身份证有效期类型 false短期1，true长期2
            // 身份证正面
            idcardPositiveImg: res?.data?.idcard_positive_img
              ? res?.data?.idcard_positive_img?.split(',')
              : [],
            // 身份证反面
            idcardBackImg: res?.data?.idcard_back_img
              ? res?.data?.idcard_back_img?.split(',')
              : [],
            // 营业执照
            licenseImg: res?.data?.license
              ? res?.data?.license?.split(',')
              : [],
          };
          // // 企业认证
          if (this.activeTab == '2') {
            let comUserInfo = {
              name: res?.data?.business_name || '', // 法人
              companyName: res?.data?.com_name || '', // 公司名称
              // 公司可选省市区
              selectAddress: {
                province: res?.data?.company_address?.[1], // 省
                province_id:
                  res?.data?.state_id == '0' ? '' : res?.data?.state_id || '', // 省id
                city: res?.data?.company_address?.[2], // 市
                city_id:
                  res?.data?.city_id == '0' ? '' : res?.data?.city_id || '', // 市id
                area: res?.data?.company_address?.[3], // 区
                area_id:
                  res?.data?.area_id == '0' ? '' : res?.data?.area_id || '', // 区id
              },
              detailAddress: res?.data?.detail_address || '', // 公司详细地址
              socialCode: res?.data?.social_code || '', // 社会信用代码
              // // 营业执照
              // licenseImg: res?.data?.license
              //   ? res?.data?.license?.split(',')
              //   : [],
              // 营业执照有效期
              licenseExpireTime:
                res?.data?.business_license_expire_type == '1'
                  ? res?.data?.business_license_expire_time
                  : '',
              // 营业执照有效期类型 false短期1，true长期2
              licenseExpireType:
                res?.data?.business_license_expire_type == '1' ? false : true,
            };
            this.userInfo = { ...this.userInfo, ...comUserInfo };
          }
          // 详情用
          if (this.pageType == 'look') {
            // 身份证有效期类型 false短期1，true长期2
            this.userInfo.idcardDate =
              res?.data?.expire_type == '1' ? res?.data?.idcard_time : '长期';
            // 经营地址
            this.userInfo.address = res?.data?.company_address?.join('');
            // 营业执照类型 false短期1，true长期2
            this.userInfo.licenseDate =
              res?.data?.business_license_expire_type == '1'
                ? res?.data?.business_license_expire_time
                : '长期';
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 提交认证信息
    async submitInfo(options) {
      try {
        const res = await editUserInfo({ ...options });
        if (res?.errcode == 0) {
          this.$cookies.set('statusShow', res?.data?.userInfo?.status, {
            maxAge: 60 * 60 * 24 * 30,
            path: '/',
          });
          this.$cookies.set('userdetail', JSON.stringify(res?.data?.userInfo), {
            maxAge: 60 * 60 * 24 * 30,
            path: '/',
          });
          this.$cookies.set(CUR_TOKEN_NAME, res?.data?.token, {
            maxAge: 60 * 60 * 24 * 30,
            path: '/',
          });
          // 打开成功弹窗,开始倒计时
          this.successDialog = true;
          this.countDown();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 点击升级按钮
    upgrade() {
      if (this.status == '1' || this.status == '4' || this.status == '5') {
        this.$message.warning('此认证状态下不可升级');
        return;
      }
      this.verifyType = '2'; // 此类型验证按钮文字改变,通过后跳转企业认证页面
      this.isVerifyPhoneShow = true; // 打开手机号验证弹窗
    },
    // 点× 关闭手机号验证弹窗
    closeVerify() {
      this.isVerifyPhoneShow = false;
      if (this.$route.params.type == 'edit') {
        this.$router.push('/account');
      }
    },
    // 成功验证信息后,关闭验证手机号弹窗
    closeVerifyPhone() {
      this.isVerifyPhoneShow = false;
    },
    // 倒计时
    countDown() {
      this.timer = setInterval(() => {
        //创建定时器
        if (this.countSecond == '1') {
          this.clearTimer(); //关闭定时器
          this.$router.push('/account');
        } else {
          this.countSecond--;
        }
      }, 1000);
    },
    //清除定时器
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 点击修改成功后弹窗关闭按钮
    alreadyBack() {
      this.$router.push('/account');
      this.clearTimer();
    },
  },
};
