
// 组件
import SearchBox from './components/SearchBox.vue';
import SummaryBox from './components/SummaryBox.vue';
import TableBox from './components/TableBox.vue';
// api
import {
  getWithdrawTotal,
  getWithdrawList,
} from '@/api/client-api/fund/withdraw';
export default {
  name: 'Withdraw',
  components: { SearchBox, SummaryBox, TableBox },
  data() {
    return {
      totalData: {}, // 汇总数据
      tableList: [], // 表格数据

      // 搜索参数
      searchOptions: {
        currency: '1',
      },
      // 分页相关
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
  },
  mounted() {
    this.getTotalData(); // 获取汇总板块数据
    this.getTableList(); // 获取列表数据
  },
  methods: {
    // 获取汇总板块数据
    async getTotalData() {
      try {
        let options = {
          ...this.searchOptions,
        };
        const res = await getWithdrawTotal(options);
        if (res?.errcode == 0) {
          this.totalData = res?.data || {};
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取列表数据
    async getTableList() {
      try {
        let options = {
          ...this.searchOptions,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        const res = await getWithdrawList(options);
        if (res?.errcode == 0) {
          this.tableList = res?.data?.list || [];
          this.total = Number(res?.data?.count) || 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 搜索列表数据
    searchList(options) {
      this.searchOptions = options;
      this.pageNum = 1;
      this.$refs.tableBox.resetSelectedList();
      this.getTotalData(); // 获取汇总板块数据
      this.getTableList(); // 获取列表数据
    },
    // 导出选中数据
    exportList(ids) {
      let options =
        'currency=' + (this.searchOptions?.currency || '1') + '&ids=' + ids;
      this.downloadFile(
        '/Withdraw/withdrawListExport?' + options,
        '提现记录列表.xlsx'
      );
      this.$refs.tableBox.resetSelectedList();
    },
    // 跳转页码
    goPages(n) {
      this.pageNum = Number(n) || 1;
      this.getTableList(); // 获取列表数据
    },
  },
};
