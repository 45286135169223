// 我的平台
import request from '@/utils/request.js';

//个人信息
export function getuserInfo(data) {
  return request({
    url: '/info/getUserBaseInfo',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

// 订单数据
export function getuseOrderInfo(data) {
  return request({
    url: 'info/userorderinfo',
    method: 'get',
    params:data
  });
}

//类目数据
export function getCategoryInfo(data) {
  return request({
    url: '/Category/getAllCategoryInfo',
    method: 'get',
    params:data
  });
}

// 用户榜单信息
export function getTopList(data) {
  return request({
    url: 'info/gettoplist',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

// 获取猜你喜欢商品列表
export function getGuessGoodsList(data) {
  return request({
    url: '/info/getGuessYouLikeItList',
    method: 'get',
    params: data,
  });
}
