// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default (app) => {
  let token = app.$cookies.get(CUR_TOKEN_NAME);
  let userdetail = app.$cookies.get('userdetail');
  let statusShow = app.$cookies.get('statusShow');
  app.store.commit('login/setToken', token);
  app.store.commit('login/setUserInfo', {
    key: 'userdetail',
    value: JSON.stringify(userdetail),
  });
  app.store.commit('login/setUserInfo', {
    key: 'statusShow',
    value: statusShow,
  });
};
