
import { getCouponsList } from '@/api/client-api/api.js';
export default {
  data() {
    return {
      radio: '',
      couponList: [],
      couponDonList: [],
    };
  },
  props: {
    // 非首件商品价格
    goodPrice: {
      type: [String, Number],
      default: '0',
    },
    // 首件商品价格（已处理过是否有优惠的逻辑）
    firstGoodsPrice: {
      type: [String, Number],
      default: 0,
    },
    goodNum: {
      type: Number,
      default: 0,
    },
    operationFee: {
      type: [String, Number],
      default: 0,
    },
    logisticsfees: {
      type: String,
      default: '0',
    },
  },
  computed: {
    goodAllPrice() {
      // 总价 = 首件价格 + 非首件价格*（商品数量-1）
      let totalPrice =
        parseFloat(this.firstGoodsPrice) +
        Math.max(Number(this.goodNum) - 1, 0) * parseFloat(this.goodPrice);
      return totalPrice;
    },
  },
  watch: {
    goodAllPrice: {
      handler() {
        if (this.couponList.length > 0) {
          this.couponDonList = this.couponList.filter((item) => {
            return Number(item.use_condition) <= Number(this.goodAllPrice);
          });
          if (this.couponDonList.length > 0) {
            this.radio = this.couponDonList[0].coupon_no;
            this.$emit(
              'getCurrentCou',
              this.radio,
              this.couponDonList[0].discount_content
            );
          } else {
            this.$emit('getCurrentCou', '', 0);
          }
        } else {
          this.$emit('getCurrentCou', '', 0);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getCouponsList();
  },
  methods: {
    getCouponsList() {
      let option = {
        item_id: this.$route.query.item_id,
      };
      getCouponsList(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.couponList = res.data.list.filter((item) => {
              let flag = false;
              if (
                item.start_time != -1 &&
                new Date(item.start_time).getTime() < Date.now() &&
                new Date(item.end_time) > Date.now()
              ) {
                flag = true;
              }
              return item.start_time == -1 || flag;
            });
            this.couponDonList = this.couponList.filter((item) => {
              return item.use_condition <= this.goodAllPrice;
            });
            if (this.couponDonList.length > 0) {
              this.radio = this.couponDonList[0].coupon_no;
              this.$emit(
                'getCurrentCou',
                this.radio,
                this.couponDonList[0].discount_content
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    couponClick(item) {
      this.radio = item.coupon_no === this.radio ? '' : item.coupon_no;
      let num = '';
      let arr = this.couponList.filter((item) => {
        return item.coupon_no == this.radio;
      });
      num = arr[0]?.discount_content;
      this.$emit('getCurrentCou', this.radio, num);
    },
  },
};
