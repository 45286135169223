
import OperationGroup from './OperationGroup';
import AppealForm from './AppealForm';
import OverrulForm from './OverrulForm';
export default {
  name: 'ReportTable',
  components: {
    OperationGroup,
    AppealForm,
    OverrulForm,
  },
  props: {
    // 表格配置
    tableFormat: {
      type: Array,
      default: () => [],
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // 数据总条数
    total: {
      type: Number,
      default: 0,
    },
    // 每页数据
    pageSize: {
      type: Number,
      default: 10,
    },
    // 页码
    pageNum: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      timers: null, // 定时器数组，每一行表格对应各自的定时器
      // 避免触发 watch 监听，所以用临时变量 countdownSeconds 保存倒计时的秒数
      countdownSeconds: null, // 倒计时数组，每一行表格对应各自的剩余时间
      currTableInfo: {}, //选中的列表信息

      // 弹框相关
      showAppealDialog: false, //控制申诉弹框
      showOverrulDialog: false, //控制驳回原因弹框
    };
  },
  filters: {
    // 空值处理
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  watch: {
    // 侦听父组件传过来的列表数据
    tableData: {
      deep: true,
      handler: function (newVal) {
        this.countdownSeconds = null;
        if (!this.timers) {
          this.timers = new Array(newVal?.length || 0);
        }
        // 初始化倒计时秒数数组
        // 仅在秒数数组从未初始化 且 表格有数据 时开启倒计时
        if (!this.countdownSeconds && newVal?.length > 0) {
          this.countdownSeconds = newVal?.map((x) => x.appeal_second);
          this.countdown(); // 开启倒计时
        } else {
          // 搜索后newVal.length可能等于0，需要清空之前有数据时开启的定时器，否则控制台报错
          if (this.timers) {
            this.timers.forEach((item) => {
              if (item) {
                clearInterval(item);
                item = null;
              }
            });
          }
          this.countdownSeconds = null;
        }
      },
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.timers) {
      this.timers.forEach((item) => {
        if (item) {
          clearInterval(item);
          item = null;
        }
      });
    }
    this.countdownSeconds = null;
  },
  methods: {
    /**
     * 工具函数
     */
    // 根据行数据过滤出对应的操作
    // 本来使用 filter，但 filter 无法获取 vue 实例，所以改用普通的方法
    /**
     * 申诉状态 0-未申诉 1-待申诉2.待审核3.已通过 4.已驳回 5-超时未申诉
     * 处罚状态 1-处罚中2-处罚结束3-处罚撒销
     */
    filterOps(row) {
      // 全状态都有详情
      const ops = [
        {
          label: '查看详情',
          event: 'detail',
        },
      ];
      let hasPendAppeal =
        row?.appeal_status == '1' || row?.appeal_status == '0'; // 是否处于待申诉状态或未申诉状态
      let hasOverruledAppeal = row?.appeal_status == '4'; //是否处于申诉驳回状态
      let hasPenaltyWithdrawn = row?.penalty_status == '3'; // 处罚是否撤销
      if (hasPendAppeal && !hasPenaltyWithdrawn) {
        // 申诉按钮的显示隐藏
        // 待申诉状态且处罚没有被撤销就可以申诉
        ops.push({ label: '申诉', event: 'appeal' });
      }
      if (hasOverruledAppeal) {
        // 查看驳回原因按钮
        ops.push({ label: '驳回原因', event: 'overrul' });
      }

      return ops;
    },
    // 倒计时
    countdown() {
      this.timers.forEach((item) => {
        if (item) {
          clearInterval(item);
          item = null;
        }
      });
      if (this.tableData && this.tableData.length > 0) {
        this.tableData.forEach((item, index) => {
          let handleTime = this.countdownSeconds[index] * 1000;
          const deadlineTime = this.getNowTimeDay(handleTime);
          this.$nextTick(() => {
            const countdownEl = document.getElementById(
              'appeal-countdown-timer-' + item.id
            );
            if (
              item?.appeal_status == '1' ||
              item?.appeal_status == '0' ||
              item?.appeal_status == '5'
            ) {
              if (countdownEl) {
                countdownEl.innerHTML = deadlineTime;
              }
            } else {
              if (countdownEl) {
                countdownEl.innerHTML = '<span>--</span>';
              }
            }
          });
        });
        this.tableData.forEach((item, index) => {
          if (
            item?.appeal_status == '1' ||
            item?.appeal_status == '0' ||
            item?.appeal_status == '5'
          ) {
            this.timers[index] = setInterval(() => {
              let handleTime = this.countdownSeconds[index] * 1000;
              this.countdownSeconds[index]--;

              // 解决表格单元格内倒计时不动问题
              // 强行用 getElementById 获取对应 DOM 元素，强行修改其 innerHTML
              const deadlineTime = this.getNowTimeDay(handleTime);
              this.$nextTick(() => {
                const countdownEl = document.getElementById(
                  'appeal-countdown-timer-' + item.id
                );
                if (countdownEl) {
                  countdownEl.innerHTML = deadlineTime;
                }
              });

              if (handleTime <= 0) {
                clearInterval(this.timers[index]);
                this.timers[index] = null;
                this.countdownSeconds[index] = null;
              }
            }, 1000);
          }
        });
      }
    },
    //获取当前时间并计算时间差(时分秒)
    getNowTimeDay(time) {
      var date3 = Number(time);
      if (date3 <= 0) {
        return '超时';
      } else {
        //计算出相差天数
        var days = Math.floor(date3 / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        let surplusTime =
          days + '天' + hours + '时' + minutes + '分' + seconds + '秒';
        return surplusTime;
      }
    },

    /**
     * 页面触发
     */
    // 点击分页按钮
    goPage(p) {
      this.$emit('change-page', Number(p));
    },
    // 点击"查看详情"
    handleClickDetail(row) {
      // this.$router.push('/complaints/illegaldetail/' + row.id);
      window.open(`/complaints/illegaldetail/${row.id}`);
    },
    // 点击”申诉“
    handleClickAppeal(row) {
      this.currTableInfo = row;
      this.showAppealDialog = true;
    },
    // 申诉弹框取消按钮
    handleCloseAppealDialog() {
      this.showAppealDialog = false;
      this.currTableInfo = {};
    },
    // 申诉弹框确认事件
    handleSubmitAppealDialog() {
      this.handleCloseAppealDialog();
      this.upData();
    },
    // 点击”驳回原因“
    handleClickOverrul(row) {
      this.currTableInfo = row;
      this.showOverrulDialog = true;
    },
    // 关闭驳回原因弹框
    handleCloseOverrulDialog() {
      this.showOverrulDialog = false;
      this.currTableInfo = {};
    },
    // 更新数据
    upData() {
      this.$emit('updata');
    },
  },
};
