
export default {
  name: 'XmExportButton',
  props: {
    customStyleName: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '154px',
    },
  },
  methods: {
    // 导出选中信息
    handleExportPart() {
      this.$emit('export-part');
    },
    // 导出全部信息
    handleExportAll() {
      this.$emit('export-all');
    },
  },
};
