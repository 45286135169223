
// 配置
import { STATUS_CONFIG, HANDLER_CONFIG } from '../js/status_config';
export default {
  name: 'ProcessStep',
  props: {
    // 当前纠纷状态
    disputeStatus: {
      type: String,
      default: '',
    },
    // 详情所需信息
    info: {
      type: Object,
      default: () => {},
    },
    // 用户物流信息
    userLogiInfo: {
      type: [Object, String],
      default: '',
    },
    // 处理归属 1分销商 2供应商 3平台 4分销商和供应商
    handler: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      jsq: '', // 计时器
      countdownNum: '', // 倒计时时间
    };
  },
  mounted() {},
  beforeDestroy() {
    if (this.jsq) {
      clearInterval(this.jsq);
      this.jsq = null;
    }
  },
  watch: {
    info: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.countdown(val);
        }
      },
    },
  },
  computed: {
    // 当前纠纷状态文字
    statusFont() {
      let currentStatus = STATUS_CONFIG?.find(
        (item) => item.id == this.disputeStatus
      );
      return currentStatus?.text || '--';
    },
    // 当前处理剩余提示label
    currentHandler() {
      let currentBelong = HANDLER_CONFIG?.find(
        (item) => item.id == this.handler
      );
      if (currentBelong?.text) {
        return currentBelong?.text + '处理';
      } else {
        return '方案响应';
      }
    },
    // 处理提示文字
    handleTipFont() {
      // 当前处理人
      let frontFont = '';
      let currentBelong = HANDLER_CONFIG?.find(
        (item) => item.id == this.handler
      );
      frontFont = currentBelong?.text || '';
      let belongFont = frontFont ? frontFont + '将在' : '您需在';

      // 处理提示文字
      let operationType;
      if (this.disputeStatus == '5') {
        operationType = '退款';
      } else if (this.disputeStatus == '3') {
        operationType = '退货发货';
      } else {
        operationType = '处理';
      }

      // 温馨提示文字
      let otherwise = '。';
      if (this.disputeStatus == '1' && this.handler == '1') {
        // 协商中
        otherwise = `，否则系统将会根据供应商提出的退款金额或退货请求执行。您可以通过
                      <span class="bold">“拒绝并新增方案”</span>、
                      <span class="bold">“同意”</span>供应商方案、
                      <span class="bold">“上传证据”</span>来处理纠纷。`;
      }
      // 使用后端拼接字段
      else if (this.info?.handleCue) {
        otherwise = '，' + this.info?.handleCue + '。';
      }

      // 拼接在一起 当前处理人+处理提示文字+倒计时+温馨提示文字
      let showFont = `${belongFont}<span class="red">${this.getNowTime(
        this.countdownNum
      )}</span>内${operationType}${otherwise}`;
      return showFont;
    },
  },
  methods: {
    // 倒计时
    countdown(info) {
      if (this.jsq) {
        clearInterval(this.jsq);
        this.jsq = null;
      }
      if (info?.handleDeadline) {
        this.jsq = setInterval(() => {
          let remainingTime =
            info.handleDeadline * 1000 - Date.parse(new Date());

          this.countdownNum = remainingTime > 0 ? remainingTime : 0;

          if (this.countdownNum === 0) {
            clearInterval(this.jsq);
            this.jsq = null;
          }
        }, 1000);
      }
    },
    //获取当前时间并计算时间差
    getNowTime(time) {
      if (time === '') {
        return '';
      }
      var date3 = time;
      if (date3 <= 0) {
        return '超时';
      } else {
        //计算出相差天数
        var days = Math.floor(date3 / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        hours = hours > 9 ? hours : '0' + hours;
        minutes = minutes > 9 ? minutes : '0' + minutes;
        seconds = seconds > 9 ? seconds : '0' + seconds;
        let surplusTime =
          days + '天' + hours + '时' + minutes + '分' + seconds + '秒';
        return surplusTime;
      }
    },
    // 点击了解处理流程
    learnProcess() {
      window.open('https://home.ximu.cn/platrule/46');
    },
    // 打开物流详情弹窗
    openlogiDetail() {
      this.$emit('look-logistics');
    },
  },
};
