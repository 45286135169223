
/**
 * 评论图片的轮播图组件
 * 1. 左右按钮切换展示大图
 * 2. 左上角提供旋转图片按钮（左转，右转）
 */
export default {
  name: 'EvaluationCarousel',
  props: {
    id: {
      type: String,
      default: '',
    },
    // 展示的图片列表
    imgs: {
      type: Array,
      default: () => [],
    },
    // 展示的视频列表（目前只有一个视频，设置为数组为了之后扩展）
    videos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currMediaIndex: 0, // 当前轮播图放大展示的图片索引
      currMediaUrl: '', // 当前轮播图放大展示的图片url
      currMediaType: '', // 当前轮播图放大展示的媒体元素类型
      currRotation: 0, // 当前图片旋转的角度
      rotateAngle: 90, // 旋转一次改变的角度

      showBigImg: false, // 是否展示大图
    };
  },
  computed: {
    /**
     * 图片视频展示数组
     * @return medias.previewUrl { String } 用于预览的图片地址（视频是第一帧地址）
     * @return medias.url { String } 用于大图/视频的地址
     * @return medias.type { String } 图片（image）or 视频（video）
     */
    medias() {
      let arr = [];
      for (let v of this.videos) {
        arr.push({
          type: 'video',
          url: v,
          previewUrl: this.getVideoPoster(v),
        });
      }
      for (let i of this.imgs) {
        arr.push({
          type: 'image',
          url: i,
          previewUrl: i,
        });
      }
      return arr;
    },
    videoId() {
      return `evaluation-${this.id}-${this.currMediaIndex}`;
    },
  },
  methods: {
    /**
     * 数据处理
     */
    /**
     * 变换大图dom元素的旋转角度
     * @param rotation 旋转角度
     */
    handleUpdateRotation(rotation) {
      if (this.currMediaType === 'image') {
        let imgDom = this.$refs.evaluationBigImage;
        if (imgDom) {
          imgDom.style.transform = `rotate(${rotation}deg)`;
        }
      }
    },

    /**
     * 页面事件
     */
    /**
     * 根据imgs数组索引更新当前展示的轮播图大图
     * @param index 将要展示的图片imgs[index]
     */
    handleChangeCurrImgByIndex(index) {
      this.currMediaIndex = index;
      this.currMediaUrl = this.medias?.[index]?.url;
      this.currMediaType = this.medias?.[index]?.type;
      this.currRotation = 0; // 旋转角度清零
      this.handleUpdateRotation(0); // 切换图片时，把dom元素的旋转角度清零
    },
    /**
     * 点击图片展示/关闭大图
     * @param url 大图的图片路径
     * @param index 大图的图片在 imgs 数组的索引
     */
    handleClickPreviewImg(item, index) {
      let url = item?.url;
      if (this.showBigImg && url != this.currMediaUrl) {
        // 当前有显示的大图并且新被点击的图与当前图片不一致，则切换显示的大图
        this.currMediaUrl = url;
        this.currMediaIndex = index;
        this.currMediaType = item?.type;
      } else {
        this.showBigImg = !this.showBigImg;
        this.currMediaUrl = this.showBigImg ? url : '';
        this.currMediaIndex = this.showBigImg ? index : -1;
        this.currMediaType = this.showBigImg ? item?.type : '';
      }
    },
    /**
     * 点击大图，关闭大图
     */
    handleClickBigImg() {
      this.showBigImg = false;
      this.currMediaUrl = '';
      this.currMediaIndex = -1;
      this.currMediaType = '';
    },
    /**
     * 旋转图片
     * @param dir 向左还是向右（`left` or `right`）
     */
    handleRotateImg(dir) {
      if (this.currMediaType === 'image') {
        let changeAngle =
          dir === 'left' ? -1 * this.rotateAngle : this.rotateAngle;
        this.currRotation = (this.currRotation + changeAngle) % 360; // 保证范围在 [-360, 360]
        this.handleUpdateRotation(this.currRotation);
      }
    },
    /**
     * 切换展示的图片
     * @param dir 上一张还是下一张（`prev` or `next`）
     */
    handleMoveImg(dir) {
      let limit = this.medias.length;
      let currIndex = this.currMediaIndex;
      // 计算移动后的下一张图片的 imgs 索引
      let nextIndex =
        dir == 'prev'
          ? (currIndex - 1 + limit) % limit
          : (currIndex + 1) % limit;
      this.handleChangeCurrImgByIndex(nextIndex);
    },
  },
};
