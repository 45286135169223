
export default {
  name: 'EditContent',
  props: {
    // 发票类型
    invoiceTypeOptions: {
      type: Array,
      default: () => [],
    },
    // 发票内容
    invoiceContentOptions: {
      type: Array,
      default: () => [],
    },
    // 该账号的发票设置信息
    invoiceMeaasge: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {}, // 表单绑定信息
      selectAreaLast: true, // 判断当前的地址选择是否在可选的最后一级
      areaSelectKey: 0, // 地区选择刷新
    };
  },
  filters: {
    // 判断是否有值
    emptyVal(val) {
      if (val && val != '') {
        return val;
      } else {
        return '--';
      }
    },
  },
  computed: {
    // 判断是不是专用发票,校验规则不一样
    isSpecialInvoice() {
      if (this.form.invoice_type == '1') return false;
      if (this.form.invoice_type == '2') return true;
    },
    // 校验规则
    rules() {
      // 自定义验证规则
      let checkSelectAddress = (rule, value, callback) => {
        console.log(value, 123123);
        if (value.province_id == '') {
          return callback(new Error('请选择收票人地址'));
        }
        if (!this.selectAreaLast) {
          return callback(new Error('请完整选择收票人地址'));
        }

        return callback();
      };
      let formRules = {
        invoice_type: [
          { required: true, message: '请选择发票类型', trigger: 'change' },
        ],
        invoice_content: [
          { required: true, message: '请选择发票内容', trigger: 'change' },
        ],
        invoice_header: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' },
        ],
        register_address: [
          {
            required: this.isSpecialInvoice,
            message: '请输入注册地址',
            trigger: 'blur',
          },
        ],
        register_phone: [
          {
            required: this.isSpecialInvoice,
            message: '请输入注册电话',
            trigger: 'blur',
          },
          {
            pattern: /^([1][3-9]\d{9}|0\d{2}-\d{8}|0\d{3}-\d{7})$/,
            message: '注册电话有误',
            trigger: 'blur',
          },
        ],
        register_bank: [
          {
            required: this.isSpecialInvoice,
            message: '请输入开户银行',
            trigger: 'blur',
          },
          {
            pattern: /^\s*([^\s].{0,28}[^\s]?)\s*$/,
            message: '请输入正确的开户银行',
            trigger: 'blur',
          },
        ],
        bank_card: [
          {
            required: this.isSpecialInvoice,
            message: '请输入银行账号',
            trigger: 'blur',
          },
          {
            pattern: /^\d{16,19}$/,
            message: '请输入正确的银行账号',
            trigger: 'blur',
          },
        ],
        consignee_name: [
          { required: true, message: '请输入收票人姓名', trigger: 'blur' },
          {
            pattern: /^\s*([^\s].{0,18}[^\s]?)\s*$/,
            message: '收票人姓名限制20字符以内',
            trigger: 'blur',
          },
        ],
        phone: [
          { required: true, message: '请输入收票人手机号', trigger: 'blur' },
          {
            pattern: /^[1][3-9]\d{9}$/,
            message: '收票人手机号有误',
            trigger: 'blur',
          },
        ],
        payee_address: [
          { required: true, message: '请选择收票人地址', trigger: 'change' },
          { validator: checkSelectAddress, trigger: ['change', 'blur'] },
        ],
        address_detail: [
          { required: true, message: '请输入收票人详细地址', trigger: 'blur' },
        ],
      };
      return formRules;
    },
  },
  watch: {
    invoiceMeaasge: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.selectAreaLast = true;
          this.areaSelectKey++; // 回显地址选择器需要
          this.form = JSON.parse(JSON.stringify(val));
        }
      },
    },
  },
  mounted() {},
  methods: {
    // 点击升级按钮
    upgrade() {
      this.$emit('click-upgrade');
    },
    // 地址选择器传出
    handleUpdateReturnAddr(areaData, isLast) {
      this.form.payee_address = JSON.parse(JSON.stringify(areaData));
      this.selectAreaLast = isLast;
    },
    // 点击保存按钮
    save() {
      this.$refs['invoiceForm'].validate((valid) => {
        if (valid) {
          let options = {};
          // 个人
          if (this.form.invoice_header_type == '1') {
            options.invoice_type = '1';
          }
          // 个人且未认证
          options.invoice_header = this.form.invoice_header;
          // 企业
          if (this.form.invoice_header_type == '2') {
            options.invoice_type = this.form.invoice_type;
          }
          // 企业且选择专用发票(不选也可以填)
          if (this.form.register_address && this.form.register_address != '') {
            options.register_address = this.form.register_address;
          }
          if (this.form.register_phone && this.form.register_phone != '') {
            options.register_phone = this.form.register_phone;
          }
          if (this.form.register_bank && this.form.register_bank != '') {
            options.register_bank = this.form.register_bank;
          }
          if (this.form.bank_card && this.form.bank_card != '') {
            options.bank_card = this.form.bank_card;
          }
          // 共有必填
          options.invoice_content = this.form.invoice_content; // 发票内容
          options.consignee_name = this.form.consignee_name; // 收票人姓名
          options.phone = this.form.phone; // 收票人手机号
          options.country = '44'; // 国
          options.province = this.form.payee_address.province_id; // 省
          options.city = this.form.payee_address.city_id; // 市
          if (
            this.form.payee_address.area_id &&
            this.form.payee_address.area_id !== ''
          ) {
            options.area = this.form.payee_address.area_id; // 区
          }
          options.detail_address = this.form.address_detail; // 详细地址
          this.$emit('save', options);
        } else {
          return false;
        }
      });
    },
    // 返回上一级
    goBack() {
      this.$emit('go-back');
    },
  },
};
