/**
 * 存储批量支付的订单id
 */
export const state = ()=>({
  choseOrdersId: [], // 批量支付的订单id
});
export const getters = {};
export const mutations = {
  /**
   * 导入批量支付的订单id
   */
  addBulkPaymentId(state, newVal) {
    state.choseOrdersId = newVal.split(',');
  },
  /**
   * 删除移出批量支付的订单id
   */
  delBulkPaymentId(state, newVal) {
    // let index = state.choseOrdersId.indexOf(newVal); // 获取要删除元素的索引
    // if (index !== -1) {
    //   state.choseOrdersId.splice(index, 1); // 从数组中删除指定元素
    // }
    state.choseOrdersId = state.choseOrdersId.filter(item => item !== newVal);
  },
};
export const actions = {};
export default {
  state,
  mutations,
  actions,
  getters,
};
