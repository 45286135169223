
import selectedCategory from './selectedCategory.vue'; //已经选择的类目
export default {
  components: { selectedCategory },
  data() {
    return {
      curCateId: '', //选定的ID
      afterCateList: [], //后面的列表
    };
  },
  props: {
    // 类目列表
    categoryList: {
      type: Array,
      default: () => [],
    },
    // 语言
    langConfig: {
      type: Object,
      default: () => {},
    },
    // 一级类目
    leaveOneInfo: {
      type: Object,
      default: null,
    },
    // 二级类目
    leaveTwoInfo: {
      type: Object,
      default: null,
    },
    // 当前二级类目所拥有的列表
    leaveTwoList: {
      type: Array,
      default: null,
    },
    // 三级类目
    leaveThreeInfo: {
      type: Object,
      default: null,
    },
    // 当前三级类目所拥有的列表
    leaveThreeList: {
      type: Array,
      default: null,
    },
  },
  methods: {
    // 页面触发
    // 鼠标进入对应类目
    enterMouse(item) {
      this.curCateId = item.supplier_class_id;
      this.afterCateList = item._child;
    },
    // 鼠标离开列表框
    outList() {
      this.afterCateList = [];
    },
    // 点击二级分类
    changgoTwo(res) {
      const id = [res.pid, res.supplier_class_id].join(',');
      this.openNewWindow('/list_' + id + '.html');
    },
    // 点击三级分类
    changge(obj, res) {
      const id = [
        this.curCateId,
        res.supplier_class_id,
        obj.supplier_class_id,
      ].join(',');
      this.openNewWindow('/list_' + id + '.html');
    },
    // 是否显示
    hasSelectedCategory() {
      if (
        this.leaveOneInfo ||
        this.leaveTwoInfo ||
        this.leaveThreeInfo ||
        this.leaveTwoList?.length > 0 ||
        this.leaveThreeList?.length > 0
      ) {
        return true;
      }
      return false;
    },
  },
};
