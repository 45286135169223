
import { GOODS_BOX } from '@/pages/shop-list/js/label_config_list_main.js';
import { mapGetters } from 'vuex';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'BasicGoodsBox',
  props: {
    // 当前信息的对象
    goodsMsg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lang: '', //语言
      token: '',
      checkGoods: [],
      collectFont: false,
    };
  },
  filters: {
    rateFilter(val) {
      if (!val) return '--';
      if (val == 0) {
        return val;
      }
      if (val && val != 0) {
        return val + '%';
      }
    },
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
    // 列表页公共多语言配置
    langConfig() {
      return GOODS_BOX[this.lang || 'zh-cn'];
    },
    // 图片懒加载
    imgURL() {
      if (this.goodsMsg.image_default_id) {
        return this.changeImgProperties(this.goodsMsg.image_default_id);
      }
    },
  },
  mounted() {
    this.lang = this.currLang;
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
  },
  methods: {
    // 点击收藏商品
    isCollectGoods(item) {
      const options = {
        item_id: item.item_id,
      };
      // 商品为已收藏状态
      if (item.is_fav == 1) {
        this.$delFav(options)
          .then((res) => {
            if (res?.errcode == 0) {
              item.is_fav = 0;
              this.collectFont = true;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      // 商品为未收藏状态
      else {
        this.$addFav(options)
          .then((res) => {
            if (res?.errcode == 0) {
              item.is_fav = 1;
              this.collectFont = true;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      setTimeout(() => {
        this.collectFont = false;
      }, 1000);
    },
    // 跳转
    goGoodsDetail(item) {
      // 跳转商品详情的公共函数
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img,
        '_self'
      );
    },
  },
};
