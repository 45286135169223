
/**
 * @author hukeyi
 * @example 示例见 admin 项目：src\pages\capital\capital-audit\index.vue
*  <xm-card-tabs
    v-model="activeBlock"
    :tabs="tabs"
    ></xm-card-tabs>
 */
export default {
  name: 'CardTabs',
  model: {
    prop: 'activeName',
    event: 'tab-click',
  },
  props: {
    activeName: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array,
      default: () => [
        // {
        //   name: '充值记录',
        //   key: 'recharge',
        // },
        // {
        //   name: '保证金扣除记录',
        //   key: 'reduce',
        // },
      ],
    },
  },
  methods: {
    handleClick(item) {
      this.$emit('tab-click', item.key);
    },
  },
};
