
export default {
  data() {
    return {};
  },
  props: {
    limtObj: {
      type: Object,
      default: () => {},
    },
    limitTip: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleColoe() {
      this.$emit('closeSupplierLimt');
    },
  },
};
