
export default {
  data() {
    return {
      options: {
        currency: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }, //数字格式化配置
      locale: 'zh-CN', //语言环境
    };
  },
  props: {
    title: {
      type: String,
      default: '收入金额',
    },
    // 提示
    content: {
      type: String,
      default: '',
    },
    // 单位
    unit: {
      type: String,
      default: '元',
    },
    // 数据
    dataInfo: {
      type: [String, Number],
      default: '0',
    },
    // 类型
    type: {
      type: String,
      default: 'normal',
    },
  },
  methods: {
    // 格式化title中的数字
    // 格式化展示数字
    formatConversion(num, type = '2') {
      if (!num) return '0';
      if (this.type == 'money') {
        const options = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        if (type == '1') {
          options.notation = 'compact';
        }
        let money = Number(num);
        return this.digitalFormat(money, 'zh-CN', options);
      }
      if (this.type == 'normal') {
        return Number(num);
      }
    },
  },
};
