/**
 * @description 似乎是项目遗留的无用老代码
 * #todo 之后看是否还需要，不需要就删了
 */
export const state = () => ({
    name: 'tom', //需要共用的数据
    age: '22',
    projectTitle: '细目商城',
    tipShow: false,
    authorizationFlagIndex: 1, //授权书页面标识
});
export const getters = {
  getName: state => state.name, //获取name
  getAge: state => state.age,
};
export const mutations = {
  setName: (state, data) => (state.name = data),
  setAge: (state, data) => (state.age = data),
  SET_PROJECTTITLE(state, data) {
    state.projectTitle = data;
  },
  SET_AUTHORIZATIONFLAG_INDEX: (state, data) => {
    state.authorizationFlagIndex = data;
    console.log('mutations----------', data);
  },
  SET_TIPSHOW: (state, data) => {
    state.tipShow = data;
  },
};
export const actions = {
  acSetName(context, name) {
    context.commit('setName', name);
  },
  acSetAge(context, age) {
    setTimeout(() => {
      context.commit('setAge', age);
    }, 1000);
  },
  setProjectTitle(context, title) {
    context.commit('SET_PROJECTTITLE', title);
  },
  setAuthorizationFlagIndex(context, index) {
    context.commit('SET_AUTHORIZATIONFLAG_INDEX', index);
  },
  settipShow(context, index) {
    context.commit('SET_TIPSHOW', index);
  },
};

