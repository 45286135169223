
import { filterEmptyKey } from '../../js/utils';
// 选项配置
import {
  commonClassOptions,
  launchClassOptions,
  commonProcessStatusOptions,
  launchKeywordOptions,
  receiveKeywordOptions,
  launchProcessResultOptions,
  receiveProcessResultOptions,
  commonAppealStatusOptions,
} from '../../js/report_search_config';
// 第一次投诉&举报
export default {
  name: 'ReportSearch',
  props: {
    // 当前激活的板块（所属于“投诉&举报列表”下的“发起”和“收到”板块）
    block: {
      type: String,
      default: '',
    },
    // 当前激活的主标签
    tab: {
      type: Number,
      default: '',
    },
  },
  data() {
    return {
      // 申诉状态
      appealStatusOptions: commonAppealStatusOptions,
      // 处理状态
      processStatusOptions: commonProcessStatusOptions,
      // 筛选内容
      searchForm: {
        cate_id: '', // 所属类别
        apply_status: '', // 申诉状态
        deal_status: '', // 处理状态
        is_violate: '', // 处理结果
        create_time: [], // 发起投诉时间
      },
      xmSelect: {}, //下拉加输入框
    };
  },
  computed: {
    // 关键词搜索选项
    keywordOptions() {
      return this.block === 'launch'
        ? launchKeywordOptions
        : receiveKeywordOptions;
    },
    // 处理结果选项
    processResultOptions() {
      return this.block === 'launch'
        ? launchProcessResultOptions
        : receiveProcessResultOptions;
    },
    // 所属分类
    classOptions() {
      return this.block === 'launch' ? launchClassOptions : commonClassOptions;
    },
  },
  methods: {
    // 关键字搜索输入框的值
    handleChangeKeyword(key, value) {
      this.xmSelect = {};
      this.xmSelect.key = key;
      this.xmSelect.value = value;
      this.handleChangeSearchForm();
    },
    // 搜索表单
    handleChangeSearchForm() {
      let formData = filterEmptyKey(Object.assign({}, this.searchForm));
      if (this.xmSelect.value && this.xmSelect?.value !== '') {
        formData[this.xmSelect.key] = this.xmSelect.value;
        formData.keyword = this.xmSelect.key;
        formData.value = this.xmSelect.value;
      }
      // 特殊处理时间字段
      if (formData?.create_time?.length > 0) {
        formData.start_time = formData.create_time[0];
        formData.end_time = formData.create_time[1];
        delete formData.create_time;
      }
      // 特殊处理发起处理结果
      if (this.block == 'launch') {
        // 投诉成立
        if (this.searchForm.is_violate == '1') {
          formData.is_establish = '1';
          formData.type = '1';
        }
        // 投诉不成立
        if (this.searchForm.is_violate == '2') {
          formData.is_establish = '2';
          formData.type = '1';
        }
        // 举报成立
        if (this.searchForm.is_violate == '3') {
          formData.is_establish = '1';
          formData.type = '2';
        }
        // 举报不成立
        if (this.searchForm.is_violate == '4') {
          formData.is_establish = '2';
          formData.type = '2';
        }
        delete formData['is_violate'];
      }
      this.$emit('search', formData);
    },
    // 点击重置按钮
    handleClickReset() {
      this.reset();
      // 重回第一页，重新获取列表
    },
    // 重置
    reset() {
      this.searchForm = {
        cate_id: '', // 所属类别
        apply_status: '', // 申诉状态
        deal_status: '', // 处理状态
        is_violate: '', // 处理结果
        create_time: [], // 发起投诉时间
      };
      this.xmSelect = {};
      this.$refs.XmSelectOption.reVal();
      this.handleChangeSearchForm();
    },
    // 回显搜索条件
    echoSearchData() {
      let searchInfo = this.getPageFilterData();
      // 下拉输入搜索
      if (searchInfo?.keyword) {
        this.xmSelect.key = searchInfo?.keyword;
        this.xmSelect.value = searchInfo?.value;
        this.$refs.XmSelectOption.reVal(searchInfo?.keyword, searchInfo?.value);
      }
      // 所属类别
      if (searchInfo?.cate_id) {
        this.searchForm.cate_id = searchInfo?.cate_id;
      }
      // 申诉状态
      if (searchInfo?.apply_status) {
        this.searchForm.apply_status = searchInfo.apply_status;
      }
      // 处理状态
      if (searchInfo?.deal_status) {
        this.searchForm.deal_status = searchInfo.deal_status;
      }
      // 时间回显
      if (searchInfo.start_time && searchInfo.end_time) {
        this.searchForm.create_time = [
          searchInfo.start_time,
          searchInfo.end_time,
        ];
      }
      // 处理结果 区分我收到的和我发起的 send_type：2表示我发起的
      if (searchInfo?.send_type == '2') {
        if (searchInfo?.is_establish == '1') {
          this.searchForm.is_violate = this.searchForm?.type == '1' ? '1' : '3';
        } else if (searchInfo?.is_establish == '2') {
          this.searchForm.is_violate = this.searchForm?.type == '1' ? '2' : '4';
        } else {
          this.searchForm.is_violate = '';
        }
      } else if (searchInfo?.is_violate) {
        this.searchForm.is_violate = searchInfo?.is_violate;
      }
    },
  },
  // beforeDestroy() {
  //   this.reset();
  // },
};
