// import request from '@/utils/request.js';
// /**
//  * POST 方法传参的字段是 'data'！不是 'params'!
//  */

export default ({ $axios }, inject) => {
  // 分销商和供应商统一登录接口(密码登录)
  inject('loginAll', (data) =>
    $axios({
      apiModule: 'user',
      url: '/login/login',
      method: 'post',
      data: data,
    })
  );
  // 分销商和供应商统一登录接口(验证码登录)
  inject('smsCodeLogin', (data) =>
    $axios({
      apiModule: 'user',
      url: '/login/smsCodeLogin',
      method: 'post',
      data: data,
    })
  );
  inject('getDistributorInfo', (data) =>
    $axios({
      url: '/adminUser/getDistributorInfo',
      method: 'get',
      params: data,
    })
  );
};

// //登录
// export function pwdLogin(data) {
//   return request({
//     url: '/login/pwdLogin',
//     method: 'post',
//     data: data,
//   });
// }

// // 分销商和供应商统一登录接口
// export function loginAll(data) {
//   return request({
//     url: '/login/login',
//     method: 'post',
//     data: data,
//   });
// }

// //验证码登录
// export function smsCodeLogin(data) {
//   return request({
//     url: '/login/smsCodeLogin',
//     method: 'post',
//     data: data,
//   });
// }

// //发送验证码
// export function sendSmsCode(data) {
//   return request({
//     url: '/sms/sendSmsCode',
//     method: 'get',
//     params: data,
//   });
// }

// //发送重置密码验证码
// export function sendRechargeSmsCode(data) {
//   return request({
//     url: '/Login/setRechargeCode',
//     method: 'post',
//     data: data,
//   });
// }

// //用户注册
// export function registerUser(data) {
//   return request({
//     url: '/User/registerUser',
//     method: 'get',
//     params: data,
//   });
// }
// // 分销商注册
// export function registerDistributor(data) {
//   return request({
//     url: '/Register/registerUser',
//     method: 'post',
//     data: data,
//   });
// }
// // 供应商注册
// export function registerSupplier(data) {
//   return request({
//     url: '/Register/supplierRegister',
//     method: 'post',
//     data: data,
//   });
// }

// // 验证原来手机号的验证码
// export function sendVerificationSms(data) {
//   return request({
//     url: '/sms/sendVerificationSms',
//     method: 'get',
//     params: data,
//   });
// }

// // 更换手机号验证
// export function editPhone(data) {
//   return request({
//     url: '/user/editPhone',
//     method: 'get',
//     params: data,
//   });
// }

// // 重置密码验证验证码
// export function resetPwCheck(data) {
//   return request({
//     url: '/Login/rechargePasswordCode',
//     method: 'post',
//     data: data,
//   });
// }
// // 忘记密码
// export function resetPassword(data) {
//   return request({
//     url: '/Login/rechargePassword',
//     method: 'post',
//     data: data,
//   });
// }

// // 埋点
// export function getPoint(data) {
//   return request({
//     url: '/BuryPoint/getPoint',
//     method: 'post',
//     data: data,
//   });
// }

// // 获取商品的基本信息（标题、图和价格）
// export function getItemBasicDetail(data) {
//   return request({
//     url: '/item/getItemBasicDetails',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取分销商信息
// export function getDistributorInfo() {
//   return request({
//     url: '/adminUser/getDistributorInfo',
//     method: 'get',
//   });
// }

// //获取用户数据
// // export function getUserInfo(params) {
// //   return request({
// //     url: baseURL + "/getUserInfo",
// //     method: "get",
// //     params:qs.stringfy(params)
// //   });
// // }

// //其实，也不一定就是params，也可以是 query 还有 data 的呀！
// //params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
// //而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。
