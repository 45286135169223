/**
 * vuex 公用的一些数据
 * @author hukeyi
 */

export const state = () => ({
  currLocation: null, // 网站顶部（<topheader>）选中的当前地址
  currLanguage: 'zh-cn', // 当前网站语言
});
export const getters = {
  /**
   * 获取地址选择器需要的地址数据对象 areaData
   * @param {*} state
   */
  currAreaData: (state) => {
    if (!state.currLocation) {
      return null;
    }

    let loc = state.currLocation;
    // 根据当前页面语言，返回对应语言的地址数据
    let areaData = {
      country: loc.country || '',
      country_id: loc.country_id,
      province: loc.province || '',
      province_id: loc.province_id,
      city: loc.city || '',
      city_id: loc.city_id,
      country_en: loc.country_en,
      province_en: loc.province_en,
      city_en: loc.city_en,
    };
    return areaData;
  },
  /**
   * 获取当前页面语言
   *
   * @param {*} state
   * @returns
   */
  currLang: (state) => {
    // return state.currLanguage || 'zh-cn';
    return 'zh-cn';
  },
  /**
   * 当前语言是否是中文
   * @param {*} state
   * @returns
   */
  isZH: (state) => {
    return !state.currLanguage || state.currLanguage === 'zh-cn';
  },
};
export const mutations = {
  /**
   * 设置当前地址
   * @param {*} state
   * @param {*} loc 地址对象
   */
  SET_CURR_LOCATION(state, loc) {
    if (loc) {
      state.currLocation = loc;
    }
  },

  /**
   * 设置当前语言
   * @param {*} state
   * @param {*} lang 当前语言
   */
  SET_CURR_LANG(state, lang) {
    state.currLanguage = 'zh-cn';
    this.$cookies.set('lang', state.currLanguage, { path: '/' });
  },
};
export const actions = {
  /**
   * 设置当前地址
   * @param {*} state
   * @param {*} data 地址对象
   */
  setCurrLocation({ commit }, data) {
    if (data) {
      commit('SET_CURR_LOCATION', data);
    }
  },
  /**
   * 设置当前语言
   * @param {*} state
   * @param {*} data 语言
   */
  setCurrLang({ commit }, data) {
    if (data) {
      commit('SET_CURR_LANG', data);
    }
  },
};
