
import { getProtocolData } from '@/api/client-api/login/api.js';
export default {
  data() {
    return {
      protocolContent: '',
      protocolId: this.$route.query.id,
    };
  },

  methods: {
    // 获取协议内容
    async getProtocolContent() {
      const options = {
        id: this.protocolId,
      };
      try {
        const res = await getProtocolData(options);
        if (res?.errcode == 0) {
          this.protocolContent = res.data?.[0]?.content;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.getProtocolContent();
  },
};
