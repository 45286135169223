
// import { getAllCategoryInfo } from '@/api/mall-home/api.js';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'GoodsSort',
  components: {},
  data() {
    return {
      token: '',
      // allCategoryTree: this.CategoryTree, // 所有类目
      afterCate: '', // 二，三级类目内容
      firstID: '', // 第一级类目ID
      secondID: '', // 第二级类目ID
      thirdID: '', // 第三级类目ID
    };
  },
  props: {
    CategoryTree: {
      type: Array,
      default: [],
    },
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 控制类目是否能使用
    isCategory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allCategoryTree() {
      return this.CategoryTree;
    },
  },
  mounted() {
    if (process.client) {
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
      // this.getAllSort();
      // this.getCategoryEnData();
    }
  },
  methods: {
    // 获取全部类目
    getAllSort() {
      let categoryData = this.$store.state.category.categoryData;
      if (categoryData) {
        if (categoryData?.length > 0 && this.lang == 'zh-cn') {
          this.allCategoryTree = categoryData;
          return;
        }
      }
      this.$getAllCategoryInfo()
        .then((res) => {
          if (res.errcode == 0) {
            if (this.lang == 'zh-cn') {
              this.allCategoryTree = res.data;
            }
            /**
             * 在商城首页获取类目数据
             * 将类目数据存在localStorage
             * 其余用到类目的地方直接从localStorage获取
             */
            this.$store.dispatch('category/setCategoryData', res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 英文类目存储
    getCategoryEnData() {
      let categoryEnData = this.$store.state.category.categoryEnData;
      if (categoryEnData?.length > 0 && this.lang == 'en') {
        this.allCategoryTree = categoryEnData;
        return;
      }
      this.$getAllCategoryInfo({ language: '3' })
        .then((res) => {
          if (res.errcode == 0) {
            if (this.lang == 'en') {
              this.allCategoryTree = res.data;
            }
            /**
             * 在商城首页获取类目数据
             * 将类目数据存在localStorage
             * 其余用到类目的地方直接从localStorage获取
             */
            this.$store.dispatch('category/setCategoryEnData', res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 悬浮一级类目
    enterFirst(item) {
      if (!this.isCategory) return;
      this.firstID = item.supplier_class_id;
      this.afterCate = item._child;
      this.afterCateView = true;
    },
    // 进入二级类目
    enterSecond(res) {
      this.secondID = res.supplier_class_id;
    },
    // 悬浮三级类目
    enterThird(item) {
      this.thirdID = item.supplier_class_id;
    },
    // 离开二级类目范围
    leaveSecondCate() {
      this.secondID = '';
    },
    // 离开三级类目
    leaveAfterCate() {
      this.thirdID = '';
    },
    // 离开类目盒子
    leaveCateBox() {
      this.firstID = '';
    },
    // 跳转类目商品页
    goCateGoods(flag) {
      let ids = [];
      switch (flag) {
        case 'first':
          ids = [this.firstID];
          break;
        case 'second':
          ids = [this.firstID, this.secondID];
          break;
        case 'third':
          ids = [this.firstID, this.secondID, this.thirdID];
          break;
      }
      //新页签打开
      // this.openNewWindow({
      //   path: '/goodsClassificat',
      //   query: { id: ids.toString() },
      // });
      this.openNewWindow('/list_' + ids.toString() + '.html');
    },
  },
};
