
export default {
  data() {
    return {};
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
