import request from '@/utils/request.js';

//获取人民币支付
export function getOrderPayment(data) {
  return request({
    url: '/order/getPaymentChannel',
    method: 'get',
    params: data,
  });
}

// 跳转支付
export function jumpPayment(data) {
  return request({
    url: '/order/jumpPayment',
    method: 'get',
    params: data,
  });
}

// 获取订单金额实时汇率
export function getRateAndPrice(data) {
  return request({
    url: '/order/getRateAndPrice',
    method: 'get',
    params: data,
  });
}
// 获取支付回调信息
export function getTradePayChannel(data) {
  return request({
    url: '/order/getTradePayChannel',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
//获取智能推荐（千人千面）商品列表
export function getRecommendItemList(data) {
  return request({
    url: '/Item/getRecommendItemList',
    method: 'get',
    apiModule: 'goods',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取微信支付状态
export function weiPayStatus(data) {
  return request({
    url: '/Order/weiPayStatus',
    method: 'get',
    params: data,
    controller: {
      closeLoading: true,
    },
  });
}
// 订单支付渠道
export function getPayDetail(data) {
  return request({
    url: '/order/orderPaymentChannel',
    method: 'get',
    params: data,
  });
}

// 线下银行卡转账 获取平台银行卡信息
export function getBankCard(data) {
  return request({
    url: '/shopTrade/getXimuBankCard',
    method: 'get',
    params: data,
  });
}

// 银行卡转账支付
export function bankcardPayment(data) {
  return request({
    url: '/shopTrade/uploadPaymentVoucher',
    method: 'post',
    data: data,
  });
}

// 查询下单是否享受补贴优惠
export function getPayHasSubsidy(data) {
  return request({
    url: '/ShopTrade/checkOrderSubsidy',
    method: 'get',
    params: data,
  });
}
