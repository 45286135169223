
import IllegalSearch from './components/IllegalSearch'; //搜索
import ViolationsNum from './components/ViolationsNum'; //违规次数
import ViolationsTable from './components/ViolationsTable'; //违规表格

import { violationsTableConfig } from './js/violations_table_config';
import { getViolationList } from '@/api/client-api/complaints/illegal.js';
export default {
  name: 'OverviewList',
  components: { IllegalSearch, ViolationsNum, ViolationsTable },
  data() {
    return {
      searchInfo: {}, //搜索传出的值
      // 表格相关
      currTableFormat: violationsTableConfig,
      tableData: [], //表格数据
      // 分页相关
      listTotal: 0, // 表格数据总数
      pageNum: 1, // 第几页
      pageSize: 10, // 页大小
    };
  },

  methods: {
    /**
     * 页面事件
     */
    // 搜索组件传出来的值
    searchData(data) {
      this.searchInfo = data;
      this.pageNum = 1;
      this.getList();
    },
    // 改变列表页码
    handleChangePage(num) {
      this.pageNum = num;
      this.getList();
    },
    // 获取列表
    getList(isCache = true) {
      const options = {
        ...this.searchInfo,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      // 存数据
      if (isCache) {
        let searthData = JSON.parse(JSON.stringify(options));
        delete searthData?.pageSize;
        this.setPageFilter(searthData);
      }
      const deleteKey = new Set(['time_key', 'timeData', 'value', 'key']);
      for (let i of deleteKey) {
        delete options?.[i];
      }
      getViolationList(options)
        .then((res) => {
          if (res) {
            if (res?.errcode == 0) {
              this.tableData = res?.data?.list || [];
              this.listTotal = Number(res?.data?.total || 0);
              // 处理当前页码过大的情况
              if (this.pageNum > 1 && this.tableData?.length == 0) {
                this.handleChangePage(1);
              }
            } else {
              this.tableData = [];
              this.listTotal = 0;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    // 获取vuex中的数据
    let searchInfo = this.getPageFilterData();
    if (searchInfo) {
      this.searchInfo = JSON.parse(JSON.stringify(searchInfo)); // 搜索条件
      this.pageNum = searchInfo?.pageNum || 1;
      delete this.searchInfo?.pageNum; // 删除this.searchData.pageNum 避免回显后切换页数数据被覆盖。
      this.$nextTick(() => {
        this.$refs.searchFrom.echoSearchData();
      });
    }
    this.getList(false);
  },
};
