
export default {
  name: 'NotCertified',
  props: {
    // 账号状态
    statusShow: {
      type: String,
      default: '',
    },
    isNdAudit: {
      type: Number,
      default: -1,
    },
    // 驳回原因
    refuseReason: {
      type: String,
      default: '',
    },
    // 驳回附件
    refuseAnnex: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      countSecond: 3, // 倒计的秒
      timer: null, // 计时器
    };
  },
  computed: {
    // 循环的驳回附件
    refuseAnnexList() {
      return this.refuseAnnex ? this.refuseAnnex?.split(',') : [];
    },
  },
  mounted() {
    this.countDown();
  },
  beforeDestroy() {
    this.clearTimer(); //关闭定时器
  },
  methods: {
    // 点击图片预览
    openPreview(item) {
      this.$emit('open-preview', item);
    },
    // 倒计时
    countDown() {
      if (this.statusShow == '3') return;
      this.timer = setInterval(() => {
        //创建定时器
        if (this.countSecond === 1) {
          this.clearTimer(); //关闭定时器
          this.sureStatus(); // 倒计时结束自动跳转
        } else {
          this.countSecond--;
        }
      }, 1000);
    },
    clearTimer() {
      //清除定时器
      clearInterval(this.timer);
      this.timer = null;
    },
    // 跳转
    sureStatus() {
      this.$emit('close-certified');
    },
  },
};
