
const tabName = {
  ALREADY: '1', //已收藏
  BEENACHANGE: '2', //有变动
  PREREMOVEDFROMSHELVES: '3', //预下架
  BEENREMOVED: '4', //已下架
  RECYCLEBIN: '5', //回收站
  BLACKLIST: '6', //黑名单
};
const secondTabName = {
  SPLITUPSKU: '1', //分仓SKU
  SPLITUPSPU: '2', //分仓SPU
  CLOSESKU: '3', //合仓SKU
  CLOSESPU: '4', //合仓SPU
};
import {
  getUserFavItemList,
  deleteFav,
  getModifyList,
  getWareArrivalList,
  blockFav,
  pullOutBlacklist,
  recycleFav,
  recoverFav,
  getUserFavItemCount,
} from '@/api/client-api/goods/GoodsAttention.js';
import AltentionTab from './components/AltentionTab.vue'; //标签
import SearchBox from './components/SearchBox'; //搜索
import GoodsTable from './components/GoodsTable.vue'; //表格组件
import GoodsInfo from './components/GoodsInfo'; //表格组件
import GoodsBox from './components/GoodsBox.vue'; //块状

import splitupSkuHeadList from './js/splitupSku'; // 分仓SKU表格配置信息
import splitupSkuMoreField from './js/splitupSkuMoreField'; // 分仓SKU显示更多字段

import splitupSpuHeadList from './js/splitupSpu'; // 分仓SPU表格配置信息
import splitupSpuMoreField from './js/splitupSpuMoreField'; // 分仓SPU显示更多字段

import closeSkuHeadList from './js/closeSku'; // 合仓SKU表格配置信息

import closeSpuHeadList from './js/closeSpu'; // 合仓SPU表格配置信息
import closeSpuMoreField from './js/closeSpuMoreField'; // 合仓SPU显示更多字段

// 黑名单
import blackList from './js/black'; // 黑名单表格配置
import PopoverComponents from './components/PopoverComponents';
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  components: {
    AltentionTab,
    SearchBox,
    GoodsTable,
    GoodsInfo,
    GoodsBox,
    PopoverComponents,
  },
  name: 'GoodsAttention',
  data() {
    return {
      lang: '', // 当前页面的语言
      // 标签
      tableFiled: [],
      activeName: '1', //一级标签
      secondTab: '4', //二级标签 1:分sku 2:分spu 3:合sku 4:合spu 初次进页面:4123
      tabNum: {
        already: 0, //已收藏
        offShelves: 0, //已下架
        issued: 0, //已出单
        recyclebin: 0, //回收站
        onChange: 0, //有变动
        blacklist: 0, //黑名单
        shelves: 0, //预下架
      }, //标签页数据
      searchObj: {}, //接收搜索组件传出来的数据
      tabName,
      secondTabName,
      downloadType: '', // 需要下载的文件类型
      searchID: '', // 需要搜索的商品ID(spu标签点击的id)

      // 表格数据
      headList: closeSpuHeadList, //表格配置 初始展示合仓SPU
      goodsList: [], //商品信息
      moreField: closeSpuMoreField, //显示更多字段 初始展示合仓SPU
      pageSize: 10,
      pages: 1,
      total: 0,
      isRadio: true, //控制是否展示价格重积比

      // 到仓数量
      wareInfo: false, //控制仓库信息弹窗
      wareData: [], //仓库信息列表
      wareItem_id: '',
      wareSku_id: '',
      waretotal: 0,
      warepageSize: 10,
      warepageNum: 1,

      //拉黑
      blockShow: false,
      blockDesc: '', //拉黑原因
      itemId: '',
      //修改记录
      editLogShow: false,
      editLogData: [],

      //排序规则
      sortStr: '',
      sortDesc: 0, // -1 升序 1 降序
      option: false, //更多字段
      tableShow: true, //显示模式 表格展示
      goodsShow: false, //显示模式 块状展示
      textInput: '请先选择',
      popoverShow: false, //下载资料弹框显隐
      priceShow: false, //价格小弹框
      informationShow: false, //下载弹框
      checkTableIdArr: [], //选中的ID
      chAndEnInfoList: [], //资料-中文/英文
      priceInfoList: [], //最低零售价
      infoList: [], //选中的列表
      checkAllId: [], //将选中的拼好的id放入数组
      checkAllSkuId: [], //将选中的拼好的id放入数组
      checkAllItemId: [], //将选中的拼好的id放入数组

      tableDown: [], //下载类型
      showCompareCol: false,
      checkBoxList: [], // 选中商品对比的列表

      currSku: {}, //选中的对象

      currentAction: false, // 当前展示块状状态 true可操作  false不可操作
    };
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
    currentActionFont() {
      if (this.currentAction) {
        return '取消操作';
      } else return '批量操作';
    },
  },
  filters: {
    getVal(val) {
      if (val == 0) {
        return '0';
      }
      if (!val) {
        return '--';
      }
      if (val) {
        return val;
      }
    },
    changeTimeFormat(time) {
      var date = new Date(time * 1000);
      var month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var currentDate =
        date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      // var hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      // var mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return date.getFullYear() + '-' + month + '-' + currentDate;
      //返回格式：yyyy-MM-dd hh:mm
    },
  },
  watch: {
    sortStr(n, o) {
      if (n != o && o != '') {
        this.sortDesc = 1;
      }
    },
  },
  activated() {},
  beforeDestroy() {
    if (process.client) {
      document.removeEventListener('click', this.bodyCloseMenus);
    }
  },

  mounted() {
    // console.log(this.$route.query.type,123);
    if (this.$route.query.type) {
      this.activeName = this.$route.query.type;
    }
    this.searchList();
    if (process.client) {
      document.addEventListener('click', this.bodyCloseMenus);
    }
    this.lang = this.currLang; // 获取当前页面语言
  },
  methods: {
    // 切换标签页触发事件
    handleClick(activeName, secondTabs) {
      this.currentAction = false; // 不可操作块状商品板块
      this.goodsList = [];
      this.activeName = activeName; //一级标签
      this.secondTab = secondTabs; //二级标签
      this.tableFiled = [];
      this.sortStr = '';
      this.sortDesc = 0;
      this.itemListData = [];
      this.itemListDataSort = [];
      this.infoList = []; //选中的列表
      this.checkAllId = []; //选中的allid
      this.checkTableIdArr = []; //选中的ID
      this.chAndEnInfoList = []; //选中的资料

      this.priceInfoList = []; //选中的最低零售价
      this.checkAllSkuId = []; //存储选中的skuid
      this.checkAllItemId = []; //存储选中的itemid
      this.total = 0;
      this.$nextTick(() => {
        this.changTableCofig(activeName, secondTabs);
      });

      this.getSearchList();
    },
    // 更新配置方法
    changTableCofig(first, secon) {
      switch (first) {
        // 已收藏 1
        case this.tabName.ALREADY:
          // 分SKU
          if (secon == this.secondTabName.SPLITUPSKU) {
            this.headList = splitupSkuHeadList;
            this.moreField = splitupSkuMoreField;
            this.isRadio = true;
          }
          // 分SPU
          if (secon == this.secondTabName.SPLITUPSPU) {
            this.headList = splitupSpuHeadList;
            this.moreField = splitupSpuMoreField;
            this.isRadio = false;
          }
          // 合SKU
          if (secon == this.secondTabName.CLOSESKU) {
            this.headList = closeSkuHeadList;
            this.moreField = [];
            this.isRadio = false;
          }
          // 合SPU
          if (secon == this.secondTabName.CLOSESPU) {
            this.headList = closeSpuHeadList;
            this.moreField = closeSpuMoreField;
            this.isRadio = false;
          }
          break;
        // 有变动 2
        case this.tabName.BEENACHANGE:
          this.headList = closeSpuHeadList;
          this.moreField = closeSpuMoreField;
          this.isRadio = false;
          break;
        // 预下架 3
        case this.tabName.PREREMOVEDFROMSHELVES:
          this.headList = splitupSkuHeadList;
          this.moreField = splitupSkuMoreField;
          this.isRadio = true;
          break;

        // 已下架 4
        case this.tabName.BEENREMOVED:
          // 分SKU
          if (secon == this.secondTabName.SPLITUPSKU) {
            this.headList = splitupSkuHeadList;
            this.moreField = splitupSkuMoreField;
            this.isRadio = true;
          }
          // 分SPU
          if (secon == this.secondTabName.SPLITUPSPU) {
            this.headList = splitupSpuHeadList;
            this.moreField = splitupSpuMoreField;
            this.isRadio = false;
          }
          // 合仓SKU
          if (secon == this.secondTabName.CLOSESKU) {
            this.headList = closeSkuHeadList;
            this.moreField = [];
            this.isRadio = false;
          }
          // 合仓SPU
          if (secon == this.secondTabName.CLOSESPU) {
            this.headList = closeSpuHeadList;
            this.moreField = closeSpuMoreField;
            this.isRadio = false;
          }
          break;
        // 回收站 5
        case this.tabName.RECYCLEBIN:
          this.headList = closeSpuHeadList;
          this.moreField = closeSpuMoreField;
          this.isRadio = false;
          break;
        // 黑名单 6
        case this.tabName.BLACKLIST:
          this.headList = blackList;
          this.moreField = [];
          this.isRadio = false;
          break;
        default:
          break;
      }
      if (this.$refs.GoodsTable) {
        this.$refs.GoodsTable.reSelectData();
      }
    },
    // 搜索组件传出来的值
    searchData(obj) {
      this.pages = 1;
      if (obj) {
        this.searchObj = obj;
        // 重置筛选
        if (this.searchObj.refilter) {
          this.sortDesc = 0;
          this.sortStr = '';
        }
      }
      this.searchList();
    },
    // 每次搜索都重置分页
    getSearchList() {
      this.pages = 1;
      if (this.$refs.pagTools) {
        this.$refs.pagTools.changecurPage(this.pages);
      }
      this.searchList();
    },
    // 获取tabs数据
    async getTabsData() {
      try {
        let res = await getUserFavItemCount();
        if (res?.errcode == 0) {
          this.tabNum = {
            already: res?.data?.count1 || 0, //已收藏
            // offShelves: res.data?.count2 || 0, //已下架
            // issued: res.data?.count3 || 0, //已出单
            recyclebin: res?.data?.count4 || 0, //回收站
            onChange: res?.data?.count5 || 0, //有变动
            blacklist: res?.data?.count6 || 0, //黑名单
            shelves: res?.data?.count7 || 0, //预下架
          };
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 获取列表数据
    async searchList() {
      this.getTabsData();
      try {
        let parme = {
          pageNum: this.pages,
          pageSize: this.pageSize,
          tab: parseInt(this.activeName), //标签页
          // item_id:searchObj.itemId,
        };
        // 二级标签
        if (
          this.activeName == this.tabName.ALREADY ||
          this.activeName == this.tabName.BEENREMOVED
        ) {
          parme.show_type = parseInt(this.secondTab);
        }
        // 类目搜索
        if (
          this.searchObj?.categoryVal &&
          this.searchObj.categoryVal.length > 0
        ) {
          parme.cat_id = this.searchObj.categoryVal[2];
          parme.cat_1 = this.searchObj.categoryVal[0];
          parme.cat_2 = this.searchObj.categoryVal[1];
        }

        // 下拉框搜索
        if (this.searchObj?.value) {
          parme[this.searchObj.key] = this.searchObj.value;
        }

        //表格筛选
        if (this.sortDesc == 1) {
          parme[this.sortStr] = 'asc';
        } else if (this.sortDesc == -1) {
          parme[this.sortStr] = 'desc';
        }
        if (this.timeShow != '') {
          parme.shelf_time = this.timeShow;
        }

        // 价格筛选
        if (
          this.searchObj.priceMin == '' &&
          typeof this.searchObj.priceMin == 'string' &&
          this.searchObj.priceMax
        ) {
          parme.price_min = '0';
          parme.price_max = this.searchObj.priceMax;
        } else if (
          this.searchObj.priceMin != '' &&
          typeof this.searchObj.priceMin == 'string'
        ) {
          parme.price_min = this.searchObj.priceMin;
          parme.price_max = this.searchObj.priceMax;
        }
        // 库存状态筛选
        if (this.searchObj?.inventoryId) {
          parme.store_status = this.searchObj.inventoryId;
        }

        // 获取收藏商品列表
        const res = await getUserFavItemList(parme);
        let that = this;
        if (res?.errcode == 0) {
          if (res?.data) {
            that.total = Number(res?.data?.count) || 0;
            that.itemListDataSort = res?.data?.list;
            // 拿标签页的数据
            // that.tabNum = {
            //   already: res.data?.count1 || 0, //已收藏
            //   offShelves: res.data?.count2 || 0, //已下架
            //   issued: res.data?.count3 || 0, //已出单
            //   recyclebin: res.data?.count4 || 0, //回收站
            //   onChange: res.data?.count5 || 0, //有变动
            //   blacklist: res.data?.count6 || 0, //黑名单
            //   shelves: res.data?.count7 || 0, //预下架
            // };
          } else {
            that.total = 0;
          }
          // that.itemListData = getFirstAvailableValue(
          //   ObjectValue('data.list', res),
          //   []
          // );
          let list = [];
          if (res?.data) {
            console.log(1);

            list = JSON.parse(JSON.stringify(res?.data?.list));
            list.forEach((item, index) => {
              item.allId =
                (item.sku_id || '0') +
                (item.ware_id || '0') +
                item.item_id +
                index;
            });
          }
          that.itemListData = list;
          that.goodsList = list;
          if (this.pages > 1 && that.goodsList?.length == 0) {
            this.pages--;
            this.goPage(this.pages, this.pageSize);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 点击合仓spu的列表商品id跳转分仓sku标签
    jumpSkuTab(item_id) {
      this.handleClick('1', '1'); // 跳转分仓sku标签
      this.searchID = item_id; // 传去搜索栏

      this.$nextTick(() => {
        this.searchID = ''; // 置空搜索栏
      });
    },
    // 关闭仓库到货信息弹窗
    closeWare() {
      this.wareInfo = false;
      this.wareData = [];
      this.wareItem_id = '';
      this.wareSku_id = '';
    },

    // 关闭
    // 控制显示隐藏批量操作按钮
    /**
     * type
     * 1-发布 2-对比 3-查看关联信息 4-查看修改记录 5-移除收藏
     * 6-下载资料 7-加入黑名单 8-下载库存资料 9-下载最低零售价资料 10-3合1下载
     *
     * tabName:
     * ALREADY 已收藏  BEENACHANGE 有变动  PREREMOVEDFROMSHELVES 预下架
     * BEENREMOVED 已下架  RECYCLEBIN 回收站  BLACKLIST 黑名单
     *
     * secondTabName:
     * SPLITUPSKU 分仓SKU  SPLITUPSPU 分仓SPU
     * CLOSESKU 合仓SKU  CLOSESPU 合仓SPU
     */
    hasShow(type) {
      if (
        this.tabName.BLACKLIST == this.activeName ||
        this.tabName.RECYCLEBIN == this.activeName
      ) {
        return false;
      }
      switch (type) {
        case '1':
          return false;
          if (
            this.activeName == this.tabName.ALREADY ||
            this.activeName == this.tabName.BEENACHANGE
          ) {
            return true;
          }
          break;
        case '2':
          if (
            this.secondTabName.CLOSESPU == this.secondTab ||
            this.secondTabName.SPLITUPSPU == this.secondTab ||
            this.secondTabName.CLOSESKU == this.secondTab
          ) {
            return false;
          }
          if (
            this.tabName.BEENACHANGE == this.activeName ||
            this.tabName.RECYCLEBIN == this.activeName ||
            this.tabName.BLACKLIST == this.activeName
          ) {
            return false;
          }
          if (
            this.activeName == this.tabName.BEENREMOVED &&
            this.secondTabName.CLOSESKU == this.secondTab
          ) {
            return false;
          }
          return true;
        case '3':
          if (
            (this.secondTab == this.secondTabName.CLOSESKU ||
              this.secondTab == this.secondTabName.SPLITUPSPU) &&
            this.activeName == this.tabName.ALREADY
          ) {
            return true;
          }
          break;
        case '4':
          if (this.activeName == this.tabName.BEENACHANGE) {
            return false;
          }
          if (this.secondTab != this.secondTabName.CLOSESPU) {
            return true;
          }
          break;
        case '5':
          return true;
        case '6':
          return false;
          // if (this.activeName == this.tabName.BEENACHANGE) {
          //   return true;
          // }
          break;
        case '7':
          if (
            this.secondTab == this.secondTabName.CLOSESPU ||
            this.activeName == this.tabName.BEENACHANGE
          ) {
            return true;
          }
          break;
        case '8':
          return false;
          // if (this.activeName == this.tabName.BEENACHANGE) {
          //   return true;
          // }
          break;
        case '9':
          return false;
          // if (this.activeName == this.tabName.BEENACHANGE) {
          //   return true;
          // }
          break;
        case '10':
          if (
            this.activeName == this.tabName.ALREADY ||
            this.activeName == this.tabName.BEENACHANGE
          ) {
            return true;
          }
          break;
        default:
          break;
      }
    },
    // 打开到仓信息弹窗
    openWareInfo(item) {
      this.wareInfo = true;
      const options = {
        item_id: item.item_id,
        sku_id: item.sku_id,
        pageNum: this.warepageNum,
        pageSize: this.warepageSize,
        MUTE_WARNING: 1,
      };
      this.wareItem_id = item.item_id;
      this.wareSku_id = item.sku_id;
      getWareArrivalList(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.wareData = res?.data?.list || [];
            this.waretotal = Number(res?.data?.count) || 0;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    warepagechange(index) {
      this.warepageNum = Number(index);
      const options = {
        item_id: this.wareItem_id,
        sku_id: this.wareSku_id,
      };
      this.openWareInfo(options);
    },
    // 控制商品对比栏的显隐
    showGoodCompare() {
      this.showCompareCol = !this.showCompareCol;
    },
    // 点击清空对比列表
    handleClickClear() {
      this.checkBoxList = [];
      this.showCompareCol = false;
    },
    // 商品对比列表删除的时候传出来的值
    deleteList(skuId) {
      let idx = this.checkBoxList.findIndex((item) => item === skuId);
      if (idx > -1) {
        this.checkBoxList.splice(idx, 1);
      }
      if (this.checkBoxList && this.checkBoxList.length === 0) {
        this.showCompareCol = false;
      }
    },
    /**
     * 复选进商品对比
     * @param { Boolean } checkbox 是否被选中
     * @param {Object} item 选中的数据对象
     */
    handleClickCompare(checked, item) {
      this.showCompareCol = true;
      const currSku = {
        sku_id: item.sku_id,
        item_id: item.item_id,
        title: item.title,
        price: item.price,
        imgUrl: item.sku_image || item.image_default_id, // 首选sku图片，若无，则用商品主图
        mainImgUrl: item.image_default_id,
      };
      const currSkuId = item.sku_id;
      this.$nextTick(() => {
        if (!checked) {
          // 取消选中，删除
          this.$refs.XmSkuCompareColumn?.deleteSku(currSkuId);
        } else {
          // 选中，检查商品对比栏是否已满
          if (this.$store.getters.isCompareListFull) {
            this.deleteList(currSkuId); // 从 checkBoxList 中删除
            this.$refs.XmSkuCompareColumn?.showTip(
              '对比栏已满，最多选择4款商品进行对比哦！'
            );
          } else {
            this.$refs.XmSkuCompareColumn?.insertSku(currSku);
          }
        }
      });
    },

    //跳转到详情页面
    comDetails(item) {
      if (!item?.item_id) return;
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img,
        '_blank',
        item.publish_language_id
      );
      // window.location.href=getProjectModuleUrl('pcshop')+'/goods_detail/index.html?itemId='+item.item_id;
    },

    // 选中/取消表格
    handleSelectionChange(val) {
      // this.infoList = []; //选中的列表
      this.checkAllId = []; //选中的allid
      this.checkTableIdArr = []; //选中的ID
      this.chAndEnInfoList = []; //选中的资料
      this.priceInfoList = []; //选中的最低零售价
      this.checkAllSkuId = []; //存储选中的skuid
      this.checkAllItemId = []; //存储选中的itemid

      let that = this;
      console.log(val, 'valvalval');

      let checkBox = '';
      let idx = '';
      // 判断是否选中
      checkBox = that.infoList.find((item) => item.allId === val.allId);
      /**
       * 使用这个方式比较有个注意点，对象中的属性，序列要一致
       */
      idx = that.infoList.findIndex((item) => val.allId === item.allId);
      // 未选中
      if (!checkBox) {
        that.infoList.push(val);
      } else {
        if (idx !== -1) {
          that.infoList.splice(idx, 1);
        }
      }

      if (that.infoList.length > 0) {
        that.infoList.forEach((item) => {
          that.checkAllId.push(item.allId);

          if (item.sku_id) {
            this.checkAllSkuId.push(item.sku_id);
            this.checkAllItemId.push(item.item_id);
          }
          // if (!this.checkAllSkuId.includes(item.sku_id)) {

          // }
          that.checkTableIdArr.push(item.item_id);
          if (item?.cn_resource) {
            // 中文资料
            const options = {
              url: item.cn_resource,
              type: 'zh-cn',
            };
            that.chAndEnInfoList.push(options);
          }
          // 英文资料
          if (item?.en_resource) {
            const options = {
              url: item.en_resource,
              type: 'en',
            };
            that.chAndEnInfoList.push(options);
          }
          // 最低零售价资料
          if (typeof item.retail_price_url == 'string') {
            item.retail_price_url = JSON.parse(item.retail_price_url);
          }
          if (item.retail_price_url && item.retail_price_url.length != '0') {
            that.priceInfoList = [...item.retail_price_url];
          }
        });
        // this.infoList = val;
      }
    },

    // 分页
    goPage(pages, pageSize) {
      this.pages = pages;
      if (pageSize) {
        this.pageSize = pageSize;
      }
      this.searchList();
    },
    // 加入黑名单点击取消按钮
    blockAfter(val = '') {
      this.blockShow = false;
      this.blockDesc = '';
      this.itemId = '';
    },

    //修改记录
    async editLogBefore() {
      try {
        const options = {
          item_id: this.checkTableIdArr.toString(','),
          MUTE_WARNING: 1,
        };
        if (
          this.checkTableIdArr.length == 0 ||
          typeof this.checkTableIdArr == Array
        ) {
          this.$message.warning('请选择数据');
          return;
        }
        if (
          this.checkTableIdArr.length > 1 ||
          typeof this.checkTableIdArr == Array
        ) {
          this.$message.warning('只能选一个数据');
          return;
        }
        this.editLogShow = true;
        const res = await getModifyList(options);
        if (res?.errcode == 0) {
          // console.log(res, 123);
          this.editLogData = res?.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
    closeChange() {
      this.editLogShow = false;
    },
    //修改记录
    editLogBefore1(item) {
      const options = {
        item_id: item.item_id,
        MUTE_WARNING: 1,
      };
      this.editLogShow = true;
      getModifyList(options)
        .then((res) => {
          if (res?.errcode == 0) {
            // console.log(res, 123);
            this.editLogData = res?.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    editLogAfter() {
      this.editLogShow = false;
      this.editLogData = [];
    },
    // 移出收藏
    async removeFocus(item) {
      try {
        let parme = {
          item_id: this.checkTableIdArr.toString(','),
        };
        if (item) {
          parme.item_id = item.item_id;
        } else {
          if (
            this.checkTableIdArr.length == 0 ||
            typeof this.checkTableIdArr == Array
          ) {
            this.$message.warning('请选择数据');
            return;
          }
        }
        const res = await recycleFav(parme);
        if (res?.errcode == 0) {
          if (res?.msg) {
            this.$message.success(res?.msg);
          }
          this.resetStorageList(); // 重置存储的数据

          // if (!item) {
          //   this.checkTableIdArr = [];
          // }
          // if (this.$refs.GoodsTable) {
          //   this.$refs.GoodsTable.reSelectData();
          // }
          this.searchList();
        }
      } catch (err) {
        console.error(err);
      }
    },
    //移出拉黑
    async cancelBlock(item) {
      try {
        let parme = {
          item_id: this.checkTableIdArr.toString(','),
        };
        if (item) {
          parme.item_id = item.item_id;
        } else {
          if (
            this.checkTableIdArr.length == 0 ||
            typeof this.checkTableIdArr == Array
          ) {
            this.$message.warning('请选择数据');
            return;
          }
        }
        const res = await pullOutBlacklist(parme);
        if (res?.errcode == 0) {
          if (res?.msg) {
            this.$message.success(res?.msg);
          }
          this.resetStorageList(); // 重置存储的数据
          // if (!item) {
          //   this.checkTableIdArr = [];
          // }
          // if (this.$refs.GoodsTable) {
          //   this.$refs.GoodsTable.reSelectData();
          // }
          this.searchList();
        }
        this.checkTableIdArr = [];
      } catch (err) {
        console.error(err);
      }
    },

    // 批量拉黑
    addBlack() {
      if (this.checkTableIdArr.length <= 0) {
        this.$message.warning('请选择数据');
        return;
      }
      this.blockShow = !this.blockShow;
    },
    // 拉黑弹框确认按钮
    saveBlockData() {
      const options = {
        item_id: this.checkTableIdArr.join(','),
        black_reason: this.blockDesc,
      };
      blockFav(options)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }

            this.resetStorageList(); // 重置存储的数据
            // this.checkTableIdArr = [];
            // if (this.$refs.GoodsTable) {
            //   this.$refs.GoodsTable.reSelectData();
            // }
            this.searchList();
            this.blockAfter(); // 拉黑弹框点击取消按钮的操作
            // this.blockShow = !this.blockShow;
            // this.blockDesc = '';
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    //删除
    async delItem(item) {
      try {
        let parme = {
          item_id: this.checkTableIdArr.toString(','),
          MUTE_WARNING: 1,
        };
        if (item) {
          parme.item_id = item.item_id;
        } else {
          if (
            this.checkTableIdArr.length == 0 ||
            typeof this.checkTableIdArr == Array
          ) {
            this.$message.warning('请选择数据');
            return;
          }
        }
        const res = await deleteFav(parme);
        if (res?.errcode == 0) {
          this.$message.success('操作成功');
          this.resetStorageList(); // 重置存储的数据
          // if (!item) {
          //   this.checkTableIdArr = [];
          // }
          // if (this.$refs.GoodsTable) {
          //   this.$refs.GoodsTable.reSelectData();
          // }
          this.searchList();
        }
      } catch (err) {
        console.error(err);
      }
    },
    //根据排序
    sortListBefore(sortStr = '') {
      /**
       * 价格是范围区间不可排序
       * 分仓sku才能进行价格排序
       * 已收藏的分仓sku和预下架可以排序
       */
      if (sortStr == 'price' && this.secondTab != 1 && this.activeName != 3) {
        return;
      }
      this.sortStr = sortStr;
      if (this.sortStr != '') {
        if (this.sortDesc != 1) {
          this.sortDesc = 1;
        } else {
          this.sortDesc = -1;
        }
      } else {
        this.sortDesc = 0;
      }
      this.getSearchList();
    },
    // 切换展示模式
    chenkGoods(type) {
      if (this[type] == true) {
        return;
      }
      this.goodsShow = !this.goodsShow;
      this.tableShow = !this.tableShow;
      if (this.tableShow) {
        this.pageSize = 10;
      }
      if (this.goodsShow) {
        if (this.infoList.length > 0) {
          this.currentAction = true;
        } else this.currentAction = false;

        this.pageSize = 12;
      }
      this.pages = 1;
      this.searchList();
    },
    // 下载资料确认按钮
    informationBtn() {
      if (this.checkAllId.length == 0) {
        this.$message.warning('请选择数据');
        return;
      }

      // 资料下载
      if (this.checkAllId?.length > 0 && this.chAndEnInfoList?.length == 0) {
        this.$message.warning('所选数据里面没有资料');
        return;
      } else if (
        this.checkAllId.length > 0 &&
        this.chAndEnInfoList?.length != this.checkAllId?.length
      ) {
        this.$message.warning('所选数据里面存在没有资料的');
        return;
      }
      this.chAndEnInfoList.forEach((item) => {
        if (item?.type == 'zh-cn') {
          this.down(item.url, '中文资料');
        }
        if (item?.type == 'en') {
          this.down(item.url, '英文资料');
        }
      });
    },
    downBtn() {
      this.informationShow = !this.informationShow;
    },
    // 下载库存资料
    updata() {
      try {
        if (this.checkTableIdArr.length == 0) {
          this.$message.warning('请选择数据');
          return;
        }
        this.downloadFile(
          '/item/exportInventoryResource?item_id=' +
            this.checkTableIdArr.join(','),
          '库存资料.xlsx'
        );
      } catch (err) {
        console.error(err);
      }
    },
    // 下载最低零售价资料
    downPrice() {
      if (this.checkAllId.length == 0) {
        this.$message.warning('请选择数据');
        return;
      }
      if (this.checkAllId.length > 0 && this.priceInfoList.length == 0) {
        this.$message.warning('所选数据里面没有最低零售价');
        return;
      }
      this.priceInfoList.forEach((item) => {
        this.down(item, '最低零售价');
      });
    },
    // 下拉选择下载
    downloadTypeChange() {},
    // 去对比页
    goUrl() {
      if (this.checkTableIdArr.length > 4) {
        this.$message.warning('不能超过四个');
        return;
      }
      if (this.checkTableIdArr.length == 0) {
        this.$message.warning('请选择数据');
        return;
      }
      // console.log(this.checkAllSkuId, this.checkAllItemId);

      var parme = {
        item_id: this.checkAllItemId.toString(','),
        sku_id: this.checkAllSkuId.toString(','),
      };
      this.$router.push({
        path: '/goods/contrast',
        query: parme,
      });
    },

    // 恢复收藏按钮事件(批量)
    async resumeFocusAll(item) {
      try {
        let parme = {
          item_id: this.checkTableIdArr.toString(','),
          MUTE_WARNING: 1,
        };

        if (item) {
          parme.item_id = item.item_id;
        } else {
          if (
            this.checkTableIdArr.length == 0 ||
            typeof this.checkTableIdArr == Array
          ) {
            this.$message.warning('请选择数据');
            return;
          }
        }
        const res = await recoverFav(parme);
        if (res?.errcode == 0) {
          this.$message.success('操作成功');
          this.resetStorageList(); // 重置存储的数据
          // if (!item) {
          //   this.checkTableIdArr = [];
          // }
          // if (this.$refs.GoodsTable) {
          //   this.$refs.GoodsTable.reSelectData();
          // }
          this.searchList();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 点击空白关闭下载弹框
    bodyCloseMenus(e) {
      const self = this;
      if (
        this.$refs.informationRef &&
        !this.$refs.informationRef.contains(e.target)
      ) {
        if (self.informationShow === true) {
          self.informationShow = false;
        }
      }
    },
    // 块状展示点击操作按钮
    clickBlockAction() {
      if (this.goodsList?.length > 0) {
        this.currentAction = !this.currentAction;
      } else {
        this.currentAction = false;
      }
      this.resetStorageList(); // 重置存储的数据
    },
    // 重置存储的数据
    resetStorageList() {
      this.infoList = []; //选中的列表
      this.checkAllId = []; //选中的allid
      this.checkTableIdArr = []; //选中的ID
      this.chAndEnInfoList = []; //选中的资料
      this.priceInfoList = []; //选中的最低零售价
      this.checkAllSkuId = []; //存储选中的skuid
      this.checkAllItemId = []; //存储选中的itemid
    },
  },
};
