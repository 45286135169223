
export default {
  name: 'OrderInfo',
  props: {
    // 当前纠纷状态
    disputeStatus: {
      type: String,
      default: '',
    },
    // 币种
    currency: {
      type: String,
      default: '',
    },
    // 订单&商品信息
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    // 跳转订单详情
    lookOrderDetail() {
      if (this.info.tid)
        window.open('/trade/orderdetails?item_id=' + this.info.tid);
    },
    /**
     * 获取规格值字符串
     * @param String specInfo 规格信息对象JSON字符串
     */
    getSpecificationDescription(specInfo) {
      if (specInfo) {
        const specObj = JSON.parse(specInfo);
        let specDesc = '';
        if (specObj) {
          specDesc = specObj.map((x) => x.value).join('，');
        }
        return specDesc;
      }
    },
  },
};
