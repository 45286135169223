
import {
  submitComplaint,
  getReportReasonList,
} from '@/api/client-api/complaints/complaints.js';
import { COMPLAINT_SCENARIO } from '@/pages/back-stage/complaints-module/complaints-report/js/complaint_type.js'; //发起场景
export default {
  data() {
    // 自定义校验规则
    let checkCheckList = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('举报理由不能为空哦'));
      }
      if (value) {
        if (value.length == 0) {
          return callback(new Error('举报理由不能为空哦'));
        } else if (value.length > 0) {
          callback();
        }
      }
    };
    return {
      COMPLAINT_SCENARIO,
      // 弹框的值
      ruleForm: {
        checkList: [], //选中的举报理由
        file: '', //上传的文件
      },
      rules: {
        checkList: [{ validator: checkCheckList, trigger: 'change' }],
      },
      // 举报理由
      reportList: [],
      // 限制上传
      fileType: ['jpg', 'jpeg', 'png'],
    };
  },
  props: {
    // 场景
    scene: {
      type: [Number, String],
      default: 1,
    },
    // 所属分类
    cate_id: {
      type: [Number, String],
      default: '',
    },
    // 评价数据
    reportObj: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.getReportReason();
  },
  methods: {
    // 获取举报理由列表
    getReportReason() {
      const options = {};

      getReportReasonList(options)
        .then((res) => {
          if (res) {
            if (res?.errcode == 0) {
              this.reportList = res?.data?.list || [];
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 上传资料事件
    uploadFile(list) {
      this.ruleForm.file = list;
    },
    // 弹窗确认按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 校验成功走这
          this.lodgeComplaint();
        } else {
          // 校验失败走这
          return false;
        }
      });
    },
    // 举报
    async lodgeComplaint() {
      try {
        const options = {
          scene: this.scene,
          report_reason: this.ruleForm.checkList?.join(','),
          cate_id: this.cate_id,
        };
        if (this.ruleForm?.file && this.ruleForm.file?.length > 0) {
          options.attachment = this.ruleForm.file.join(',');
        }
        // 评价
        if (
          this.scene == this.COMPLAINT_SCENARIO.GOODS_DETAIL_EVALUATION ||
          this.scene == this.COMPLAINT_SCENARIO.ORDER_EVALUATION
        ) {
          options.title = '评价举报';
          options.data_id = this.reportObj.id;
          options.type = '2';
        }
        let res = await submitComplaint(options);
        if (res) {
          if (res?.errcode == 0) {
            this.$emit('submitForm');
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 取消按钮事件
    cancelBtn() {
      this.$emit('cancelBtn');
    },
  },
};
