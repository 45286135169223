/**
 * 下单支付等模块
 * - 下单页面
 */

import GoodsPay from '@/pages/goods-pay/placeOrder/pay.vue'; // 商品下单页面
import PayChose from '@/pages/goods-pay/PayChose.vue'; // 支付选择页面
import recharge from '@/pages/goods-pay/recharge/recharge.vue'; // 充值页面
import disburse from '@/pages/goods-pay/disburse/index.vue'; // 欠费页面
import PaySuccess from '@/pages/goods-pay/PaySuccess.vue'; // 支付成功

const payRoutes = [
  // 下单页
  {
    path: '/pay',
    name: 'GoodsPay',
    component: GoodsPay,
    meta: {
      isAuth: true,
    },
  },
  // 支付选择页面
  {
    path: '/paychose',
    name: 'PayChose',
    component: PayChose,
    meta: {
      isAuth: true,
    },
  },
  /**
   * 充值页面，额外增加路由
   */
  {
    path: '/recharge/:type/:currency/:id?',
    name: 'recharge',
    component: recharge,
    meta: {
      isAuth: true,
    },
  },

  /**
   * 支出页面，目前只有欠费支付
   */
  {
    path: '/disburse/:type/:currency/:id?',
    name: 'disburse',
    component: disburse,
    meta: {
      isAuth: true,
    },
  },
  /**
   * 支付成功
   */
  {
    path: '/paysuccess',
    name: 'PaySuccess',
    component: PaySuccess,
    meta: {
      isAuth: true,
    },
  },
];

export default payRoutes;
