
export default {
  data() {
    return {
      brandIDList: [], //品牌被选Id列表
      curGoodProperty: this.goodProperty, //当前类别列表
    };
  },
  props: {
    // 语言
    langConfig: {
      type: Object,
      default: () => {},
    },
    // 类别列表(品牌、属性等)
    goodProperty: {
      type: Array,
      default: () => [
        //   // {
        //   //   title: '品牌',
        //   //   is_radio: true,
        //   //   is_ext: false,
        //   //   radio: [],
        //   //   checkbox: [],
        //   //   children:[
        //   //     {brand_name:'的客户发的课时费',brand_id:'1'},
        //   //     {brand_name:'的客户发的课时费',brand_id:'2'},
        //   //     {brand_name:'的客户发的课时费',brand_id:'3'},
        //   //     {brand_name:'的客户发的课时费',brand_id:'4'},
        //   //     {brand_name:'的客户发的课时费',brand_id:'5'},
        //   //     {brand_name:'的客户发的课时费',brand_id:'6'},
        //   //   ],
        //   // }
      ],
    },
    // 关键字
    keyword: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (process.client) {
      this.judgmentDisplayMore();
    }
  },
  methods: {
    // 页面触发
    // 是否展示展开按钮
    judgmentDisplayMore() {
      let arr = JSON.parse(JSON.stringify(this.goodProperty || [])) || [];
      for (let i = 0; i < arr?.length; i++) {
        const dom =
          document.getElementsByClassName('btn-box-' + i)?.[0] || null;
        // 一行的高度是50  大于50就可以出现展开了
        if (dom.offsetHeight > 50) {
          arr[i].isMore = true;
        }
      }
      this.curGoodProperty = arr;
    },
    // 点击单品牌跳转
    brandGo(item) {
      switch (this.keyword) {
        case '':
          this.openNewWindow(
            '/brand_' + encodeURIComponent(item.brand_id) + '.html'
          );
          break;
        default:
          this.openNewWindow(
            '/keywords=' +
              encodeURIComponent(this.keyword) +
              '/' +
              item.brand_id
          );
          break;
      }
    },
    // 品牌/属性多选确认按钮
    configBtn(itemList) {
      this.brandIDList = [];
      itemList.forEach((item) => {
        this.brandIDList.push(item.brand_id);
      });
      if (this.keyword) {
        window.open(
          '/keywords=' +
            encodeURIComponent(this.keyword) +
            '/' +
            this.brandIDList.join(',')
        );
      } else {
        window.open('/brand_' + this.brandIDList.join(',') + '.html');
      }
    },
    // 多选处理函数
    cancelCheckboxHandler(item) {
      this.$set(item, 'checkbox', []);
      this.$set(item, 'is_radio', true);
    },
  },
};
