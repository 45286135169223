
export default {
  name: 'ChallengeDetailBox',
  props: {
    // 挑战详细信息
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    // 点击挑战板块
    clickChallenge() {
      this.$emit(
        'change-selected',
        this.detail?.chal_id,
        this.detail?.selected
      );
    },
  },
};
