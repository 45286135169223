
// import Video from 'video.js';
// import 'video.js/dist/lang/zh-CN'
// import 'video.js/dist/video-js.css';
export default {
  name: 'previewimg',
  props: {
    showurl: {
      type: String,
      default: '',
    },
    // 是否可以旋转
    isRote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      player: null, // 用来存储当前 video
      currenttype: '', //
      degNum: 0, // 旋转角度
    };
  },
  computed: {
    cssVars() {
      return {
        '--degNum': this.degNum + 'deg',
      };
    },
  },
  mounted() {
    // this.player = videojs(this.$refs.video);
    // var options = {
    //   muted: true,
    //   controls : true,
    //   height:300,
    //   width:300,
    //   loop : true,
    // };

    // this.player  = Video('example_video_1', options, function onPlayerReady() {

    // });
    let length = this.showurl.split('.').length;
    if (this.showurl.split('.')[length - 1] == 'mp4') {
      this.currenttype = 1;
      this.initVideo();
    } else {
      this.currenttype = 2;
    }
  },
  beforeDestroy() {
    if (this.currenttype == 1) {
      this.player.dispose();
    }
  },
  methods: {
    closeimg() {
      this.$emit('closepreimg');
    },
    initVideo() {
      this.player = videojs('myVideo', {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: 'auto',
        //设置视频播放器的显示宽度（以像素为单位）
        width: '500px',
        //设置视频播放器的显示高度（以像素为单位）
        height: '500px',
      });
    },
    // 点击旋转90°
    rotateLeft() {
      this.degNum = this.degNum - 90;
    },
  },
};
