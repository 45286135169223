/**
 * 爆单挑战接口
 */
import request from '@/utils/request.js';

// 商城首页--首页弹窗弹出条件
export function showLargePopUpWindow(data) {
  return request({
    url: '/explosiveOrder/showLargePopUpWindow',
    method: 'get',
    params: data,
  });
}

// 商城首页--爆单挑战弹窗内容
export function getExplosiveContent(data) {
  return request({
    url: '/explosiveOrder/getExplosiveContent',
    method: 'get',
    params: data,
  });
}

// 商城首页--关闭小弹窗
export function closeSmallPopUpWindow(data) {
  return request({
    url: '/explosiveOrder/closeSmallPopUpWindow',
    method: 'get',
    params: data,
  });
}

// 商城首页--放弃挑战
export function abandonChallenge(data) {
  return request({
    url: '/explosiveOrder/abandonChallenge',
    method: 'post',
    data: data,
  });
}

// 商城首页/营销活动--参加挑战
export function takeChallenge(data) {
  return request({
    url: '/explosiveOrder/takeChallenge',
    method: 'post',
    data: data,
  });
}

// 营销活动--挑战列表tab面板数据
export function getPanelData(data) {
  return request({
    url: '/explosiveOrder/getPanelData',
    method: 'get',
    params: data,
  });
}

// 营销活动--挑战列表
export function getExplosiveOrderList(data) {
  return request({
    url: '/explosiveOrder/getExplosiveOrderList',
    method: 'get',
    params: data,
  });
}

// 营销活动--结束挑战
export function endChallenge(data) {
  return request({
    url: '/explosiveOrder/endChallenge',
    method: 'post',
    data: data,
  });
}
