
import RegisterProtocol from '@/pages/protocol/registerProtocol.vue';
import shopSettledProtocol from '@/pages/protocol/shopSettledProtocol.vue';
import supplierSettledProtocol from '@/pages/protocol/supplierSettledProtocol.vue';
import privacyProtocol from '@/pages/protocol/privacyProtocol.vue';
import privacySupplierProtocol from '@/pages/protocol/privacySupplierProtocol.vue';

export default {
  components: {
    RegisterProtocol,
    shopSettledProtocol,
    supplierSettledProtocol,
    privacyProtocol,
    privacySupplierProtocol,
  },
  props: {
    type: {
      type: String,
      default: 'shop',
    },
  },
  data() {
    return {
      activeNames: [],
    };
  },
  methods: {
    changeCollapse(idx) {
      let dom = document.getElementsByClassName('box-content');
      dom[0].scrollTop = 240 + 48 * (idx - 1);
    },
  },
};
