const splitupSkuMoreField = [
  {
    text: '承诺发货天数',
    fieladList: { filed: 'logistics_days', text: '承诺发货天数', width: '92' },
  },
  {
    text: '销售限制',
    fieladList: {
      filed: 'sales_restrictions',
      text: '销售限制',
      width: '140',
      isEdit: true,
    },
  },
  {
    text: '是否需要授权',
    fieladList: {
      filed: 'is_accept',
      text: '是否需要授权',
      width: '92',
      isEdit: true,
    },
  },
  {
    text: '样品政策',
    fieladList: {
      filed: 'sample_policy',
      text: '样品政策',
      width: '116',
      isEdit: true,
    },
  },
  {
    text: '接受预测/锁货',
    fieladList: {
      filed: 'is_accept_predict',
      text: '接受预测/锁货',
      width: '98',
      isEdit: true,
    },
  },
  {
    text: '税率',
    fieladList: {
      filed: 'import_tariff',
      text: '税率',
      width: '64',
    },
  },
];
export default splitupSkuMoreField;
