
import {
  getCountries,
  getStates,
  getCity,
  getRegions,
} from '@/api/client-api/api.js';
export default {
  // inject: [
  //   'country',
  //   'country_id',

  //   'province',
  //   'province_id',
  //   'city',
  //   'city_id',
  // ],
  //isEnglish标识需要返回英文名称
  props: [
    'areaData',
    'continentId',
    'langType',
    'placeholderKey',
    'isEnglish',
    'en_areaData',
    'multipleFlag',
    'wareId',
    'wareType',
    'crossType',
    'eu',
    'countryId',
  ],

  data() {
    return {
      showDropdown: false,
      tabName: '',
      initialActive: '', // 搜索出来的符合条件的首字母
      searchTarget: '', // 用户搜索框的输入
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标

      country_list: [],
      province_list: [],
      city_list: [],
      areaList: [], // 区数据
      /**
       * 需要传出的数据：
       */
      addressData: {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
        area: '',
        area_id: '',
      },
      en_addressData: {
        en_country: '',
        en_province: '',
        en_city: '',
        en_area: '',
      },
      haveAreas: '1', // 标记所选国家是否有区
      itemWidth: 208, // 宽度
    };
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      const addr = `${this.addressData.country}${
        this.addressData.country && this.addressData.country.length > 0
          ? '/'
          : ''
      }${this.addressData.province}${
        this.addressData.city && this.addressData.city.length > 0 ? '/' : ''
      }${this.addressData.city}${
        this.addressData.area && this.addressData.area.length > 0 ? '/' : ''
      }${this.addressData.area}`;
      return addr;
    },
    cssWidth() {
      return {
        '--itemWidth': this.itemWidth + 'px',
      };
    },
  },
  methods: {
    //点击x清空选择地址
    clearAddressFunc() {
      Object.keys(this.addressData).forEach((item) => {
        this.addressData[item] = '';
      });
      if (this.isEnglish) {
        Object.keys(this.en_addressData).forEach((item) => {
          this.en_addressData[item] = '';
        });
      }
      if (
        this.langType == 'en' &&
        ((this.wareType == 2 && this.crossType == 2) ||
          (this.wareType == 3 && this.eu == 1))
      ) {
        this.changeTab('country');
      } else {
        this.changeTab('province');
      }

      this.$emit(
        'update',
        this.addressData,
        this.en_addressData,
        this.multipleFlag
      );
    },
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 0：国家菜单；1：省份菜单；2：城市菜单
     * item: 点击的哪个选项
     */
    async selectOption(index, item) {
      if (index == 0) {
        this.province_list = [];
        this.city_list = [];
        this.areaList = []; // 将区数据置空
        // 选择国家
        this.addressData.country =
          this.langType == 'en' ? item.name : item.cname;
        this.addressData.country_id = item.id;
        // 清空状态
        this.addressData.province = '';
        this.addressData.province_id = '';
        this.addressData.city = '';
        this.addressData.city_id = '';
        this.addressData.area = '';
        this.addressData.area_id = '';
        this.haveAreas = item.have_areas;
        if (this.isEnglish) {
          this.en_addressData.en_country = item.name;
          this.en_addressData.en_province = this.en_addressData.en_city = '';
        }
        if (item.has_state == '1') this.getProvinceList();
        else if (item.has_state == '0') this.getCityList();
      } else if (index == 1) {
        this.city_list = [];
        this.areaList = []; // 将区数据置空
        // 选择省份
        this.addressData.province =
          this.langType == 'en' ? item.name : item.cname;
        this.addressData.province_id = item.id;
        // 清空状态
        this.addressData.city = '';
        this.addressData.city_id = '';
        this.addressData.area = '';
        this.addressData.area_id = '';

        if (this.isEnglish) {
          this.en_addressData.en_province = item.name;
          this.en_addressData.en_city = '';
        }
        this.getCityList();
      } else if (index == 2) {
        this.areaList = []; // 将区数据置空
        this.addressData.city = this.langType == 'en' ? item.name : item.cname;
        this.addressData.city_id = item.id;
        this.addressData.area = '';
        this.addressData.area_id = '';
        if (this.isEnglish) {
          this.en_addressData.en_city = item.name;
        }
        if (this.haveAreas == 1) {
          // 有区
          const areas = await this.getAreaList(this.addressData.city_id);
          if (areas?.length == 0) {
            this.closeDropdown();
          }
        } else {
          // 可以传出数据了
          this.closeDropdown();
        }
        // 可以传出数据了
        // this.closeDropdown();
      } else if (index == 3) {
        if (this.isEnglish) {
          this.en_addressData.en_area = item.name;
        }
        this.addressData.area = this.langType == 'en' ? item.name : item.cname;
        this.addressData.area_id = item.id;
        // 区
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.$emit(
        'update',
        this.addressData,
        this.en_addressData,
        this.multipleFlag
      );
      this.showDropdown = false;
      this.$refs.selector && this.$refs.selector.blur(); // 选择器的尖角符号向下
    },
    // 搜索国家，高亮用户输入数据后的首字母
    async searchCountry() {
      let that = this;
      const options = {
        is_initial_group: 1,
        name: this.searchTarget,
        id: this.continentId ? this.continentId : '',
        API_LANG_TYPE: this.langType,
      };
      try {
        let res = await getCountries(options);
        if (res.errcode == 0) {
          let countryData = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].initial == 'C' && this.langType == 'en') {
              let filterData = res.data[i].list.filter((item) => {
                return item.id != 44;
              });
              res.data[i].list = filterData;
            }
            countryData.push(res.data[i]);
          }
          that.country_list = countryData;
          // that.country_list = res.data;
          if (that.country_list) {
            that.initialActive = res.data[0].initial;
          }
          return;
        }
      } catch (err) {
        console.error(err);
      }
    },
    getCountryList() {
      let that = this;
      return new Promise(async (resolve, reject) => {
        const options = {
          is_initial_group: 1,
          id: this.continentId ? this.continentId : '',
          API_LANG_TYPE: this.langType,
        };
        // 英文版，海外仓,欧盟
        if (this.langType == 'en' && this.wareType == 3 && this.eu == 1) {
          options.war_id = this.wareId;
        }
        try {
          let res = await getCountries(options);
          if (res.errcode == 0) {
            if (this.wareType == 2 && this.crossType == 2) {
              let countryData = [];
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].initial == 'C' && this.langType == 'en') {
                  let filterData = res.data[i].list.filter((item) => {
                    return item.id != 44;
                  });
                  res.data[i].list = filterData;
                }
                countryData.push(res.data[i]);
              }
              that.country_list = countryData;
              resolve(countryData);
            } else {
              that.country_list = res.data;
              resolve();
            }

            return;
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    getProvinceList() {
      // TODO 没有上级id的情况下，不请求数据
      if (!this.addressData.country_id) return;
      let that = this;
      return new Promise(async (resolve, reject) => {
        const options = {
          id: this.addressData.country_id,
        };
        try {
          let res = await getStates(options);
          if (res.errcode == 0) {
            that.province_list = res.data;
            that.changeTab('province');
            resolve(res.data);
            return;
          }
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    getCityList() {
      return new Promise(async (resolve, reject) => {
        const upperType =
          this.addressData.province_id && this.addressData.province_id != '0'
            ? ''
            : 'country';
        const upperId =
          upperType != 'country'
            ? this.addressData.province_id
            : this.addressData.country_id;
        const options = {
          id: upperId,
          type: upperType,
        };
        let that = this;
        try {
          let res = await getCity(options);
          if (res.errcode == 0) {
            that.city_list = res.data;
            if (that.city_list.length > 0) {
              that.changeTab('city');
              resolve(res.data);
            } else {
              that.closeDropdown();
            }
            return;
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    // 获取区的数据
    getAreaList(id) {
      return new Promise((resolve, reject) => {
        let option = {
          id,
        };
        getRegions(option)
          .then((res) => {
            if (res.errcode == 0) {
              this.changeTab('area');
              this.areaList = res.data; // 获取到区数据
              return resolve(res.data);
            }
            return reject(res);
          })
          .catch((err) => {
            console.error(err);
            return reject(err);
          });
        // let api = '/supplier/getRegions';
        // xmJson(
        //   api,
        //   option,
        //   (res) => {
        //     if (res.errcode == 0) {
        //       this.changeTab('area');
        //       this.areaList = res.data; // 获取到区数据
        //     }
        //   },
        //   (err) => {
        //     console.log(err, 'err');
        //   }
        // );
      });
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
      };
      this.en_addressData = this.$options.data().en_addressData;
      this.initialActive = '';
      this.searchTarget = '';
      this.tabName = '';
      this.country_list = [];
      this.province_list = [];
      this.city_list = [];
      this.areaList = []; // 将区数据置空
    },
  },
  beforeDestroy() {
    this.resetData();
  },
  async mounted() {
    // 判断是否是跨境仓，是否选择中国
    await this.getCountryList();
    if (
      this.langType == 'zh-cn' ||
      (this.langType == 'en' && this.wareType == 3 && this.eu == 0) ||
      (this.wareType == 2 && this.crossType == 1)
    ) {
      this.addressData.country_id = this.countryId;
      this.addressData.country = '';
      this.getProvinceList();
      if (
        this.langType == 'zh-cn' ||
        (this.wareType == 2 && this.crossType == 1)
      ) {
        // 默认国内有区
        this.haveAreas = 1;
      } else {
        // 默认国外无区
        this.haveAreas = 0;
      }
    } else {
      this.changeTab('country');
    }

    // 判断编辑回显
    if (this.areaData.country_id) {
      this.addressData.country_id = this.areaData.country_id;
    }
    if (this.areaData.province_id) {
      this.addressData.province_id = this.areaData.province_id;
    }
    if (this.areaData.city_id) {
      this.addressData.city_id = this.areaData.city_id;
    }
    if (this.areaData.area_id) {
      this.addressData.area_id = this.areaData.area_id;
    }
    if (this.areaData.country) {
      this.addressData.country = this.areaData.country;
    }
    if (this.areaData.province) {
      this.addressData.province = this.areaData.province;
    }
    if (this.areaData.city) {
      this.addressData.city = this.areaData.city;
    }
    if (this.areaData.area) {
      this.addressData.area = this.areaData.area;
    }
    // if (this.addressData.country_id) {
    //   // 使用 provide-inject 传参
    //   this.addressData.country = this.areaData
    //     ? this.areaData.country || ""
    //     : "";
    //   if (this.langType == "en")
    //     this.addressData.country_id = this.areaData
    //       ? this.areaData.country_id
    //       : "";
    //   this.addressData.province = this.areaData
    //     ? this.areaData.province || ""
    //     : "";
    //   this.addressData.province_id = this.areaData
    //     ? this.areaData.province_id
    //     : "";
    //   this.addressData.city = this.areaData ? this.areaData.city || "" : "";
    //   this.addressData.city_id = this.areaData ? this.areaData.city_id : "";
    //   this.addressData.area = this.areaData ? this.areaData.area || "" : "";
    //   this.addressData.area_id = this.areaData
    //     ? this.areaData.area_id || ""
    //     : "";
    //   // if (this.langType == "en") this.addressData.country = this.country || "";
    //   // if (this.langType == "en")
    //   //   this.addressData.country_id = this.countryId || "";
    //   // this.addressData.province = this.province || "";
    //   // this.addressData.province_id = this.province_id || "";
    //   // this.addressData.city = this.city || "";
    //   // this.addressData.city_id = this.city_id || "";
    //   // this.addressData.area = this.area || "";
    //   // this.addressData.area_id = this.area_id || "";
    // } else {
    //   // 若父组件传入的地址数据，是在其组件渲染后（比如，在父组件的mounted函数中向后端请求数据）
    //   // 才获取的，那么就应当使用props传参，参数名为 `areaData`
    //   if (this.langType == "en")
    //     this.addressData.country = this.areaData
    //       ? this.areaData.country || ""
    //       : "";
    //   if (this.langType == "en")
    //     this.addressData.country_id = this.areaData
    //       ? this.areaData.country_id
    //       : "";
    //   this.addressData.province = this.areaData
    //     ? this.areaData.province || ""
    //     : "";
    //   this.addressData.province_id = this.areaData
    //     ? this.areaData.province_id
    //     : "";
    //   this.addressData.city = this.areaData ? this.areaData.city || "" : "";
    //   this.addressData.city_id = this.areaData ? this.areaData.city_id : "";
    //   this.addressData.area = this.areaData ? this.areaData.area || "" : "";
    //   this.addressData.area_id = this.areaData
    //     ? this.areaData.area_id || ""
    //     : "";
    //   if (this.isEnglish) {
    //     //英文(地区)回显
    //     this.en_addressData.en_country = this.en_areaData
    //       ? this.en_areaData.en_country || ""
    //       : "";
    //     this.en_addressData.en_province = this.en_areaData
    //       ? this.en_areaData.en_province || ""
    //       : "";
    //     this.en_addressData.en_city = this.en_areaData
    //       ? this.en_areaData.en_city || ""
    //       : "";
    //     this.en_addressData.en_area = this.en_areaData
    //       ? this.en_areaData.en_area || ""
    //       : "";
    //   }
    // }
    if (this.addressData.country_id) {
      let arr = [];
      this.country_list.forEach((item) => {
        arr = arr.concat(item.list);
      });
      let countryItem = arr.filter((item) => {
        return item.id == this.addressData.country_id;
      });
      this.haveAreas = countryItem[0]?.have_areas;
    }
    if (this.addressData.province_id && this.addressData.province_id != '0') {
      // 有省份的国家，才获取省份
      const res = await this.getProvinceList();
    }
    if (this.addressData.city_id && this.addressData.city_id != '0') {
      const res = await this.getCityList();
    }
    if (this.addressData.area_id && this.addressData.area_id != '0') {
      this.getAreaList(this.addressData.city_id);
    }
  },
};
