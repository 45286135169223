
var reg_tel =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import {
  sendSmsCode,
  sendVerificationSms,
  editPhone,
} from '@/api/client-api/api.js';
export default {
  name: 'editPhone',
  data() {
    return {
      active: 0,
      phone_show: '', // 隐藏的手机号
      phone: '',
      timer: null, // 定时器
      newtimer: null, // 新定时器
      numscond: 60,
      newnumscond: 60,
      beforephoneCode: '', // 原来手机验证码
      newPhoneNum: '', // 新的手机号码
      newphoneCode: '', // 新的手机号的验证码
    };
  },
  mounted() {
    this.getBasicInformation();
  },
  methods: {
    next(active) {
      if (active == 0) {
        if (this.beforephoneCode == '') {
          // this.$refs.putcode.style.borderColor = '#D22316';
          // this.$refs.errortip.style = 'display: block';
          // this.$refs.getcode.style = 'border-color: #D22316; color: #D22316';
          this.$message.warning('请输入验证码');
          return;
        }
        let option = {
          code: this.beforephoneCode,
          phone: this.phone,
        };
        sendVerificationSms(option)
          .then((res) => {
            if (res?.errcode == 0) {
              if (res?.msg) {
                this.$message.success(res?.msg);
              }
              if (this.active++ > 2) this.active = 0;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (active == 1) {
        if (this.newPhoneNum == '') {
          // this.$refs.newPhoneNum.style.borderColor = '#D22316';
          this.$message.warning('请输入手机号');
          return;
        }
        if (this.newphoneCode == '') {
          // this.$refs.errorphonetip.style = 'display: block';
          // this.$refs.putcode.style.borderColor = '#D22316';
          // this.$refs.errortip.style = 'display: block';
          // this.$refs.getcode.style = 'border-color: #D22316; color: #D22316';
          this.$message.warning('请输入验证码');
          return;
        }
        let option = {
          code: this.newphoneCode,
          phone: this.newPhoneNum,
        };
        editPhone(option)
          .then((res) => {
            if (res?.errcode == 0) {
              if (res?.msg) {
                this.$message.success(res?.msg);
              }
              if (this.active++ > 2) this.active = 0;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      // if (this.active++ > 2) this.active = 0;
    },
    // 获取基本信息
    getBasicInformation() {
      getBasicInformation()
        .then((res) => {
          if (res?.errcode == 0) {
            this.status = res?.data?.status;
            this.phone_show = res?.data?.phone_show;
            this.phone = res?.data?.phone;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取原来的手机号验证码
    sendSmsCode() {
      let option = {
        phone: this.phone,
        type: 'distributorEditInfo',
      };
      sendSmsCode(option)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }

            this.timer = setInterval(() => {
              this.numscond = this.numscond - 1;
              if (this.numscond == 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.numscond = 60;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取新的手机验证码
    sendNewSmsCode() {
      if (this.newPhoneNum == '') {
        // this.$refs.errorphonetip.style = 'display: block';
        // this.$refs.newPhoneNum.style.borderColor = '#D22316';
        return;
      }
      if (!reg_tel.test(this.newPhoneNum)) {
        this.$message({
          message: '请输入正确的手机号',
          type: 'warning',
        });
        // this.$refs.newPhoneNum.style.borderColor = '#D22316';
        return;
      }
      let option = {
        phone: this.newPhoneNum,
        type: 'modify',
      };
      sendSmsCode(option)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }

            this.newtimer = setInterval(() => {
              this.newnumscond = this.newnumscond - 1;
              if (this.newnumscond == 0) {
                clearInterval(this.newtimer);
                this.newtimer = null;
                this.newnumscond = 60;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 原来的手机验证码失焦
    inputBlur(textContent, type) {
      if (
        (type == 'beforephoneCode' && textContent == '') ||
        (type == 'newphoneCode' && textContent == '')
      ) {
        // this.$refs.putcode.style = 'border-color: #D22316';
        // this.$refs.errortip.style = 'display: block';
        // this.$refs.getcode.style = 'border-color: #D22316; color: #D22316';
      } else if (
        (type == 'beforephoneCode' && textContent != '') ||
        (type == 'newphoneCode' && textContent != '')
      ) {
        // this.$refs.putcode.style = 'border-color: #CBCBCB';
        // this.$refs.errortip.style = 'display: none';
        // this.$refs.getcode.style = 'border-color: #CBCBCB; color: #D9D9D9';
      }
      if (type == 'newPhoneNum' && textContent == '') {
        // this.$refs.errorphonetip.style = 'display: block';
        // this.$refs.newPhoneNum.style = 'border-color: #D22316';
      } else if (type == 'newPhoneNum' && textContent != '') {
        // this.$refs.errorphonetip.style = 'display: none';
        // this.$refs.newPhoneNum.style = 'border-color: #CBCBCB';
      }
      if (type == 'newphoneCode' && textContent == '') {
        // this.$refs.putcode.style = 'border-color: #D22316';
        // this.$refs.errortip.style = 'display: block';
        // this.$refs.getnewcode.style = 'border-color: #D22316; color: #D22316';
      } else if (type == 'newphoneCode' && textContent != '') {
        // this.$refs.putcode.style = 'border-color: #CBCBCB';
        // this.$refs.errortip.style = 'display: none';
        // this.$refs.getnewcode.style = 'border-color: #CBCBCB; color: #D9D9D9';
      }
    },

    // 完成后返回
    goback() {
      this.$router.push({ path: '/account' });
    },
  },
};
