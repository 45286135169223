
export default {
  name: 'GoodCardsRow',
  props: {
    list: {
      type: Array,
      default: () => [
        // {
        //   item_id: 0,
        //   item_image_url: '',
        //   title: '默认标题',
        //   price: '0',
        // },
      ],
    },
    currencyMark: {
      type: String,
      default: '￥',
    },
    // 是否要给图片插入 alt 属性
    needAlt: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickCard(item) {
      this.toCachedShopdetail(
        item.item_id,
        item.item_image_url,
        item.sku_lowest_price_img
      );
    },
  },
};
