
export default {
  name: 'CapitalTip',
  props: {
    // 提示的类型
    type: {
      type: String,
      default: 'success',
    },
    // 提示的内容
    content: {
      type: String,
      default: '默认内容',
    },
  },
};
