
import HomeDialog from '@/components/XimuComponents/XimuDialog.vue';
import UploadPic from './UploadPic.vue';
const MOBILE = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
const TEL = /^(0\d{2,3}-){0,1}\d{7,8}$/; //座机
export default {
  name: 'NewGoodsNeed',
  components: { HomeDialog, UploadPic },
  props: {
    // 是否需要查看大图
    isLarger: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newGoodsNeed: {
        pics: '', // 商品图片
        name: '', // 商品名称
        isAcceptAlike: '', // 是否接受相似商品
        supplierName: '', // 供应商名称
        supplierPhone: '', // 供应商电话
        brandName: '', // 品牌名称
        urlLink: '', // 网址链接
        detailDescribe: '', // 详情描述
      },
      isAcceptAlike: [
        { label: '接受', value: '1' },
        { label: '不接受', value: '0' },
      ],
      uploadPicTip: false, // 未上传商品图片提示
      inputNameTip: false, // 未填写商品名称提示
      acceeptAlikeTip: false, // 未选择是否接受相似商品提示
      supplierPhoneTip: false, // 供应商电话号码错误提示
    };
  },
  watch: {
    'newGoodsNeed.pics'(val) {
      if (val !== '') {
        this.uploadPicTip = false;
      }
    },
    'newGoodsNeed.name'(val) {
      if (val.trim() !== '') {
        this.inputNameTip = false;
      }
    },
    'newGoodsNeed.isAcceptAlike'(val) {
      if (val !== '' && val !== undefined) {
        this.acceeptAlikeTip = false;
      }
    },
    'newGoodsNeed.supplierPhone'(val) {
      if (
        (val.trim() !== '' &&
          !MOBILE.test(val.trim()) &&
          !TEL.test(val.trim())) ||
        val.trim() == ''
      ) {
        this.supplierPhoneTip = false;
      }
    },
  },
  mounted() {},
  methods: {
    // 获取上传的商品图片
    getUploadPic(pics) {
      this.newGoodsNeed.pics = pics;
    },
    // 提交
    async submit() {
      try {
        if (this.newGoodsNeed.pics === '') {
          this.uploadPicTip = true;
        }
        if (
          this.newGoodsNeed.name.trim() === '' ||
          this.newGoodsNeed.isAcceptAlike === undefined
        ) {
          this.inputNameTip = true;
        }
        if (this.newGoodsNeed.isAcceptAlike === '') {
          this.acceeptAlikeTip = true;
        }
        if (
          this.newGoodsNeed.supplierPhone.trim() !== '' &&
          !MOBILE.test(this.newGoodsNeed.supplierPhone.trim()) &&
          !TEL.test(this.newGoodsNeed.supplierPhone.trim())
        ) {
          this.supplierPhoneTip = true;
          return;
        }
        if (
          this.newGoodsNeed.pics === '' ||
          this.newGoodsNeed.name.trim() === '' ||
          this.newGoodsNeed.isAcceptAlike === '' ||
          this.newGoodsNeed.isAcceptAlike === undefined
        ) {
          return;
        }
        let options = {
          list: this.newGoodsNeed.pics.toString(),
          item_name: this.newGoodsNeed.name.trim(),
          accept_similar_item: this.newGoodsNeed.isAcceptAlike,
          public_show: 1,
        };
        if (this.newGoodsNeed.supplierName.trim() !== '') {
          options.supplier_name = this.newGoodsNeed.supplierName.trim();
        }
        if (this.newGoodsNeed.supplierPhone.trim() !== '') {
          options.supplier_phone = this.newGoodsNeed.supplierPhone.trim();
        }
        if (this.newGoodsNeed.brandName.trim() !== '') {
          options.brand_name = this.newGoodsNeed.brandName.trim();
        }
        if (this.newGoodsNeed.detailDescribe.trim() !== '') {
          options.description = this.newGoodsNeed.detailDescribe.trim();
        }
        const res = await this.$addNewItemNeed(options);
        if (res?.errcode == 0) {
          this.$message.success('新增成功');
          this.$emit('submit');
          this.closeDialog();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.newGoodsNeed = {
        pics: '', // 商品图片
        name: '', // 商品名称
        isAcceptAlike: '', // 是否接受相似商品
        supplierName: '', // 供应商名称
        supplierPhone: '', // 供应商电话
        brandName: '', // 品牌名称
        urlLink: '', // 网址链接
        detailDescribe: '', // 详情描述
      };
      this.$emit('close-need');
    },
  },
};
