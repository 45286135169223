
import PlateGoodsShowBox from './PlateGoodsShowBox'; // 潮流新方向（数码）/三的展示组件
export default {
  name: 'GoodsActivityContent',
  components: { PlateGoodsShowBox },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 活动商品信息
    activityGoodsMsg: {
      type: Object,
      default: () => {},
    },
    // 需要分页的信息总页数
    goodsListTotalPage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 潮流新方向（数码）/三向右滚动需要后续数据
    scrollRight(num) {
      this.$emit('scroll-right', num);
    },
  },
};
