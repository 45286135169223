
import { language } from '../static/switchLanguage';
export default {
  data() {
    return {
      languageObj: language,
      currentLanguage: {},
      orderlist: {}, // 订单数据
      widthObj: {
        // 长度对象
        cell1: 185,
        cell2: 181,
        cell3: 180,
        cell4: 165,
        cell5: 165,
        cell6: 156,
        cell7: 170,
        cell8: 160,
      },
      pickup: false, // 收起
      logisticsfees: '', // 物流费
      insuranceFee: '', // 保价费
      pickindex: 2,
    };
  },
  props: {
    logisticslist: {
      type: Array,
      default: () => [],
    },
    wareCountry: {
      type: String,
      default: '',
    },
    wareCity: {
      type: String,
      default: '',
    },
    endCountry: {
      type: String,
      default: '',
    },
    endCity: {
      type: String,
      default: '',
    },
  },
  computed: {
    // 动态css
    cssWidth() {
      return {
        '--cell1': this.widthObj.cell1 + 'px',
        '--cell2': this.widthObj.cell2 + 'px',
        '--cell3': this.widthObj.cell3 + 'px',
        '--cell4': this.widthObj.cell4 + 'px',
        '--cell5': this.widthObj.cell5 + 'px',
        '--cell6': this.widthObj.cell6 + 'px',
        '--cell7': this.widthObj.cell7 + 'px',
        '--cell8': this.widthObj.cell8 + 'px',
      };
    },
    currLanguage() {
      return this.$store.state.common.currLanguage;
    },
  },
  watch: {
    currLanguage: {
      handler(val) {
        if (val == 'zh-cn') {
          this.currentLanguage = this.languageObj.chObj;
        } else if (val == 'en') {
          this.currentLanguage = this.languageObj.enObj;
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 切换物流模板
    tempalgechange(item) {
      if (!item.checked) {
        this.currentteplate = {};
        this.logisticsfees = '';
        this.insuranceFee = '';
      } else {
        this.currentteplate = item;
        this.logisticsfees = item.price;
        this.insuranceFee = item.insurance_charge;
      }
      this.logisticslist.forEach((res) => {
        if (res.cha_id != item.cha_id) {
          res.checked = false;
        }
      });
      this.$emit('getlog', this.currentteplate);
    },
    // 更多
    moreData() {
      this.pickup = !this.pickup;
      if (!this.pickup) {
        this.pickindex = 2;
      } else {
        this.pickindex = 1000;
      }
    },
  },
};
