const splitupSkuHeadList = [
  {
    filed: 'goodsInfo',
    text: '商品信息',
    width: '362',
    isEdit: true,
    fixed: 'fixed',
  },
  {
    filed: 'price',
    text: '价格(￥)',
    width: '94',
    isHeaderEdit: true,
  },
  { filed: 'operation_fee', text: '操作费(￥)', width: '72' },
  { filed: 'ware_logistics', text: '到仓物流费', width: '80' },
  { filed: 'import_tariff', text: '税率', width: '64' },
  { filed: 'evaluate_count', text: '评价', width: '64', isHeaderEdit: true },
  { filed: 'sale_count', text: '销量', width: '64', isHeaderEdit: true },
  {
    filed: 'store',
    text: '在库数量',
    width: '88',
    isHeaderEdit: true,
  },
  // { filed: 'store_status', text: '库存状态', width: '116',isEdit: true, },
  {
    filed: 'recent_arrival_count',
    text: '最近到货数量',
    width: '92',
    isEdit: true,
  },
  {
    filed: 'product_qualification_rate',
    text: '承诺合格率',
    width: '100',
    isHeaderEdit: true,
  },
  {
    filed: 'real_pass_rate',
    text: '实际合格率',
    width: '100',
    isHeaderEdit: true,
  },
  {
    filed: 'return_ratio',
    text: '退货率',
    width: '76',
    isHeaderEdit: true,
  },
  {
    filed: 'in_time_send_ratio',
    text: '发货及时率',
    width: '100',
    isHeaderEdit: true,
  },
  {
    filed: 'black_reason',
    text: '拉黑原因',
    width: '96',
    isEdit: true,
  },
  {
    filed: 'black_time',
    text: '拉黑时间',
    width: '84',
  },
  {
    filed: 'isOperation',
    text: '操作',
    width: '100',
    isEdit: true,
  },
];
export default splitupSkuHeadList;
