const emptySet = new Set(['', null, undefined, '-1', 0]);

/**
 * 过滤掉对象中值为空的字段
 * @author hukeyi
 * @param {*} obj
 * @returns 有值的字段组成的新对象
 */
export function filterEmptyKey(obj) {
  let newObj = {};
  for (let key of Object.keys(obj)) {
    // 特殊处理数组
    if (Array.isArray(obj[key])) {
      if (obj[key].length === 0) {
        continue;
      }
      newObj[key] = obj[key];
    }
    if (!emptySet.has(obj[key])) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

/**
 * 供应商可举报分类: 6-'禁限售类', 7-'知识产权类', 8-'政治有害类' 17'交互信息违规'
 * 分销商可举报分类: 13-'禁限售类', 14'知识产权类', 15'政治有害类',16'交互信息违规'
 */
const reportClassIds = new Set([6, 7, 8, 17, 13, 14, 15, 16]);
// 所属分类是否为“举报”
export function isReport(id) {
  let target = Number(id || 0);
  return reportClassIds.has(target);
}
