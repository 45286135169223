
import {
  getDisputeList,
  ExportDispute,
  addOrderMemo,
  getDisputePanel,
  getOrderSourceList,
} from '@/api/client-api/trade/orderlist.js';
import dayjs from 'dayjs';
export default {
  name: 'reFund',
  data() {
    return {
      activeName: '1',
      info: {},
      seach: {
        //搜索
        status: '', // 状态
        orderTime: [], // 时间
        seachType: '', // 以什么数字搜索
        inputvalue: '', // 输入的数字
        memo_type: '', // 备注
        warehouses_type: '', // 仓库类型
        currency: '', // 交易币种
        order_source: '', // 订单来源
      },
      statusOptions: [
        { label: '全部纠纷状态', value: '' },
        { label: '纠纷申请中', value: '0' },
        { label: '方案协商中', value: '1' },
        { label: '平台介入处理中', value: '2' },
        { label: '待分销商退货', value: '3' },
        { label: '待供应商收货', value: '4' },
        { label: '待供应商退款', value: '5' },
        { label: '拒绝退款', value: '7' },
      ],
      startTime: '请选择状态时间范围起始',
      endTime: '请选择状态时间范围结束',
      seachOptions: [
        { label: '订单号', value: 'tid' },
        // {label:"供应商ID",value:"supplier_id"},
        // {label:"仓库ID",value:"3"},
        { label: '商品标题', value: 'title' },
        { label: '商品ID', value: 'item_id' },
        // {label:"物流单号",value:"6"},
      ],
      currencyOptions: [
        { label: '全部币种', value: '' },
        { label: '人民币', value: '1' },
        { label: '美元', value: '2' },
      ],
      warehouseOptions: [
        { label: '全部仓库', value: '' },
        { label: '供应商仓', value: '1' },
        { label: '平台仓', value: '2' },
      ],
      selectOptions: [
        { label: '全部', value: '' },
        { label: '有备注', value: '1' },
        { label: '无备注', value: '2' },
      ],
      orderSourceOptions: [], // 订单来源
      timer: null, // 定时器
      jsq: '1',
      placename: '请先选择',
      orderList: [],
      selectOrderCheckbox: [],
      isIndeterminate: false,
      isOrderSleectAll: false, //全选
      ishowimg: true,
      totalnum: 0, // 数据总数
      pageNum: 1,
      pageSize: 10,
      remarkshow: false,
      remarkcontent: '',
      currentitemid: '',
      batchTableList: [], // 需要导出的订单ID们
    };
  },
  computed: {
    pickerOptions() {
      return {
        disabledDate: (time) => {
          let nowDate = parseInt(dayjs().format('YYYYMMDD')),
            thenDate = parseInt(dayjs(time).format('YYYYMMDD'));
          return thenDate > nowDate;
        },
      };
    },
  },
  mounted() {
    if (process.client) {
      this.getKanban();
      let info =
        JSON.parse(JSON.stringify(this.getPageFilterData() || null)) || null;
      if (info) {
        this.echoSearchData(info);
      }
      this.fetchOrderSourceList();
      this.getDisputeList1(false);
    }
  },
  methods: {
    // 处理收货人信息-地址
    handleReceiverAddr(info) {
      let addressArr = [
        info.receiver_country,
        info.receiver_state,
        info.receiver_city,
        info.receiver_district,
        info.receiver_address,
      ];
      let addressData = addressArr.filter(Boolean);
      return addressData?.join(' ') || '--';
    },
    // 跳转商品详情
    toShopDetail(item) {
      if ('1' == item.currency_rate) {
        // 人民币
        item.publish_language_id = '2';
      }
      if ('2' == item.currency_rate) {
        // 美元
        item.publish_language_id = '3';
      }
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img,
        '_blank',
        item.publish_language_id
      );
    },
    // 获取纠纷列表看板信息
    getKanban() {
      const options = { MUTE_WARNING: 1 };
      getDisputePanel(options)
        .then((res) => {
          if (res.errcode == 0) {
            this.info = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    checkmenu(index) {
      if (this.activeName == index) {
        return;
      }
      this.activeName = index;
      this.pageNum = 1;
      this.resizedata();
      this.getDisputeList1();
    },
    resizedata() {
      this.seach = {
        status: '',
        orderTime: [],
        seachType: '',
        inputvalue: '',
        memo_type: '',
        warehouses_type: '',
        currency: '',
        order_source: '',
      };
      this.pageNum = 1;
      this.getDisputeList1();
    },
    /**
     * 筛选条件回显
     * @param {Object} info 当前页面缓存的搜索条件
     */
    echoSearchData(info) {
      this.pageNum = info?.pageNum || 1;
      this.activeName = info?.tag_type;
      this.seach = { ...info, orderTime: [], inputvalue: '' };
      // 时间范围
      if (info?.start_created_time && info?.end_created_time) {
        this.seach.orderTime = [
          info?.start_created_time,
          info?.end_created_time,
        ];
      }
      // 下拉加输入框
      if (info?.[info?.seachType]) {
        this.seach.seachType = info?.seachType;
        this.seach.inputvalue = info?.[info?.seachType] || '';
      }
      // 仓库类型
      if (info?.war_id) {
        this.seach.warehouses_type = info?.war_id;
      }
    },
    /**
     * 获取纠纷列表
     * @param {Boolean} isCache 控制是否对筛选条件进行缓存
     */
    getDisputeList1(isCache = true) {
      const options = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        tag_type: this.activeName,
        dispute_status: this.seach.status,
        memo_type: this.seach.memo_type, // 备注
        warehouses_type: this.seach.warehouses_type, // 仓库类型
        currency: this.seach.currency, // 交易币种
        order_source: this.seach.order_source, // 订单来源
      };
      if (this.seach.orderTime != null && this.seach.orderTime.length != 0) {
        options.start_created_time = this.seach.orderTime[0];
        options.end_created_time = this.seach.orderTime[1];
      }
      if (this.seach.inputvalue.trim() != '') {
        if (this.seach.seachType == '') {
          options.seachType = 'tid';
          options.tid = this.seach.inputvalue;
        } else {
          options.seachType = this.seach.seachType;
          options[this.seach.seachType] = this.seach.inputvalue;
        }
      }
      if (isCache) {
        this.setPageFilter(options);
      }
      getDisputeList(options)
        .then((res) => {
          /* res.data.list.forEach( item =>{
            item.itemInfo[0].btnpolicy = '';
            if(item.return_policy.is_d_affecttwosale == '1'){
            item.itemInfo[0].btnpolicy = '分销商问题，影响二次销售，分销商承担' + item.return_policy.product_distributors_cost + '%;';
            }else if(item.return_policy.is_d_affecttwosale == '2'){
            item.itemInfo[0].btnpolicy = '分销商问题，影响二次销售，分销商不承担货运费;';
            }
            if(item.return_policy.is_d_unaffecttwosale == 1){
            item.itemInfo[0].btnpolicy = item.itemInfo[0].btnpolicy + '分销商问题，不影响二次销售';
            }else if(item.return_policy.is_d_unaffecttwosale == 2){
            item.itemInfo[0].btnpolicy = item.itemInfo[0].btnpolicy + '分销商问题，不影响二次销售，不承担运费;';
            }
            if(item.return_policy.is_s_exitmoneynoprod == 1){
            item.itemInfo[0].btnpolicy = item.itemInfo[0].btnpolicy + '供应商问题，因质量问题无法退货，退款不退货';
            }else if(item.return_policy.is_s_exitmoneynoprod == 2){
            item.itemInfo[0].btnpolicy = item.itemInfo[0].btnpolicy + '供应商问题，因质量问题无法退货，退款退货;';
            }
            if(item.return_policy.is_s_uncondreturnpack == 1){
            item.itemInfo[0].btnpolicy = item.itemInfo[0].btnpolicy + '供应商问题，无条件退货不包退邮费';
            }else if(item.return_policy.is_s_uncondreturnpack == 2){
            item.itemInfo[0].btnpolicy = item.itemInfo[0].btnpolicy + '供应商问题，无条件退货包退邮费,供应商承担' + item.user_distributors_cost + '%;';
            }
        }); */
          if (res.errcode == 0) {
            /* if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.timer = setInterval(() => {
            const t1 = Date.parse(new Date());
            res.data.list.forEach((item) => {
              item.handle_deadline_diff =
                item.handle_deadline * 1000 - t1 > 0
                  ? item.handle_deadline * 1000 - t1
                  : 0;
            });
            this.$forceUpdate();
          }, 1000); */
            this.orderList = res.data.list;
            this.totalnum = Number(res.data.total);

            // 倒计时
            this.orderList.forEach((item) => {
              item.handle_deadline_show = this.countdown(
                item.handle_deadline * 1000
              );
            });

            this.orderList.forEach((item) => {
              item.CountDown = setInterval(() => {
                item.handle_deadline_show = this.countdown(
                  item.handle_deadline * 1000
                );
                this.$forceUpdate();
              }, 1000);
              if (item.handle_deadline_show <= 0) {
                clearInterval(item.CountDown);
                // 如果是分销商操作
                item.CountDown = null;
              }
            });
            if (this.orderList?.length == 0 && this.pageNum > 1) {
              this.goPage(1);
            }
            // console.log(this.orderList, 'this.orderList');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 搜索查询
    changeSearch() {
      this.pageNum = 1;
      /* if (this.$refs.pagTools) {
        this.$refs.pagTools.changecurPage(this.pageNum);
      } */
      this.getDisputeList1();
    },
    goPage(currentpagr, pageSize) {
      // 当前页
      this.pageNum = Number(currentpagr);
      if (pageSize) {
        this.pageSize = pageSize;
      }
      this.getDisputeList1();
    },
    //获取当前时间并计算时间差
    getNowTime(time) {
      if (process.client) {
        var date3 = time;
        if (date3 <= 0) {
          return '超时';
        } else {
          //计算出相差天数
          var days = Math.floor(date3 / (24 * 3600 * 1000));
          //计算出小时数
          var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
          var hours = Math.floor(leave1 / (3600 * 1000));
          //计算相差分钟数
          var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
          var minutes = Math.floor(leave2 / (60 * 1000));
          //计算相差秒数
          var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
          var seconds = Math.round(leave3 / 1000);
          hours = hours > 9 ? hours : '0' + hours;
          minutes = minutes > 9 ? minutes : '0' + minutes;
          seconds = seconds > 9 ? seconds : '0' + seconds;
          let surplusTime =
            days + '天' + hours + '时' + minutes + '分' + seconds + '秒';
          return surplusTime;
        }
      }
    },
    // 倒计时
    // 自己定义一个setInterval 放进自己定义的变量里 进行倒计时 ，注：记得销毁计时器
    countdown(gettime) {
      // 需要传进来的时间戳  10位数就*1000
      const t1 = Date.parse(new Date());
      return gettime - t1 > 0 ? gettime - t1 : 0;
    },
    handleClick(v) {
      this.activeName = v.name;
      this.getDisputeList1();
    },
    changeStatus() {},
    changeStatusa(e) {
      if (e == '1') {
        this.placename = '订单号';
      } else if (e == '2') {
        this.placename = '供应商ID';
      } else if (e == '3') {
        this.placename = '仓库ID';
      } else if (e == '4') {
        this.placename = '商品标题';
      } else if (e == '5') {
        this.placename = '商品ID';
      } else if (e == '6') {
        this.placename = '物流单号';
      }
    },
    onOrderItemChange(val) {
      const currLength = val.length;
      const listLength = this.orderList.length;

      this.isOrderSleectAll = currLength == listLength;

      this.isIndeterminate = currLength > 0 && currLength < listLength;
    },
    //全选按钮
    onSelectOrderAll(val) {
      if (val) {
        this.selectOrderCheckbox = this.orderList.map((item) => item.tid);
      } else {
        this.selectOrderCheckbox = [];
      }

      this.isIndeterminate = false;
    },

    /* changeImgSrc(){
            this.ishowimg = !this.ishowimg
        }, */
    changeIndexBack() {
      this.ishowimg = !this.ishowimg;
    },
    // 供应商拒付订单详情
    loadPageReplaceOrPush(id) {
      window.open(`/trade/disputes?item_id=${id}`);
    },
    // 查看发票
    viewInvoice(tid) {
      window.open(`/fund/invoice/2/${tid}`);
    },
    // 复制图片
    changeImgSrc() {
      this.ishowimg = !this.ishowimg;
    },
    // 复制操作
    // 复制供应商ID
    _copy(context) {
      // 创建输入框元素
      let oInput = document.createElement('input');
      // 将想要复制的值
      oInput.value = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$message.success('复制成功');
      // 复制后移除输入框
      oInput.remove();
    },
    editRemarkshow(item) {
      this.remarkshow = true;
      this.currentitemid = item.tid;
      this.remarkcontent = item.user_remarks;
    },
    subBtn() {
      // 修改备注
      let option = {
        tid: this.currentitemid,
        user_remarks: this.remarkcontent,
        MUTE_WARNING: 1,
      };
      addOrderMemo(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.$message.success('编辑成功');
            this.remarkshow = false;
            this.getDisputeList1();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    delsubBtn() {
      this.remarkshow = false;
      this.currentitemid = '';
      this.remarkcontent = '';
    },
    // 选择批量导出的表格数据
    handleSelectionChange(val) {
      this.batchTableList = [];
      val.forEach((item) => {
        this.batchTableList.push(item.tid);
      });
    },
    // 批量导出
    batchExport() {
      if (this.batchTableList.length == 0) {
        this.$message.warning('请选择需要导出的订单');
        return;
      }
      let options = {
        tid: this.batchTableList.toString(),
      };
      ExportDispute(options)
        .then((res) => {
          if (res.errcode == 0) {
            this.$message.success('导出成功');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取订单来源选项列表 #todo-228
    async fetchOrderSourceList() {
      try {
        const params = {};
        const res = await getOrderSourceList(params);
        if (res?.errcode == 0) {
          this.orderSourceOptions = res.data?.orderSource || [];
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
