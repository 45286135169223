
import RatingBoard from "./components/RatingBoard";
import IconButton from "./components/IconButton";

import { BTN_LABELS, INFO_ASIDE_LABEL } from "./js/label_config_info_aside";
import { COMMON_LABELS } from "./js/label_config_common";

export default {
  name: "DetailInfoAside",
  components: { IconButton, RatingBoard },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: "zh-cn",
    },
    // 货币标志
    currency: {
      type: String,
      default: "¥",
    },
    // 供应商ID
    supplierId: {
      type: String,
      default: "",
    },
    // 供应商名称
    supplierName: {
      type: String,
      default: "",
    },
    // 商品ID
    itemId: {
      type: String,
      default: "",
    },
    // sku ID
    skuId: {
      type: String,
      default: "",
    },
    // 仓库ID
    warehouseId: {
      type: String,
      default: "",
    },
    // 精选商品
    choiceList: {
      type: Array,
      default: () => [],
    },
    // 店铺热销
    hotSaleStoreList: {
      type: Array,
      default: () => [],
    },
    // 热门收藏
    hotAttentionList: {
      type: Array,
      default: () => [],
    },
    // 商品的评分
    goodScores: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      /**
       * 店内分类相关暂时无 #fixme
       */
      currentCollapse: "1", // 店内分类的风琴折页（展开的是哪个折页）
      // 店内分类对应的折页数据
      // {
      //   key: '1',
      //   title: 'XXXX专区',
      //   goodList: [{ value: '商品1' }],
      // },
      collapseList: [],
      hotGoods: "hotSale", // 侧边栏tabs
      showShadow: false, // 是否显示底部阴影
    };
  },
  computed: {
    /**
     * 联系客服的get查询参数
     */
    contactQueryParams() {
      // 当前无skulist，直接返回空数组
      if (!this.skuId || !this.warehouseId) {
        return [];
      }
      const params = [
        { key: "type", value: "good" },
        { key: "item_id", value: this.itemId },
        { key: "sku_id", value: this.skuId },
        { key: "ware_id", value: this.warehouseId },
      ];
      return params;
    },
    // 按钮标签
    btnLabelConfig() {
      return BTN_LABELS[this.lang || "zh-cn"];
    },
    // 标签
    labelConfig() {
      return INFO_ASIDE_LABEL[this.lang || "zh-cn"];
    },
    // 通用的标签
    commonLabelConfig() {
      return COMMON_LABELS[this.lang || "zh-cn"];
    },
    // 按钮的文本样式
    iconBtnLabelStyle() {
      return this.lang === "en"
        ? { color: "#333", fontSize: "14px", fontWeight: "400" }
        : {
            color: "#333",
            fontSize: "12px",
            fontWeight: "300",
          };
    },
    // 按钮的样式
    iconBtnStyle() {
      return this.lang === "en"
        ? {
            padding: "2px 23px",
            borderRadius: "35px",
          }
        : {
            padding: "5px 11px",
            borderRadius: "35px",
          };
    },
  },
  methods: {
    /**
     * 点击进入店铺
     */
    handleEnterShop() {
      this.$router.push("/shop_" + this.supplierId + ".html");
    },
    /**
     * 点击精选商品
     * @param item 被点击的商品
     */
    handleClickChoiceItem(item) {
      this.toCachedShopdetail(
        item.item_id,
        item.item_image_url,
        item.sku_lowest_price_img,
        "_blank"
      );
    },
    isElementPinned() {
      if (!process.client) {
        return;
      }
      const elem = document.querySelector("#detailInfoAsideHeader");
      if (elem) {
        let { top } = elem.getBoundingClientRect();
        this.showShadow = top == 0;
      }
    },
  },
  mounted() {
    /**
     * 监听滚动事件，实时改变标签页的底部阴影
     */
    window.addEventListener("scroll", this.throttle(this.isElementPinned, 150));
  },
};
