/**
 * @FileDescription: 商城列表合店铺列表接口
 */
import request from '@/utils/request.js';

//类目
export function getCategory(data) {
  return request({
    url: 'categoryInfo/getCategory',
    method: 'get',
    params: data,
  });
}
// 类目（树状）
export function getCategoryTree(data) {
  return request({
    url: 'Category/getAllCategoryInfo',
    method: 'get',
    params: data,
  });
}

// 相关类目下的属性
export function getCategoryAttribute(data) {
  return request({
    url: 'CategoryInfo/getCategoryAttribute',
    method: 'get',
    params: data,
  });
}

// 商品列表
export function search(data) {
  return request({
    url: '/es/search',
    method: 'get',
    params: data,
  });
}

// 可售平台
export function getSlaePlatformList(data) {
  return request({
    url: 'item/getSlaePlatformList',
    method: 'get',
    params: data,
  });
}

// 精选商品
export function getSelectedItemList(data) {
  return request({
    url: 'item/getSelectedItemList',
    method: 'get',
    params: data,
  });
}
// 店铺热销\热门收藏
export function getShopGoodsList(data) {
  return request({
    url: 'item/getShopGoodsList',
    apiModule: 'goods',
    method: 'get',
    params: data,
  });
}

// 品牌
export function getBrandInfo(data) {
  return request({
    url: 'CategoryInfo/getBrandInfo',
    method: 'get',
    params: data,
  });
}

// 地区
// 洲
export function getContinents(data) {
  return request({
    url: 'user/getContinents',
    method: 'get',
    params: data,
  });
}
// 国
export function getCountries(data) {
  return request({
    url: 'user/getCountries',
    method: 'get',
    params: data,
  });
}

// 获取省/州
export function getStates(data) {
  return request({
    url: 'user/getStates',
    method: 'get',
    params: data,
  });
}
// 获取城市
export function getCity(data) {
  return request({
    url: 'user/getCity',
    method: 'get',
    params: data,
  });
}

// 获取供应商店铺详情
export function getSupplierInfo(data) {
  return request({
    url: '/Supplier/getSupplierInfo',
    method: 'get',
    params: data,
  });
}

// 获取供应商分类
export function getCategoryList(data) {
  return request({
    url: '/Supplier/getCategoryList',
    method: 'get',
    params: data,
  });
}

// 获取供应商商品销量排行
export function getSupplierSaleRankList(data) {
  return request({
    url: '/item/getSupplierSaleRankList',
    method: 'get',
    params: data,
  });
}
