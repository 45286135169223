import request from '@/utils/request.js';
//交易记录-账单明细
export function getTransactionList(data) {
  return request({
    url: '/TransactionRecord/getTransactionList',
    method: 'get',
    params: data,
    apiModule: 'fund',
    controller: {
      openLoading: true,
    },
  });
}
//交易记录-账单明细
export function getSecurityDepositList(data) {
  return request({
    url: '/TransactionRecord/getSecurityDepositList',
    method: 'get',
    apiModule: 'fund',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 支付方式
export function getPaymentChannel(data) {
  return request({
    url: '/MoneyManage/getPaymentChannel',
    method: 'get',
    params: data,
  });
}
// 交易汇总
export function getTransactionSummary(data) {
  return request({
    url: '/TransactionRecord/getTransactionSummary',
    method: 'get',
    apiModule: 'fund',
    params: data,
  });
}
