/**
 * 中英文公告板块设置(未登录)
 * NotLogBulletin.vue
 */

export const NOT_LOGIN_FONT = {
  'zh-cn': {
    welcome: '细目开放招商啦!',
    feature: '智选好品，全球分销',
    register: '注册',
    login: '登录',
    'bulletin-title': '平台公告',
    'no-notice': '暂无公告',
  },
  en: {
    welcome: 'Ximu starts to attract investment!',
    feature: 'Perfect goods，Global distribution',
    register: 'Sign up',
    login: 'Sign in',
    'bulletin-title': 'Platform Announcement',
    'no-notice': 'No announcements',
  },
};

/**
 * 中英文公告板块设置(已登录)
 * AlreadyLogBulletin.vue
 */

export const ALREADY_LOGIN_FONT = {
  'zh-cn': {
    welcome: '欢迎来到细目！',
    unpaid: '待付款',
    'wait-send': '待发货',
    'wait-receive': '待收货',
    'wait-evaluate': '待评价',
    notice: '公告',
    more: '更多',
    'no-notice': '暂无公告',
  },
  en: {
    welcome: 'Welcome to Ximu !',
    unpaid: 'Unpaid',
    'wait-send': 'Awaiting shipment',
    'wait-receive': 'Waiting for receiving',
    'wait-evaluate': 'To review',
    notice: 'Notice',
    more: 'More',
    'no-notice': 'No announcements',
  },
};
