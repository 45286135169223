import request from '@/utils/request.js';

// 获取用户认证信息
export function getUserDetailInfo(data) {
  return request({
    apiModule: 'user',
    url: 'info/getUserDetailInfo',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

//修改用户信息
export function editUserInfo(data) {
  return request({
    apiModule: 'user',
    url: 'info/editUserInfo',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 获取角色
export function getrolelist(data) {
  return request({
    url: 'info/getrolelist',
    method: 'get',
    params: data,
  });
}
