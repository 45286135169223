
export default {
  props: {
    title: {
      type: String,
    },
    isShow:{
      type:Boolean,
      default:true
    },
    isRed: {
      type:Boolean,
      default:false
    }
  },
  data() {
    return {};
  },
  methods: {

  },
};
