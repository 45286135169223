
// 工具函数
import tools from '@/utils/tool-function/tool.js';
export default {
  name: 'XmTopNav',
  props: {
    // 是否是商城首页(控制是否出现'细目首页')
    isHome: {
      type: Boolean,
      default: false,
    },
    // 是否是前台页面(控制是否出现'切换海外分销')
    isFrontDesk: {
      type: Boolean,
      default: true,
    },
    /**
     * 版心宽度类名,默认width1200
     * 可选值width1000, width1400
     */
    centerClass: {
      type: String,
      default: 'width1200',
    },
  },
  data() {
    return {
      token: '',
      status: '', // 账号状态 -1删除,0待完善资料,1待审核,2已通过,3已驳回,4拉黑,5二次提交审核,6注销申请审核通过
      userdetail: {}, // 用户信息
      activeIndex: null, // 菜单栏样式
      // 地区相关
      localArea: '',
      areaList: [],
      // 右侧导航列表
      rightList: [
        { id: '1', title: '我的平台', link: '/myplatform' },
        { id: '2', title: '商品', link: '/goods' },
        { id: '3', title: '我的订单', link: '/trade' },
        { id: '4', title: '营销活动', link: '/activity' },
        { id: '5', title: '账号及认证', link: '/account' },
        { id: '10', title: '投诉&举报', link: '/complaints' },
        { id: '8', title: '资金', link: '/fund' },
      ],
      treeData: [], // 网站导航
    };
  },
  async fetch() {
    this.token = this.$store.state.login.loginInfo.token;
    this.status = this.$store.state.login.loginInfo.statusShow;
    this.userdetail = JSON.parse(
      this.$store.state.login?.loginInfo?.userdetail || '{}'
    );
  },
  mounted() {
    if (process.client) {
      this.token = this.$store.state.login.loginInfo.token;
      this.status = this.$store.state.login.loginInfo.statusShow;
      this.userdetail = JSON.parse(
        this.$store.state.login?.loginInfo?.userdetail || '{}'
      );
      this.updateRouter(); // 判断高亮导航栏
      this.judgeReacquire(); // 判断是否需要重新获取地址、网站导航信息
    }
  },
  computed: {
    // 当前是否有未读通知
    hasUnreadMsgs() {
      return this.$store.state?.chat?.unread > 0;
    },
    // 未读通知数
    unreadMsgNum() {
      let num = this.$store.state?.chat?.unread;
      return num > 99 ? '99+' : num;
    },
    // 是否有未读通知
    hasUnreadNotices() {
      return false; // #todo
    },
    // 未读通知数展示
    unreadNoticeNum() {
      return '0'; //  #todo
    },
  },
  watch: {
    '$route.path'(n) {
      if (process.client) {
        this.updateRouter();
      }
    },
  },
  methods: {
    // 刷新后根据路由判断导航栏样式
    updateRouter() {
      if (this.isFrontDesk) return; // 是前台页面不需要判断导航栏高亮哪一个
      let currentPath = this.$route.path;
      const result = this.rightList.find((item) =>
        currentPath.startsWith(item.link)
      );
      if (result) this.activeIndex = result?.id;
    },
    // 获取精简省地区
    getState() {
      let options = {
        id: 44,
        is_simplify: 1,
      };
      this.$getStates(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.areaList = res?.data || [];
            this.$store.commit('home/addProvinceList', this.areaList);
            // 默认选中一个地址
            this.setDefaultArea(this.areaList);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 设置当前地址
    setCurrArea(
      province_id,
      province,
      country_id = '44',
      country = '中国',
      ...areaEnData
    ) {
      if (!province_id || !province) {
        return;
      }
      // 设置当前页面的数据
      this.localArea = province;

      // 设置 vuex 中存的全局数据
      let areaData = {
        country,
        country_id,
        province,
        province_id,
        province_en: areaEnData?.[0] || '',
        country_en: areaEnData?.[1] || '',
      };
      this.$store.dispatch('common/setCurrLocation', areaData);
    },
    /**
     * 默认选中一个地址
     * #todo 当前做的是直接默认到广东，之后可能要根据用户ip所在地动态切换
     * 所以留了一个口 defaultId ，可以动态传入用户ip所在地id
     * @param { Array } areas 地址列表
     * @param { String } defaultId 默认地址的id，默认为广东省id
     */
    setDefaultArea(areas, defaultId = '3558') {
      // 首先去 vuex 里找是否有已选中的地址，需要中文地址
      let item = this.$store.state.common.currLocation;
      // 如果没有，则用默认的地址
      if (!item || !item.province_id) {
        item = areas?.find((x) => x.id === defaultId);
      }
      this.setCurrArea(
        item.province_id || item.id,
        item.province || item.cname,
        item.country_id,
        item?.country,
        item.name || item.province_en || '', // 省份的英文
        item.country_en || 'China' // 国家的英文
      );
    },
    // 选择地区
    choseArea(item) {
      this.setCurrArea(
        item.id,
        item.cname,
        item.country_id,
        item?.country,
        item.name || '',
        'China'
      );
    },
    // 获取网站导航
    getNavbar() {
      let option = { MUTE_WARNING: 1 };
      this.$getNavbar(option)
        ?.then((res) => {
          if (res?.errcode == 0) {
            this.treeData = res?.data?.list || [];
            this.$store.commit('home/addSiteNavList', this.treeData);
          }
        })
        ?.catch((err) => {
          console.error(err);
        });
    },
    // 判断是否需要重新获取地址、网站导航信息
    judgeReacquire() {
      this.areaList = this.$store.state.home.provinceList || []; // 地址信息
      this.treeData = this.$store.state.home.siteNavList || []; // 网站导航
      let lastTime = this.$store.state.home.changeTime || null; // 上一次存储时间
      let nowTime = new Date().getTime();
      // 当前时间-上次存储时间 > 一天, 重新获取
      if (nowTime - lastTime > 24 * 60 * 60 * 1000) {
        this.getState(); // 获取地址信息
        this.getNavbar(); // 获取网站导航信息
        return;
      }
      if (this.areaList?.length == 0) {
        this.getState(); // 获取地址信息
      } else {
        this.setDefaultArea(this.areaList); // 默认选中一个地址
      }
      if (this.treeData?.length == 0) this.getNavbar(); // 获取网站导航信息
    },
    // 点击导航栏跳转对应链接
    jumpPage(index, link) {
      // 循环出来的数据
      if (index != 11 && link) {
        this.diffJumpPage(link);
      }
      // 聊天
      if (index == 11) {
        this.chatWithCustomerService();
      }
    },
    // 头像下拉框跳转
    mineShip(index) {
      this.activeIndex = -1;
      if (index == 1) {
        // 地址管理
        this.diffJumpPage('/mine/addressmannge');
      } else if (index == 2) {
        // 授权书管理
        this.diffJumpPage('/mine/authorizationmanage');
      } else if (index == 3) {
        // 登出
        this.quit();
      }
    },
    // 前台页面打开新标签页，后台页面跳转当前页面
    diffJumpPage(link) {
      if (!this.token) {
        this.$router.push('/login');
        return;
      }
      if (this.isFrontDesk) this.openNewWindow(link);
      if (!this.isFrontDesk) this.currencyJump(link);
    },
    // 当前页面跳转
    currencyJump(link) {
      this.$router.push(link);
    },
    // 切换至海外分销
    changeOversea() {
      window.open(process.env.NUXT_ENV.VUE_JUMP_SHOP_Local, '_self');
    },
    // 退出登录
    quit() {
      tools.logout();
    },
  },
};
