
import {
  getSupAddrList,
  addSupAddr,
  editSupAddr,
  delSupAddr,
  setSupAddr,
  getPhoneCode,
} from '@/api/client-api/mine/mineapi.js';
import publicAddress from './components/publicAddress.vue';
import areaSelectRegion from './components/areaSelectRegion.vue';
// 组件
import AutomaticIdentification from '@/pages/goods-pay/placeOrder/components/AutomaticIdentification.vue';
const hasChineseChar = new RegExp('[\\u4e00-\\u9fa5]+');
const TELNUMBER = /^1\d{10}$/; // 大陆手机号验证
const ZIPCODE = /^[0-9]\d{5}$/; // 中国邮政编码验证
export default {
  name: 'AddressMannge',
  components: {
    publicAddress,
    areaSelectRegion,
    AutomaticIdentification,
  },
  data() {
    return {
      regionarr: [],
      toptitle: '地址管理',
      editmodelshoe: false,
      tableData: [],
      addobj: {
        contentname: '',
        phoneCodeObject: {}, // 整个区号
        phoneCode: '', // 区号
        contenttel: '', // 手机号
        countryType: '1', // 1国内 2国外
        areaSelect: {
          country: '', // 国
          country_id: '', // 国id
          province: '', // 省
          province_id: '', // 省id
          city: '', // 市
          city_id: '', // 市id
          region: '', // 区
          region_id: '', // 区id
        },
        address: '', // 详细地址
        zipcode: '', // 邮编
        checked: false,
        checkedaccept: false,
      },
      phoneCodeList: [], // 手机区号列表
      areaSelectKey: 0, // 地区选择刷新
      addressStatus: false, // 判断当前地址选择是否选到了最低一级
      zoom: 12,
      center: [121.59996, 31.197646],
      tablenum: 1,
      tabletotal: 1,
      pageSize: 10, // 表格页数
      addresstype: 'edit',
      isEdit: false,
      unselectedFlag: [], //判断该国家是否无省无市
      codeLimit: '', // 手机号位数限制长度
      isChinaLand: false, // 区号是否是中国大陆
      addressErrorTip: false, // 地址选择器错误提示
      addressMaxLimit: 120, // 详细地址最大字数限制
      addressMinLimit: 2, // 详细地址最小字数限制
      receiveNameMaxLimit: 25, // 收货人最大字数限制
    };
  },
  mounted() {
    this.getSupAddrList();
  },
  computed: {
    // 语言
    lang() {
      if (this.addobj.countryType == '1') return 'zh-cn';
      if (this.addobj.countryType == '2') return 'en';
    },
    // 根据地区选择拿不同配置
    configInfo() {
      // 国内
      if (this.addobj.countryType == '1') {
        return {
          continentId: '44',
          countryInitial: 'Z',
          startAddress: 'province',
          endAddress: 'area',
          countryType: '0',
        };
      } else {
        // 国外
        return {
          continentId: '',
          countryInitial: '',
          startAddress: 'country',
          endAddress: 'city',
          countryType: '1',
        };
      }
    },
    // 表单验证规则
    addobjrules() {
      let fontCheck,
        detilTrimCheck,
        detilAddressCheck,
        phoneCheck,
        zipCodeCheck;
      // 不区分国内外
      phoneCheck = (rule, value, callback) => {
        if (
          (!this.codeLimit && value?.trim()?.length > 15) ||
          (this.codeLimit && value?.trim()?.length != Number(this.codeLimit)) ||
          (this.isChinaLand && !TELNUMBER.test(value?.trim()))
        ) {
          callback(new Error('手机号码格式不正确'));
        }
        callback();
      };
      detilTrimCheck = (rule, value, callback) => {
        if (value?.trim()?.length < this.addressMinLimit) {
          callback(
            new Error(
              `详细地址长度需要在${this.addressMinLimit}-${this.addressMaxLimit}个汉字或字符之间，不能包含特殊字符`
            )
          );
        } else {
          callback();
        }
      };
      // 国内
      if (this.addobj.countryType == '1') {
        // 联系人
        fontCheck = (rule, value, callback) => {
          if (value?.trim()?.length > 25) {
            callback(new Error('联系人超过25字符'));
          } else {
            callback();
          }
        };
        // 邮编
        zipCodeCheck = (rule, value, callback) => {
          if (value?.trim() && !ZIPCODE.test(value?.trim())) {
            callback(new Error('邮政编码格式错误'));
          } else {
            callback();
          }
        };
      }
      // 国外
      if (this.addobj.countryType == '2') {
        // 联系人
        fontCheck = (rule, value, callback) => {
          if (value?.trim()?.length > 100) {
            callback(new Error('联系人超过100字符'));
          }
          hasChineseChar.lastIndex = 0;
          if (hasChineseChar.test(value)) {
            callback(new Error('联系人含有中文'));
          }
          callback();
        };
        // 详细地址
        detilAddressCheck = (rule, value, callback) => {
          hasChineseChar.lastIndex = 0;
          if (hasChineseChar.test(value)) {
            callback(new Error('详细地址含有中文'));
          } else {
            callback();
          }
        };
        // 邮编
        zipCodeCheck = (rule, value, callback) => {
          hasChineseChar.lastIndex = 0;
          if (hasChineseChar.test(value)) {
            callback(new Error('邮编含有中文'));
          } else {
            callback();
          }
        };
      }
      let rules = {
        contentname: [
          {
            required: true,
            message: `收货人姓名长度需要在1-${this.receiveNameMaxLimit}个汉字或字符之间，不能包含特殊字符`,
            trigger: ['blur', 'change'],
          },
          { validator: fontCheck, trigger: ['blur', 'change'] },
        ],
        phoneCode: [
          { required: true, message: '请选择区号', trigger: 'change' },
        ],
        contenttel: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: phoneCheck, trigger: 'blur' },
        ],
        countryType: [
          { required: true, message: '请选择地区', trigger: 'change' },
        ],
        address: [
          {
            required: true,
            validator: detilTrimCheck,
            message: `详细地址长度需要在${this.addressMinLimit}-${this.addressMaxLimit}个汉字或字符之间，不能包含特殊字符`,
            trigger: 'blur',
          },
          { validator: detilAddressCheck, trigger: ['blur', 'change'] },
        ],
        zipcode: [
          {
            required: this.addobj.countryType != '1',
            // pattern: /^[0-9]\d{5}$/,
            message: '请输入邮政编码',
            trigger: 'blur',
          },
          { validator: zipCodeCheck, trigger: 'blur' },
        ],
      };
      return rules;
    },
  },
  methods: {
    //判断只能输入数字
    allNumber(value, filed) {
      this.addobj[filed] = value.replace(/[^\d]/g, '');
    },
    // 点击新建地址
    newaddress() {
      this.addressStatus = false; // 默认地址没有选择完整
      this.addobj = this.$options.data().addobj;
      this.addresstype = 'new';
      this.editmodelshoe = !this.editmodelshoe;
      this.toptitle = '新增收货地址';

      this.getPhoneCodeList(); // 获取手机区号
    },
    // 表格点击编辑
    tableedit(obj) {
      this.addobj.areaSelect = {
        country: obj?.countries_name ? obj?.countries_name : '', // 国
        country_id: obj?.country_id ? obj?.country_id : '', // 国id
        province: obj?.province_name ? obj?.province_name : '', // 省
        province_id: obj?.province_id ? obj?.province_id : '', // 省id
        city: obj?.city_name ? obj?.city_name : '', // 市
        city_id: obj?.city_id ? obj?.city_id : '', // 市id
        area: obj?.region_name ? obj?.region_name : '', // 区
        area_id: obj?.region_id ? obj?.region_id : '', // 区id
      };

      this.addobj.id = obj?.id; // 地址id
      this.addobj.phoneCodeObject = { id: obj?.code_id }; // 电话区号
      this.addobj.phoneCode = obj?.code_id; // 电话区号id
      this.addobj.contenttel = obj?.phone; // 联系电话
      this.addobj.countryType = obj?.type?.toString(); // 地区 1国内 2国外
      this.addobj.contentname = obj?.username; // 联系人
      this.addobj.address = obj?.address; // 详细地址
      this.addobj.zipcode = obj?.zip; // 邮政编码
      this.addobj.checked = obj?.status == 1 ? true : false;
      this.addobj.checkedaccept = obj?.invoice_status == 1 ? true : false;
      this.editmodelshoe = true;
      this.toptitle = '编辑收货地址';
      this.addresstype = 'edit';

      this.areaSelectKey++; // 回显地址选择器需要
      this.addressStatus = true; // 默认地址选择完整

      this.getPhoneCodeList(); // 获取手机区号
    },
    // 点击取消按钮
    cancel() {
      this.editmodelshoe = !this.editmodelshoe;
      this.toptitle = '地址管理';
      this.addobj = this.$options.data().addobj;
    },
    // 点击保存地址
    saveAddress() {
      this.$refs.addressForm.validate((valid) => {
        if (!this.addressStatus) {
          this.addressErrorTip = true;
          return;
        }
        if (valid) {
          let option = {
            type: this.addobj.countryType, // 1国内 2国外
            code_id: this.addobj.phoneCode, // 手机区号id
            address: this.addobj.address, // 详细地址
            username: this.addobj.contentname?.trim(), // 联系人
            phone: this.addobj.contenttel?.trim(), // 手机号
            invoice_status: this.addobj.checkedaccept ? '1' : '2', // 发票地址状态 1 默认 2非默认 3删除
            status: this.addobj.checked ? '1' : '2', // 地址状态 1 默认 2非默认 3删除
            province_id: this.addobj.areaSelect.province_id, // 省id
            city_id: this.addobj.areaSelect.city_id, // 市id
          };

          // 邮政编码
          if (this.addobj.zipcode?.trim()) {
            option.zip = this.addobj.zipcode?.trim();
          }

          // 国家id
          if (this.addobj.countryType == '1') {
            option.country_id = '44'; // 国id
            option.region_id = this.addobj.areaSelect.area_id; // 区id
          }
          if (this.addobj.countryType == '2') {
            option.country_id = this.addobj.areaSelect.country_id; // 国id
          }

          if (this.addresstype == 'new') {
            addSupAddr(option)
              .then((res) => {
                if (res?.errcode == 0) {
                  if (res?.msg) {
                    this.$message.success(res?.msg);
                  }
                  this.tablenum = 1;
                  this.getSupAddrList();
                  this.cancel();
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            option.id = this.addobj.id;
            editSupAddr(option)
              .then((res) => {
                if (res?.errcode == 0) {
                  if (res?.msg) {
                    this.$message.success(res?.msg);
                  }
                  this.tablenum = 1;
                  this.getSupAddrList();
                  this.cancel();
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        } else {
          return false;
        }
      });
    },
    // 获取列表数据
    getSupAddrList() {
      let option = {
        pages: this.tablenum,
        pageSize: this.pageSize,
        MUTE_WARNING: 1,
      };
      getSupAddrList(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.tableData = res?.data?.data;
            this.tabletotal = Number(res?.data?.count) || 0;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 设置默认地址
    setSupAddr(obj) {
      let option = {
        id: obj.id,
        MUTE_WARNING: 1,
      };
      setSupAddr(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.getSupAddrList();
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 删除地址
    delSupAddr(obj) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: 'delCancelDialog',
        confirmButtonClass: 'delConfirmDialog',
      })
        .then(() => {
          let option = {
            id: obj.id,
            MUTE_WARNING: 1,
          };
          delSupAddr(option)
            .then((res) => {
              if (res?.errcode == 0) {
                this.tablenum = 1;
                this.getSupAddrList();
                if (res?.msg) {
                  this.$message.success(res?.msg);
                }
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {});
    },
    // 选择地址
    choseArea(info, enInfo, status) {
      console.log(info, '选中的地址信息', status);
      // console.log(info, '选中的地址信息');
      this.addobj.areaSelect = {
        country: info.country, // 国
        country_id: info.country_id, // 国id
        province: info.province, // 省
        province_id: info.province_id, // 省id
        city: info.city, // 市
        city_id: info.city_id, // 市id
        area: info.area, // 区
        area_id: info.area_id, // 区id
      };
      this.addressStatus = status;
      this.addressErrorTip = !status; // 错误提示展示状态
    },
    // 获取手机区号
    getPhoneCodeList() {
      let options = {
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      getPhoneCode(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.phoneCodeList = res?.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 改变地区
    changeCountryType() {
      this.$refs.addressSelect.clearAddressFunc();
      this.areaSelectKey++;
      this.getPhoneCodeList(); // 获取手机区号
    },
    // 选择手机区号
    chosePhoneCode(even) {
      this.codeLimit = even?.limitation?.toString() || '';
      this.addobj.phoneCode = even?.id || '';
      if (even?.code == '86') {
        this.isChinaLand = true;
      } else {
        this.isChinaLand = false;
      }
    },
    // -----------------------------------------------------
    // 智能识别
    setAddress(data) {
      // 地址信息
      const options = ['country', 'state', 'city', 'area'];
      for (let i = 0; i < options?.length; i++) {
        if (data?.[options?.[i] + '_id'] && data?.[options?.[i] + '_name']) {
          this.addobj.areaSelect[options?.[i] + '_id'] =
            data?.[options?.[i] + '_id'];
          this.addobj.areaSelect[options?.[i]] = data?.[options?.[i] + '_name'];
          // 省的情况
          if (options?.[i] == 'state' || options?.[i] == 'province') {
            this.addobj.areaSelect['province_id'] =
              data?.[options?.[i] + '_id'];
            this.addobj.areaSelect['province'] = data?.[options?.[i] + '_name'];
          }
        }
      }
      if (data?.area_id) {
        this.addressStatus = true;
      }
      this.areaSelectKey++; // 回显地址选择器需要
      // 详细地址
      if (data?.detail) {
        this.addobj.address = data?.detail || '';
      }
      // 收货人
      if (data?.receive_name) {
        this.addobj.contentname = data?.receive_name || '';
      }
      // 手机号
      if (data?.phone) {
        this.addobj.contenttel = data?.phone || '';
      }
    },
    goPage(val = 1, pageSize) {
      this.tablenum = Number(val) || 1;
      if (pageSize) {
        this.pageSize = pageSize;
      }
      this.getSupAddrList();
    },
  },
};
