
export default {
  name: 'TypeChose',
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 选择注册类型
    needRegister(type) {
      this.$emit('register-type', type);
    },
  },
};
