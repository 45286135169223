
import {
  getList,
  getNewList,
  getItemIdTitleList,
  addItemNeed,
  addNewItemNeed,
  dealNeedResult,
} from "@/api/client-api/goods/GoodsDemand.js";
import NewGoodsNeed from "@/pages/mall-home/home/components/NewGoodsNeed";
export default {
  components: {
    NewGoodsNeed,
  },
  name: "goodsDemand",
  data() {
    return {
      searchObj: {
        DemandType: "1",
      },
      typeNum: "-1",
      deveOptions: [
        { label: "全部状态", value: "" },
        { label: "完成开发", value: "1" },
        { label: "处理中", value: "0" },
        { label: "开发失败", value: "2" },
        { label: "已拒绝", value: "3" },
      ], //开发状态
      demandOptions: [
        { label: "现有商品需求", value: "1" },
        { label: "开发新品需求", value: "2" },
      ], //商品需求类型
      currentpage: "",
      newsNeedDialog: false,
      uploadDialogVisible: false,
      formobjNow: {
        goodsID: "",
        pubilc: "",
        uploadshopimg: [],
        name: "",
      }, //现有需求
      goodsIDoptions: [], //商品ID
      goodsDeoptions: [
        { label: "降价需求", value: "1" },
        { label: "语言素材", value: "2" },
        { label: "素材补充", value: "3" },
        { label: "仓库增加", value: "4" },
        { label: "活动增加", value: "5" },
        { label: "包装建议", value: "6" },
      ], //商品需求
      dialogVisibleNow: false,
      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        reacpe: [
          {
            required: true,
            message: "请选择是否接受相似产品",
            trigger: "change",
          },
        ],
        pubilc: [
          {
            required: true,
            message: "请选择是否允许平台公开展示",
            trigger: "blur",
          },
        ],
      },
      rules1: {},
      type: "",
      total: 0,
      pages: 1,
      pageSize: 8,
      // 状态弹框
      dialogVisibleStatus: false,
      statusTitle: "", //状态标题
      statusStyle: "", //className
      statusInfo: {
        title: "123123",
        statue: "1",
      },
      tableList: [], //商品需求列表
      dialogSuccess: false, //成功弹框
      dialogErr: false, //拒绝弹框
      remark: "", //拒绝原因
      jsq: null,
      productlist: [], //商品ID列表
      token: this.$store.state.login.loginInfo.token,
    };
  },
  computed: {},
  watch: {},
  filters: {},
  mounted() {
    this.getNowList();
    // this.getNeList();
  },
  methods: {
    clearInput() {
      this.getSearchList();
    },
    getSearchList() {
      this.pages = 1;
      if (this.$refs.pageTools) {
        this.$refs.pageTools.changecurPage(this.pages);
      }
      this.searchList();
    },
    searchList() {
      if (this.searchObj.DemandType == "1") {
        this.getNowList();
      } else if (this.searchObj.DemandType == "2") {
        this.getNeList();
      }
    },
    // 需求类型
    changDemandTypeType(e) {
      this.pages = 1;
      if (this.$refs.pageTools) {
        this.$refs.pageTools.changecurPage(this.pages);
      }

      if (e == "1") {
        this.getNowList();
      } else if (e == "2") {
        this.getNeList();
      }
    },
    // 获取现有商品列表
    getNowList() {
      const opgions = {
        pageNum: this.pages,
        pageSize: this.pageSize,
        MUTE_WARNING: 1,
      };
      opgions.search_value = this.searchObj.itemName;
      opgions.develop_type = this.searchObj.deveType;
      getList(opgions)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.data?.list) {
              this.tableList = res?.data.list;
              this.total = Number(res?.data?.total) || 0;
            } else {
              this.tableList = [];
              this.total = 0;
            }
            if (res.data.list && res.data.list.length > "3") {
              this.typeNum = "1";
            }
            if (res.data.list && res.data.list.length <= "3") {
              this.typeNum = "-1";
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取新品现有商品
    getNeList() {
      const options = {
        pageNum: this.pages,
        pageSize: this.pageSize,
        MUTE_WARNING: 1,
      };
      options.keyword = this.searchObj.itemName;
      options.develop_type = this.searchObj.deveType;
      getNewList(options)
        .then((res) => {
          if (res?.errcode == 0) {
            let list = [];
            if (res?.data?.list) {
              list = JSON.parse(JSON.stringify(res?.data?.list));
            }
            if (list.length > 0) {
              // list.forEach((item) => {
              //   item.develop_item_link1 =
              //     'https://shop.ximu.cn/shopdetail?id=' + item.develop_item_link;
              // });
              this.total = Number(res?.data?.total) || 0;
              this.tableList = list;
            } else {
              this.total = 0;
              this.tableList = [];
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击确定跳转
    goPageBtn(val) {
      this.pages = val;
      // 现有列表
      if (this.searchObj.DemandType == "1") {
        this.getNowList();
      } else if (this.searchObj.DemandType == "2") {
        this.getNeList();
      }
    },
    // 点击分页模块事件
    currentChange(val) {
      this.pages = val;
      // 现有列表
      if (this.searchObj.DemandType == "1") {
        this.getNowList();
      } else if (this.searchObj.DemandType == "2") {
        this.getNeList();
      }
    },
    goPage(val) {
      this.pages = val;
      // 现有列表
      if (this.searchObj.DemandType == "1") {
        this.getNowList();
      } else if (this.searchObj.DemandType == "2") {
        this.getNeList();
      }
    },
    // 打开素材管理
    picgoods(value) {
      this.uploadDialogVisible = true;
      this.type = value;
    },
    closepublic() {
      this.uploadDialogVisible = false;
    },
    beforeUpload(file) {
      let flag = true;
      if (file.size / 1024 / 1024 > 5) {
        flag = false;
      }
      return flag;
    },
    successUpload(response, file, fileList) {
      this.formobjNow.uploadshopimg.push(response.data.link);
    },

    closeDialogFunc(falseAndTrue, flag, file) {
      this.formobjNow.uploadshopimg.push(file.key);
      this.uploadDialogVisible = false;
    },
    // 开发需求提交事件
    saveBtn(n) {
      // n:1-新品  2-现有
      if (n == "1") {
        this.searchObj.DemandType = "2";
        this.addItemNewNeed();
      } else if (n == "2") {
        this.searchObj.DemandType = "1";
        this.addItemNowNeed();
      }
    },
    // 开发现有商品事件
    async addItemNowNeed() {
      try {
        const options = {
          demand_type: this.formobjNow.pubilc, //需求类型（1-降价需求 2-语言素材 3-素材补充 4-仓库增加 5-活动增加 6-包装建议 ）
          item_id: this.formobjNow.goodsID, //商品ID
          demand_detail: this.formobjNow.remark, //详细描述
        };
        if (this.formobjNow.uploadshopimg.length > 0) {
          options.demand_image = this.formobjNow.uploadshopimg.join(",");
        }
        if (options.item_id) {
          if (options.item_id == "") {
            this.$message.warning("请输入商品ID");
            return;
          }
        }
        if (this.formobjNow.name == "") {
          this.$message.warning("请输入商品ID");
          return;
        }
        if (this.formobjNow.pubilc == "") {
          this.$message.warning("请选择商品需求");
          return;
        }
        const res = await addItemNeed(options);
        if (res?.errcode == 0) {
          if (res?.msg) {
            this.$message.success(res?.msg);
          }
          this.dialogVisibleNow = !this.dialogVisibleNow;
          this.getSearchList();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 现有需求弹框事件
    getItemInfo(query) {
      let option = {
        item_id: query,
        MUTE_WARNING: 1,
      };
      getItemIdTitleList(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.productlist = res?.data;
          } else {
            this.productlist = [];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    prodectchange(val) {
      for (let i = 0; i < this.productlist.length; i++) {
        if (this.productlist[i].item_id == val) {
          this.formobjNow.name = this.productlist[i].title;
          this.goodsID = this.productlist[i].item_id;
        }
      }
    },
    //商品ID获得焦点触发
    selectfoucs() {
      setTimeout(() => {
        let rulesDom = this.$refs["shopId"].$el.querySelector(
          ".el-input .el-input__suffix .el-input__suffix-inner .el-input__icon"
        ); // 找到dom
        rulesDom.classList.add("is-reverse");
      }, 0);
    },
    //商品ID失去焦点触发
    selectblur() {
      setTimeout(() => {
        let rulesDom = this.$refs["shopId"].$el.querySelector(
          ".el-input .el-input__suffix .el-input__suffix-inner .el-input__icon"
        ); // 找到dom
        rulesDom.classList.contains("is-reverse")
          ? rulesDom.classList.remove("is-reverse")
          : "";
        rulesDom.classList.add("el-icon-arrow-up");
      }, 0);
    },
    // 现有初始化
    closeInfoNow() {
      this.formobjNow = {
        reacpe: "",
        pubilc: "",
        uploadshopimg: [],
        name: "",
      };
      this.productlist = [];
    },
    // 状态初始化
    closeInfoStatus() {
      this.jsq = setTimeout(() => {
        // this.statusInfo = {};
        clearTimeout(this.jsq);
        this.jsq = null;
      }, 500);
    },
    // 打开状态弹框
    openStatus(info) {
      this.statusInfo = info;
      if (info.demand_have_id) {
        this.statusTitle = "需求ID：" + info.demand_have_id;
      } else if (info.need_id) {
        this.statusTitle = "需求ID：" + info.need_id;
      }
      if (this.statusInfo.state == "3") {
        this.statusStyle = "newBox1";
      } else if (this.statusInfo.demand_status == "4") {
        this.statusStyle = "newBox1";
      } else {
        this.statusStyle = "";
      }

      this.dialogVisibleStatus = true;
    },
    // 接受按钮
    acceptedBtn() {
      // console.log(this.statusInfo, 123213);
      const options = {
        is_accept: "1", //是否接受0-不接受 1- 接受
      };
      if (this.searchObj.DemandType == "1") {
        // 需求ID
        options.need_id = this.statusInfo.demand_have_id;
      } else if (this.searchObj.DemandType == "2") {
        options.need_id = this.statusInfo.need_id;
      }
      this.btnFunction(options);
    },
    // 不接受按钮
    notAcceptedBtn() {
      this.dialogVisibleStatus = !this.dialogVisibleStatus;
      this.dialogErr = !this.dialogErr;
    },
    // 拒绝确定弹框
    btnRes() {
      const options = {
        is_accept: "0",
        reason: this.remark,
      };
      if (this.searchObj.DemandType == "1") {
        // 需求ID
        options.need_id = this.statusInfo.demand_have_id;
      } else if (this.searchObj.DemandType == "2") {
        options.need_id = this.statusInfo.need_id;
      }

      if (this.remark == "") {
        this.$message.warning("请输入拒绝原因");
        return;
      }
      this.btnFunction(options);
    },
    //查看商品详情
    goGoodsInfo() {
      // 现有
      if (this.searchObj.DemandType == "1") {
        this.toCachedShopdetail(
          this.statusInfo.item_id,
          this.statusInfo.image_default_id,
          this.statusInfo.sku_lowest_price_img
        );
      }
      // 新品
      if (this.searchObj.DemandType == "2") {
        // this.$router.push({
        //   path: '/shopdetail',
        //   query: { id: this.addUrl(this.statusInfo.develop_item_link) },
        // });
        // #fixme 跳转详情需要商品id和商品主图地址，很可能是错的
        let imgUrl =
          this.statusInfo.list && this.statusInfo.list.length > 0
            ? this.statusInfo.list[0]
            : "";
        this.toCachedShopdetail(this.statusInfo.develop_item_link, imgUrl);
      }
    },
    btnFunction(options) {
      dealNeedResult(options)
        .then((res) => {
          if (res?.errcode == 0) {
            // 接受
            if (options.is_accept == "1") {
              // 现有
              if (this.searchObj.DemandType == "1") {
                this.getNowList();
              } else if (this.searchObj.DemandType == "2") {
                // 新品
                this.getNeList();
              }
              this.dialogSuccess = !this.dialogSuccess;
              this.dialogVisibleStatus = !this.dialogVisibleStatus;
            } else if (options.is_accept == "0") {
              this.$message.success(res.msg);
              // 不接受
              this.dialogErr = !this.dialogErr;
              // 现有
              if (this.searchObj.DemandType == "1") {
                this.getNowList();
              } else if (this.searchObj.DemandType == "2") {
                // 新品
                this.getNeList();
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 现有弹框删除
    delUploadshopimg(index) {
      this.formobjNow.uploadshopimg.splice(index, 1);
    },
    // 关闭新品弹框
    closeNewNeed() {
      this.newsNeedDialog = false;
    },
    // 新品弹框确认按钮
    submit() {
      // this.getNowList();
      this.searchList();
    },
  },
};
