
export default {
  data() {
    return {};
  },
  props: {
    // 为商品详情特定的
    goodsInfo: {
      type: Object,
      default: {},
    },
  },
  computed: {
    categories() {
      if (
        this.goodsInfo &&
        this.goodsInfo.good_categories &&
        this.goodsInfo.good_categories?.length > 0
      ) {
        let categoriesName = ''
        for (let i = 0; i < this.goodsInfo.good_categories.length; i++) {
          if (i>0) {
            categoriesName +='-' + this.goodsInfo.good_categories[i].name
          } else {
            categoriesName += this.goodsInfo.good_categories[i].name
          }

        }
        return categoriesName
      }
    },
  },
  mounted() {},
  methods: {
    clickEvents() {
      const obj = {
        item_id: this.item_id,
        image_default_id: this.src,
        sku_lowest_price_img: this.sku_lowest_price_img,
      };
      this.$emit('comDetails', obj);
    },
  },
};
