
import accountManager from '@/components/comme/accountManager.vue';
// 跨域登录
import {
  crossDomainLogin,
  removeDom,
} from '@/utils/cross-domain-login/cross_domain_login.js';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'contactBackend',
  components: {
    accountManager,
  },
  data() {
    return {
      token: '',
    };
  },

  methods: {
    /**
     * 未登录点击客服 跳转登录页
     * 登录后点击客服 跳转相应聊天页面
     */
    handleClickCustomerService() {
      if (this.token) {
        this.chatWithCustomerService();
      } else {
        this.goLogin();
      }
    },
    goLogin() {
      this.$router.push('/login');
    },
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
    if (process.client) {
      crossDomainLogin('iframe-box-max');
    }
  },
  destroyed() {
    removeDom('iframe-box-max');
  },
};
