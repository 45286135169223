
import ReviewItem from '../ReviewItem';
import OperationGroup from '../OperationGroup';

// 弹框
import AppealForm from '../AppealForm';
import ProcessingEvaluation from '../ProcessingEvaluation';
import InitiateComplaints from '../InitiateComplaints';

import { isReport } from '../../js/utils';
export default {
  name: 'ReportTable',
  components: {
    ReviewItem,
    OperationGroup,
    AppealForm,
    ProcessingEvaluation,
    InitiateComplaints,
  },
  props: {
    // 当前切换到的主标签页
    tab: {
      type: [String, Number],
      default: '',
    },
    // 当前切换到的次标签页
    block: {
      type: String,
      default: '',
    },
    // 表格配置
    tableFormat: {
      type: Array,
      default: () => [],
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // 数据总条数
    total: {
      type: Number,
      default: 0,
    },
    // 每页数据
    pageSize: {
      type: Number,
      default: 10,
    },
    // 页码
    pageNum: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      timers: null, // 定时器数组，每一行表格对应各自的定时器
      // 避免触发 watch 监听，所以用临时变量 countdownSeconds 保存倒计时的秒数
      countdownSeconds: null, // 倒计时数组，每一行表格对应各自的剩余时间

      currComplaintId: '', // 当前点击的行对应的投诉id
      currComplaintInfo: null, // 当前点击的行对应的投诉信息

      showReviewDialog: false, // 是否展示评价弹窗
      showAppealDialog: false, // 是否展示申诉弹窗
      showSecondReportDialog: false, // 是否展示发起二次投诉弹窗
    };
  },
  filters: {
    // 空值处理
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  watch: {
    // 侦听父组件传过来的列表数据
    tableData: {
      deep: true,
      handler: function (newVal) {
        this.countdownSeconds = null;
        if (!this.timers) {
          this.timers = new Array(newVal?.length || 0);
        }
        // 初始化倒计时秒数数组
        // 仅在秒数数组从未初始化 且 表格有数据 时开启倒计时
        if (!this.countdownSeconds && newVal?.length > 0) {
          this.countdownSeconds = newVal?.map((x) => x.remain_second);
          this.countdown(); // 开启倒计时
        }
      },
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.timers) {
      this.timers.forEach((item) => {
        if (item) {
          clearInterval(item);
          item = null;
        }
      });
    }
    this.countdownSeconds = null;
  },
  methods: {
    /**
     * 工具函数
     */
    // 根据行数据过滤出对应的操作
    // 本来使用 filter，但 filter 无法获取 vue 实例，所以改用普通的方法
    filterOps(row) {
      const ops = [
        {
          label: '查看详情',
          event: 'detail',
        },
      ];
      let tab = this.tab; // 当前的主标签页id
      let block = this.block; // 当前为“收到”还是“发起”
      let hasSolved = row?.deal_status == 2; // 是否已处理
      let hasNotEstablished = row?.is_establish == 2; // 投诉、举报是否不成立
      let hasReviewed = Number(row?.evaluate_time || 0) > 1; // 是否已评价
      let allowAppeal = row?.appeal_status == 1; // 是否还能申诉（未申诉且未超时）
      let hasSecondReport = row?.is_complaint == 1; //是否已经二次投诉
      let canAppeal = isReport(row?.cate_id);
      if (block === 'launch') {
        // 发起表格的操作列配置
        // 已处理且还没评价，就展示评价操作
        if (hasSolved && !hasReviewed) {
          ops.push({ label: '评价', event: 'review' });
        }
        // 当前为一次投诉/举报，且投诉/举报结果是 不成立 时，才显示二次投诉
        if (tab == 1 && hasNotEstablished && !hasSecondReport & !canAppeal) {
          ops.push({ label: '二次投诉', event: 'second-report' });
        }
      } else if (block === 'receive') {
        // 收到表格的操作列配置
        // 未处理且还能申诉
        if (!hasSolved && allowAppeal && !canAppeal) {
          ops.push({ label: '申诉', event: 'appeal' });
        }
        if (tab == 1 && hasSecondReport) {
          ops.push({ label: '二次投诉列表', event: 'second-list' });
        }
      }

      return ops;
    },
    // 倒计时
    countdown() {
      this.timers.forEach((item) => {
        if (item) {
          clearInterval(item);
          item = null;
        }
      });
      if (this.tableData && this.tableData.length > 0) {
        this.tableData.forEach((item, index) => {
          if (
            item.deal_status === '1' &&
            item.appeal_status === '1' &&
            !isReport(item.cate_id) &&
            this.block != 'launch'
          ) {
            let handleTime = this.countdownSeconds[index] * 1000;
            const deadlineTime = this.getNowTimeDay(handleTime);
            this.$nextTick(() => {
              const countdownEl = document.getElementById(
                'appeal-countdown-timer-' + item.complaint_id
              );
              if (countdownEl) {
                countdownEl.innerHTML = deadlineTime;
              }
            });
          } else {
            this.$nextTick(() => {
              const countdownEl = document.getElementById(
                'appeal-countdown-timer-' + item.complaint_id
              );
              if (countdownEl) {
                countdownEl.innerHTML = '<span>--</span>';
              }
            });
          }
        });
        this.tableData.forEach((item, index) => {
          if (
            item.deal_status === '1' &&
            item.appeal_status === '1' &&
            !isReport(item.cate_id) &&
            this.block != 'launch'
          ) {
            this.timers[index] = setInterval(() => {
              let handleTime = this.countdownSeconds[index] * 1000;
              this.countdownSeconds[index]--;

              // 解决表格单元格内倒计时不动问题
              // 强行用 getElementById 获取对应 DOM 元素，强行修改其 innerHTML
              const deadlineTime = this.getNowTimeDay(handleTime);
              this.$nextTick(() => {
                const countdownEl = document.getElementById(
                  'appeal-countdown-timer-' + item.complaint_id
                );
                if (countdownEl) {
                  countdownEl.innerHTML = deadlineTime;
                }
              });

              if (handleTime <= 0) {
                clearInterval(this.timers[index]);
                this.timers[index] = null;
                this.countdownSeconds[index] = null;
              }
            }, 1000);
          }
        });
      }
    },
    //获取当前时间并计算时间差(时分秒)
    getNowTimeDay(time) {
      var date3 = Number(time);
      if (date3 <= 0) {
        return '已超时';
      } else {
        //计算出小时数
        var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(date3 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        let surplusTime = hours + '时' + minutes + '分' + seconds + '秒';
        return surplusTime;
      }
    },

    /**
     * 页面触发
     */
    // 点击分页按钮
    goPage(p, pageSize) {
      this.$emit('change-page', Number(p), pageSize);
    },
    handleClickDownload(row) {
      // 下载资料 #todo
      let link = row?.attachment?.split(',');
      if (link && link.length > 0) {
        link.forEach((item) => {
          this.down(item, item);
        });
      }
      console.log('click download', link);
    },
    // 点击“违规跳转”
    goIllegallist(row) {
      if (
        (!row.is_violate_text && row.is_violate_text == '') ||
        row.is_violate == '2'
      )
        return;
      // 违规了
      if (row.is_violate == '1') {
        this.$router.push('/complaints/illegallist');
      }
    },
    // 点击查看详情按钮
    handleClickDetail(row) {
      // 如果是评价方面的举报/投诉
      if (row.cate_id == '16' || row.cate_id == '17') {
        window.open(`/complaints/report/detail/${row.complaint_id}`);
        // this.$router.push('/complaints/report/detail/' + row.complaint_id);
      } else {
        window.open(`/complaints/detail/${row.complaint_id}`);
        // this.$router.push('/complaints/detail/' + row.complaint_id);
      }
    },
    // 点击申诉
    handleClickAppeal(row) {
      this.currComplaintId = row?.complaint_id;
      this.showAppealDialog = true;
    },
    // 关闭申诉弹窗
    handleCloseAppealDialog() {
      this.currComplaintId = '';
      this.showAppealDialog = false;
    },
    // 申诉弹窗确认按钮
    handleSubmitAppealDialog() {
      this.handleCloseAppealDialog();
      this.upData();
    },
    // 点击评价按钮
    handleClickReview(row) {
      this.currComplaintId = row?.complaint_id;
      this.showReviewDialog = true;
    },
    // 关闭评价弹框
    handleCloseReviewDialog() {
      this.currComplaintId = '';
      this.showReviewDialog = false;
    },
    // 评价确认按钮
    handleSubmitReviewDialog() {
      this.handleCloseReviewDialog();
      this.upData();
    },
    // 点击“二次投诉”
    handleClickSecondReport(row) {
      this.currComplaintInfo = row;
      this.showSecondReportDialog = true;
    },
    // 二次投诉弹窗关闭
    handleCloseSecondReportDialog() {
      this.showSecondReportDialog = false;
      this.currComplaintInfo = null;
    },
    // 二次投诉弹窗确认按钮
    handleSubmitSecondReportDialog() {
      this.handleCloseSecondReportDialog();
      this.upData();
    },
    changeTab() {
      this.$emit('change-tab');
    },
    // 更新数据
    upData() {
      this.$emit('updata');
    },
  },
};
