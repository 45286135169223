export const language = {
  // 英文
  enObj: {
    stepOne: '1.Goods Details', // 第一步
    stepTwo: '2.Fill in and verify order ', // 第二步
    stepThree: '3.submitted order', // 第三步
    selectAddress: 'Select shipping address', // 选择收获地址
    default: 'Default', // 默认地址
    modify: 'modify', // 修改
    newAddress: 'New address', // 新地址
    temAddress: 'Temporary Address', // 临时地址
    manageAddress: 'Manage addresses', // 管理收货地址
    orderTitle: 'Confirm order information',
    goodsInformation: 'Goods information', // 商品信息
    attributes: 'Attributes',
    price: 'Unit-price',
    num: 'Number',
    store: 'Store',
    warehouse: 'Warehouse',
    orderType: 'Order type',
    goodId: 'Goods ID',
    packSize: 'Package size',
    packWeight: 'Package weight',
    goodPrice: 'Price：$',
    operaFee: 'Operating fee',
    sampleOder: 'Sample order',
    logInfo: 'Logistics company ',
    logPar: 'Logistics partners',
    tranTime: 'Transit time limit',
    transPort: 'Transportation time',
    traceable: 'Is it traceable',
    logFee: 'Logistics expenses',
    insuranceFee: 'Insurance fee',
    deliveDate: 'Expected delivery date',
    opera: 'Opera',
    symbol: '$',
    logInformation: 'Logistics information',
    platformSide: 'platform side',
    supplier: 'supplier',
    day: 'day',
    nodata: 'no data',
    allLog: 'All logistics information',
    collapse: 'Collapse',
    totalAmount: 'Total product amount',
    logExpenses: 'Logistics expenses',
    operaFee: 'Operating fee',
    totalPay: 'Total amount payable', // 应付总额
    delivery: 'Delivery to', // 配送至
    consignee: 'Consignee',
    pendingPay: 'Add Pending Payment',
    summit: 'Submit Order',
    sendTo: 'send to', // 发往
    // 新建地址英文翻译
    addressHolder: 'Please enter detailed address information',
    addressInfo: 'Address information',
    deatilAddress: 'Detailed address',
    postalCode: 'Postal code',
    consigneeName: 'Consignee name',
    phoneNumber: 'Phone number',
    node: 'Notes',
    setDefault: 'Set as default shipping address',
    postaHolder: 'Please fill in the postal code',
    consigHolder: "Please fill in the recipient's name",
    phoneNumberHolder: 'Please enter your phone number',
    areaCodeHolder: 'Please enter the area code',
    save: 'save',
    logisticsWareFees: 'Warehouse Delivery Fee:',
    textrecognition: 'Text Recognition',
  },
  // 中文
  chObj: {
    stepOne: '1.商品详情', // 第一步
    stepTwo: '2.填写核对订单信息 ', // 第二步
    stepThree: '3.成功提交订单', // 第三步
    selectAddress: '选择收货地址', // 选择收获地址
    default: '默认地址', // 默认地址
    modify: '修改', // 修改
    newAddress: '新地址', // 新地址
    temAddress: '使用临时地址', // 临时地址
    manageAddress: '管理收货地址',
    orderTitle: '确认订单信息',
    goodsInformation: '商品信息',
    attributes: '商品属性',
    price: '单价',
    num: '数量',
    store: '店铺',
    warehouse: '仓库信息',
    orderType: '订单类型',
    goodId: '商品ID',
    packSize: '包装尺寸',
    packWeight: '包装重量',
    goodPrice: '价格：￥',
    operaFee: '操作费',
    sampleOder: '普通单',
    logInfo: '物流公司',
    logPar: '物流合作方',
    tranTime: '中转时效',
    transPort: '运输时效',
    traceable: '是否可追踪',
    logFee: '物流费',
    insuranceFee: '保价费',
    deliveDate: '预计到货日期',
    opera: '操作',
    symbol: '￥',
    logInformation: '物流信息',
    platformSide: '平台端',
    supplier: '供应商',
    day: '天',
    nodata: '暂无数据',
    allLog: '所有物流信息',
    collapse: '收起物流信息',
    totalAmount: '总商品金额',
    logExpenses: '物流费',
    operaFee: '操作费',
    totalPay: '应付总额',
    delivery: '配送至',
    consignee: '收货人',
    pendingPay: '加入待付款',
    summit: '提交订单',
    sendTo: '发往', // 发往
    // 新建地址中文
    addressHolder:
      '请输入详细地址信息，如道路、门牌号 、小区、楼栋号单元等信息',
    addressInfo: '地址信息',
    deatilAddress: '详细地址',
    postalCode: '邮政编码',
    consigneeName: '收货人姓名',
    phoneNumber: '联系方式',
    node: '备注',
    setDefault: '设置为默认收货地址',
    postaHolder: '请填写邮政编码',
    consigHolder: '请填写收货人姓名',
    areaCodeHolder: '请输入区号',
    phoneNumberHolder: '请输入号码',
    save: '保存',
    logisticsWareFees: '到仓物流费:',
    textrecognition: '文本识别',
  },
};
