
import { getInfo, cancelBrand } from '@/api/client-api/mine/mineapi.js';
export default {
  data() {
    return {
      // 品牌授权详情
      detailObj: {},
      shopLink: [],
      currntStatus: '', // 当前状态
      statusTetx: {
        0: '待审核',
        1: '已授权',
        2: '已驳回',
        3: '已取消',
        4: '已过期',
        5: '待审核', // 续期待审核
        6: '已取消', // 续期已取消
        7: '已驳回', // 续期已驳回
      },
      isEvaVisible: false, //确认弹框
    };
  },
  mounted() {
    this.getDetail(this.$route.params.id);
  },
  methods: {
    getDetail(id) {
      let option = {
        id,
      };
      getInfo(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.detailObj = res?.data?.list;
            this.shopLink = res?.data?.list?.shop_link;
            this.currntStatus = res?.data?.list?.status;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 打开链接
    openLink(link) {
      let flag = /https?:\/\//.test(link);
      if (!flag) return this.$message.warning('链接无效');
      window.open(link, '_blank');
    },
    // 返回
    goback() {
      this.$router.go(-1);
    },
    // 续期申请
    renewalApplication() {
      this.$router.push(`/mine/nweEmpower/2/${this.detailObj.id}`);
    },
    // 再次申请
    reapply() {
      this.$router.push(`/mine/nweEmpower/3/${this.detailObj.id}`);
    },
    // 取消申请
    cancelApply() {
      this.isEvaVisible = true;
    },
    // 关闭确认弹框
    closeDelEvaDialog() {
      this.isEvaVisible = false;
    },
    // 取消授权
    revoke() {
      let option = {
        id: this.detailObj.id,
      };
      cancelBrand(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.isEvaVisible = false;
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.goback();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
