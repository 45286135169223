
import GoodListItem from './GoodListItem.vue';

export default {
  components: { GoodListItem },
  props: {
    status: {
      type: String,
      default: '--',
    },
    orderId: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '--',
    },
    intro: {
      type: String,
      default: '--',
    },
    price: {
      type: String,
      default: '暂无报价',
    },
    imgUrl: {
      type: String,
      default: '',
    },
    itemId: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClickSend() {
      this.$emit('send');
    },
    // 跳转订单详情
    toOrderDetail() {
      // #memo 限制的账号不给发送请求或跳转页面（限制：冻结 or 关闭）
      if (this.hasAccountLimited()) {
        return;
      }
      if (this.orderId) {
        let id = this.orderId;
        let routeData = this.$router.resolve({
          path: '/trade/orderdetails',
          query: {
            item_id: id,
          },
        });

        // 打开新页面
        window.open(routeData.href, '_blank');
      }
    },
  },
};
