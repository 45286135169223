
export default {
  data() {
    return {
      searchObj: {
        categoryVal: [], //类目数据
        key: '', //下拉加输入框的key值
        value: '', //搜索的关键字
        priceMin: '', //价格筛选最小值
        priceMax: '', //价格筛选最大值
        inventoryId: '', //库存状态
      }, //搜索数据
      optionData: [
        { label: '商品标题', value: 'title' },
        { label: '商品ID', value: 'item_id' },
        { label: '供应商ID', value: 'supplier_id' },
        { label: '仓库名称', value: 'war_name' },
      ], //下拉搜索框的下拉数据
      inventoryOptions: [
        { label: '正常', value: '1' },
        { label: '即将售罄', value: '2' },
        { label: '已售罄', value: '3' },
      ],
      priceShow: false, //控制输入框显示隐藏
    };
  },
  props: {
    activeName: {
      type: [Number, String],
      default: '1',
    },
    secondTab: {
      type: [Number, String],
      default: '-1',
    }, //二级标签
    // 需要搜索的商品ID
    searchGoodsID: {
      type: [Number, String],
      default: '',
    },
  },
  computed: {
    // 根据传入的商品id渲染组件
    returnShow() {
      if (this.searchGoodsID == '') {
        return {
          selectVal: '',
          inputVal: '',
        };
      } else {
        return {
          selectVal: 'item_id',
          inputVal: this.searchGoodsID,
        };
      }
    },
  },
  mounted() {
    if (process.client) {
      document.addEventListener('click', this.bodyCloseMenus);
    }
  },
  beforeDestroy() {
    if (process.client) {
      document.removeEventListener('click', this.bodyCloseMenus);
    }
  },
  methods: {
    // 输入框限制
    inputChange(type) {
      this.searchObj[type] = this.searchObj[type]
        .replace(/[^\d.]/g, '') // 数字或者小数点
        .replace(/^0+(\d)/, '$1') // 以0开头后面为数字则过滤掉0
        .replace(/^\./, '0.') // 第一位是小数点则替换成 0.
        .replace(/^(\d{6})\d+|((\.\d{2})\d+)$/g, '$1$3');
    },
    // 价格搜索清空按钮事件
    rePrice() {
      this.searchObj.priceMin = '';
      this.searchObj.priceMax = '';
      this.priceShow = false;
    },
    // 价格搜索确定按钮
    cofigBtn() {
      // this.priceShow = !this.priceShow
      if (
        parseFloat(this.searchObj.priceMin) >
        parseFloat(this.searchObj.priceMax)
      ) {
        this.$message.warning('搜索价格最小值不要大于最大值');
        return;
      }
      this.priceShow = !this.priceShow;
      this.SearchFor();
    },
    // 点击空白关闭价格弹框
    bodyCloseMenus(e) {
      const self = this;
      if (this.$refs.inputBox && !this.$refs.inputBox.contains(e.target)) {
        if (self.isSelect === true) {
          self.isSelect = false;
        }
      }
    },
    // 类目搜索组件搜索方法
    categoryOptionFun() {
      this.SearchFor();
    },
    /**
     * 下拉搜索框的搜索方法
     * @param {String} selectVaule 下拉框的值
     * @param {String} inputValue 搜索框的值
     */
    xmSelectData(selectVaule, inputValue) {
      this.searchObj.key = selectVaule;
      this.searchObj.value = inputValue;
      this.SearchFor();
    },
    // 重置筛选
    resetData() {
      this.searchObj = {
        categoryVal: [], //类目数据
        key: '', //下拉加输入框的key值
        value: '', //搜索的关键字
        priceMin: '', //价格筛选最小值
        priceMax: '', //价格筛选最大值
        inventoryId: '', //库存状态
        refilter: true, //重置筛选
      };
      if (this.$refs.XmSelect) {
        this.$refs.XmSelect.reVal();
      }
    },
    // 传出去的方法
    SearchFor() {
      // 需要传出去的值
      this.$emit('searchData', this.searchObj);
    },
    // 控制库存搜索的显示隐藏
    hasInventorySearch() {
      if (this.secondTab === '1') {
        return true;
      }
      if (this.activeName === '2') {
        return false;
      }
      if (this.activeName === '3') {
        return false;
      }
    },
  },
};
