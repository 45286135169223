
export default {
  name: 'SelectCategory',
  props: {
    value: {},
    checkStrictly: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String,Number],
      default: 255,
    },
  },
  data() {
    return {
      categoryList: [],
      keyindex: 0,
    };
  },
  mounted() {
    this.getCategory();
    //点击文本就让它自动点击前面的input就可以触发选择但是因组件阻止了冒泡，暂时想不到好方法来触发。
    //这种比较耗性能，暂时想不到其他的，能实现效果了
    setInterval(function () {
      document.querySelectorAll('.el-cascader-node__label').forEach(el => {
        el.onclick = function () {
          if (this.previousElementSibling) this.previousElementSibling.click();
        };
      });
    }, 1000);
  },
  computed: {
    cascaderValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    categoryOptions() {
      return {
        label: 'name',
        value: 'supplier_class_id',
        children: '_child',
        checkStrictly: this.checkStrictly,
      };
    },
  },
  methods: {
    //获取类目
    getCategory() {
      let list =JSON.parse(JSON.stringify(this.$store.state.category.categoryData));
      let options = {
        level: '1',
        name: '全部类目',
        pid: '0',
      };
      for (let i = 0; i < list.length; i++) {
        if (list[i].name != '全部类目') {
          list.unshift(options);
          break
        } else {
          break
        }
      }
      this.categoryList = list;
    },
    valchang(val) {
      if (!val) {
        this.keyindex++;
      }
    },
    changeFunc(data) {
      this.$refs.cascader.dropDownVisible = false;
      this.$emit('change', data);
    },
  },
};
