
import { getStates, getCity, getRegions } from '@/api/client-api/api.js';
export default {
  props: {
    // 回显的地址数据
    areaData: {
      type: Object,
      default: () => {},
    },
    // 显示在选择器上的占位字符
    placeholderKey: {
      type: String,
      default: '请选择',
    },
    // 当前省市区选择器的国家，默认为中国
    targetCountryId: {
      type: String,
      default: '44',
    },
    // 是否实时更新，每选择一次地址就更新父组件数据
    isLiveUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDropdown: false,
      tabName: '',

      province_list: [],
      city_list: [],
      areaList: [], // 区数据
      /**
       * 需要传出的数据：
       */
      addressData: {
        province: '',
        province_id: '',
        city: '',
        city_id: '',
        area: '',
        area_id: '',
      },
    };
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      if (!this.addressData) {
        return '';
      }
      const addr = `${this.addressData.province}${
        this.addressData.city?.length > 0 ? '/' : ''
      }${this.addressData.city}${
        this.addressData.area && this.addressData.area?.length > 0 ? '/' : ''
      }${this.addressData.area}`;
      return addr;
    },
  },
  methods: {
    //点击 x 清空选择地址
    clearAddressData() {
      Object.keys(this.addressData).forEach((item) => {
        this.addressData[item] = '';
      });
      this.changeTab('province');
      this.$emit('update', this.addressData);
    },
    // 切换下拉框标签页
    changeTab(val) {
      this.tabName = val;
    },
    // 关闭选择器下拉框
    closeDropdown() {
      this.$emit('update', this.addressData, true);
      // console.log('关闭下拉框', this.addressData);
      this.showDropdown = false;
      this.$refs?.selector?.blur(); // 选择器的尖角符号向下
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 1：省份菜单；2：城市菜单；3：区菜单
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      if (index == 1) {
        this.city_list = [];
        this.areaList = []; // 将区数据置空
        // 选择省份
        this.addressData.province = item.cname;
        this.addressData.province_id = item.id;
        // 清空状态
        this.addressData.city = '';
        this.addressData.city_id = '';
        this.addressData.area = '';
        this.addressData.area_id = '';

        if (this.isLiveUpdate) {
          this.$emit('update', this.addressData, false);
        }
        this.getCityList();
      } else if (index == 2) {
        this.areaList = []; // 将区数据置空
        // 选择城市
        this.addressData.city = item.cname;
        this.addressData.city_id = item.id;
        // 清空状态
        this.addressData.area = '';
        this.addressData.area_id = '';

        if (this.isLiveUpdate) {
          this.$emit('update', this.addressData, false);
        }

        // 有区
        this.getAreaList(this.addressData.city_id);
      } else if (index == 3) {
        this.addressData.area = item.cname;
        this.addressData.area_id = item.id;
        // 可以传出数据了
        this.closeDropdown();
      }
    },
    // 获取省份数据
    getProvinceList() {
      let that = this;
      return new Promise(async (resolve, reject) => {
        const options = {
          id: this.targetCountryId,
        };
        try {
          let res = await getStates(options);
          if (res.errcode == 0) {
            that.province_list = res.data;
            that.changeTab('province');
            resolve(res.data);
            return;
          }
          this.$message({ type: 'warning', message: res.msg });
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    // 获取城市数据
    getCityList() {
      return new Promise(async (resolve, reject) => {
        const options = {
          id: this.addressData.province_id,
          type: '',
        };
        let that = this;
        try {
          let res = await getCity(options);
          if (res.errcode == 0) {
            that.city_list = res.data;
            if (that.city_list.length > 0) {
              that.changeTab('city');
              resolve(res.data);
            } else {
              that.closeDropdown();
            }
            return;
          }
          this.$message({ type: 'warning', message: res.msg });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    // 获取区的数据
    getAreaList(id) {
      return new Promise(async (resolve, reject) => {
        const options = { id };
        try {
          let res = await getRegions(options);
          if (res.errcode == 0) {
            this.areaList = res.data; // 获取到区数据
            if (this.areaList.length > 0) {
              this.changeTab('area');
              resolve(res.data);
            } else {
              this.closeDropdown();
            }
            resolve(res.data);
          }
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        province: '',
        province_id: '',
        city: '',
        city_id: '',
        area: '',
        area_id: '',
      };
      this.tabName = '';
      this.province_list = [];
      this.city_list = [];
      this.areaList = [];
    },
  },
  async mounted() {
    await this.getProvinceList();
    this.changeTab('province');

    this.addressData.province = this.areaData?.province || '';
    this.addressData.province_id = this.areaData?.province_id || '';
    this.addressData.city = this.areaData?.city || '';
    this.addressData.city_id = this.areaData?.city_id || '';
    this.addressData.area = this.areaData?.area || '';
    this.addressData.area_id = this.areaData?.area_id || '';

    if (this.addressData.city_id && this.addressData.city_id != '0') {
      await this.getCityList();
    }
    if (this.addressData.area_id && this.addressData.area_id != '0') {
      await this.getAreaList(this.addressData.city_id);
    }
  },
  beforeDestroy() {
    this.resetData();
  },
};
