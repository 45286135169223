// 违规列表
export const violationsTableConfig = [
  {
    label: '违规编号',
    prop: 'id',
    width: '152px',
  },
  {
    label: '违规大类',
    prop: 'violation_category_text',
    width: '104px',
  },
  {
    label: '违规小类',
    prop: 'violation_subcategory_text',
    width: '104px',
  },
  {
    label: '次数',
    prop: 'number',
    width: '48px',
  },
  {
    label: '处罚方式',
    prop: 'punish_describe',
    width: '169px',
    isSlot: true,
  },
  {
    label: '处罚生效时间',
    prop: 'punishment_effective_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '处罚失效时间',
    prop: 'punishment_expiry_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '违规失效时间',
    prop: 'violation_expiry_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '申诉状态',
    prop: 'appeal_status_text',
    width: '90px',
  },
  {
    label: '处罚状态',
    prop: 'penalty_status_text',
    width: '90px',
  },
  {
    label: '违规状态',
    prop: 'violation_status_text',
    width: '90px',
  },
  {
    label: '申诉时间',
    prop: 'appeal_time',
    width: '110px',
    isSlot: true,
  },
  {
    label: '申诉剩余时间',
    prop: 'appeal_second',
    width: '126px',
    isSlot: true,
  },
  {
    label: '操作',
    prop: 'operations',
    width: '92px',
    fixed: 'right',
    isSlot: true,
  },
];
